import { Field, Form, Formik } from "formik";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import * as yup from "yup";

import { nameofFactory } from "../../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { requiredTitleStringSchema } from "../../../../services/validation/stringSchemas";
import CancelButton from "../buttons/cancelButton";
import { FormikCheckboxInputPlain } from "../forms/formik/formikCheckboxInputPlain";
import { FormikColorInput } from "../forms/formik/formikColorPicker";
import FormikDebugInfo from "../forms/formik/formikDebugInfo";
import { FormikHtmlInput } from "../forms/formik/formikHtmlInput";
import { FormikSelectInput } from "../forms/formik/formikSelectInput";
import { FormikTextInput } from "../forms/formik/formikTextInput";
import FormikValidationSummary from "../forms/formik/formikValidationSummary";
import { BaseModalProps } from "../modals/baseModalProps";

import { EdgeTypesArray, FlowItem, NodeOrEdgeData, NodeTypesArray } from "./models/pbd-flow-utils";

const nameof = nameofFactory<NodeOrEdgeData>();

const getValidationSchema = (t: TFunction, kind: FlowItem) => {
  const ValidationSchema: yup.ObjectSchema<NodeOrEdgeData> = yup.object({
    label: kind == "Node" ? requiredTitleStringSchema(t, 1024) : yup.string().notRequired(),
    kind: yup.mixed<FlowItem>().oneOf(["Node", "Edge"]).required(),
    id: yup.string().required(),
    backgroundColor: yup.string(),
    borderColor: yup.string(),
    fontColor: yup.string(),
    type: yup.string().notRequired(),
    isAnimated: yup.boolean().notRequired(),
  });
  return ValidationSchema;
};

interface IProps extends BaseModalProps {
  selectedObject: NodeOrEdgeData;
  onEdit: (obj: NodeOrEdgeData) => Promise<void>;
  onDelete: (obj: NodeOrEdgeData) => void;
}

const EditNodeOrEdgeModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { selectedObject, modal, toggle, onEdit, onDelete } = props;

  const initialValues: NodeOrEdgeData = {
    id: selectedObject.id,
    label: selectedObject.label,
    type: selectedObject.type,
    backgroundColor: selectedObject.backgroundColor,
    borderColor: selectedObject.borderColor,
    fontColor: selectedObject.fontColor,
    kind: selectedObject.kind,
    isAnimated: selectedObject.isAnimated ?? false,
  };

  const submitter = useFormikAPISubmitter<NodeOrEdgeData>(
    (val) => onEdit(val),
    [onEdit],
    () => toggle(),
  );

  return (
    <Modal isOpen={modal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        {t("Edit")} {selectedObject.type}
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={initialValues}
          onSubmit={submitter}
          validationSchema={getValidationSchema(t, selectedObject.kind)}
        >
          {(formikBag) => (
            <Form>
              <FormikDebugInfo formikBag={formikBag} />
              {formikBag.values.kind == "Node" && (
                <FormGroup>
                  <Label for={nameof("type")}>{t("Type")}</Label>
                  <Field
                    component={FormikSelectInput}
                    id={nameof("type")}
                    name={nameof("type")}
                    formText={t("Custom input types include formatting")}
                  >
                    {NodeTypesArray.map((x) => (
                      <option key={x} value={x} defaultValue={formikBag.initialValues.type}>
                        {t(x)}
                      </option>
                    ))}
                  </Field>
                </FormGroup>
              )}

              {formikBag.values.kind == "Edge" && (
                <>
                  <FormGroup>
                    <Label for={nameof("type")}>{t("Type")}</Label>
                    <Field
                      component={FormikSelectInput}
                      id={nameof("type")}
                      name={nameof("type")}
                      formText={t("Custom input types include formatting")}
                    >
                      {EdgeTypesArray.map((x) => (
                        <option key={x} value={x} defaultValue={formikBag.initialValues.type}>
                          {t(x)}
                        </option>
                      ))}
                    </Field>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Field component={FormikCheckboxInputPlain} name={nameof("isAnimated")} id="isAnimated" />
                      {t("Is animated")}
                    </Label>
                  </FormGroup>
                </>
              )}

              <FormGroup>
                <Label for={nameof("label")}>{t("Label")}</Label>
                {selectedObject.kind == "Node" && formikBag.values.type?.startsWith("custom") ? (
                  <Field name={nameof("label")} component={FormikHtmlInput} />
                ) : (
                  <Field name={nameof("label")} component={FormikTextInput} />
                )}
              </FormGroup>
              <FormGroup>
                <Label for={nameof("fontColor")}>{t("Font color")}</Label>
                <Field name={nameof("fontColor")} component={FormikColorInput} />
              </FormGroup>
              <FormGroup>
                <Label for={nameof("borderColor")}>{t("Border color")}</Label>
                <Field name={nameof("borderColor")} component={FormikColorInput} />
              </FormGroup>
              <FormGroup>
                <Label for={nameof("backgroundColor")}>{t("Background color")}</Label>
                <Field name={nameof("backgroundColor")} component={FormikColorInput} />
              </FormGroup>
              <FormGroup>
                <CancelButton onClick={toggle} />
                <Button color="danger" className="me-1" onClick={() => onDelete(selectedObject)}>
                  {t("Delete")}
                </Button>
                <Button color="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
                  {t("Save")}
                </Button>
              </FormGroup>
              <FormikValidationSummary formikBag={formikBag} />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default EditNodeOrEdgeModal;
