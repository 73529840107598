import React from "react";
import { Badge, Button, Card, Form, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useSWR from "swr";

import {
  ArticleChangelogSettings,
  ArticleFeedbackSettings,
  ArticleFolderSettings,
  ArticleLayoutSettings,
  ArticleSettings,
  ArticleWatermarkSettings,
  IArticleActionSetting,
  IArticleSettings,
  IModuleSettingDTO,
  PbdModule,
  SettingType,
} from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../pbdServices/services/service-context";

import { DefaultArticleActionSettings } from "../../../Models/Articles/ArticleActionSetting";
import { wrapApiCallWithToast } from "../../../pbdServices/services/Api/api-wrapper";
import FeatureFlagService from "../../../pbdServices/services/FeatureFlags/featureFlagService";
import ArticleChangelogForm from "../components/articleConfiguration/articleChangelogForm";
import ArticleFeedbackForm from "../components/articleConfiguration/articleFeedbackForm";
import ArticleFolderForm from "../components/articleConfiguration/articleFolderForm";
import ArticleLayoutForm from "../components/articleConfiguration/articleLayoutForm";
import ArticleWatermarkForm from "../components/articleConfiguration/articleWatermarkForm";

const ArticleConfigurationPage = () => {
  const { t } = useTranslation();
  const { pbdModule } = useParams<{ pbdModule: PbdModule }>() as { pbdModule: PbdModule };
  const [submitting, setSubmitting] = React.useState(false);
  const { moduleSettingsService } = useAPIServices();
  const { moduleSettingsApi } = useAPIs();
  const [articleActionSetting, setArticleActionSetting] = React.useState<IArticleActionSetting>();

  const { data: articleSettings, mutate } = useSWR(
    `/api/moduleSettings/${pbdModule}/articles`,
    () => moduleSettingsApi.getArticleSetting(pbdModule),
    {
      revalidateOnFocus: false,
    },
  );

  React.useEffect(() => {
    if (articleSettings) {
      setArticleActionSetting(articleSettings.articleActionSettings);
    }
  }, [articleSettings]);

  const save = async (settingsType: SettingType) => {
    setSubmitting(true);
    let resp: Promise<IModuleSettingDTO>;
    if (settingsType == SettingType.ArticleActionSetting) {
      resp = moduleSettingsService.save(pbdModule, settingsType, articleActionSetting);
    } else {
      throw Error("Invalid settings");
    }
    await wrapApiCallWithToast(() => resp);
    setSubmitting(false);
  };

  const handleSubmit = (dto: IArticleSettings) =>
    moduleSettingsApi.updateArticleSettings(pbdModule, new ArticleSettings(dto));

  const handleSuccess = () => {
    mutate();
  };

  return (
    <>
      <h2>{t(pbdModule)}</h2>
      <hr />
      <Card className="mb-3">
        <Card.Body>
          {articleSettings?.articleLayoutSettings && (
            <ArticleLayoutForm
              itemToUpdate={articleSettings.articleLayoutSettings}
              onSubmit={(x) =>
                handleSubmit({ ...articleSettings, articleLayoutSettings: new ArticleLayoutSettings(x) })
              }
              onSuccess={handleSuccess}
            />
          )}
          {articleSettings?.articleFeedbackSettings && (
            <ArticleFeedbackForm
              itemToUpdate={articleSettings.articleFeedbackSettings}
              onSubmit={(x) =>
                handleSubmit({ ...articleSettings, articleFeedbackSettings: new ArticleFeedbackSettings(x) })
              }
              onSuccess={handleSuccess}
            />
          )}
          {articleSettings?.articleWatermarkSettings && (
            <ArticleWatermarkForm
              itemToUpdate={articleSettings.articleWatermarkSettings}
              onSubmit={(x) =>
                handleSubmit({ ...articleSettings, articleWatermarkSettings: new ArticleWatermarkSettings(x) })
              }
              onSuccess={handleSuccess}
            />
          )}
          {articleSettings?.articleChangelogSettings && (
            <ArticleChangelogForm
              itemToUpdate={articleSettings.articleChangelogSettings}
              onSubmit={(x) =>
                handleSubmit({ ...articleSettings, articleChangelogSettings: new ArticleChangelogSettings(x) })
              }
              onSuccess={handleSuccess}
            />
          )}
          {articleSettings?.articleFolderSettings && (
            <ArticleFolderForm
              itemToUpdate={articleSettings.articleFolderSettings}
              onSubmit={(x) => {
                return handleSubmit({ ...articleSettings, articleFolderSettings: new ArticleFolderSettings(x) });
              }}
              onSuccess={handleSuccess}
            />
          )}

          {/* TODO: Refactor to extra form; attach submit to normal submit action */}
          {FeatureFlagService.isDocumentManagementTaskAvailable() && articleActionSetting && (
            <>
              <h4>{t("Actions")}</h4>
              <hr />
              <ListGroup className="mb-3">
                <ListGroup.Item>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="switch"
                      id={SettingType.ArticleActionSetting}
                      checked={articleActionSetting.createTaskOnPublishNewRevision}
                      onChange={(y) =>
                        setArticleActionSetting({
                          ...articleActionSetting,
                          createTaskOnPublishNewRevision: y.target.checked,
                        })
                      }
                      label={
                        <>
                          {t("Create tasks on publish of a new revision")} <Badge bg="info">Experimental</Badge>
                        </>
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="taskTitle">
                    <Form.Label>{t("Task title")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="taskTitle"
                      value={articleActionSetting.taskTitle}
                      onChange={(e) =>
                        setArticleActionSetting({
                          ...articleActionSetting,
                          taskTitle: e.target.value,
                        })
                      }
                      placeholder={DefaultArticleActionSettings.taskTitle}
                    />
                  </Form.Group>
                </ListGroup.Item>
              </ListGroup>
              <Form.Group className="mb-3">
                <Button disabled={submitting} onClick={() => save(SettingType.ArticleActionSetting)} variant="primary">
                  {t("Save")}
                </Button>
              </Form.Group>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default ArticleConfigurationPage;
