import { DateTime } from "luxon";

import { IAuditDTO, IAuditRequirementDTO, PbdStatus } from "@generatedCode/pbd-core/pbd-core-api";

import { IBaseDTO } from "../../../Models/BaseClasses/BaseDTO";

export interface IAuditMatrixDTO {
  headings: string[];
  auditRequirements: IAuditRequirementDTO[];
  content: IAuditMatrixContentDTO[];
}

export interface IAuditMatrixColumn extends IAuditRequirementDTO {
  fulfilled?: boolean;
}

export interface IAuditsWithRequirements extends IAuditDTO {
  requirements: IAuditRequirementDTO[];
}

export class AuditMatrixVM {
  constructor() {
    this.columns = [];
    this.rows = [];
    this.footer = [];
  }
  columns: IAuditMatrixColumn[];
  rows: IAuditsWithRequirements[];
  footer: IAuditMatrixColumn[];
}

export interface IAuditMatrixContentDTO extends IBaseDTO {
  status: PbdStatus;
  displayName: string;
  href: string;
  doneAt?: DateTime;
  statusOfRequirements: IStatusOfRequirementsDTO[];
}

export interface IStatusOfRequirementsDTO {
  nameOfRequirement: string;
  conducted: boolean;
  planned: boolean;
  requirementNeeded: boolean;
}
