import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import { IClaimDTO, ICustomField, IOrganisationDTO, PbdModule } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../pbdServices/services/service-context";

import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import { ClaimType } from "../../../Models/Enums/ClaimType";
import { ClaimRoutePaths } from "../../claims/claimRoutePaths";
import IdComponent from "../../shared/components/id/idComponent";
import TagsInTable from "../../shared/components/tags/tagsInTable";
import FormattedUserInput from "../../shared/components/text/formattedUserInput";
import { useTypedParams } from "../../shared/hooks/useTypedParams";
import DetailsPrintPageLayout from "../../shared/layouts/print/detailsPrintPageLayout";

const getClaimByDescription = (type: ClaimType, t: TFunction) => {
  if (type == ClaimType.Customer) {
    return t("Customer");
  }

  if (type == ClaimType.Supplier) {
    return t("Supplier");
  }

  return t("Claimed by");
};

function PrintClaimPage() {
  const { claimsApi, organisationsApi, settingsApi, customFieldsApi } = useAPIs();
  const { printService } = useAPIServices();
  const { id } = useTypedParams(["id"]);
  const { t } = useTranslation();
  const [claim, setClaim] = React.useState<IClaimDTO>();
  const [customFields, setCustomFields] = React.useState<ICustomField[]>([]);
  const [productOwner, setProductOwner] = React.useState<IOrganisationDTO>();
  const { data: dataArray } = useSWR(`api/claims/${id}/connectedElements`, () =>
    printService.getConnectedDataForPrint(id),
  );
  const { data: comments } = useSWR(`api/claims/${id}/comments`, () => claimsApi.getComments(Number(id)));
  const { data: attachments } = useSWR(`api/claims/${id}/files`, () => claimsApi.getConnectedFiles(Number(id)));
  const { data: costs } = useSWR(`api/claims/${id}/costs`, () => claimsApi.getCosts(Number(id)));

  React.useEffect(() => {
    async function getData() {
      const resp = await claimsApi.getById(Number(id));
      setClaim(resp);
      const claimCustomFields = await customFieldsApi.getCustomFieldsByModule(PbdModule.ClaimManagement);
      setCustomFields(claimCustomFields);
    }
    getData();
  }, [id]);

  React.useEffect(() => {
    async function getData() {
      const basicAdminData = await settingsApi.getBasicAdminData();
      if (basicAdminData.organisationId) {
        const org = await organisationsApi.getById(basicAdminData.organisationId);
        setProductOwner(org);
      }
    }
    getData();
  }, []);

  return (
    <DetailsPrintPageLayout
      availableOptions={[
        "showAttachments",
        "showCustomFields",
        "showQrCode",
        "showComments",
        "includeImages",
        "showCosts",
        "showProducts",
        "showDefects",
        "showTodos",
        "showClaims",
      ]}
      title={t("Claim report")}
      attachments={attachments}
      comments={comments}
      urlToDetailsPage={ClaimRoutePaths.EditPage.replace(":id", id)}
      item={claim}
      customFields={customFields}
      costs={costs}
      commentsApi={claimsApi}
      dataArray={dataArray}
    >
      {claim && (
        <div>
          <h5>
            {t("Title")}: {claim.title} <IdComponent id={claim.id} />
          </h5>
          <h5>
            {t("Sender")}: {productOwner && <small>{productOwner.title}</small>}{" "}
            {productOwner?.addresses &&
              productOwner.addresses.length > 0 &&
              productOwner.addresses.find((x) => x.isPrimary)?.fullAddress && (
                <small>
                  , {t("Address")}: {productOwner.addresses.find((x) => x.isPrimary)?.fullAddress}
                </small>
              )}
          </h5>
          <h5>
            {t("Responsible")}: <small>{claim.responsible?.fullName}</small>
          </h5>
          <h5>
            {t("Created")}:{" "}
            <small>
              <span className="me-1">{claim.createdBy?.fullName}</span>
              {DateTimeLuxonHelpers.convertUtcToDate(claim.createdAt)}
            </small>
          </h5>
          <h5>
            {t("Claim type")}: <small>{t(claim.claimType)}</small>
          </h5>
          <h5>
            {t("Claimed at")}: <small>{DateTimeLuxonHelpers.convertUtcToDate(claim.claimedAt)}</small>
          </h5>
          {claim.deadline && (
            <h5>
              {t("Deadline")}: <small>{DateTimeLuxonHelpers.convertUtcToDate(claim.deadline)}</small>
            </h5>
          )}
          {claim.organisation && (
            <h5>
              {getClaimByDescription(claim.claimType, t)}: <small>{claim.organisation.title}</small>
            </h5>
          )}
          {claim.claimedBy && (
            <h5>
              {t("Contact person")}: <small>{claim.claimedBy.fullName}</small>
            </h5>
          )}
          <h5 className="me-2">
            {t("Tags")}:{" "}
            <small>
              <TagsInTable tags={claim.tags} />
            </small>
          </h5>
          <h5>{t("Description")}</h5>
          <FormattedUserInput content={claim.description} />
        </div>
      )}
    </DetailsPrintPageLayout>
  );
}

export default PrintClaimPage;
