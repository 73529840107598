/* eslint-disable @typescript-eslint/naming-convention */
import { Editor } from "@tinymce/tinymce-react";
import { ErrorMessage, FieldHookConfig, FieldProps, useField } from "formik";
import i18next from "i18next";
import { upperFirst } from "lodash";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormFeedback, FormText } from "reactstrap";

import { ArticleStatus, IArticleDTO } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../../pbdServices/services/service-context";

import { GlobalQmBaseConstants } from "../../../../../Constants/GlobalQmBaseConstants";
import { ArticlesQueryParameters } from "../../../../../pbdServices/services/Articles/models/query-parameters";
import { ArticleRoutePaths } from "../../../../articles/articleRoutePaths";
import { EditorOptionsCustom, getBaseTinyConfig } from "../../../tinymce/baseTinyConfig";
import { IBaseFormElementProps } from "./baseFormElementProps";

interface AutocompleterItemSpec {
  type?: "autocompleteitem";
  value: string;
  text?: string;
  icon?: string;
}

const createLink = (item: IArticleDTO) => {
  return `<a data-id="${item.id}" data-type="article" title="#${item.id} Article" href="${ArticleRoutePaths.DetailsPage(
    item.module,
  ).replace(":id", item.id.toString())}#">${titleContent(item)}</a>`;
};

const titleContent = (item: IArticleDTO) => {
  let title = item.title;
  if (!item.isPublished) {
    title += ` (${i18next.t("Unpublished")})`;
  }
  if (item.isWithdrawn) {
    title += ` (${i18next.t("Withdrawn")})`;
  }
  return title;
};

const baseQuery: ArticlesQueryParameters = { take: 10, status: [ArticleStatus.Pending, ArticleStatus.Published] };

export const queryForArticle = async (
  pattern: string,
  api: (qs: ArticlesQueryParameters) => Promise<IArticleDTO[]>,
): Promise<AutocompleterItemSpec[]> => {
  const searchString = pattern.toLocaleLowerCase();
  let resp: IArticleDTO[];
  if (searchString.startsWith("doc:")) {
    resp = await api({
      q: searchString.replace("doc:", ""),
      ...baseQuery,
    });
  } else if (searchString.startsWith("blog:")) {
    resp = await api({ q: searchString.replace("blog:", ""), ...baseQuery });
  } else if (searchString.startsWith("wiki:")) {
    resp = await api({ q: searchString.replace("wiki:", ""), ...baseQuery });
  } else {
    resp = [];
  }

  const data: AutocompleterItemSpec[] = resp.map((x) => {
    return { value: createLink(x), text: titleContent(x), icon: "articles" };
  });
  return data;
};

interface HtmlEditorProps {
  urlToUpload?: string;
  /**This is used for sending mails with absolute urls instead of relative urls */
  disableRelativeUrls?: boolean;
  /**CustomId for text editor. The default id is the field name. In case there are multiple visible editors a custom id needs to be provided to show the text editor everywhere. */
  customTextEditorId?: string;
  formText?: string;
  isDisabled?: boolean;
  placeholder?: string;
}

export const FormikHtmlInput = ({
  field,
  form: { setFieldValue, setFieldTouched },
  urlToUpload,
  disableRelativeUrls,
  customTextEditorId,
  formText,
  placeholder,
  ...props
}: FieldProps<string> & HtmlEditorProps) => {
  const { articlesApi } = useAPIs();
  let toolbar = "bold italic underline forecolor | link | bullist numlist | code removeformat";
  if (urlToUpload) {
    toolbar = "bold italic underline forecolor | link image | bullist numlist | code removeformat";
  }
  let tinyMceInit: EditorOptionsCustom = {
    ...getBaseTinyConfig(i18next.language),
    toolbar: toolbar,
    plugins: ["link image code autolink lists charmap anchor paste autoresize table"],
    menubar: false,
    paste_data_images: urlToUpload ? true : false,
    images_upload_url: urlToUpload,
    autoResizeBottomMargin: 20,
    setup: function (editor) {
      editor.ui.registry.addIcon("articles", `📄`);
      editor.ui.registry.addIcon("clock", `🕐`);

      /* An autocompleter that allows you to insert special characters */
      editor.ui.registry.addAutocompleter("documents", {
        ch: "$",
        minChars: 5,
        columns: 1,
        fetch: (pattern: string) => queryForArticle(pattern, (qs) => articlesApi.getAllQuery(qs)),
        onAction: function (autocompleteApi, rng, value) {
          editor.selection.setRng(rng);
          editor.insertContent(value);
          autocompleteApi.hide();
        },
      });
    },
    placeholder,
  };
  if (disableRelativeUrls) {
    tinyMceInit = { ...tinyMceInit, relative_urls: false, remove_script_host: false };
  }

  return (
    <>
      <Editor
        id={customTextEditorId ?? field.name}
        textareaName={field.name}
        value={field.value}
        init={tinyMceInit}
        tinymceScriptSrc={GlobalQmBaseConstants.TinyMceUrl}
        onEditorChange={(e) => setFieldValue(field.name, e)}
        onBlur={(e) => setFieldTouched(field.name)}
        disabled={props.isDisabled}
      />
      <ErrorMessage component={FormFeedback} className="order-last" {...field} />
      {formText && <FormText color="muted">{formText}</FormText>}
    </>
  );
};

export function FormikHtmlInputGroup(
  props: FieldHookConfig<string> & IBaseFormElementProps & HtmlEditorProps & { children?: React.ReactNode },
) {
  const { formText, label, id, customTextEditorId, urlToUpload, placeholder, disableRelativeUrls } = props;
  const [field, meta, helpers] = useField(props);
  const { t } = useTranslation();
  const { setValue, setTouched } = helpers;

  const localId = id ?? props.name;

  // const invalid = meta.error != undefined && meta.touched;

  const { articlesApi } = useAPIs();
  let toolbar = "bold italic underline forecolor | link | bullist numlist | code removeformat";
  if (urlToUpload) {
    toolbar = "bold italic underline forecolor | link image | bullist numlist | code removeformat";
  }
  let tinyMceInit: EditorOptionsCustom = {
    ...getBaseTinyConfig(i18next.language),
    toolbar: toolbar,
    plugins: ["link image code autolink lists charmap anchor paste autoresize table"],
    menubar: false,
    paste_data_images: urlToUpload ? true : false,
    images_upload_url: urlToUpload,
    autoResizeBottomMargin: 20,
    setup: function (editor) {
      editor.ui.registry.addIcon("articles", `📄`);
      editor.ui.registry.addIcon("clock", `🕐`);

      /* An autocompleter that allows you to insert special characters */
      editor.ui.registry.addAutocompleter("documents", {
        ch: "$",
        minChars: 5,
        columns: 1,
        fetch: (pattern: string) => queryForArticle(pattern, (qs) => articlesApi.getAllQuery(qs)),
        onAction: function (autocompleteApi, rng, value) {
          editor.selection.setRng(rng);
          editor.insertContent(value);
          autocompleteApi.hide();
        },
      });
    },
    placeholder,
  };
  if (disableRelativeUrls) {
    tinyMceInit = { ...tinyMceInit, relative_urls: false, remove_script_host: false };
  }

  return (
    <Form.Group className="mb-3" controlId={localId}>
      <Form.Label>{label ?? t(upperFirst(props.name))}</Form.Label>
      {props.children}
      <Editor
        id={customTextEditorId ?? field.name}
        textareaName={field.name}
        value={field.value}
        init={tinyMceInit}
        tinymceScriptSrc={GlobalQmBaseConstants.TinyMceUrl}
        onEditorChange={(e) => setValue(e)}
        onBlur={(e) => setTouched(true)}
        disabled={props.isDisabled}
      />
      <ErrorMessage {...field}>
        {(msg) => (
          <Form.Control.Feedback type="invalid" className="order-last">
            {msg}
          </Form.Control.Feedback>
        )}
      </ErrorMessage>
      {formText && <Form.Text muted>{formText}</Form.Text>}
    </Form.Group>
  );
}
