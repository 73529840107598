import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { PbdStatus } from "@generatedCode/pbd-core/pbd-core-api";

import { StatusString } from "../../../../Models/Enums/Status";
import { qmBaseIcons } from "../icons/qmBaseIcons";

function getStatusTitle(t: TFunction, status: StatusString | PbdStatus): string {
  switch (status) {
    case PbdStatus.Open:
      return t("Open");
    case PbdStatus.InProgress:
      return t("InProgress");
    case PbdStatus.Completed:
      return t("Completed");
    case PbdStatus.Delayed:
      return t("Delayed");
    case PbdStatus.Planned:
      return t("Planned");
    case PbdStatus.Effective:
      return t("Effective");
    case PbdStatus.Pending:
      return t("Pending");
    case PbdStatus.Submitted:
      return t("Submitted");
    case PbdStatus.ReturnedToSender:
      return t("ReturnedToSender");
    case PbdStatus.Approved:
      return t("Approved");
    case PbdStatus.UnderReview:
      return t("UnderReview");
    case PbdStatus.SelfSignIn:
      return t("SelfSignIn");
    case PbdStatus.PendingInvitation:
      return t("PendingInvitation");
    case PbdStatus.PendingAttendanceConfirmation:
      return t("PendingAttendanceConfirmation");
    case PbdStatus.PendingRating:
      return t("PendingRating");
    case PbdStatus.Blocked:
      return t("Blocked");
    case PbdStatus.NotApproved:
      return t("NotApproved");
    case PbdStatus.Active:
      return t("Active");
    case PbdStatus.Inactive:
      return t("Inactive");
    case PbdStatus.Accepted:
      return t("Accepted");
    case PbdStatus.NotAccepted:
      return t("NotAccepted");
    case PbdStatus.Attended:
      return t("Attended");
    case PbdStatus.NotAttended:
      return t("NotAttended");
    case PbdStatus.Withdrawn:
      return t("Withdrawn");
    default:
      //TODO
      return t(status);
  }
}

interface IProps {
  status: StatusString | PbdStatus;
  className?: string;
}

function StatusIconComponent(props: IProps) {
  const { status, className } = props;
  const { t } = useTranslation();
  const title = getStatusTitle(t, status);

  switch (status) {
    case PbdStatus.Open:
      return <qmBaseIcons.CheckCircle color="muted" title={title} className={className} />;
    case PbdStatus.Completed:
    case PbdStatus.Accepted:
    case PbdStatus.Attended:
      return <qmBaseIcons.CheckCircleSolid color="success" title={title} className={className} />;
    case PbdStatus.InProgress:
      return <qmBaseIcons.InProgress color="primary" title={title} className={className} />;

    case PbdStatus.UnderReview:
      //TODO purple
      return <qmBaseIcons.UnderReview color="primary" title={title} className={className} />;
    case PbdStatus.Submitted:
      return <qmBaseIcons.Submitted color="primary" title={title} className={className} />;
    case PbdStatus.Approved:
      return <qmBaseIcons.Approved color="warning" title={title} className={className} />;

    case PbdStatus.ReturnedToSender:
      return <qmBaseIcons.ReturnedToSender color="danger" title={title} className={className} />;
    case PbdStatus.NotApproved:
    case PbdStatus.NotAccepted:
    case PbdStatus.NotAttended:
      return <qmBaseIcons.NotApproved color="danger" title={title} className={className} />;
    case PbdStatus.Withdrawn:
      return <qmBaseIcons.Withdrawn title={title} className={className} />;
    case PbdStatus.SelfSignIn:
      return <qmBaseIcons.SignUp title={title} className={className} />;
    default:
      return <qmBaseIcons.Unknown title={t("Unknown")} className={className} />;
  }
}

export default StatusIconComponent;
