import React from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";

import { ITimeIntervalDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { DateTimeLuxonHelpers } from "../../../../Helpers/DateTimeLuxonHelpers";

interface IItem {
  isRecurring: boolean;
  /**If the event is recurring the timeInterval must be set */
  monitoringInterval?: ITimeIntervalDTO;
  /**This requires a recurring event */
  useWarningTime?: boolean;
  warningTimeInterval?: ITimeIntervalDTO;
}

interface IProps {
  item: IItem;
}

const RecurringComponent: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { item } = props;
  return (
    <React.Fragment>
      {item.isRecurring && (
        <dl>
          <dt>{t("Surveillance interval")}</dt>
          <dd>
            {item.monitoringInterval
              ? DateTimeLuxonHelpers.getLocalizedTimeSpanString(item.monitoringInterval)
              : t("Not set")}
            {!item.monitoringInterval?.timeSpanISO && (
              <Badge color="danger">{t("Surveillance corrupted - Please update the interval")}</Badge>
            )}
          </dd>
          {item.useWarningTime && item.warningTimeInterval && (
            <React.Fragment>
              <dt>{t("Warning time")}</dt>
              <dd>
                {DateTimeLuxonHelpers.getLocalizedTimeSpanString(item.warningTimeInterval)}
                {!item.warningTimeInterval.timeSpanISO && (
                  <Badge color="danger">{t("Warning time corrupted - Please update the interval")}</Badge>
                )}
              </dd>
            </React.Fragment>
          )}
        </dl>
      )}
      {!item.isRecurring && (
        <dl>
          <dt>{t("Surveillance")}</dt>
          <dd>{t("Not marked as recurring")}</dd>
        </dl>
      )}
    </React.Fragment>
  );
};
export default RecurringComponent;
