import { IToDoDTO } from "@generatedCode/pbd-core/pbd-core-api";

const routePrefix = "/TaskManagement";

export class TaskManagementRoutePaths {
  public static HomePage = routePrefix;
  public static IndexPage = `${routePrefix}/Index`;
  public static SelectPage = `${routePrefix}/Select`;
  public static CreateProjectPage = `${routePrefix}/Projects/Create`;
  public static IndexProjectsPage = `${routePrefix}/Projects`;
  public static CreateToDoPage = `${routePrefix}/Todos/Create`;
  public static ChooseToDoTemplate = `${routePrefix}/Todos/Create/Choose`;
  public static EditProjectPage = `${routePrefix}/Projects/Edit/:id`;
  public static IndexTodoPage = `${routePrefix}/Todos`;
  public static CostsIndex = `${routePrefix}/Costs`;
  public static EditTodoPage = `${routePrefix}/Todos/Edit/:id`;
  public static EditTodoPageFn = (dto: Pick<IToDoDTO, "id">) =>
    `${routePrefix}/Todos/Edit/:id`.replace(":id", dto.id.toString());
}
