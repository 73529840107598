import { Button, Collapse, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { IApplicationUserDTO, ITenantDTO } from "@generatedCode/pbd-core/pbd-core-api";

import AvatarSpanWithName from "../../../shared/components/tenants/avatarSpanWithName";
import { useToggle } from "../../../shared/hooks/useToggle";

interface IProps {
  existingTenant: ITenantDTO;
  user: IApplicationUserDTO;
  mapAccountToTenant: () => Promise<void>;
  admins: ITenantDTO[] | undefined;
}

function ExistingTenantComponent(props: IProps) {
  const { t } = useTranslation();
  const { existingTenant, user, mapAccountToTenant, admins } = props;
  const [showAdmin, toggleShowAdmin] = useToggle();
  return (
    <>
      <p className="text-center">{t("Great, we have already found you in our database.")}</p>
      <div className="text-center mb-5">
        <AvatarSpanWithName tenant={existingTenant} includePopover={false} />
      </div>
      <div className="d-grid gap-2 mb-3">
        <Button variant="primary" onClick={mapAccountToTenant} size="lg">
          {t("Continue")}
        </Button>
      </div>
      <div className="form-text text-center">
        {t("The data was provided by your organization. In case of errors, please contact your admin.")}
      </div>
      <div className="text-center">
        <Button variant="link" onClick={toggleShowAdmin} size="sm">
          {t("Contact your admin")}
        </Button>
      </div>
      <Collapse in={showAdmin}>
        <Table responsive>
          <tbody>
            {admins?.map((x) => (
              <tr key={x.id}>
                <td>
                  <AvatarSpanWithName
                    tenant={x}
                    // AppContext not available here
                    includePopover={false}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Collapse>
    </>
  );
}
export default ExistingTenantComponent;
