import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { Button } from "react-bootstrap";
import { GroupBase, PropsValue, SelectComponentsConfig } from "react-select";
import useSWR from "swr";

import { ControllerContextData } from "@generatedCode/pbd-core/pbd-core-api-services";
import { useAPIs } from "../../../../../../pbdServices/services/service-context";

import { qmBaseIcons } from "../../../icons/qmBaseIcons";
import { BaseSelectAsyncWithDTO, BaseSelectAsyncWithDTOProps } from "../base/base-select-async";
import { ISelectOptionDTO } from "../base/select-option-dto";

function filterByLabel(label: string, queryStr: string): boolean {
  return label.toLocaleUpperCase().includes(queryStr.toLocaleUpperCase());
}

export interface IBaseDTO<TKey> {
  id: TKey;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPrefetchSelectProps<
  TKey,
  TDTO extends IBaseDTO<TKey>,
  TQuery = void,
  IsMulti extends boolean = boolean,
> extends Omit<BaseSelectAsyncWithDTOProps<TDTO, IsMulti>, "loadOptions"> {
  query: TQuery;
  loadAllOptions(query: TQuery): Promise<TDTO[]>;
  filterOptions?: (query: string, options: TDTO[]) => TDTO[];
  keyValue?: PropsValue<TKey>;
  defaultKeyValue?: PropsValue<TKey>;
  onAddOption?: (txt: string) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function PrefetchSelect<TKey, TDTO extends IBaseDTO<TKey>, TQuery = void, IsMulti extends boolean = boolean>(
  props: IPrefetchSelectProps<TKey, TDTO, TQuery, IsMulti>,
) {
  const { query, loadAllOptions, filterOptions, keyValue, ...selectProps } = props;
  const { data, isValidating } = useSWR(["selectOptions", query], ([key, query]) => loadAllOptions(query));

  const loadOptions = React.useCallback(
    async (queryStr: string, _options: TDTO, additional: any) => {
      if (data === undefined) {
        return {
          options: [],
          hasMore: false,
          additional: additional,
        };
      }

      const opts = filterOptions?.(queryStr, data) ?? data;
      return {
        options: opts,
        hasMore: false,
        additional: additional,
      };
    },
    [data, filterOptions],
  );

  const value = React.useMemo(() => {
    if (props.value != null) return props.value;

    if (keyValue !== undefined && data !== undefined) {
      if (_.isArray(keyValue)) {
        return data.filter((dto) => keyValue.includes(dto.id));
      } else {
        return data.find((x) => x.id === keyValue);
      }
    }

    return null;
  }, [data, keyValue, props.value]);

  const defaultValue = React.useMemo(() => {
    if (props.defaultValue != null) return props.defaultValue;

    if (keyValue !== undefined && data !== undefined) {
      if (_.isArray(keyValue)) {
        return data.filter((dto) => keyValue.includes(dto.id));
      } else {
        return data.find((x) => x.id === keyValue);
      }
    }

    return null;
  }, [data, keyValue, props.defaultValue]);

  return (
    <div className="d-flex">
      <BaseSelectAsyncWithDTO
        className={clsx(props.className, "flex-grow-1")}
        value={value}
        defaultValue={defaultValue}
        //@ts-expect-error TODO: Fix after BS5 Update
        loadOptions={loadOptions}
        isLoading={isValidating}
        {...selectProps}
        components={props.components}
      />
      {props.onAddOption !== undefined && (
        <Button variant="outline-primary" onClick={() => props.onAddOption?.("")}>
          <qmBaseIcons.Plus />
        </Button>
      )}
    </div>
  );
}

type IPrefetchDTOSelectProps<
  TKey,
  TDTO extends IBaseDTO<TKey>,
  TQuery = void,
  IsMulti extends boolean = boolean,
> = Omit<IPrefetchSelectProps<TKey, TDTO, TQuery, IsMulti>, "loadAllOptions" | "filterOptions" | "mapToSelectDTO">;

export function makePrefetchDTOSelect<
  TKey,
  TDTO extends IBaseDTO<TKey>,
  TQuery = void,
  IsMulti extends boolean = boolean,
>(
  loadAllOptions: (query: TQuery) => Promise<TDTO[]>,
  mapToSelectDTO: (dto: TDTO) => ISelectOptionDTO<TDTO>,
  isMulti: IsMulti,
  filterOptions?: (query: string, options: TDTO[]) => TDTO[],
  components?: SelectComponentsConfig<TDTO, IsMulti, GroupBase<TDTO>>,
) {
  return (props: IPrefetchDTOSelectProps<TKey, TDTO, TQuery, IsMulti>) => {
    const filter = (query: string, options: TDTO[]) =>
      options.filter((x) => filterByLabel(mapToSelectDTO(x).label, query));
    const { ...selectProps } = props;

    const loadOpts = React.useCallback((query: TQuery) => loadAllOptions(query), []);

    return (
      <PrefetchSelect
        loadAllOptions={loadOpts}
        filterOptions={filterOptions ?? filter}
        mapToSelectDTO={mapToSelectDTO}
        isMulti={isMulti}
        components={components}
        {...selectProps}
      />
    );
  };
}

export function makeApiPrefetchDTOSelect<
  TKey,
  TDTO extends IBaseDTO<TKey>,
  TQuery = void,
  IsMulti extends boolean = boolean,
>(
  loadAllOptionsApi: (api: ControllerContextData, query: TQuery) => Promise<TDTO[]>,
  mapTagToSelectDTO: (dto: TDTO) => ISelectOptionDTO<TDTO>,
  isMulti: IsMulti,
  filterOptions?: (query: string, options: TDTO[]) => TDTO[],
  components?: SelectComponentsConfig<TDTO, IsMulti, GroupBase<TDTO>>,
) {
  return (props: IPrefetchDTOSelectProps<TKey, TDTO, TQuery, IsMulti>) => {
    const apis = useAPIs();
    const loadAllOptions = React.useCallback((query: TQuery) => loadAllOptionsApi(apis, query), [apis]);
    const Select = React.useMemo(
      () =>
        makePrefetchDTOSelect<TKey, TDTO, TQuery, IsMulti>(
          loadAllOptions,
          mapTagToSelectDTO,
          isMulti,
          filterOptions,
          components,
        ),
      [loadAllOptions],
    );

    return <Select {...props} />;
  };
}

export type PrefetchSelectDTOComponent<
  TKey,
  TDTO extends IBaseDTO<TKey>,
  TQuery = void,
  IsMulti extends boolean = boolean,
> = React.ComponentType<IPrefetchDTOSelectProps<TKey, TDTO, TQuery, IsMulti>>;

export default PrefetchSelect;
