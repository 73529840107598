import { DateTime } from "luxon";
import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import { ICapabilitiesDTO } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { DateTimeLuxonHelpers } from "../../../../Helpers/DateTimeLuxonHelpers";
import { ValidationResult } from "../../../../pbdServices/Models/Shared/validation-result";
import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import { useAppContext } from "../../contexts/appContext";
import { ILayoutActions } from "../../layouts/detailsPage/detailsPageLayout";
import ButtonLoadingSpinner from "../buttons/buttonLoadingSpinner";
import { qmBaseIcons } from "../icons/qmBaseIcons";
import AvatarSpanWithName from "../tenants/avatarSpanWithName";
import GenericAlert from "./genericAlert";

interface DeletedObjectProps {
  isDeletedAt?: DateTime;
  isDeletedById?: number;
  isDeleted?: boolean;
  id: number;
  capabilities?: ICapabilitiesDTO;
  warnings?: ValidationResult[];
}

export interface IDeletedComponentProps {
  /**The type definition is required to mark this prop as required but also nullable so that we can implement this component without further null checking */
  baseObject: DeletedObjectProps | undefined;
  onRestore?: (id: number) => Promise<void>;
  refreshParent?: () => void;
  actions?: ILayoutActions;
}

/**
 * This component renders an is deleted alert. It also contains all the necessary logic to restore items.
 */
function DeletedComponent(props: IDeletedComponentProps) {
  const { baseObject, onRestore, refreshParent, actions } = props;
  const { t } = useTranslation();
  const { tenantsApi } = useAPIs();
  const { handleApiError } = useAppContext();
  const [loading, setLoading] = React.useState(false);
  const { data } = useSWR(baseObject?.isDeletedById ? ["/api/tenants", baseObject.isDeletedById] : undefined, () => {
    if (baseObject?.isDeletedById) {
      return tenantsApi.getById(baseObject.isDeletedById);
    } else {
      return undefined;
    }
  });

  if (!baseObject?.isDeleted) return null;

  const isRestoreAvailable = actions?.restore != undefined || onRestore != undefined;

  const handleRestore = async () => {
    setLoading(true);
    if (onRestore) {
      await wrapApiCallWithToast(() => onRestore(baseObject.id), { handleApiError, hideOnSuccess: true });
    } else if (actions?.restore != undefined) {
      await wrapApiCallWithToast(
        () => {
          // //@ts-expect-error TODO: How can we implement corrected null check here. Error goes away in non strict
          return actions.restore(baseObject.id);
        },
        { handleApiError, hideOnSuccess: true },
      );
    } else {
      throw new Error("Not implemented");
    }
    setLoading(false);
    refreshParent?.();
  };

  return (
    <GenericAlert type="danger" className="text-center" heading={t("Deleted")} alertIcon={<qmBaseIcons.Delete />}>
      <div className="d-flex justify-content-center align-items-center">
        {data && <AvatarSpanWithName tenant={data} />}
        {baseObject.isDeletedAt && (
          <span className="ms-2">{DateTimeLuxonHelpers.convertUtcToDateTime(baseObject.isDeletedAt)}</span>
        )}
      </div>

      <hr />
      {isRestoreAvailable && (
        <div className="d-flex">
          <div className="flex-grow-1">
            <p>{t("The item needs to be restored to be editable again")}</p>
          </div>
          <div>
            {baseObject.capabilities?.canRestore ? (
              <Button variant="secondary" onClick={handleRestore} disabled={loading}>
                {loading && <ButtonLoadingSpinner />} {t("Restore")}
              </Button>
            ) : (
              <em>{t("You are not allowed to restore this element")}</em>
            )}
          </div>
        </div>
      )}
    </GenericAlert>
  );
}

export default DeletedComponent;
