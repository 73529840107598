import { RowData, Table } from "@tanstack/react-table";
import { ChangeEvent } from "react";
import { Form, Pagination } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { IPagination } from "@generatedCode/pbd-core/pbd-core-api";

import { GlobalQmBaseConstants } from "../../../../../Constants/GlobalQmBaseConstants";

import { ReactTableStateDTO } from "./reactTableStateDTO";

interface IProps<T extends RowData> {
  table: Table<T>;
  setTableState: (value: React.SetStateAction<ReactTableStateDTO<T>>) => void;
  pagination?: IPagination;
}

export function PaginationComponent<T extends RowData>({ table, setTableState, pagination }: IProps<T>) {
  const { t } = useTranslation();
  const pageSize = table.getState().pagination.pageSize;
  const pageIndex = table.getState().pagination.pageIndex;
  const count = table.getPreFilteredRowModel().rows.length;

  const changePagination = (e: ChangeEvent<HTMLSelectElement>) => {
    table.setPageSize(Number(e.target.value));
    setTableState((prev) => ({
      ...prev,
      pageSize: Number(e.target.value),
    }));
  };

  return (
    <div className="d-flex justify-content-between">
      <div>
        <small className="text-muted">
          {count == 0 ? 0 : pageIndex * pageSize + 1} {t("to")}{" "}
          {Math.min(pagination?.totalItems ?? count, (pageIndex + 1) * pageSize)} {t("from")}{" "}
          {pagination?.totalItems ?? count} {t("Entries")}
        </small>
      </div>
      <div>
        {(table.getRowCount() > GlobalQmBaseConstants.MinEntriesForPagination ||
          (pagination && pagination.totalItems > GlobalQmBaseConstants.MinEntriesForPagination)) && (
          <Pagination>
            <Form.Select
              value={table.getState().pagination.pageSize}
              onChange={changePagination}
              title={"Entries per page"}
            >
              {[10, 25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>
            <Pagination.First onClick={() => table.firstPage()} disabled={!table.getCanPreviousPage()} />
            <Pagination.Prev onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} />
            <Pagination.Item active title={t("Current")}>
              {table.getState().pagination.pageIndex + 1}
            </Pagination.Item>
            <Pagination.Next onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} />
            <Pagination.Last onClick={() => table.lastPage()} disabled={!table.getCanNextPage()} />
          </Pagination>
        )}
      </div>
    </div>
  );
}
export default PaginationComponent;
