import { ITenantDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { DepartmentPositionQueryParameters } from "../../pbdServices/services/DepartmentPositions/models/query-parameters";
import { TenantQueryParameters } from "../../pbdServices/services/Tenants/models/query-parameters";
import RestUtilities from "../../services/restClients/restUtilities";

const routePrefix = "/Employees";

export class EmployeeRoutePaths {
  public static HomePage = routePrefix;
  public static OrganizationChart = `${routePrefix}/OrganizationChart`;
  public static OrganizationChartNew = `${routePrefix}/OrganizationChartNew`;
  public static OrganizationRfChart = `${routePrefix}/OrganizationRfChart`;
  public static IndexPage = `${routePrefix}/Index`;
  public static IndexPageFn = (query?: TenantQueryParameters) =>
    `${this.IndexPage}/${RestUtilities.getQueryString(query)}`;
  public static IndexDeleted = `${routePrefix}/Index/?isDeleted=1`;
  public static CreatePage = `${routePrefix}/Create`;
  public static EditPage = `${routePrefix}/Edit/:id`;
  public static EditPageFn = (dto: Pick<ITenantDTO, "id">) => this.EditPage.replace(":id", dto.id.toString());
  public static IndexPageDepartments = `${routePrefix}/Departments`;
  public static DetailsPageDepartment = `${routePrefix}/Departments/Details/:id`;
  public static IndexPageDepartmentPositions = `${routePrefix}/DepartmentPositions`;
  public static IndexPageDepartmentPositionsFn = (query?: DepartmentPositionQueryParameters) =>
    `${routePrefix}/DepartmentPositions${RestUtilities.getQueryString(query)}`;
  public static DetailsPageDepartmentPosition = `${routePrefix}/DepartmentPositions/Details/:id`;
  public static BirthdayPage = `${routePrefix}/Birthdays`;
}
