import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";

import {
  IClaimDTO,
  ICustomField,
  IProductDTO,
  IPurchaseOrderDTO,
  ISalesOrderDTO,
} from "@generatedCode/pbd-core/pbd-core-api";

import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import CustomFieldsDescriptionList from "../../shared/components/customFields/customFieldsDescriptionList";
import StatusBadge from "../../shared/components/status/statusBadge";
import FormattedUserInput from "../../shared/components/text/formattedUserInput";

interface IProps {
  claim: IClaimDTO;
  claimProducts?: IProductDTO[];
  claimPurchaseOrders?: IPurchaseOrderDTO[];
  claimSalesOrders?: ISalesOrderDTO[];
  customFieldsForModule: ICustomField[];
}

function ClaimDetailsHorizontal(props: IProps) {
  const { claim, claimProducts, claimPurchaseOrders, claimSalesOrders, customFieldsForModule } = props;
  const { t } = useTranslation();
  return (
    <Table bordered>
      <tbody>
        <tr>
          <td>
            <b>{t("ID")}</b>
            <br />#{claim.id}
          </td>
          <td>
            <b>{t("Claimed at")}</b>
            <br />
            {DateTimeLuxonHelpers.convertUtcToDate(claim.claimedAt)}
          </td>
          <td>
            <b>{t("Claim type")}</b>
            <br />
            {t(claim.claimType)}
          </td>
          <td>
            <b>{t("Claimed by")}</b>
            <br />
            {claim.organisation != null && <span>{claim.organisation.title}</span>}
            {claim.claimedBy != null && <span> - {claim.claimedBy.fullName}</span>}
          </td>
        </tr>
        {claimProducts && claimProducts.length > 0 && (
          <tr>
            <td colSpan={4}>
              <b>{t("Products")}</b>
              <ul>
                <li>
                  #{t("Ref number")} - {t("Name")} ({t("Quantity")})
                </li>

                {claimProducts.map((p) => (
                  <li key={p.id}>
                    {p.externalId ?? p.id} - {p.title}({p.quantityInfo?.quantity}{" "}
                    {p.quantityInfo?.unit != null ? p.quantityInfo.unit.title : ""})
                  </li>
                ))}
              </ul>
            </td>
          </tr>
        )}

        {claimPurchaseOrders && claimPurchaseOrders.length > 0 && (
          <tr>
            <td colSpan={4}>
              <b>{t("Purchase Orders")}</b>
              <ul>
                {claimPurchaseOrders.map((p) => (
                  <li key={p.id}>
                    #{p.externalId ?? p.id} - {p.title}
                  </li>
                ))}
              </ul>
            </td>
          </tr>
        )}

        {claimSalesOrders && claimSalesOrders.length > 0 && (
          <tr>
            <td colSpan={4}>
              <b>{t("Sales Orders")}</b>
              <ul>
                {claimSalesOrders.map((p) => (
                  <li key={p.id}>
                    #{p.externalId ?? p.id} - {p.title}
                  </li>
                ))}
              </ul>
            </td>
          </tr>
        )}
        {claim.title != "" && (
          <tr>
            <td colSpan={4}>
              <b>{t("Title")}</b>
              <p>{claim.title}</p>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan={4}>
            <b>{t("Description")}</b>
            <FormattedUserInput content={claim.description} />
          </td>
        </tr>

        <tr>
          {customFieldsForModule.length > 0 && claim.customFields && claim.customFields.length > 0 && (
            <td colSpan={4}>
              <b>{t("Custom Fields")}</b>
              <CustomFieldsDescriptionList
                customFieldValues={claim.customFields}
                customFields={customFieldsForModule}
              />
            </td>
          )}
        </tr>
        <tr>
          <td colSpan={4}>
            <StatusBadge status={claim.status} mr />
            {claim.doneBy &&
              claim.doneAt &&
              `${claim.doneBy.fullName} ${DateTimeLuxonHelpers.convertUtcToDate(claim.doneAt)}`}
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default ClaimDetailsHorizontal;
