import { DateTime } from "luxon";

import {
  ApplicationGroupAssignedDTO,
  ApplicationUserClaimDTO,
  ApplicationUserDTO,
  EntityKey,
} from "@generatedCode/pbd-core/pbd-core-api";

import { ClaimTypeNames } from "../../../../Constants/ClaimTypeNames";
import { ProductNames } from "../../../../Constants/ProductNames";
import { TenantsMockData } from "../../../../pbdServices/__tests__/config/mockData/tenantMockData";
import { MeAsUser } from "../../../../pbdServices/services/UserSettings/models/me-as-user";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";

const MockClaims: ApplicationUserClaimDTO[] = [
  new ApplicationUserClaimDTO({
    claimType: ClaimTypeNames.qmBase_ProductName,
    claimValue: ProductNames.qmBase,
  }),
  new ApplicationUserClaimDTO({
    claimType: ClaimTypeNames.TenantId,
    claimValue: "1",
  }),
];

const MockAssignedGroups: ApplicationGroupAssignedDTO[] = [
  new ApplicationGroupAssignedDTO({
    id: "myGroupId",
    name: "myGroup",
  }),
];

export class ApplicationUserMockData {
  items: ApplicationUserDTO[];
  constructor() {
    this.items = [
      new ApplicationUserDTO({
        userName: "john@doe.com",
        email: "john@doe.com",
        createdAt: DateTime.now(),
        lastSuccessfulLogin: DateTime.now(),
        accessFailedCount: 0,
        id: "b77d409a-10cd-4a47-8e94-b0cd0ab50aa1",
        roles: [],
        claims: MockClaims,
        logins: [],
        emailConfirmed: true,
        hasLocalAccount: true,
        groups: [],
        twoFactorEnabled: false,
        entityKey: EntityKey.ApplicationUser,
      }),
      new ApplicationUserDTO({
        userName: "max@mustermann.com",
        email: "max@mustermann.com",
        createdAt: DateTime.now(),
        lastSuccessfulLogin: DateTime.now(),
        accessFailedCount: 0,
        id: "b77d409a-10cd-4a47-8e94-b0cd0ab50999",
        roles: [],
        claims: [],
        logins: [],
        emailConfirmed: true,
        hasLocalAccount: true,
        groups: MockAssignedGroups,
        twoFactorEnabled: false,
        entityKey: EntityKey.ApplicationUser,
      }),
    ];
  }
  getById(id: string) {
    return this.items.find((x) => x.id == id);
  }

  getJohnDoe() {
    return this.items[0];
  }

  static getPlaceholderUser(id: string) {
    return new ApplicationUserDTO({
      id,
      userName: "Deleted user",
      email: "",
      emailConfirmed: false,
      accessFailedCount: 0,
      hasLocalAccount: false,
      twoFactorEnabled: false,
      createdAt: DateTime.fromISO("2000-01-01"),
      entityKey: EntityKey.ApplicationUser,
    });
  }
}

export const MockMeAsUser_JohnDoe: MeAsUser = {
  tenant: new TenantsMockData().getJohnDoe(),
  user: new ApplicationUserMockData().getJohnDoe(),
  roles: [PbdRoles.Admin],
  settings: [],
  groups: [],
  claims: new ApplicationUserMockData().getJohnDoe().claims ?? [],
  isReadOnly: false,
};
