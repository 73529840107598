import { PbdModule, PbdRoleTypes } from "@generatedCode/pbd-core/pbd-core-api";

/* eslint-disable @typescript-eslint/naming-convention */
export class PbdRoles {
  public static Admin = "Admin_Admin";
  public static Dev = "Dev";
  public static Preview = "Preview";

  //_${PbdRoleTypes.}

  // DocumentManagement
  public static DocumentManagement_Standard = `${PbdModule.DocumentManagement}_${PbdRoleTypes.Standard}`;
  public static DocumentManagement_ModuleAdmin = `${PbdModule.DocumentManagement}_${PbdRoleTypes.ModuleAdmin}`;
  public static DocumentManagement_Settings = `${PbdModule.DocumentManagement}_${PbdRoleTypes.Settings}`;

  // Blog
  public static Blog_Standard = `${PbdModule.Blog}_${PbdRoleTypes.Standard}`;
  public static Blog_ModuleAdmin = `${PbdModule.Blog}_${PbdRoleTypes.ModuleAdmin}`;

  // KnowledgeBase
  public static KnowledgeBase_Standard = `${PbdModule.KnowledgeBase}_${PbdRoleTypes.Standard}`;
  public static KnowledgeBase_ModuleAdmin = `${PbdModule.KnowledgeBase}_${PbdRoleTypes.ModuleAdmin}`;

  // Trainings
  public static Trainings_Standard = `${PbdModule.TrainingManagement}_${PbdRoleTypes.Standard}`;
  public static Trainings_ModuleAdmin = `${PbdModule.TrainingManagement}_${PbdRoleTypes.ModuleAdmin}`;
  public static TrainingManagement_Settings = `${PbdModule.TrainingManagement}_${PbdRoleTypes.Settings}`;

  // Custom forms
  public static CustomForms_ModuleAdmin = `${PbdModule.CustomForms}_${PbdRoleTypes.ModuleAdmin}`;
  public static CustomForms_Standard = `${PbdModule.CustomForms}_${PbdRoleTypes.Standard}`;

  // Settings
  public static Settings_Standard = `${PbdModule.Settings}_${PbdRoleTypes.Standard}`;
  public static Settings_ModuleAdmin = `${PbdModule.Settings}_${PbdRoleTypes.ModuleAdmin}`;

  // Audits
  public static AuditManagement_Standard = `${PbdModule.AuditManagement}_${PbdRoleTypes.Standard}`;
  public static AuditManagement_Settings = `${PbdModule.AuditManagement}_${PbdRoleTypes.Settings}`;
  public static AuditManagement_ModuleAdmin = `${PbdModule.AuditManagement}_${PbdRoleTypes.ModuleAdmin}`;

  //Employees
  public static Employees_Standard = `${PbdModule.Employees}_${PbdRoleTypes.Standard}`;
  public static Employees_ModuleAdmin = `${PbdModule.Employees}_${PbdRoleTypes.ModuleAdmin}`;

  //Claims
  public static ClaimManagement_Standard = `${PbdModule.ClaimManagement}_${PbdRoleTypes.Standard}`;
  public static ClaimManagement_ModuleAdmin = `${PbdModule.ClaimManagement}_${PbdRoleTypes.ModuleAdmin}`;
  public static ClaimManagement_Settings = `${PbdModule.ClaimManagement}_${PbdRoleTypes.Settings}`;

  // ProjectAndTaskManagement
  public static ProjectAndTaskManagement_Standard = `${PbdModule.ProjectAndTaskManagement}_${PbdRoleTypes.Standard}`;
  public static ProjectAndTaskManagement_ModuleAdmin = `${PbdModule.ProjectAndTaskManagement}_${PbdRoleTypes.ModuleAdmin}`;

  // MaintenanceManagement
  public static MaintenanceManagement_Standard = `${PbdModule.MaintenanceManagement}_${PbdRoleTypes.Standard}`;
  public static MaintenanceManagement_ModuleAdmin = `${PbdModule.MaintenanceManagement}_${PbdRoleTypes.ModuleAdmin}`;
  public static MaintenanceManagement_Settings = `${PbdModule.MaintenanceManagement}_${PbdRoleTypes.Settings}`;

  // CRM
  public static Crm_Standard = `${PbdModule.Crm}_${PbdRoleTypes.Standard}`;
  public static Crm_ModuleAdmin = `${PbdModule.Crm}_${PbdRoleTypes.ModuleAdmin}`;

  // AbsencePlanner
  public static AbsencePlanner_Standard = `${PbdModule.AbsencePlanner}_${PbdRoleTypes.Standard}`;
  public static AbsencePlanner_ModuleAdmin = `${PbdModule.AbsencePlanner}_${PbdRoleTypes.ModuleAdmin}`;
  public static AbsencePlanner_Approver = `${PbdModule.AbsencePlanner}_${PbdRoleTypes.Approver}`;

  // OpportunityManagement
  public static OpportunityManagement_Standard = `${PbdModule.OpportunityManagement}_${PbdRoleTypes.Standard}`;
  public static OpportunityManagement_ModuleAdmin = `${PbdModule.OpportunityManagement}_${PbdRoleTypes.ModuleAdmin}`;
  public static OpportunityManagement_Settings = `${PbdModule.OpportunityManagement}_${PbdRoleTypes.Settings}`;

  // QualificationMatrix
  public static QualificationMatrix_Standard = `${PbdModule.QualificationMatrix}_${PbdRoleTypes.Standard}`;
  public static QualificationMatrix_ModuleAdmin = `${PbdModule.QualificationMatrix}_${PbdRoleTypes.ModuleAdmin}`;
  public static QualificationMatrix_Settings = `${PbdModule.QualificationMatrix}_${PbdRoleTypes.Settings}`;

  // IdeaManagement
  public static IdeaManagement_Standard = `${PbdModule.IdeaManagement}_${PbdRoleTypes.Standard}`;
  public static IdeaManagement_ModuleAdmin = `${PbdModule.IdeaManagement}_${PbdRoleTypes.ModuleAdmin}`;
  public static IdeaManagement_Coordinator = `${PbdModule.IdeaManagement}_${PbdRoleTypes.Coordinator}`;
  public static IdeaManagement_Manager = `${PbdModule.IdeaManagement}_${PbdRoleTypes.Manager}`;
  public static IdeaManagement_Settings = `${PbdModule.IdeaManagement}_${PbdRoleTypes.Settings}`;

  // Products
  public static Products_Standard = `${PbdModule.Products}_${PbdRoleTypes.Standard}`;
  public static Products_ModuleAdmin = `${PbdModule.Products}_${PbdRoleTypes.ModuleAdmin}`;

  // GoalManagement
  public static GoalManagement_Standard = `${PbdModule.GoalManagement}_${PbdRoleTypes.Standard}`;
  public static GoalManagement_ModuleAdmin = `${PbdModule.GoalManagement}_${PbdRoleTypes.ModuleAdmin}`;

  // DefectManagement
  public static DefectManagement_Standard = `${PbdModule.DefectManagement}_${PbdRoleTypes.Standard}`;
  public static DefectManagement_ModuleAdmin = `${PbdModule.DefectManagement}_${PbdRoleTypes.ModuleAdmin}`;

  // QualityMonitor
  public static QualityMonitor_Standard = `${PbdModule.QualityMonitor}_${PbdRoleTypes.Standard}`;
  public static QualityMonitor_ModuleAdmin = `${PbdModule.QualityMonitor}_${PbdRoleTypes.ModuleAdmin}`;
}
