import { download, generateCsv, mkConfig } from "export-to-csv";
import i18next from "i18next";
import { isBoolean, isNull, isNumber, isString, isUndefined } from "lodash";
import { DateTime } from "luxon";
import Papa from "papaparse";

import { ICustomField, ICustomFieldDTO } from "@generatedCode/pbd-core/pbd-core-api";

export type ExportFieldValue = DateTime | string | number | boolean | undefined;
export type ExportType = Record<string, ExportFieldValue>;
export type ExportData = Record<string, string | number | boolean | undefined>;

export default class ExportService {
  private static _mapVal(val: ExportFieldValue) {
    if (isUndefined(val) || isNull(val)) return "";
    if (isString(val)) return val;
    if (isNumber(val)) return val;
    if (isBoolean(val)) return val;
    if (DateTime.isDateTime(val)) {
      // This format is validated in EXCEL 2021 (de-DE) and works. Before this is changed please test in EXCEL.
      return val.toISO({ suppressMilliseconds: true, includeOffset: false }).replace("T", " ");
    }

    throw new Error(`Invalid val: ${val}`);
  }

  static mapExportData<T extends ExportType>(data: T) {
    return Object.entries(data).reduce<ExportData>((acc, cur) => {
      const [key, val] = cur;
      acc[key] = ExportService._mapVal(val);
      return acc;
    }, {});
  }

  static exportCSV<T, U extends ExportType>(name: string, data: T[], map: (val: T) => U, headers?: string[]) {
    const options = ExportService.getOptions(name, headers);
    const csv = generateCsv(options)(data.map((x) => this.mapExportData(map(x))));
    download(options)(csv);
  }

  static getOptions(name: string, headers?: string[]) {
    const now = DateTime.now();
    const ts = now.toISO();
    return mkConfig({
      filename: `${i18next.t(name)}_${ts}`,
      fieldSeparator: ";",
      quoteStrings: true,
      decimalSeparator: ".",
      showTitle: true,
      title: `${i18next.t(name)} exported at ${ts}`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      columnHeaders: headers,
    });
  }

  static getCustomFields(fieldsFromObject: ICustomFieldDTO[], customFieldsFromSettings: ICustomField[]) {
    const obj: Record<string, string> = {};
    for (const item of customFieldsFromSettings) {
      obj[`cf_${item.name}`] = fieldsFromObject.find((x) => x.id === item.id)?.value ?? "";
    }
    return obj;
  }

  static getSelectedOrAll<T>(selected: T[], all: T[]) {
    if (selected.length > 0) return selected;
    return all;
  }

  static exportToCsv<T>(data: T[]) {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const elem = window.document.createElement("a");
    elem.href = window.URL.createObjectURL(blob);
    elem.download = `customers-${DateTime.now().toISO()}.csv`;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}
