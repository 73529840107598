import React from "react";
import { useLocation } from "react-router-dom";

import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { AbsencePlannerRoutePaths } from "../../absencePlanner/absencePlannerRoutePaths";
import { AdminRoutePaths } from "../../admin/adminRoutePaths";
import { ArticleRoutePaths } from "../../articles/articleRoutePaths";
import { AuditRoutePaths } from "../../audits/auditRoutePaths";
import { ClaimRoutePaths } from "../../claims/claimRoutePaths";
import { CrmRoutePaths } from "../../crm/crmRoutePaths";
import { DefectRoutePaths } from "../../defects/defectRoutePaths";
import { EmployeeRoutePaths } from "../../employees/employeeRoutePaths";
import { FormRoutePaths } from "../../forms/formRoutePaths";
import { GoalRoutePaths } from "../../goals/goalRoutePaths";
import { IdeaManagementRoutePaths } from "../../ideaManagement/ideaManagementRoutePaths";
import { MaintenanceManagementRoutePaths } from "../../maintenanceManagement/maintenanceManagementRoutePaths";
import { OpportunityRoutePaths } from "../../opportunities/opportunityRoutePaths";
import { ProductRoutePaths } from "../../products/productRoutePaths";
import { ProfileRoutePaths } from "../../profile/profileRoutePaths";
import { QualificationMatrixRoutePaths } from "../../qualificationMatrix/qualificationMatrixRoutePaths";
import { QualityMonitorRoutePaths } from "../../qualityMonitor/qualityMonitorRoutePaths";
import { SettingsRoutePaths } from "../../settings/settingsRoutePaths";
import { StoreRoutePaths } from "../../store/storeRoutePaths";
import { TaskManagementRoutePaths } from "../../taskManagement/taskManagementRoutePaths";
import { TrainingRoutePaths } from "../../trainings/trainingRoutePaths";

export function useCurrentAppHook() {
  const location = useLocation();
  const memoApp = React.useMemo(() => {
    return getApp(location.pathname);
  }, [location.pathname]);
  return { currentApp: memoApp };
}

class RouteCheck {
  pathname: string;
  constructor(pathname: string) {
    this.pathname = pathname.toUpperCase();
  }

  startsWith(appHomePath: string) {
    if (this.pathname.startsWith(appHomePath.toUpperCase())) {
      return true;
    }
    return false;
  }
}

export function getApp(pathname: string) {
  const routeCheck = new RouteCheck(pathname);
  if (routeCheck.startsWith(ArticleRoutePaths.HomePage(PbdModule.DocumentManagement))) {
    return PbdModule.DocumentManagement;
  } else if (routeCheck.startsWith(ArticleRoutePaths.HomePage(PbdModule.Blog))) {
    return PbdModule.Blog;
  } else if (routeCheck.startsWith(ArticleRoutePaths.HomePage(PbdModule.KnowledgeBase))) {
    return PbdModule.KnowledgeBase;
  } else if (routeCheck.startsWith(ClaimRoutePaths.HomePage)) {
    return PbdModule.ClaimManagement;
  } else if (routeCheck.startsWith(TaskManagementRoutePaths.HomePage)) {
    return PbdModule.ProjectAndTaskManagement;
  } else if (routeCheck.startsWith(AdminRoutePaths.HomePage)) {
    return PbdModule.Admin;
  } else if (routeCheck.startsWith(SettingsRoutePaths.Home)) {
    return PbdModule.Settings;
  } else if (routeCheck.startsWith(StoreRoutePaths.HomePage)) {
    return PbdModule.Store;
  } else if (routeCheck.startsWith(GoalRoutePaths.HomePage)) {
    return PbdModule.GoalManagement;
  } else if (routeCheck.startsWith(DefectRoutePaths.HomePage)) {
    return PbdModule.DefectManagement;
  } else if (routeCheck.startsWith(QualificationMatrixRoutePaths.HomePage)) {
    return PbdModule.QualificationMatrix;
  } else if (routeCheck.startsWith(FormRoutePaths.HomePage)) {
    return PbdModule.CustomForms;
  } else if (routeCheck.startsWith(AuditRoutePaths.HomePage)) {
    return PbdModule.AuditManagement;
  } else if (routeCheck.startsWith(CrmRoutePaths.HomePage)) {
    return PbdModule.Crm;
  } else if (routeCheck.startsWith(OpportunityRoutePaths.HomePage)) {
    return PbdModule.OpportunityManagement;
  } else if (routeCheck.startsWith(TrainingRoutePaths.HomePage)) {
    return PbdModule.TrainingManagement;
  } else if (routeCheck.startsWith(QualityMonitorRoutePaths.HomePage)) {
    return PbdModule.QualityMonitor;
  } else if (routeCheck.startsWith(MaintenanceManagementRoutePaths.HomePage)) {
    return PbdModule.MaintenanceManagement;
  } else if (routeCheck.startsWith(AbsencePlannerRoutePaths.HomePage)) {
    return PbdModule.AbsencePlanner;
  } else if (routeCheck.startsWith(IdeaManagementRoutePaths.HomePage)) {
    return PbdModule.IdeaManagement;
  } else if (routeCheck.startsWith(ProfileRoutePaths.HomePage)) {
    return PbdModule.Profile;
  } else if (routeCheck.startsWith(EmployeeRoutePaths.HomePage)) {
    return PbdModule.Employees;
  } else if (routeCheck.startsWith(ProductRoutePaths.HomePage)) {
    return PbdModule.Products;
  }
  if (pathname.toLowerCase().includes("/documentmanagement")) {
    return PbdModule.DocumentManagement;
  } else if (pathname.toLowerCase().includes("documents")) {
    // this is for q-monitor temp workaround
    return PbdModule.DocumentManagement;
  } else if (pathname.toLowerCase().includes("/blog")) {
    return PbdModule.Blog;
  } else if (pathname.toLowerCase().includes("/knowledgebase")) {
    return PbdModule.KnowledgeBase;
  } else if (pathname.toLowerCase().includes("/claimmanagement")) {
    return PbdModule.ClaimManagement;
  } else if (pathname.toLowerCase().includes("/taskmanagement")) {
    return PbdModule.ProjectAndTaskManagement;
  } else if (pathname.toLowerCase().startsWith("/admin")) {
    return PbdModule.Admin;
  } else if (pathname.toLowerCase().startsWith("/settings")) {
    return PbdModule.Settings;
  } else if (pathname.toLowerCase().startsWith("/store")) {
    return PbdModule.Store;
  } else if (pathname.toLowerCase().startsWith("/goals")) {
    return PbdModule.GoalManagement;
  } else if (pathname.toLowerCase().startsWith("/errormanagement")) {
    return PbdModule.DefectManagement;
  } else if (
    pathname == "/" ||
    pathname.toLowerCase().startsWith("/home") ||
    pathname.toLowerCase().startsWith("/onboarding")
  ) {
    return PbdModule.Startpage;
  } else if (pathname.toLowerCase().startsWith("/qualification")) {
    return PbdModule.QualificationMatrix;
  } else if (pathname.toLowerCase().startsWith("/forms")) {
    return PbdModule.CustomForms;
  } else if (pathname.toLowerCase().startsWith("/audits")) {
    return PbdModule.AuditManagement;
  } else if (pathname.toLowerCase().startsWith("/crm")) {
    return PbdModule.Crm;
  } else if (pathname.toLowerCase().startsWith("/opportunities")) {
    return PbdModule.OpportunityManagement;
  } else if (pathname.toLowerCase().startsWith("/trainings")) {
    return PbdModule.TrainingManagement;
  } else if (pathname.toLowerCase().startsWith("/qualitymonitor")) {
    return PbdModule.QualityMonitor;
  } else if (pathname.toLowerCase().startsWith("/maintenancemanagement")) {
    return PbdModule.MaintenanceManagement;
  } else if (pathname.toLowerCase().startsWith("/absenceplanner")) {
    return PbdModule.AbsencePlanner;
  } else if (pathname.toLowerCase().startsWith("/ideamanagement")) {
    return PbdModule.IdeaManagement;
  } else if (pathname.toLowerCase().startsWith("/profile")) {
    return PbdModule.Profile;
  } else if (pathname.toLowerCase().startsWith("/employees")) {
    return PbdModule.Employees;
  } else if (pathname.toLowerCase().startsWith("/products")) {
    return PbdModule.Products;
  } else if (pathname.toLowerCase().startsWith("/dev")) {
    return PbdModule.Dev;
  }
  console.warn("Not implemented", pathname);
  return PbdModule.None;
}
