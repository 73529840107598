import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { ArticleRoutePaths } from "./articleRoutePaths";

const getNestedRoutes = (routeToCheck: (pbdModule: PbdModule) => string): string => {
  const mappedRoute = routeToCheck(PbdModule.None);
  return mappedRoute.replace(`/${PbdModule.None}/`, "");
};

/**Auth must be handled in each page because pages are used in different apps */
export const articleLazyRoutes: RouteObject[] = [
  {
    // path: getNestedRoutes(ArticleRoutePaths.HomePage),
    path: "",
    Component: lazy(() => import("./pages/homePageArticle")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.IndexPage),
    Component: lazy(() => import("./pages/indexPageArticles")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.UnpublishedPage),
    Component: lazy(() => import("./pages/indexPageUnpublished")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.IndexPageMenuItems),
    Component: lazy(() => import("./pages/indexPageArticlesMenuItems")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.DetailsPageMenuItems),
    Component: lazy(() => import("../menuItems/pages/detailsPageMenuItem")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.IndexPageNews),
    Component: lazy(() => import("./pages/indexPageNews")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.DetailsPage),
    Component: lazy(() => import("./pages/detailsPageArticle")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.CreatePage),
    Component: lazy(() => import("./pages/createPageArticle")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.EditPage),
    Component: lazy(() => import("./pages/editPageArticle")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.NotificationsPage),
    Component: lazy(() => import("./pages/notificationsPage")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.ComparePage),
    Component: lazy(() => import("./pages/comparePageArticle")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.IndexTemplatesPage),
    Component: lazy(() => import("./pages/indexPageArticleTemplates")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.DetailsTemplatePage),
    Component: lazy(() => import("./pages/detailsPageArticleTemplates")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.IndexArticleViewsPage),
    Component: lazy(() => import("../articleViews/pages/viewsOverviewPage")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.ArticleViewsByArticlePage),
    Component: lazy(() => import("../articleViews/pages/articleViewsByArticle")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.ReportingsPage),
    Component: lazy(() => import("./pages/reportingsPage")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.RecentPage),
    Component: lazy(() => import("../articleViews/pages/lastViewPage")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.DocumentGraph),
    Component: lazy(() => import("./pages/documentGraph")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.ReviewsPage),
    Component: lazy(() => import("./pages/reviewsPage")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.RatingsByArticlePage),
    Component: lazy(() => import("../articleRatings/pages/indexPageArticleRatingsByArticle")),
  },
  {
    path: getNestedRoutes(ArticleRoutePaths.EmployeeRecords),
    Component: lazy(() => import("../articleRevisionReviews/page/indexPageEmployeeArticleReview")),
  },
];

export const documentManagementLazyRoutes = [
  ...articleLazyRoutes,
  {
    path: ArticleRoutePaths.StaticDocumentsIndex.replace(`/${PbdModule.DocumentManagement}/`, ""),
    Component: lazy(() => import("./pages/staticDocumentsPage")),
  },
  {
    path: ArticleRoutePaths.CareNavigatorCertificate.replace(`/${PbdModule.DocumentManagement}/`, ""),
    Component: lazy(() => import("./pages/careNavigatorCertificate")),
  },
];
