import { Formik } from "formik";
import { DateTime } from "luxon";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ObjectSchema, object, string } from "yup";

import { IPrimaryNotificationInfo, PrimaryNotificationInfo } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import { nameofFactory } from "../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import FormikCustomForm from "../../shared/components/forms/formik/formikCustomForm";
import { FormikTextInputGroup } from "../../shared/components/forms/formik/formikTextInput";
import { useEmergencyContactHook } from "../../shared/hooks/useEmergencyContactHook";
import GettingStartedIndex from "./gettingStarted/gettingStartedIndex";

type LocalType = Pick<IPrimaryNotificationInfo, "email">;

const nameof = nameofFactory<LocalType>();

const ValidationSchema: ObjectSchema<LocalType> = object({
  email: string().required().email(),
});

function MyInformationPage() {
  const { t } = useTranslation();
  const { appSettingsApi, settingsApi } = useAPIs();

  const { data, isLoading, mutate } = useEmergencyContactHook();

  const submitter = useFormikAPISubmitter<LocalType>(
    (val) =>
      appSettingsApi.updatePrimaryNotificationInfo(
        new PrimaryNotificationInfo({ ...val, lastUpdatedAt: DateTime.now() }),
      ),
    [appSettingsApi],
    () => {
      mutate();
    },
  );

  if (isLoading) return null;

  const initialValues: LocalType = { email: data?.email ?? "" };

  return (
    <>
      <h2>{t("My information")}</h2>
      <hr />
      <GettingStartedIndex />
      <Card className="mb-3">
        <Card.Header>
          <Card.Title as="h5">{t("Emergency contact")}</Card.Title>
        </Card.Header>
        <Card.Body>
          <p>
            {t(
              "Please confirm or enter a new email address so we can update you on any qmBase service or security issues that might occur.",
            )}
          </p>
          <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
            {(formikBag) => (
              <FormikCustomForm formikBag={formikBag}>
                <FormikTextInputGroup name={nameof("email")} />
              </FormikCustomForm>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </>
  );
}
export default MyInformationPage;
