import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { AppUiData } from "../../../Constants/app-ui-data";

interface IProps {
  app: PbdModule;
  as?: "Link";
  size?: "sm";
}

function AppIcon(props: IProps) {
  const { t } = useTranslation();
  const { app, as, size } = props;
  const navbarItem = AppUiData[app];
  const appIcon = (
    <div
      className={`appLogoAvatar${size ? "-" + size : ""} ${
        navbarItem.backgroundClassName
      } text-center rounded-1 shadow`}
    >
      <span className="initials">
        <i className={navbarItem.iconClassName} />
      </span>
    </div>
  );
  if (as == "Link") {
    return (
      <Link to={navbarItem.homePage || "undefined"} title={t("App start page")}>
        {appIcon}
      </Link>
    );
  } else {
    return appIcon;
  }
}
export default AppIcon;
