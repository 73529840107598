import { OptionProps } from "react-select";

import {
  IDepartmentDTO,
  IQualificationDTO,
  ITagDTO,
  ITenantDTO,
  PbdModule,
} from "@generatedCode/pbd-core/pbd-core-api";

import { GlobalQmBaseConstants } from "../../../../../../Constants/GlobalQmBaseConstants";
import { DepartmentQueryParameters } from "../../../../../../pbdServices/services/Departments/models/query-parameter";
import { QualificationQueryParameters } from "../../../../../../pbdServices/services/Qualifications/qualificationService";
import { ISelectOptionDTO } from "../base/select-option-dto";
import { makeApiPrefetchDTOSelect } from "./prefetch-select";

export interface IBaseDTOWithTitle {
  id: string | number;
  title: string;
}

export function defaultMapToSelectDTO<TDTO extends IBaseDTOWithTitle>(dto: TDTO): ISelectOptionDTO<TDTO> {
  return {
    value: dto,
    label: dto.title,
    key: dto.id,
  };
}

export interface ITagSelectTitleKeyDTO extends Omit<ITagDTO, "id"> {
  id: string;
}

export const TagSelect = makeApiPrefetchDTOSelect<number, ITagDTO, PbdModule | undefined, false>(
  async (api, query: PbdModule | undefined) => {
    return (
      await api.tagsApi.getAllQuery({
        pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
        module: query ?? PbdModule.None,
      })
    ).data;
  },
  defaultMapToSelectDTO,
  false,
);

export const TagSelectMulti = makeApiPrefetchDTOSelect<number, ITagDTO, PbdModule | undefined, true>(
  async (api, query: PbdModule | undefined) => {
    return (
      await api.tagsApi.getAllQuery({
        pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
        module: query ?? PbdModule.None,
      })
    ).data;
  },
  defaultMapToSelectDTO,
  true,
);

export const TagTitleKeySelect = makeApiPrefetchDTOSelect<string, ITagSelectTitleKeyDTO, PbdModule | undefined, false>(
  async (api, query: PbdModule | undefined) => {
    return (
      await api.tagsApi.getAllQuery({
        pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
        module: query ?? PbdModule.None,
      })
    ).data.map((tag) => ({
      ...tag,
      id: tag.title,
    }));
  },
  defaultMapToSelectDTO,
  false,
);

export const TagTitleKeySelectMulti = makeApiPrefetchDTOSelect<
  string,
  ITagSelectTitleKeyDTO,
  PbdModule | undefined,
  true
>(
  async (api, query: PbdModule | undefined) => {
    return (
      await api.tagsApi.getAllQuery({
        pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
        module: query ?? PbdModule.None,
      })
    ).data.map((tag) => ({
      ...tag,
      id: tag.title,
    }));
  },
  defaultMapToSelectDTO,
  true,
);

export function mapTenantToSelectDTO(tag: ITenantDTO): ISelectOptionDTO<ITenantDTO> {
  return {
    value: tag,
    label: tag.fullName ?? "",
    key: tag.id,
  };
}

function TenantOption<IsMulti extends boolean>(props: OptionProps<ITenantDTO, IsMulti>) {
  const { data } = props;
  return (
    <div {...props.innerProps}>
      #{data.id} {data.fullName}
      {data.primaryDepartmentPosition && (
        <span>
          <br />
          <small className="text-info">
            {data.primaryDepartmentPosition.title} - {data.primaryDepartmentPosition.department.title}
          </small>
        </span>
      )}
    </div>
  );
}

// export const TenantSelectSingle = makeApiPrefetchDTOSelect<number, ITenantDTO, string | undefined, false>(
//   async (api, query: string | undefined) => {
//     return await api.tenantsApi.getAllQuery({
//       q: query,
//     });
//   },
//   mapTenantToSelectDTO,
//   false,
//   undefined,
//   // eslint-disable-next-line @typescript-eslint/naming-convention
//   { Option: TenantOption },
// );

// export const TenantSelectMulti = makeApiPrefetchDTOSelect<number, ITenantDTO, TenantQueryParameters | undefined, true>(
//   async (api, query: TenantQueryParameters | undefined) => {
//     return await api.tenantsApi.getAllQuery(query ?? {});
//   },
//   mapTenantToSelectDTO,
//   true,
//   undefined,
//   // eslint-disable-next-line @typescript-eslint/naming-convention
//   { Option: TenantOption },
// );

export const DepartmentSelect = makeApiPrefetchDTOSelect<
  number,
  IDepartmentDTO,
  DepartmentQueryParameters | undefined,
  false
>(
  async (api, query: DepartmentQueryParameters | undefined) => {
    return await api.departmentsApi.getAllQuery(query ?? {});
  },
  defaultMapToSelectDTO,
  false,
);

export const DepartmentSelectMulti = makeApiPrefetchDTOSelect<
  number,
  IDepartmentDTO,
  DepartmentQueryParameters | undefined,
  true
>(
  async (api, query: DepartmentQueryParameters | undefined) => {
    return await api.departmentsApi.getAllQuery(query ?? {});
  },
  defaultMapToSelectDTO,
  true,
);

export const QualificationSelect = makeApiPrefetchDTOSelect<
  number,
  IQualificationDTO,
  QualificationQueryParameters | undefined,
  false
>(
  async (api, query: QualificationQueryParameters | undefined) => {
    return await api.qualificationsApi.getAllQuery(query ?? {});
  },
  defaultMapToSelectDTO,
  false,
);

export const QualificationSelectMulti = makeApiPrefetchDTOSelect<
  number,
  IQualificationDTO,
  QualificationQueryParameters | undefined,
  true
>(
  async (api, query: QualificationQueryParameters | undefined) => {
    return await api.qualificationsApi.getAllQuery(query ?? {});
  },
  defaultMapToSelectDTO,
  true,
);
