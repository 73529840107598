import i18next from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { Column } from "react-table";

import { IManyToManyConnectionDTO, PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { AppUiData } from "../../../../Constants/app-ui-data";
import { DateTimeLuxonHelpers } from "../../../../Helpers/DateTimeLuxonHelpers";
import BaseTableProps from "../tables/BaseTableProps";
import { ReactstrapTable } from "../tables/reactstrapTable";

interface IProps extends BaseTableProps<IManyToManyConnectionDTO> {
  routePath: string;
  localStorageKey: string;
}

const ManyToManyConnectionTable: React.FC<IProps> = (props) => {
  const { columnsVisibleDefault = ["title", "createdAt", "module"], localStorageKey, routePath } = props;

  function getEditLink(item: IManyToManyConnectionDTO, title: string) {
    if (Object.values(PbdModule).includes(item.module as PbdModule)) {
      const currentModule = item.module as PbdModule;
      const link = AppUiData[currentModule].edit;
      if (link) {
        if (!link.startsWith(routePath)) {
          return <a href={link.toString().replace(":id", item.id.toString())}>{title}</a>;
        } else {
          return <Link to={link.toString().replace(":id", item.id.toString())}>{title}</Link>;
        }
      }
    }
    return <>{title}</>;
  }

  const columns = React.useMemo<Column<IManyToManyConnectionDTO>[]>(
    () => [
      {
        id: "id",
        Header: <>{i18next.t("ID")}</>,
        accessor: "id",
        Cell: ({ row }) => <>{getEditLink(row.original, row.original.id.toString())}</>,
      },
      {
        id: "title",
        Header: <>{i18next.t("Title")}</>,
        accessor: "title",
        Cell: ({ row }) => <>{getEditLink(row.original, row.original.title)}</>,
      },
      {
        id: "createdAt",
        Header: <>{i18next.t("Created")}</>,
        accessor: "createdAt",
        Cell: ({ row }) => (
          <>{row.original.createdAt && DateTimeLuxonHelpers.convertUtcToDateTime(row.original.createdAt)}</>
        ),
      },
      {
        id: "module",
        Header: <>{i18next.t("Module")}</>,
        accessor: "module",
        Cell: ({ row }) => <>{i18next.t(row.original.module)}</>,
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <ReactstrapTable<IManyToManyConnectionDTO>
        columns={columns}
        localStorageStateKey={localStorageKey}
        columnsVisibleDefault={columnsVisibleDefault}
        {...props}
      />
    </React.Fragment>
  );
};

export default ManyToManyConnectionTable;
