import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { ICustomField } from "@generatedCode/pbd-core/pbd-core-api";

import { PbdRoles } from "../../services/Authz/PbdRoles";
import { PbdRouteModel } from "../router/pbdRouteModel";
import { DefectRoutePaths } from "./defectRoutePaths";

const defaultRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.DefectManagement_ModuleAdmin, PbdRoles.DefectManagement_Standard],
};

export const defectLazyRoutes: RouteObject[] = [
  {
    path: DefectRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePageDefects")),
    ...defaultRouteParams,
  },
  {
    path: DefectRoutePaths.IndexPage,
    Component: lazy(() => import("./pages/indexPageDefects")),
    ...defaultRouteParams,
  },
  {
    path: DefectRoutePaths.EditPage,
    Component: lazy(() => import("./pages/detailsPageDefect")),
    ...defaultRouteParams,
  },
  {
    path: DefectRoutePaths.CreatePage,
    Component: lazy(() => import("./pages/createPageDefect")),
    ...defaultRouteParams,
  },
  {
    path: DefectRoutePaths.Graph,
    Component: lazy(() => import("../graphs/pages/graphPage")),
    ...defaultRouteParams,
  },
];

export class DefectSettings {
  customFields?: ICustomField[];
}
