import React from "react";

import { ConfigApi } from "@generatedCode/pbd-core/pbd-core-api";
import { ServiceContextProvider } from "../../../pbdServices/services/service-context";

interface IProps {
  children: React.ReactNode;
}

const config = new ConfigApi();

export function ServiceContextWrapper(props: IProps) {
  return <ServiceContextProvider value={config}>{props.children}</ServiceContextProvider>;
}
