import clsx from "clsx";
import { TFunction } from "i18next";
import { DateTime } from "luxon";
import { Badge, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { UiData } from "../../../../Constants/app-ui-data";
import { useToggle } from "../../hooks/useToggle";
import CounterBadge from "../badges/counterBadge";
import { IconComponent } from "../icons/icon-base";
import { qmBaseIcons } from "../icons/qmBaseIcons";
import SmartViewModal from "../smartViews/smartViewModal";
import ListGroupItemSmartViews from "./listGroupItemSmartViews";
import SidebarAppHeader from "./sidebarAppHeader";
import SidebarNewEntry from "./sidebarNewEntry";

interface ISidebarItem {
  id?: string;
  href?: string;
  isExternal?: boolean;
  title?: string;
  /**Use component to omit everything else. */
  component?: React.ReactNode;
  description?: string;
  icon?: IconComponent;
  count?: number;
  countTitle?: string;
  /**Entry will be highlighted for 2 months */
  newSince?: DateTime;
  position?: number;
}

export class SidebarItem implements ISidebarItem {
  constructor(item: ISidebarItem) {
    this.id = item.id ?? item.href ?? "missingId";
    this.title = item.title;
    this.isExternal = item.isExternal;
    this.href = item.href;
    this.icon = item.icon;
    this.description = item.description;
    this.component = item.component;
    this.count = item.count;
    this.countTitle = item.countTitle;
    this.position = item.position;
  }
  id: string;
  href?: string | undefined;
  isExternal?: boolean;
  title?: string;
  component?: React.ReactNode;
  description?: string | undefined;
  icon?: IconComponent | undefined;
  count?: number;
  countTitle?: string;
  position?: number;

  static created(href: string, t: TFunction) {
    return new SidebarItem({
      id: "create",
      title: t("New"),
      icon: qmBaseIcons.Plus,
      href,
    });
  }
  static overview(href: string, t: TFunction, title?: string) {
    return new SidebarItem({
      id: "overview",
      title: title ?? t("Overview"),
      icon: qmBaseIcons.ListSolid,
      href,
    });
  }
  static deleted(href: string, t: TFunction) {
    return new SidebarItem({
      id: "recycleBin",
      title: t("Recycle bin"),
      icon: qmBaseIcons.Delete,
      href,
    });
  }
  static settings(href: string, t: TFunction) {
    return new SidebarItem({
      id: "settings",
      title: t("Settings"),
      icon: qmBaseIcons.Gear,
      href,
    });
  }
  static costs(href: string, t: TFunction) {
    return new SidebarItem({
      id: "costs",
      title: t("Costs"),
      icon: qmBaseIcons.Cost,
      href,
    });
  }
}

interface PrepProps {
  createUrl?: string;
  overviewUrl?: string;
  t: TFunction;
}

export function prepareSidebar({ createUrl, overviewUrl, t }: PrepProps) {
  const items: SidebarItem[] = [];
  if (createUrl) {
    items.push(new SidebarItem({ id: "new", title: t("New"), icon: qmBaseIcons.Plus, href: createUrl }));
  }
  if (overviewUrl) {
    items.push(
      new SidebarItem({ id: "overview", title: t("Overview"), icon: qmBaseIcons.ListSolid, href: overviewUrl }),
    );
  }
  return items;
}

function ListGroupItemWrapper(props: ISidebarItem & { children: React.ReactNode; className?: string }) {
  if (props.href) {
    if (props.isExternal) {
      return (
        <ListGroup.Item
          as={"a"}
          href={props.href}
          target="_blank"
          rel="noopener noreferrer"
          action
          className={props.className}
        >
          {props.children}
        </ListGroup.Item>
      );
    } else {
      return (
        <ListGroup.Item as={Link} to={props.href} action className={props.className}>
          {props.children}
        </ListGroup.Item>
      );
    }
  } else {
    return <ListGroup.Item className={props.className}>{props.children}</ListGroup.Item>;
  }
}

interface IProps {
  app: PbdModule;
  title?: string;
  items: ISidebarItem[];
  children?: React.ReactNode;
  showSmartViews?: boolean;
  uiData?: UiData;
}

function SidebarNew(props: IProps) {
  const { t } = useTranslation();
  const { app, items, showSmartViews, uiData } = props;
  const [modal, toggle] = useToggle();

  const smartViewApp = app == PbdModule.ProjectAndTaskManagement ? PbdModule.ToDoManagement : app;
  return (
    <>
      <div
        className="d-flex flex-column align-items-stretch flex-shrink-0 bg-body-tertiary mh-100 shadow-sm"
        id="sidebar-wrapper"
      >
        <SidebarAppHeader app={app} />
        <ListGroup variant="flush" className="border-bottom">
          {uiData && <SidebarNewEntry uiData={uiData} />}
          {items
            .sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
            .map((x) => (
              <ListGroupItemWrapper key={x.id} {...x} className="bg-body-tertiary">
                {x.component}
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <span className={clsx(x.description && "fw-bold", "mb-1", "text-truncate")}>
                    {x.icon && <x.icon />} {x.title}
                  </span>
                  <div>
                    {x.isExternal && <qmBaseIcons.ExternalLink />}
                    {x.count != undefined && x.count > 0 && (
                      <CounterBadge count={x.count} countTitle={x.countTitle} color="primary" />
                    )}
                    {x.newSince && x.newSince > DateTime.now().minus({ month: 2 }) && <Badge>{t("New")}</Badge>}
                  </div>
                </div>
                {x.description && <div className="col-10 mb-1 small">{x.description}</div>}
              </ListGroupItemWrapper>
            ))}
          {showSmartViews && <ListGroupItemSmartViews onToggleModal={toggle} app={smartViewApp} />}
        </ListGroup>
        {props.children}
      </div>
      <SmartViewModal modal={modal} toggle={toggle} app={smartViewApp} />
    </>
  );
}
export default SidebarNew;
