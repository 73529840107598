import i18next from "i18next";
import React from "react";
import { Column } from "react-table";

import { IDepartmentDTO, ITenantMinDTO } from "@generatedCode/pbd-core/pbd-core-api";

import BaseTableProps from "../../shared/components/tables/BaseTableProps";
import { getIdColumn } from "../../shared/components/tables/columns/idColumn";
import { getTitleColumn } from "../../shared/components/tables/columns/titleColumn";
import { ReactstrapTable } from "../../shared/components/tables/reactstrapTable";

export type TableType = IDepartmentDTO & {
  tenants?: ITenantMinDTO[];
  tenantsCount?: number;
  departmentPositionsCount?: number;
};

type IProps = BaseTableProps<TableType>;

const DepartmentTable = (props: IProps) => {
  const {
    columnsVisibleDefault = ["title", "departmentPositions", "tenants"],
    titleColumnBaseLink,
    includeTitleLink,
  } = props;

  const columns = React.useMemo<Column<TableType>[]>(
    () => [
      getIdColumn(includeTitleLink, titleColumnBaseLink),
      getTitleColumn(includeTitleLink, titleColumnBaseLink),
      {
        id: "departmentPositions",
        Header: <>{i18next.t("Positions")}</>,
        accessor: "departmentPositionsCount",
        Cell: ({ row }) => <>{row.original.departmentPositions?.length}</>,
      },
      {
        id: "tenants",
        Header: <>{i18next.t("Persons")}</>,
        accessor: "tenantsCount",
        Cell: ({ row }) => <>{row.original.tenantsCount && row.original.tenantsCount}</>,
      },
    ],
    [],
  );

  return (
    <ReactstrapTable<TableType>
      columns={columns}
      columnsVisibleDefault={columnsVisibleDefault}
      localStorageStateKey={"DepartmentDTO"}
      {...props}
    />
  );
};

export default DepartmentTable;
