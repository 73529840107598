import { TFunction } from "i18next";
import { boolean, object, ObjectSchema, ref, string } from "yup";

import { IPrivacyPolicySettingDTO, IRegisterDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import UserService from "../../../pbdServices/services/Users/userService";

export const getRegisterValidationSchema = (t: TFunction, privacyPolicy?: IPrivacyPolicySettingDTO) => {
  const validationSchema: ObjectSchema<IRegisterDTO> = object({
    email: string().required(t("This field is required")).email(t("Must be a valid email")).max(100),
    password: string()
      .matches(
        UserService.strongRegex,
        t("Your password must contain lower case uppercase number and special characters"),
      )
      .required(t("This field is required"))
      .min(GlobalQmBaseConstants.PasswordRequiredLength)
      .max(100),
    confirmPassword: string()
      .oneOf([ref("password")], t("Passwords must match"))
      .required(t("This field is required")),
    acceptTerms: boolean()
      .required(t("The terms and conditions must be accepted."))
      .oneOf(privacyPolicy?.isRequired ? [true] : [true, false], t("The terms and conditions must be accepted.")),
  });
  return validationSchema;
};
