import clsx from "clsx";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import Dropzone, { FileRejection } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { IUploadLimitationDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { NumberHelpers } from "../../../../Helpers/NumberHelpers";
import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import { useAppContext } from "../../contexts/appContext";
import { useToggle } from "../../hooks/useToggle";
import GenericAlert from "../alerts/genericAlert";
import ButtonBlockWrapper from "../buttons/buttonBlockWrapper";
import { qmBaseIcons } from "../icons/qmBaseIcons";
import { LoadingComponent } from "../loading/loadingComponent";

interface IProps {
  isMulti?: boolean;
  limitations: IUploadLimitationDTO;
  /**
   * This can be used to trigger the upload directly.
   * This prop or **onDropLocal** must be used.
   */
  onDrop?: (dto: File[], fileRejections: FileRejection[]) => Promise<void>;
  /**
   * This property can used to store uploaded files in browser for later usage.
   * This prop or **onDrop** must be used.
   */
  onDropLocal?: (dto: File[], fileRejections: FileRejection[]) => void;
  onSuccess?: () => void;
}

function DropzoneComponent(props: IProps) {
  const { limitations, isMulti = false, onDrop, onDropLocal, onSuccess } = props;

  if (!onDrop && !onDropLocal) {
    throw Error("One onDrop method must be implemented.");
  }
  const { t } = useTranslation();
  const { handleApiError } = useAppContext();
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [active, toggleActive] = useToggle();

  const handleSuccess = React.useCallback(() => {
    setSuccess(true);
    onSuccess?.();
  }, [onSuccess]);

  const handleDrop = React.useCallback(
    async function (dto: File[], fileRejections: FileRejection[]) {
      setLoading(true);
      if (onDrop) {
        await wrapApiCallWithToast(() => onDrop(dto, []), { handleApiError, onSuccess: handleSuccess });
      } else if (onDropLocal) {
        onDropLocal(dto, fileRejections);
      }
      setLoading(false);
    },
    [handleApiError, handleSuccess, onDrop, onDropLocal],
  );

  const handleFilePaste = (clipboardData: DataTransfer) => handleDrop(Array.from(clipboardData.files), []);

  return (
    <Dropzone
      maxSize={NumberHelpers.megabyteToByte(limitations.fileSize)}
      accept={limitations.allowedExtensions}
      maxFiles={isMulti ? limitations.maxFileCount : 1}
      multiple={isMulti}
      onDrop={handleDrop}
      noClick
      noKeyboard
    >
      {({ getRootProps, getInputProps, acceptedFiles, fileRejections, open, isDragActive }) => (
        <div>
          {!success && acceptedFiles.length > 0 && (
            <GenericAlert type={"info"} heading={t("Files to upload")}>
              <ul>
                {acceptedFiles.map((x, i) => (
                  <li key={i}>
                    {x.name} - {(x.size / (1024 * 1024)).toFixed(2)} Mb
                  </li>
                ))}
              </ul>
            </GenericAlert>
          )}
          {success && acceptedFiles.length > 0 && (
            <GenericAlert type={"success"} heading={t("Upload completed")}>
              <ul>
                {acceptedFiles.map((x, i) => (
                  <li key={i}>
                    {x.name} - {(x.size / (1024 * 1024)).toFixed(2)} Mb
                  </li>
                ))}
              </ul>
            </GenericAlert>
          )}
          {fileRejections.length > 0 && (
            <GenericAlert type="danger" heading={t("Rejected files")}>
              <ul>
                {fileRejections.map((x, i) => (
                  <li key={i}>
                    {x.file.name} - {(x.file.size / (1024 * 1024)).toFixed(2)} Mb
                    <ul>
                      {x.errors.map((e) => (
                        <li key={e.code}>{e.message}</li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </GenericAlert>
          )}

          <div
            {...getRootProps()}
            className={clsx("mb-3 border-2 rounded", (active || isDragActive) && "border border-info")}
            style={{
              borderStyle: active ? undefined : "dashed",
              cursor: loading ? undefined : "pointer",
            }}
            onClick={() => toggleActive()}
            aria-hidden="true"
            onPaste={active ? (e) => handleFilePaste(e.clipboardData) : undefined}
          >
            <div>
              {loading && (
                <div className="d-flex align-items-center justify-content-center" style={{ minHeight: "10rem" }}>
                  <LoadingComponent />
                </div>
              )}
              {!loading && (
                <Row>
                  <Col
                    md={{ span: 6, offset: 3 }}
                    sm={{ span: 8, offset: 2 }}
                    xs={{ span: 10, offset: 1 }}
                    className="p-3 text-center"
                  >
                    <input {...getInputProps()} />
                    <qmBaseIcons.FileUpload size="xxl" className="text-muted" />
                    <p className="lead">{t("Upload files")}</p>
                    <p>{t("Drop or paste files here.")}</p>
                    {active && <p>{t("Pasting active")}</p>}
                    <ButtonBlockWrapper>
                      <Button variant="light" onClick={open}>
                        {t("Select files")}
                      </Button>
                    </ButtonBlockWrapper>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
}
export default DropzoneComponent;
