import { DateTime, Duration } from "luxon";

import { IQualificationDTO, ITimeIntervalDTO, TimeInterval } from "@generatedCode/pbd-core/pbd-core-api";

import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import { mapNullable } from "../../../Helpers/filterMap";
import { DeadlineResponse } from "./models/deadline-response";

export function calculateDeadlineAndWarningTime(
  currentDate: DateTime,
  surveillanceIntervalTimeSpan: string,
  warningTimeTimeSpan?: string,
): DeadlineResponse {
  const nextInspection = DateTimeLuxonHelpers.addTimeSpan(currentDate, surveillanceIntervalTimeSpan);
  const warningTime = mapNullable(warningTimeTimeSpan, (span) =>
    DateTimeLuxonHelpers.addTimeSpan(nextInspection, span),
  );

  return {
    nextInspection,
    warningTime,
  };
}

export function calculateTimeSpanFromInterval(value: number, type: TimeInterval): Duration {
  switch (type) {
    case TimeInterval.Year:
      return Duration.fromObject({ years: value });
    case TimeInterval.Month:
      return Duration.fromObject({ month: value });
    case TimeInterval.Week:
      return Duration.fromObject({ days: value * 7 });
    case TimeInterval.Day:
      return Duration.fromObject({ day: value });
    case TimeInterval.Hour:
      return Duration.fromObject({ hour: value });
    case TimeInterval.Minute:
      return Duration.fromObject({ minute: value });
    case TimeInterval.Second:
      return Duration.fromObject({ second: value });
    default:
      throw new Error("invalid time interval");
  }
}

export function sanitizeTimeInput<
  T extends Pick<IQualificationDTO, "isRecurring" | "monitoringInterval" | "useWarningTime" | "warningTimeInterval">,
>(dto: T) {
  if (dto.isRecurring && dto.monitoringInterval) {
    dto.monitoringInterval.timeSpanISO = calculateTimeSpanFromInterval(
      dto.monitoringInterval.value,
      dto.monitoringInterval.type,
    ).toISO();
    if (dto.useWarningTime && dto.warningTimeInterval) {
      dto.warningTimeInterval.timeSpanISO = calculateTimeSpanFromInterval(
        dto.warningTimeInterval.value,
        dto.warningTimeInterval.type,
      ).toISO();
    }
  }
  return dto;
}

export function isFirstIntervalGreaterThanSecond(
  monitoringInterval: ITimeIntervalDTO | undefined,
  warningTimeInterval: ITimeIntervalDTO | undefined,
): boolean {
  if (!monitoringInterval || !warningTimeInterval) return false;
  const monitoring = calculateTimeSpanFromInterval(monitoringInterval.value, monitoringInterval.type);
  const warning = calculateTimeSpanFromInterval(warningTimeInterval.value, warningTimeInterval.type);
  return monitoring > warning;
}
