import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import FormattedUserInput from "../../shared/components/text/formattedUserInput";
import { useExternalLoginHook } from "../../shared/hooks/useExternalLoginHook";

function CustomPrivacyPage() {
  const { t } = useTranslation();
  const { data } = useExternalLoginHook();
  const navigate = useNavigate();
  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <Button onClick={() => navigate(-1)}>
            <qmBaseIcons.ArrowLeft /> {t("Back to register")}
          </Button>
          {data?.privacyPolicySetting?.content && (
            <FormattedUserInput content={data.privacyPolicySetting.content} type="HTML" />
          )}
        </Card.Body>
      </Card>
    </>
  );
}
export default CustomPrivacyPage;
