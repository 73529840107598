import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";

import { IQualificationDTO } from "@generatedCode/pbd-core/pbd-core-api";

interface IProps {
  items: IQualificationDTO[];
}

const QualificationTablePrintView: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { items } = props;
  return (
    <React.Fragment>
      <h5>
        {t("Qualifications")} ({items.length})
      </h5>
      <Table className="table table-bordered">
        <thead>
          <tr style={{ borderStyle: "solid", borderColor: "black", fontSize: "large" }}>
            <th>{t("Qualification")}</th>
          </tr>
        </thead>
        <tbody>
          {items.map((x) => (
            <tr key={x.id} style={{ borderStyle: "solid", borderColor: "black" }}>
              <td>{x.title}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};
export default QualificationTablePrintView;
