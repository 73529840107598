import { Expression, Parser } from "expr-eval";

import { IOpportunityCategoryVariable } from "@generatedCode/pbd-core/pbd-core-api";

export type Variables = Record<string, number>;

export const FormulaHelpers = {
  /**RegExp to replace all @ */
  sanitizeFormula(formula: string): string {
    return formula.replace(/@/g, "");
  },

  isVariablesCorrect(formula: Expression, variables: IOpportunityCategoryVariable[]) {
    const variablesInFormula = formula.variables();
    const variablesAvailable = variables.map((x) => x.variableName);
    for (const variable of variablesInFormula) {
      if (!variablesAvailable.includes(variable)) {
        throw Error("Variable is not defined");
      }
    }
  },

  isValid(formula: string, variables?: IOpportunityCategoryVariable[]) {
    const parser = new Parser();
    try {
      const output = parser.parse(this.sanitizeFormula(formula));
      if (variables) {
        this.isVariablesCorrect(output, variables);
        const transVar = this.transformVariables(variables);
        output.evaluate(transVar);
        return { isValid: true };
      }
      throw new Error("Variables incorrect or not found");
    } catch (ex) {
      return { isValid: false, error: ex };
    }
  },

  calculateFormula(formula: string, variables: Variables): number {
    const parser = new Parser();
    const output = parser.parse(formula);
    return output.evaluate(variables);
  },

  arithmeticOperators: ["+", "-", "*", "/", "(", ")"],

  transformVariables(variables: IOpportunityCategoryVariable[]) {
    const variablesMinValue: Variables = {};
    for (const variable of variables) {
      const key: string = variable.variableName;
      variablesMinValue[key] = variable.scaleMinimum;
    }
    return variablesMinValue;
  },
};
