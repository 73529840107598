import { ITagDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { ListHelpers } from "../../../../Helpers/ListHelpers";
import TagComponent from "./tagComponent";

interface IProps {
  tags: ITagDTO[] | undefined;
}

function TagsInTable(props: IProps) {
  const { tags } = props;
  if (!tags || tags.length == 0) return null;
  const sorted = ListHelpers.sortByString(tags, (x) => x.title);
  return (
    <>
      {sorted.map((x) => (
        <TagComponent key={x.id} tag={x} />
      ))}
    </>
  );
}

export default TagsInTable;
