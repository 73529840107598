import { useTranslation } from "react-i18next";

import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { CostRoutePaths } from "../../costs/costRoutePaths";
import { GoalProgressRoutePaths } from "../../goalProgresses/goalProgressRoutePaths";
import { SettingsRoutePaths } from "../../settings/settingsRoutePaths";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { GoalRoutePaths } from "../goalRoutePaths";

const SidebarGoals = () => {
  const { t } = useTranslation();
  const links: SidebarItem[] = [
    SidebarItem.created(GoalRoutePaths.CreatePage, t),
    SidebarItem.overview(GoalRoutePaths.IndexPage + "?status=Open&status=InProgress", t, t("Goals")),
  ];
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.ChartBar,
      title: t("Progress"),
      href: GoalProgressRoutePaths.IndexPage,
    }),
  );
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.Graph,
      title: t("Graph"),
      href: GoalRoutePaths.Graph,
    }),
  );
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.Comments,
      title: t("Comments"),
      href: GoalRoutePaths.CommentsPage,
    }),
  );
  links.push(
    SidebarItem.costs(
      `${CostRoutePaths.IndexPageCostsByModule.replace(
        ":pbdModule",
        GoalRoutePaths.HomePage.replace("/", ""),
      )}/?keyName=Goal`,
      t,
    ),
  );
  links.push(SidebarItem.settings(SettingsRoutePaths.GoalHome, t));
  return <SidebarNew app={PbdModule.GoalManagement} items={links} showSmartViews />;
};

export default SidebarGoals;
