import React from "react";
import { useTranslation } from "react-i18next";

import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { CostRoutePaths } from "../../costs/costRoutePaths";
import { SettingsRoutePaths } from "../../settings/settingsRoutePaths";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { ClaimRoutePaths } from "../claimRoutePaths";

/**
 * Claims sidebar
 * */
const SidebarClaims: React.FC = () => {
  const { t } = useTranslation();
  const links: SidebarItem[] = [
    SidebarItem.created(ClaimRoutePaths.ChooseTemplate, t),
    SidebarItem.overview(ClaimRoutePaths.IndexPage + "/?status=Open&status=InProgress", t),
  ];
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.ListCheck,
      title: t("8D-Reports"),
      href: ClaimRoutePaths.IndexPage8DReports,
    }),
  );
  links.push(
    SidebarItem.costs(
      `${CostRoutePaths.IndexPageCostsByModule.replace(":pbdModule", PbdModule.ClaimManagement)}/?keyName=Claim&app=${
        PbdModule.ClaimManagement
      }`,
      t,
    ),
  );

  links.push(SidebarItem.settings(SettingsRoutePaths.ClaimManagementHome, t));

  return <SidebarNew app={PbdModule.ClaimManagement} items={links} />;
};

export default SidebarClaims;
