import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { ICustomField, IEntityTemplateDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { PbdRoles } from "../../services/Authz/PbdRoles";
import { PbdRouteModel } from "../router/pbdRouteModel";
import { TaskManagementRoutePaths } from "./taskManagementRoutePaths";

export class TodoSettings {
  customFields?: ICustomField[];
  templates?: IEntityTemplateDTO[];
}

const defaultRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.ProjectAndTaskManagement_ModuleAdmin, PbdRoles.ProjectAndTaskManagement_Standard],
};

export const taskManagementLazyRoutes: RouteObject[] = [
  {
    path: TaskManagementRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePage")),
    ...defaultRouteParams,
  },
  {
    path: TaskManagementRoutePaths.SelectPage,
    Component: lazy(() => import("./pages/selectCreatePage")),
    ...defaultRouteParams,
  },
  {
    path: TaskManagementRoutePaths.CreateToDoPage,
    Component: lazy(() => import("../todos/pages/createPageTodo")),
    exact: false,
    ...defaultRouteParams,
  },
  {
    path: TaskManagementRoutePaths.EditTodoPage,
    Component: lazy(() => import("../todos/pages/detailsPageTodo")),
    exact: false,
    ...defaultRouteParams,
  },
  {
    path: TaskManagementRoutePaths.IndexTodoPage,
    Component: lazy(() => import("../todos/pages/indexPageTodo")),
    ...defaultRouteParams,
  },
  {
    path: TaskManagementRoutePaths.IndexProjectsPage,
    Component: lazy(() => import("../projects/pages/indexPageProject")),
    ...defaultRouteParams,
  },
  {
    path: TaskManagementRoutePaths.CreateProjectPage,
    Component: lazy(() => import("../projects/pages/createPageProject")),
    ...defaultRouteParams,
  },
  {
    path: TaskManagementRoutePaths.EditProjectPage,
    Component: lazy(() => import("../projects/pages/detailsPageProject")),
    ...defaultRouteParams,
  },
];
