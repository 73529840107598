import { RouteObject } from "react-router-dom";

import { ErrorPage } from "../shared/pages/errorPage";

import { AccountRoutePaths } from "./accountRoutePaths";
import AccountRequestPage from "./pages/accountRequestPage";
import ActivatePageProfile from "./pages/activatePageProfile";
import ConfirmChangeEmailPage from "./pages/confirmChangeEmailPage";
import ConfirmEmailPage from "./pages/confirmEmailPage";
import CustomPrivacyPage from "./pages/customPrivacyPage";
import ExternalLoginCallbackPage from "./pages/externalLoginCallbackPage";
import ForgotPasswordPage from "./pages/forgotPasswordPage";
import GoodbyePage from "./pages/goodbyePage";
import LoginPage from "./pages/loginPage";
import LoginWith2FaPage from "./pages/loginWith2FaPage";
import LoginWithRecoveryCodePage from "./pages/loginWithRecoveryCodePage";
import RegisterPage from "./pages/registerPage";
import ResetPasswordPage from "./pages/resetPasswordPage";

export const accountObjects: RouteObject[] = [
  {
    path: AccountRoutePaths.Login,
    element: <LoginPage />,
  },
  {
    path: AccountRoutePaths.LoginWith2FaPage,
    element: <LoginWith2FaPage />,
  },
  {
    path: AccountRoutePaths.LoginWithRecoveryCodePage,
    element: <LoginWithRecoveryCodePage />,
  },
  {
    path: AccountRoutePaths.Register,
    element: <RegisterPage />,
  },
  {
    path: AccountRoutePaths.Request,
    element: <AccountRequestPage />,
  },
  {
    path: AccountRoutePaths.ConfirmEmail,
    element: <ConfirmEmailPage />,
  },
  {
    path: AccountRoutePaths.ConfirmChangeEmail,
    element: <ConfirmChangeEmailPage />,
  },
  {
    path: AccountRoutePaths.ForgotPassword,
    element: <ForgotPasswordPage />,
  },
  {
    path: AccountRoutePaths.ResetPassword,
    element: <ResetPasswordPage />,
  },
  {
    path: AccountRoutePaths.Goodbye,
    element: <GoodbyePage />,
  },
  {
    path: AccountRoutePaths.ExternalLoginCallback,
    element: <ExternalLoginCallbackPage />,
  },
  {
    path: AccountRoutePaths.ErrorPage,
    element: <ErrorPage />,
  },
  {
    path: AccountRoutePaths.ActivateProfilePage,
    element: <ActivatePageProfile />,
  },
  {
    path: AccountRoutePaths.PrivacyPage,
    element: <CustomPrivacyPage />,
  },
];
