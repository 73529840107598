import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  ConnectionsToEightDReportDTO,
  EightDReportStep,
  IEightDReportEditDTO,
} from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import { EightDReportStepDataValue } from "../../../Models/EightDReports/EightDReportStep";
import { PrintOptions } from "../../prints/components/printoutIncludeOptions";
import { PrintRoutePaths } from "../../prints/printRoutePaths";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import { useToggle } from "../../shared/hooks/useToggle";
import { isDimensionReady } from "../helpers/helperFunctions";
import EightDReportDescriptionForm, { DescriptionFormValues } from "./eightDReportDescriptionForm";
import EightDReportDescriptionRenderer from "./eightDReportDescriptionRenderer";
import FilloutSectionPlaceholder from "./filloutSectionPlaceholder";

interface IProps {
  itemToUpdate: IEightDReportEditDTO;
  refreshParent: () => void;
  step: EightDReportStepDataValue;
  printOptions?: PrintOptions;
}

function CongratulationsCard(props: IProps) {
  const { itemToUpdate, refreshParent, step, printOptions } = props;
  const { t } = useTranslation();
  const { eightDReportsApi } = useAPIs();
  const [editMode, toggleEditMode] = useToggle();
  const location = useLocation();
  const isPrintView = location.pathname == PrintRoutePaths.EightDReports.replace(":id", itemToUpdate.id.toString());

  const handleSetCongratulation = (values: DescriptionFormValues) => {
    return eightDReportsApi.setReportStep(
      itemToUpdate.id,
      new ConnectionsToEightDReportDTO({
        step: step.step,
        description: values.description,
        showClaim: false,
        teamLeaderId: 0,
      }),
    );
  };

  if (printOptions?.showOnlyCompletedSections && !isDimensionReady(itemToUpdate, EightDReportStep.Congratulations))
    return null;

  return (
    <Card className="mb-3">
      <Card.Header>
        <div className="d-flex justify-content-between">
          <Card.Title as="h5">D8 - {t("Congratulations")}</Card.Title>
          <div>
            {!isPrintView && (
              <Button variant="primary" onClick={toggleEditMode}>
                <qmBaseIcons.PencilAlt />
              </Button>
            )}
          </div>
        </div>
      </Card.Header>
      {editMode ? (
        <Card.Body>
          <EightDReportDescriptionForm
            onSubmit={handleSetCongratulation}
            valueToUpdate={itemToUpdate.congratulationsDescription}
            heading={t("Description")}
            toggleEditMode={toggleEditMode}
            onSuccess={() => {
              refreshParent();
              toggleEditMode();
            }}
            step={step}
          />
        </Card.Body>
      ) : (
        <Card.Body>
          {!isPrintView && (
            <FilloutSectionPlaceholder show={!itemToUpdate.isCongratulationsReady} toggleEditMode={toggleEditMode} />
          )}
          {itemToUpdate.congratulationsDescription && (
            <EightDReportDescriptionRenderer
              includeImagesInPrintView={printOptions?.includeImages}
              description={itemToUpdate.congratulationsDescription}
            />
          )}
        </Card.Body>
      )}
    </Card>
  );
}

export default CongratulationsCard;
