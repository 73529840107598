import { sortBy } from "lodash";

import {
  CompanyFunctionCreateDTO,
  ConnectQualificationsDTO,
  ICompanyFunctionDTO,
  ICompanyFunctionsControllerClient,
  IDepartmentPositionForTenantDTO,
  IDepartmentPositionWithChildrenDTO,
} from "@generatedCode/pbd-core/pbd-core-api";

import StringHelpers from "../../../Helpers/StringHelpers";
import { BaseExportService } from "../Base/BaseExportService";
import { DepartmentPositionFitnessService } from "../DepartmentPositions/departmentPositionFitnessService";
import { ExportType } from "../Export/exportService";
import { CopyForm } from "../copy/copyService";
import { CompanyFunctionQueryParameters } from "./models/query-parameters";

export default class CompanyFunctionService extends BaseExportService<ICompanyFunctionDTO> {
  private _api: ICompanyFunctionsControllerClient;
  constructor(api: ICompanyFunctionsControllerClient) {
    super("Company functions");
    this._api = api;
  }

  async getAllAsVm(query: CompanyFunctionQueryParameters = {}) {
    return this._api.getAllQuery(query).then((resp) => sortBy(resp, (x) => x.title));
  }

  static getFormula(dto: ICompanyFunctionDTO): string {
    if (!dto.requiredQualifications || dto.requiredQualifications.length == 0) return "";
    if (!StringHelpers.isNullOrWhitespace(dto.formula)) return dto.formula!;
    if (dto.requiredQualifications.find((x) => x.weighting != 1) == undefined) return "";
    const form = `(${dto.requiredQualifications.map((r) => `QualificationId_${r.id}*${r.weighting}`).join("+")})/${
      dto.requiredQualifications.length
    }`;
    return form;
  }

  static showLegacyFormula(dto: ICompanyFunctionDTO): boolean {
    if (dto.requiredQualifications?.find((x) => x.weighting != 1) == undefined) {
      return false;
    } else {
      return true;
    }
  }

  static isDefaultCalculation(dto: ICompanyFunctionDTO): boolean {
    if (!StringHelpers.isNullOrWhitespace(dto.formula)) return false;
    if (this.showLegacyFormula(dto)) return false;
    return true;
  }

  static getRequiredCompanyFunctions(
    departmentPositionForTenant: IDepartmentPositionForTenantDTO,
    departmentPositions?: IDepartmentPositionWithChildrenDTO[],
    companyFunctions?: ICompanyFunctionDTO[],
  ) {
    let data: ICompanyFunctionDTO[] = [];
    if (departmentPositions) {
      const departmentPosition = departmentPositions.find((y) => y.id == departmentPositionForTenant.id);
      if (departmentPosition && companyFunctions) {
        data = DepartmentPositionFitnessService.getUniqueCompanyFunctions([departmentPosition], companyFunctions);
      }
    }
    return data;
  }

  mapToExport(x: ICompanyFunctionDTO): ExportType {
    return {
      id: x.id,
      title: x.title,
      countOfConnectedElements: x.requiredQualifications?.length,
      connectedQualifications: x.requiredQualifications?.map((x) => x.title).join(", "),
      tags: x.tags?.map((x) => x.title).join(", "),
      createdAt: x.createdAt,
      responsible: x.responsible?.fullName,
      description: x.description,
    };
  }

  async copy(form: CopyForm, item: ICompanyFunctionDTO, responsibleId: number) {
    const itemToCreate = new CompanyFunctionCreateDTO({
      title: form.title,
      description: item.description,
      responsibleId: responsibleId,
    });

    const resp = await this._api.create(itemToCreate);

    if (form.options.requiredQualification && item.requiredQualifications && item.requiredQualifications.length > 0) {
      const qualifications = new ConnectQualificationsDTO({
        qualificationIds: item.requiredQualifications.map((x) => x.id),
      });
      await this._api.addQualifications(resp.id, qualifications);
    }

    return resp;
  }
}
