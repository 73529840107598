import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormGroup, Input, Label } from "reactstrap";

import { PbdStatus } from "@generatedCode/pbd-core/pbd-core-api";

import DepartmentPositionSelect from "../inputControl/select/departmentPositionSelect";
import DepartmentSelect from "../inputControl/select/departmentSelect";
import StatusSelect from "../inputControl/select/statusSelect";
import TagSelect from "../inputControl/select/tagSelect";
import { FilterElement, SearchFilterTypes } from "./availableSearchFilters";
import FilterItemDefaultContent from "./filterItemDefaultContent";

interface IProps {
  item: FilterElement;
  onChange: (dto: FilterElement) => void;
}

const FilterItemContent: React.FC<IProps> = (props) => {
  const { item, onChange } = props;
  const { t } = useTranslation();

  const handleChange = (value: string | number | string[] | number[] | boolean | Date | null | undefined) => {
    const copy = { ...item };
    copy.queryValue = value;
    onChange(copy);
  };

  switch (item.type) {
    case SearchFilterTypes.Tags:
      return (
        <FormGroup>
          <Label>{t("Tags")}</Label>
          <TagSelect onChange={handleChange} isMulti isClearable selectedIds={item.queryValue as string[]} />
        </FormGroup>
      );
    case SearchFilterTypes.Department:
      return (
        <FormGroup>
          <Label>{t("Departments")}</Label>
          <DepartmentSelect onChange={handleChange} selectedIds={item.queryValue as number[]} isMulti isClearable />
        </FormGroup>
      );
    case SearchFilterTypes.DepartmentPosition:
      return (
        <FormGroup>
          <Label>{t("Position")}</Label>
          <DepartmentPositionSelect onChange={handleChange} isMulti selectedIds={item.queryValue as number[]} />
        </FormGroup>
      );
    case SearchFilterTypes.IsDeleted:
      return (
        <FormGroup>
          <Form.Check
            type="switch"
            id={item.queryKey}
            onChange={(e) => handleChange(e.target.checked)}
            name={item.filterType}
            label={t("Deleted")}
            defaultChecked={item.queryValue as boolean}
          />
        </FormGroup>
      );
    case SearchFilterTypes.IsEmployee:
      return (
        <FormGroup>
          <Form.Check
            type="switch"
            id={item.queryKey}
            onChange={(e) => handleChange(e.target.checked)}
            name={item.filterType}
            label={t("Only employees")}
            defaultChecked={item.queryValue as boolean}
          />
        </FormGroup>
      );
    case SearchFilterTypes.CreatedFrom:
      return (
        <FormGroup>
          <Label>{t("Created from")}</Label>
          <Input
            type="datetime-local"
            value={item.queryValue as string}
            onChange={(e) => handleChange(e.target.value)}
          />
        </FormGroup>
      );
    case SearchFilterTypes.CreatedTo:
      return (
        <FormGroup>
          <Label>{t("Created to")}</Label>
          <Input
            type="datetime-local"
            value={item.queryValue as string}
            onChange={(e) => handleChange(e.target.value)}
          />
        </FormGroup>
      );
    case SearchFilterTypes.Status:
      return (
        <FormGroup>
          <Label>{t("Status")}</Label>
          <StatusSelect
            isMulti
            isClearable
            onChange={(e) => handleChange(e)}
            selectedIds={item.queryValue as PbdStatus[] | undefined}
          />
        </FormGroup>
      );
    case SearchFilterTypes.claimIsJustified:
      return (
        <FormGroup>
          <Form.Check
            type="switch"
            id={item.queryKey}
            onChange={(e) => handleChange(e.target.checked)}
            name={item.filterType}
            label={t("Justified")}
            defaultChecked={item.queryValue as boolean}
          />
        </FormGroup>
      );
    default:
      return <FilterItemDefaultContent {...props} />;
  }
};
export default FilterItemContent;
