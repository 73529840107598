import i18next from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { CellProps, Column } from "react-table";

import { IAbsencePolicyDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { DateTimeLuxonHelpers } from "../../../../Helpers/DateTimeLuxonHelpers";
import CategorySpan from "../../../shared/components/categories/categorySpan";
import IdComponent from "../../../shared/components/id/idComponent";
import BaseTableProps from "../../../shared/components/tables/BaseTableProps";
import VisibleColumns from "../../../shared/components/tables/VisibleColumns";
import { getCreatedAtColumn } from "../../../shared/components/tables/columns/createdAtColumn";
import { ReactstrapTable } from "../../../shared/components/tables/reactstrapTable";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

type IProps = BaseTableProps<IAbsencePolicyDTO>;

export const AbsencePolicyTable: React.FC<IProps> = (props) => {
  const { columnsVisibleDefault = VisibleColumns.absencePolicyColumns } = props;

  const columns = React.useMemo<Column<IAbsencePolicyDTO>[]>(
    () => [
      {
        id: "id",
        Header: "ID",
        accessor: "id", // accessor is the "key" in the data
        Cell: ({ row }) => (
          <>
            <Link to={SettingsRoutePaths.EditPageAbsencePolicy.replace(":id", row.original.id.toString())}>
              #{row.original.id}
            </Link>
          </>
        ),
      },
      {
        id: "title",
        Header: <>{i18next.t("Title")}</>,
        accessor: "title",
        Cell: ({ row }) => (
          <>
            <Link to={SettingsRoutePaths.EditPageAbsencePolicy.replace(":id", row.original.id.toString())}>
              {row.original.title}
            </Link>
          </>
        ),
      },
      {
        id: "absenceType",
        Header: <>{i18next.t("Type")}</>,
        accessor: "absenceType",
        Cell: ({ row }: CellProps<IAbsencePolicyDTO>) => (
          <>{row.original.absenceType && <CategorySpan item={row.original.absenceType} />}</>
        ),
      },
      {
        id: "availableDays",
        Header: <>{i18next.t("Available days")}</>,
        accessor: "availableDays",
        Cell: ({ row }) => <>{row.original.availableDays}</>,
      },
      {
        id: "validFrom",
        Header: <>{i18next.t("Valid from")}</>,
        accessor: (x) => x.validFrom.toMillis(),
        Cell: ({ row }: CellProps<IAbsencePolicyDTO>) => (
          <>{DateTimeLuxonHelpers.convertUtcToDate(row.original.validFrom)}</>
        ),
      },
      {
        id: "validTo",
        Header: <>{i18next.t("Valid to")}</>,
        accessor: (x) => x.validTo.toMillis(),
        Cell: ({ row }: CellProps<IAbsencePolicyDTO>) => (
          <>{DateTimeLuxonHelpers.convertUtcToDate(row.original.validTo)}</>
        ),
      },
      {
        id: "absencePolicyParentId",
        Header: <>{i18next.t("Parent policy")}</>,
        accessor: "absencePolicyParentId",
        Cell: ({ row }) => (
          <>
            {row.original.absencePolicyParentId && (
              <Link
                to={SettingsRoutePaths.EditPageAbsencePolicy.replace(
                  ":id",
                  row.original.absencePolicyParentId.toString(),
                )}
              >
                {i18next.t("Policy")} <IdComponent id={row.original.absencePolicyParentId} />
              </Link>
            )}
          </>
        ),
      },
      getCreatedAtColumn(),
    ],
    [],
  );

  return (
    <React.Fragment>
      <ReactstrapTable<IAbsencePolicyDTO>
        columns={columns}
        columnsVisibleDefault={columnsVisibleDefault}
        localStorageStateKey={"AbsencePolicyDTO"}
        {...props}
      />
    </React.Fragment>
  );
};
