import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import {
  CategoryCreateDTO,
  ICategoryCreateDTO,
  ICategoryWithCustomFieldsDTO,
} from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { ICategoryEntityTemplate } from "../../../../Models/EntityTemplates/CategoryEntityTemplate";
import EntityTemplatesButton from "../../../shared/components/entityTemplates/entityTemplatesButton";
import { useFeatureFlagHook } from "../../../shared/hooks/useFeatureFlagHook";
import { useRefreshHook } from "../../../shared/hooks/useRefreshHook";
import BaseSettingsForm, { hasId } from "../../components/editPage/baseSettingsForm";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

const EditPageInventoryCategories = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const { inventoryCategoriesApi } = useAPIs();
  const { data } = useFeatureFlagHook();
  const [loading, setLoading] = React.useState(true);
  const [itemToUpdate, setItemToUpdate] = React.useState<ICategoryWithCustomFieldsDTO>();
  const [{ refresh, handleRefresh }] = useRefreshHook();

  const { data: existingInventoryCategories } = useSWR(`inventoryCategories`, () => inventoryCategoriesApi.getAll());

  React.useEffect(() => {
    async function getData() {
      const type = await inventoryCategoriesApi.getById(Number(id));
      setItemToUpdate(type);
    }
    if (id != null) {
      getData().then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [id, refresh]);

  async function handleSubmit(itemToCreate: ICategoryCreateDTO) {
    if (id) {
      return inventoryCategoriesApi.edit(Number(id), new CategoryCreateDTO(itemToCreate));
    } else {
      return inventoryCategoriesApi.create(new CategoryCreateDTO(itemToCreate));
    }
  }

  async function toggleDelete() {
    if (!itemToUpdate) throw Error("Item missing");
    if (itemToUpdate.isDeleted) {
      await inventoryCategoriesApi.restore(itemToUpdate.id);
    } else {
      await inventoryCategoriesApi.delete(itemToUpdate.id);
    }
    handleRefresh();
  }

  const handleTemplateClick = (dto: ICategoryEntityTemplate) => {
    const itemToCreate: ICategoryCreateDTO = {
      title: dto.title,
      description: dto.description,
      color: dto.color ?? undefined,
      isRecurring: false,
    };
    return inventoryCategoriesApi.create(new CategoryCreateDTO(itemToCreate));
  };

  const handleSuccess = (dto?: unknown) => {
    if (hasId(dto)) {
      navigate(SettingsRoutePaths.EditPageInventoryCategories.replace(":id", dto.id.toString()));
    } else {
      handleRefresh();
    }
  };

  const entityTemplateButton = (
    <EntityTemplatesButton
      onSelect={handleTemplateClick}
      templateName={"inventoryCategory"}
      onSuccess={handleSuccess}
    />
  );

  if (loading) return null;

  return (
    <BaseSettingsForm
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      itemToUpdate={itemToUpdate}
      onDelete={toggleDelete}
      onRestore={(tId) => inventoryCategoriesApi.restore(tId)}
      additionalFields={data?.isLegacyInventoryCategoryCustomFieldsAvailable ? ["Color", "OldCustomFields"] : ["Color"]}
      handleRefresh={handleRefresh}
      entityTemplateComponent={entityTemplateButton}
      existingItems={existingInventoryCategories}
      formType={"InventoryCategory"}
    />
  );
};

export default EditPageInventoryCategories;
