import React from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { HttpVerbs, ICategoryDTO } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { ErrorMessage } from "../../../../Models/Errors/ErrorMessage";
import { SettingsIndexDTO } from "../../../../Models/Settings/SettingsIndexDTO";
import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import SwrApiHelpers from "../../../../pbdServices/services/Api/swr-api-helpers";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { hasRole } from "../../../../services/Authz/authService";
import { TableClickCommand } from "../../../shared/components/tables/TableClickCommand";
import { useAppContext } from "../../../shared/contexts/appContext";
import { useConfirmation } from "../../../shared/contexts/modalConfirmationContext";
import { useRecycleBinHook } from "../../../shared/hooks/useRecycleBinHook";
import BaseSettingsIndexCard from "../../components/baseSettingsIndexCard";
import { BaseSettingsTable } from "../../components/baseSettingsTable";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

export function mapDataTrainingTypeToSettingsDTO(data?: ICategoryDTO[]) {
  if (!data) return undefined;
  const tableData: SettingsIndexDTO[] = [];
  data.forEach((d) => {
    const item = new SettingsIndexDTO({
      id: d.id,
      title: d.title,
      color: d.color,
      createdAt: d.createdAt,
      links: [
        {
          rel: "self",
          href: SettingsRoutePaths.EditPageTrainingTypes.replace(":id", d.id.toString()),
          method: HttpVerbs.GET,
        },
      ],
    });
    tableData.push(item);
  });
  return tableData;
}

const IndexPageTrainingTypes: React.FC = () => {
  const { showRecycleBin, toggleRecycleBin } = useRecycleBinHook();
  const navigate = useNavigate();
  const { trainingTypesApi } = useAPIs();
  const { meAsUser, setErrorMessage, handleApiError } = useAppContext();
  const [selected, setSelected] = React.useState<SettingsIndexDTO[]>([]);
  const confirm = useConfirmation();

  const { data, isValidating, mutate } = useSWR(
    [`/api/trainingTypes`, showRecycleBin],
    () => {
      return trainingTypesApi.getAllQuery({
        isDeleted: showRecycleBin,
      });
    },
    SwrApiHelpers.defaultIndexPageOptions(),
  );

  const handleClick = (command: TableClickCommand) => {
    if (command.action == "Delete") {
      if (selected) {
        confirm({ itemsToDelete: selected }).then(() => submitDeleteRequest(selected.map((x) => Number(x.id))));
      }
    }
  };

  const submitDeleteRequest = async (items: number[]) => {
    if (!hasRole(meAsUser, [PbdRoles.TrainingManagement_Settings])) {
      setErrorMessage(new ErrorMessage(403));
    } else {
      for (const s of items) {
        await wrapApiCallWithToast(() => trainingTypesApi.delete(Number(s)), { handleApiError });
      }
      mutate();
    }
  };

  const tableRows = React.useMemo(() => mapDataTrainingTypeToSettingsDTO(data), [data]);

  return (
    <React.Fragment>
      <BaseSettingsIndexCard
        data={data}
        cardTitle="Training categories"
        showRecycleBin={showRecycleBin}
        toggleRecycleBin={toggleRecycleBin}
        pathToCreate={SettingsRoutePaths.CreatePageTrainingTypes}
        loading={isValidating}
      >
        <BaseSettingsTable
          tableRows={tableRows}
          onCreateClick={() => navigate(SettingsRoutePaths.CreatePageTrainingTypes)}
          setSelected={(ids) => setSelected(ids.map((x) => x))}
          availableTableActions={["Delete"]}
          onClick={handleClick}
        />
      </BaseSettingsIndexCard>
    </React.Fragment>
  );
};

export default IndexPageTrainingTypes;
