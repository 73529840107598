import React from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  ConnectionsToEightDReportDTO,
  EightDReportStep,
  ICustomField,
  IEightDReportEditDTO,
  IFileDTO,
  IPurchaseOrderDTO,
  ISalesOrderDTO,
} from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import { EightDReportStepDataValue } from "../../../Models/EightDReports/EightDReportStep";
import FileRoutePaths from "../../files/fileRoutePaths";
import { PrintOptions } from "../../prints/components/printoutIncludeOptions";
import { PrintRoutePaths } from "../../prints/printRoutePaths";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import { useToggle } from "../../shared/hooks/useToggle";
import { isDimensionReady } from "../helpers/helperFunctions";
import ClaimDetailsHorizontal from "./claimDetailsHorizontal";
import EightDReportDescriptionForm, { DescriptionFormValues } from "./eightDReportDescriptionForm";
import EightDReportDescriptionRenderer from "./eightDReportDescriptionRenderer";
import FilloutSectionPlaceholder from "./filloutSectionPlaceholder";

interface IProps {
  itemToUpdate: IEightDReportEditDTO;
  refreshParent: () => void;
  claimPurchaseOrders?: IPurchaseOrderDTO[];
  claimSalesOrders?: ISalesOrderDTO[];
  customFieldsForModule: ICustomField[];
  files?: IFileDTO[];
  printOptions?: PrintOptions;
  step: EightDReportStepDataValue;
}

function ClaimCard(props: IProps) {
  const { itemToUpdate, refreshParent, files, printOptions, step } = props;
  const { t } = useTranslation();
  const { eightDReportsApi } = useAPIs();
  const [editMode, toggleEditMode] = useToggle();
  const [showClaim, setShowClaim] = React.useState<boolean>(false);
  const location = useLocation();
  const isPrintView = location.pathname == PrintRoutePaths.EightDReports.replace(":id", itemToUpdate.id.toString());

  const handleSetProblem = (values: DescriptionFormValues) => {
    return eightDReportsApi.setReportStep(
      itemToUpdate.id,
      new ConnectionsToEightDReportDTO({
        step: EightDReportStep.Problem,
        showClaim: showClaim,
        description: values.description,
        teamLeaderId: 0,
      }),
    );
  };

  if (printOptions?.showOnlyCompletedSections && !isDimensionReady(itemToUpdate, EightDReportStep.Problem)) return null;

  return (
    <Card className="mb-3">
      <Card.Header>
        <div className="d-flex justify-content-between">
          <Card.Title as="h5">{t("D2 - Problem")}</Card.Title>
          {!isPrintView && (
            <div>
              <Button variant="primary" onClick={toggleEditMode}>
                <qmBaseIcons.Pencil />
              </Button>
            </div>
          )}
        </div>
      </Card.Header>
      {editMode ? (
        <Card.Body>
          <Form.Check
            type="checkbox"
            id="checkBoxShowClaim"
            label={t("Show claim in 8D-Report")}
            onChange={(e) => setShowClaim(e.target.checked)}
            defaultChecked={itemToUpdate.showClaim}
          />
          {itemToUpdate.claim?.product && (
            <ClaimDetailsHorizontal
              claim={itemToUpdate.claim}
              claimProducts={itemToUpdate.claim.products}
              claimPurchaseOrders={props.claimPurchaseOrders}
              claimSalesOrders={props.claimSalesOrders}
              customFieldsForModule={props.customFieldsForModule}
            />
          )}

          <EightDReportDescriptionForm
            onSubmit={handleSetProblem}
            valueToUpdate={itemToUpdate.problemDescription}
            heading={t("Description")}
            toggleEditMode={toggleEditMode}
            onSuccess={() => {
              refreshParent();
              toggleEditMode();
            }}
            step={step}
          />
        </Card.Body>
      ) : (
        <Card.Body>
          {!isPrintView && (
            <FilloutSectionPlaceholder show={!itemToUpdate.isProblemReady} toggleEditMode={toggleEditMode} />
          )}
          {itemToUpdate.claim && itemToUpdate.isProblemReady && itemToUpdate.showClaim && (
            <ClaimDetailsHorizontal
              claim={itemToUpdate.claim}
              claimProducts={itemToUpdate.claim.products}
              claimPurchaseOrders={props.claimPurchaseOrders}
              claimSalesOrders={props.claimSalesOrders}
              customFieldsForModule={props.customFieldsForModule}
            />
          )}

          {itemToUpdate.problemDescription && (
            <EightDReportDescriptionRenderer
              includeImagesInPrintView={printOptions?.includeImages}
              description={itemToUpdate.problemDescription}
            />
          )}

          <hr />
          {printOptions?.includeImages &&
            files
              ?.filter((x) => x.mimeType?.startsWith("image/"))
              .map((x) => (
                <div key={x.id}>
                  <div className="col-lg-3 col-md-4 col-xs-6">
                    <a href={FileRoutePaths.SaSUrl(x.id)} className="d-block mb-4 h-100">
                      <img
                        alt="thumbnail"
                        className="img-fluid img-thumbnail"
                        src={FileRoutePaths.SaSUrl(x.id)}
                        title={x.title}
                      />
                    </a>
                  </div>
                  <hr />
                </div>
              ))}
        </Card.Body>
      )}
    </Card>
  );
}

export default ClaimCard;
