import React from "react";
import { Card, CardTitle, Col, ListGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import useSWR from "swr";

import {
  CategoryCreateDTO,
  ConnectCompanyFunctionsDTO,
  ConnectDepartmentPositionsDTO,
  IBaseMinDTO,
  ICategoryCreateDTO,
  IDepartmentPositionDTO,
  ParentChildType,
} from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import { wrapApiCallWithToast } from "../../../pbdServices/services/Api/api-wrapper";
import BaseSettingsForm, { hasId } from "../../settings/components/editPage/baseSettingsForm";
import { SettingsRoutePaths } from "../../settings/settingsRoutePaths";
import CompanyFunctionsConnectedCard from "../../shared/components/connectionElements/companyFunctions/companyFunctionsConnectedCard";
import DepartmentPositonsConnectedCard from "../../shared/components/connectionElements/departmentPositions/departmentPositionsConnectedCard";
import { useConfirmation } from "../../shared/contexts/modalConfirmationContext";
import { useDepartmentPositionsHook } from "../../shared/hooks/useDepartmentPositionsHook";
import { useRefreshHook } from "../../shared/hooks/useRefreshHook";

function EditPageDepartmentPosition() {
  const { t } = useTranslation();
  const { id } = useParams<{ id?: string }>();
  const { departmentPositionsApi, companyFunctionsApi } = useAPIs();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [itemToUpdate, setItemToUpdate] = React.useState<IDepartmentPositionDTO>();
  const [childPositions, setChildPositions] = React.useState<IDepartmentPositionDTO[]>();
  const [parentPositions, setParentPositions] = React.useState<IDepartmentPositionDTO[]>();
  const [{ refresh, handleRefresh }] = useRefreshHook();
  const confirm = useConfirmation();

  const { data: existingDepartmentPositions } = useDepartmentPositionsHook({});

  const { data: connectedCompanyFunctions, mutate: mutateCompanyFunctions } = useSWR(
    id ? `/api/departmentPositions/${id}/companyFunctions` : null,
    () => companyFunctionsApi.getAllQuery({ departmentPositionId: [Number(id)] }),
  );

  const tryToDelete = (dto: IBaseMinDTO) => {
    confirm({ itemsToDelete: [{ id: dto.id, title: dto.title }] }).then(() => submitDeleteRequest(dto));
  };

  React.useEffect(() => {
    async function getData() {
      if (id) {
        setLoading(true);
        const item = await departmentPositionsApi.getById(Number(id));
        setItemToUpdate(item);

        const children = await departmentPositionsApi.getConnectedDepartmentPositions(
          Number(id),
          ParentChildType.Child,
        );
        setChildPositions(children);
        const parents = await departmentPositionsApi.getConnectedDepartmentPositions(
          Number(id),
          ParentChildType.Parent,
        );
        setParentPositions(parents);
      }
      setLoading(false);
    }
    getData();
  }, [id, refresh]);

  async function handleSubmit(itemToCreate: ICategoryCreateDTO) {
    if (id) {
      return departmentPositionsApi.edit(Number(id), new CategoryCreateDTO(itemToCreate));
    } else {
      return departmentPositionsApi.create(new CategoryCreateDTO(itemToCreate));
    }
  }

  const submitDeleteRequest = async (dto: IBaseMinDTO) => {
    const resp = await wrapApiCallWithToast(() => departmentPositionsApi.hardDelete(dto.id));
    if (resp.isOk) {
      navigate(SettingsRoutePaths.IndexPageDepartmentPosition);
    }
  };

  const handleConnectCompanyFunction = async (ids: number[]) => {
    if (!itemToUpdate) throw Error("Missing data");
    const resp = await wrapApiCallWithToast(() =>
      departmentPositionsApi.connectCompanyFunctions(
        itemToUpdate.id,
        new ConnectCompanyFunctionsDTO({ companyFunctionIds: ids }),
      ),
    );
    if (resp.isOk) {
      mutateCompanyFunctions();
    }
  };

  const handleSuccess = (dto?: unknown) => {
    console.log(dto);
    if (hasId(dto)) {
      navigate(SettingsRoutePaths.EditPageDepartmentPositions.replace(":id", dto.id.toString()));
    } else {
      handleRefresh();
    }
  };

  return (
    <>
      {!loading && (
        <BaseSettingsForm
          itemToUpdate={itemToUpdate}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          additionalFields={["DescriptionAsHtml", "Department", "Capacity"]}
          onDelete={itemToUpdate ? () => tryToDelete(itemToUpdate) : undefined}
          handleRefresh={handleRefresh}
          department={itemToUpdate?.department}
          existingItems={existingDepartmentPositions}
          formType={"DepartmentPosition"}
        />
      )}

      <Row>
        {parentPositions && parentPositions.length > 0 && (
          <Col md={4}>
            <Card>
              <Card.Header>
                <CardTitle as="h5">{t("Parent positions")}</CardTitle>
              </Card.Header>
              <ListGroup variant="flush">
                {parentPositions.map((x) => (
                  <ListGroup.Item
                    key={x.id}
                    as={Link}
                    to={SettingsRoutePaths.EditPageDepartmentPositions.replace(":id", x.id.toString())}
                    action
                  >
                    {x.title}{" "}
                    <small className="text-muted" title={t("Department")}>
                      {t("in")} {x.department.title}
                    </small>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
          </Col>
        )}

        <Col>
          {itemToUpdate && childPositions && (
            <DepartmentPositonsConnectedCard
              baseDTO={itemToUpdate}
              refreshParent={handleRefresh}
              data={childPositions}
              onConnectSubmit={async (ids) => {
                await departmentPositionsApi.connectChildDepartmentPositions(
                  itemToUpdate.id,
                  new ConnectDepartmentPositionsDTO({ departmentPositionIds: ids }),
                );
              }}
              onDisconnectSubmit={async (dpId) => {
                await departmentPositionsApi.disconnectChildDepartmentPosition(itemToUpdate.id, dpId);
              }}
              cardTitle={t("Subordinate positions")}
              columnsVisibleDefault={["title", "department"]}
              cardBody={
                <Card.Body>
                  <small className="text-muted">
                    {t(
                      "To record hierarchical relationships within the company, further items can be subordinated to each item.",
                    )}
                  </small>
                </Card.Body>
              }
            />
          )}
        </Col>
      </Row>
      {itemToUpdate && connectedCompanyFunctions && (
        <CompanyFunctionsConnectedCard
          data={connectedCompanyFunctions}
          refreshParent={() => mutateCompanyFunctions()}
          baseDTO={itemToUpdate}
          cardTitle={t("Company functions")}
          onConnectSubmit={handleConnectCompanyFunction}
          onDisconnectSubmit={(id) => departmentPositionsApi.disconnectCompanyFunction(itemToUpdate.id, id)}
          cardBody={
            <Card.Body>
              <small className="text-muted">
                {t(
                  "To create requirement profiles for employees, individual positions can be linked to functions. For each employee who performs a corresponding position, the requirement profile is automatically supplemented by the linked function. Each function can also be linked to the qualifications required for it.",
                )}
              </small>
            </Card.Body>
          }
        />
      )}
    </>
  );
}

export default EditPageDepartmentPosition;
