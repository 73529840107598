import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { IDepartmentPositionDTO } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../../pbdServices/services/service-context";

import { DepartmentPositionTable } from "../../../../departmentPositions/components/departmentPositionTable";
import { SettingsRoutePaths } from "../../../../settings/settingsRoutePaths";
import { ConnectModalProps } from "../connectModalProps";
import ConnectionModal from "../shared/connectionModal";

type IProps = ConnectModalProps<IDepartmentPositionDTO>;
/**
 * Generic Modal to connect department positions with anything else. For example trainings
 * */
function ConnectDepartmentPositionModal(props: IProps) {
  const { connectedElements, modal, toggle, refreshParent, onSubmit } = props;
  const { t } = useTranslation();
  const { departmentPositionsApi } = useAPIs();
  const [submitting, setSubmitting] = React.useState(false);
  const [selected, setSelected] = React.useState<number[]>([]);
  const navigate = useNavigate();
  const { data } = useSWR(modal ? "/api/departmentPositions" : null, () => departmentPositionsApi.getAll());

  const filtered = React.useMemo(() => {
    if (data && connectedElements) {
      return data.filter((x) => !connectedElements.map((y) => y.id).includes(x.id));
    } else {
      return undefined;
    }
  }, [connectedElements, data]);

  async function handleSubmit() {
    setSubmitting(true);
    await onSubmit(selected);
    setSubmitting(false);
    setSelected([]);
    toggle();
    refreshParent();
  }

  return (
    <ConnectionModal
      submitting={submitting}
      selected={selected}
      modalTitle={t("Positions")}
      onSubmitClick={handleSubmit}
      {...props}
    >
      <DepartmentPositionTable
        tableRows={filtered}
        columnsToExclude={["isPrimary", "requiredCompanyFunctions", "requiredCompanyFunctionsCount"]}
        setSelected={(ids) => setSelected(ids.map((x) => Number(x.id)))}
        onCreateClick={() => navigate(SettingsRoutePaths.CreatePageDepartmentPositions)}
        isAlwaysSelectable
        includeTitleLink={false}
        key={"modal"}
      />
    </ConnectionModal>
  );
}

export default ConnectDepartmentPositionModal;
