import Fuse from "fuse.js";
import React from "react";
import { Button, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ILinkObject, PbdStatus } from "@generatedCode/pbd-core/pbd-core-api";

import { qmBaseIcons } from "../icons/qmBaseIcons";
import IdComponent from "../id/idComponent";
import ExternalLink from "../links/externalLink";
import { ProgressBar } from "../progress/progressBar";
import StatusIconComponent from "../status/statusIcon";

export interface ExistingItem {
  id: number;
  title: string;
  status?: PbdStatus;
  links?: ILinkObject[];
}

interface IProps {
  searchTerm?: string;
  existingItems?: ExistingItem[];
  /**Exclude the item you are currently editing */
  itemsToExclude?: ExistingItem[];
  submitting?: boolean;
  onConnectDuplicateClick?: (id: number) => void;
}

function DuplicatedItemsListGroup(props: IProps) {
  const { searchTerm, onConnectDuplicateClick, submitting, existingItems, itemsToExclude } = props;
  const { t } = useTranslation();

  const result = React.useMemo(() => {
    if (searchTerm && existingItems) {
      if (searchTerm.length < 3) return [];
      let arrayExceptExcludedData = existingItems;
      if (itemsToExclude) {
        arrayExceptExcludedData = existingItems.filter((x) => !itemsToExclude.map((m) => m.id).includes(x.id));
      }
      const fuse = new Fuse(arrayExceptExcludedData, { keys: ["title"], includeScore: true, threshold: 0.35 });
      const resultFromFuse = fuse.search(searchTerm);
      return resultFromFuse.splice(0, 5);
    } else {
      return undefined;
    }
  }, [existingItems, itemsToExclude, searchTerm]);

  return (
    <>
      {!result && searchTerm && (
        <small className="text-muted">
          {t("We are checking for potential duplicates...")} <qmBaseIcons.CircleNotch className="fa-spin" />
        </small>
      )}
      {result && result.length > 0 && (
        <ListGroup className="shadow-sm mb-1">
          <ListGroup.Item variant="info">
            <h6>
              <qmBaseIcons.Info /> {t("We've found some similar items that already exist")}
            </h6>
          </ListGroup.Item>
          {result.map((x) => (
            <ListGroup.Item key={x.item.id} className="d-flex justify-content-between">
              <span>
                {x.item.links?.find((x) => x.rel == "Details") ? (
                  <ExternalLink
                    href={x.item.links.find((x) => x.rel == "Details").href}
                    label={
                      <>
                        {x.item.status && <StatusIconComponent status={x.item.status} />} {x.item.title}{" "}
                        <IdComponent id={x.item.id} />
                      </>
                    }
                  />
                ) : (
                  <>
                    {x.item.status && <StatusIconComponent status={x.item.status} />} {x.item.title}{" "}
                    <IdComponent id={x.item.id} />
                  </>
                )}
              </span>
              <ProgressBar
                currentValue={1 - (x.score ?? 0)}
                maxValue={1}
                title={t("Similarity")}
                color={1 - (x.score ?? 0) > 0.8 ? "success" : "secondary"}
                minWidth={"3rem"}
              />
              {onConnectDuplicateClick && (
                <Button
                  size="sm"
                  onClick={() => onConnectDuplicateClick(x.item.id)}
                  color="outline-primary"
                  disabled={submitting}
                >
                  <qmBaseIcons.Plus /> {t("Connect")}
                </Button>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </>
  );
}

export default DuplicatedItemsListGroup;
