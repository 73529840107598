import { Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import invariant from "tiny-invariant";

import { CustomFieldEditDTO, ICustomField, ICustomFieldEditDTO, PbdModule } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { useTryDelete } from "../../../../pbdServices/services/Api/api-hooks";
import FeatureFlagService from "../../../../pbdServices/services/FeatureFlags/featureFlagService";
import { LoadingComponent } from "../../../shared/components/loading/loadingComponent";
import { useAppContext } from "../../../shared/contexts/appContext";
import { useModuleSettingsHook } from "../../../shared/hooks/useModuleSettingsHook";
import BaseSettingsFormHeader from "../../components/editPage/baseSettingsFormHeader";
import { SettingsRoutePaths } from "../../settingsRoutePaths";
import CustomFieldApiConfigCard from "../components/customFieldApiConfigCard";
import CustomFieldAuthCard from "../components/customFieldAuthCard";
import CustomFieldForm from "../components/customFieldForm";

function EditPageCustomFields() {
  const navigate = useNavigate();
  const { meAsUser } = useAppContext();
  const { customFieldsApi } = useAPIs();
  const {
    /**Use this function to make sure that the appSettings are refreshed. It must be used on Create, Edit, Delete.*/
    mutate: mutateModuleSettings,
  } = useModuleSettingsHook();
  const { id, module } = useParams<{ id: string; module: PbdModule }>();
  invariant(module, "Missing module");
  invariant(id, "Missing id");

  const { data: itemToUpdate } = useSWR(["/api/customFields", id, module], () =>
    customFieldsApi.getCustomFieldByModuleAndId(module, id),
  );

  const handleEdit = (itemToCreate: ICustomFieldEditDTO) =>
    customFieldsApi.edit(module, itemToCreate.id, new CustomFieldEditDTO(itemToCreate));

  const tryToDelete = useTryDelete<ICustomField, string>(
    (val) => customFieldsApi.delete(module, val.id),
    [customFieldsApi, module],
    () => {
      mutateModuleSettings();
      redirectToIndex();
    },
  );

  const redirectToIndex = () => navigate(SettingsRoutePaths.IndexPageCustomFields.replace(":module", module));

  if (!itemToUpdate) return <LoadingComponent />;

  return (
    <>
      <Card className="mb-3">
        <BaseSettingsFormHeader itemToUpdate={itemToUpdate} onDelete={() => tryToDelete(itemToUpdate)} />
        <Card.Body>
          <CustomFieldForm itemToUpdate={itemToUpdate} onSubmit={handleEdit} onSuccess={() => mutateModuleSettings()} />
        </Card.Body>
      </Card>
      <CustomFieldAuthCard item={itemToUpdate} module={module} />
      {FeatureFlagService.isApiCustomFieldAvailable(meAsUser) && (
        <CustomFieldApiConfigCard itemToUpdate={itemToUpdate} module={module} />
      )}
    </>
  );
}

export default EditPageCustomFields;
