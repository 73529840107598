import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

const getTitle = (pathname: PbdModule) => {
  switch (pathname) {
    case PbdModule.AbsencePlanner:
      return "Absence planner";
    case PbdModule.ClaimManagement:
      return "Claim management";
    case PbdModule.IdeaManagement:
      return "Idea management";
    case PbdModule.TrainingManagement:
      return "Training management";
    case PbdModule.AuditManagement:
      return "Audit management";
    case PbdModule.Employees:
      return "Employees";
    case PbdModule.OpportunityManagement:
      return "Risks & Chances";
    case PbdModule.MaintenanceManagement:
      return "Maintenance";
    case PbdModule.DefectManagement:
      return "Error management";
    case PbdModule.QualificationMatrix:
      return "Functions & Qualifications";
    case PbdModule.Crm:
      return "CRM";
    case PbdModule.GoalManagement:
      return "Goal management";
    case PbdModule.ProjectAndTaskManagement:
      return "Tasks";
    default:
      console.warn("Unknown app " + pathname);
      return "General";
  }
};

function HomePageAppSettings() {
  const { t } = useTranslation();
  const params = useParams<{ pbdModule: PbdModule }>() as { pbdModule: PbdModule };
  return (
    <>
      <h2>{t(getTitle(params.pbdModule))}</h2>
      <hr />
    </>
  );
}

export default HomePageAppSettings;
