import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import {
  AddressRoomCreateDTO,
  AddressRoomEditDTO,
  IAddressRoomDTO,
  IAddressRoomEditDTO,
} from "@generatedCode/pbd-core/pbd-core-api";

import { nameofFactory } from "../../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { useAPIs } from "../../../../pbdServices/services/service-context";
import FormikCustomForm from "../../../shared/components/forms/formik/formikCustomForm";
import { FormikTextInputGroup } from "../../../shared/components/forms/formik/formikTextInput";

const ValidationSchema: yup.ObjectSchema<IAddressRoomEditDTO> = yup.object({
  title: yup.string().required(),
  id: yup.number().required(),
});

interface IProps {
  baseObject: { id: number };
  itemToUpdate?: IAddressRoomDTO;
  afterSubmit: () => void;
}

const nameof = nameofFactory<IAddressRoomDTO>();

const RoomsForm: React.FC<IProps> = (props) => {
  const { itemToUpdate, afterSubmit, baseObject } = props;
  const { addressesApi } = useAPIs();
  const { t } = useTranslation();

  const submitter = useFormikAPISubmitter<IAddressRoomEditDTO>(
    (values) => {
      if (itemToUpdate) {
        return addressesApi.editAddressRoom(baseObject.id, itemToUpdate.id, new AddressRoomEditDTO(values));
      }
      return addressesApi.createAddressRoom(baseObject.id, new AddressRoomCreateDTO(values));
    },
    [addressesApi, baseObject.id, itemToUpdate],
    () => afterSubmit(),
  );

  const initialValues: IAddressRoomEditDTO = {
    title: itemToUpdate?.title ?? "",
    id: itemToUpdate?.id ?? 0,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
      {(formikBag) => (
        <FormikCustomForm formikBag={formikBag}>
          <FormikTextInputGroup name={nameof("title")} />
        </FormikCustomForm>
      )}
    </Formik>
  );
};

export default RoomsForm;
