import { uniq } from "lodash";

import {
  ICommentDTO,
  ICommentsControllerClient,
  ITenantDTO,
  ITenantsControllerClient,
} from "@generatedCode/pbd-core/pbd-core-api";

import { EmployeeRoutePaths } from "../../../ClientApp/employees/employeeRoutePaths";
import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";

export type CommentMapped = ICommentDTO & { contentMapped?: string };
const regex = new RegExp("<@(.*?\\d)>", "g");

export default class CommentService {
  api: ICommentsControllerClient;
  private _tenantsApi: ITenantsControllerClient;
  constructor(commentControllerClient: ICommentsControllerClient, tenantControllerClient: ITenantsControllerClient) {
    this.api = commentControllerClient;
    this._tenantsApi = tenantControllerClient;
  }

  async mapMentions(comments: ICommentDTO[]): Promise<CommentMapped[]> {
    const ids: number[] = [];
    for (const c of comments) {
      ids.push(...this._getTenantIds(c.content ?? ""));
    }
    const uniqueIds = uniq(ids);
    let tenants: ITenantDTO[] = [];
    if (ids.length > 0) {
      tenants = (
        await this._tenantsApi.getAllQuery({
          id: uniqueIds,
          pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
        })
      ).data;
    }
    const mapped: CommentMapped[] = comments.map((x) => {
      return { ...x, contentMapped: this.mapComment(x.content ?? "", tenants) };
    });

    return mapped;
  }

  mapComment(content: string, tenants: ITenantDTO[]): string {
    let m;
    while ((m = regex.exec(content)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      // The result can be accessed through the `m`-variable.
      const matchString = m[0];
      const matchId = Number(m[1]);
      const tenantToMatch = tenants.find((t) => t.id == matchId);
      if (tenantToMatch) {
        content = content.replace(
          matchString,
          `<a href="${EmployeeRoutePaths.EditPage.replace(":id", tenantToMatch.id.toString())}">${
            tenantToMatch.fullName
          }</a>`,
        );
      }
    }
    return content;
  }

  private _getTenantIds(content: string) {
    const regex = new RegExp("<@(.*?\\d)>", "g");
    const str = `<@1>  asd asd <@2> asd qwe <@3> <@123asd> `;
    let m;
    const ids: number[] = [];
    while ((m = regex.exec(content)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
        // console.log(`Found match, group ${groupIndex}: ${match}`);
        if (groupIndex == 1) {
          ids.push(Number(match));
        }
      });
    }
    return ids;
  }
}
