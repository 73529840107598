import {
  ColumnDef,
  RowSelectionState,
  SortingState,
  Updater,
  VisibilityState,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";

import { useLocalStorage } from "../../../hooks/useLocalStorage";
import TableLoadingSkeleton from "../components/tableLoadingSkeleton";
import TableNoDataRow from "../components/tableNoDataRow";
import { idOfSelectionColumn } from "../reactstrapTable";
import { useTableKeyName } from "../tableHelperFunctions";

import { CustomTableContextProvider } from "./customTableContext";
import { fuzzyFilter } from "./fuzzyFilter";
import PaginationComponent from "./pagination-component";
import { ReactTableStateDTO } from "./reactTableStateDTO";
import { CustomRowData, ReactstrapTableProps } from "./reactstrap-table-props";
import { getSelectionColumn } from "./selectionColumn";
import { TableHeadComponent } from "./tableHeadComponent";
import TableToolbar from "./tableToolbar";

function ReactstrapTable<T extends CustomRowData>({
  tableRows,
  columns,
  setSelected,
  localStorageStateKey,
  onCreateButtonTitle,
  onCreateClick,
  onClick,
  activeFilters,
  availableTableActions,
  columnsVisibleDefault,
  onRowClickNew,
  onDeleteClick,
  resetSelectedRowCounter = 0,
  filterBox,
  isAlwaysSelectable = false,
  ...props
}: ReactstrapTableProps<T>) {
  const defaultData = useMemo(() => [], []);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [globalFilter, setGlobalFilter] = useState("");

  const tableKey = useTableKeyName(`${localStorageStateKey}-v2`);
  const columnsWithSelect: ColumnDef<T>[] = [getSelectionColumn<T>(localStorageStateKey), ...columns];

  const [tableState, setTableState] = useLocalStorage<ReactTableStateDTO<T>>(
    tableKey,
    new ReactTableStateDTO(columnsWithSelect, columnsVisibleDefault, isAlwaysSelectable),
  );

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    ...tableState.columnVisibility,
    [idOfSelectionColumn]: isAlwaysSelectable,
  });
  const [sorting, setSorting] = useState<SortingState | undefined>(tableState.sorting);

  const handleSetColumnVisibility = (dto: Updater<VisibilityState>) => {
    const updateValue = functionalUpdate<VisibilityState>(dto, columnVisibility);
    if (isAlwaysSelectable) {
      updateValue[idOfSelectionColumn] = true;
    }
    setTableState({ ...tableState, columnVisibility });
    setColumnVisibility(updateValue);
  };

  useEffect(() => {
    if (columnVisibility) {
      setTableState({ ...tableState, columnVisibility });
    }
  }, [columnVisibility]);

  useEffect(() => {
    setRowSelection({});
  }, [resetSelectedRowCounter]);

  useEffect(() => {
    if (setSelected && tableRows) {
      // Current workaround until this is resolved
      // https://github.com/tannerlinsley/react-table/issues/2210

      // My own perfect solution
      const idsOfSelected = Object.keys(rowSelection);
      const totalSelection = tableRows.filter((x) => idsOfSelected.includes(x.id.toString()));
      setSelected(totalSelection);
    }
    // do not change the dependency array or it will lead to infinite loops
  }, [rowSelection]);

  useEffect(() => {
    if (sorting && sorting.length > 0) {
      setTableState({ ...tableState, sorting });
    }
  }, [sorting]);

  const table = useReactTable<T>({
    data: tableRows ?? defaultData,
    columns: columnsWithSelect,
    initialState: {
      pagination: { pageSize: tableState.pageSize },
      // columnVisibility: tableState.columnVisibility,
    },
    state: {
      sorting,
      rowSelection,
      globalFilter,
      columnVisibility,
    },
    getRowId: (row) => row.id.toString(),
    onColumnVisibilityChange: handleSetColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <CustomTableContextProvider onDeleteClick={onDeleteClick} onRowClickNew={onRowClickNew}>
      <TableToolbar
        table={table}
        onCreateButtonTitle={onCreateButtonTitle}
        onCreateClick={onCreateClick}
        tableKey={tableKey}
        setGlobalFilter={setGlobalFilter}
        availableTableActions={availableTableActions}
        onClick={onClick}
        activeFilters={activeFilters ?? {}}
        onDelete={(k) => {
          props.onDeleteFilterKey?.(k);
        }}
        filterBox={filterBox}
        setColumnVisibility={handleSetColumnVisibility}
        smartViewBox={props.smartViewBox}
      />
      <Table responsive>
        <TableHeadComponent table={table} />
        <tbody>
          {!tableRows && <TableLoadingSkeleton columns={columns} />}
          {tableRows?.length == 0 && (
            <TableNoDataRow
              onClick={onClick}
              // availableTableActions={availableTableActions}
              availableTableActions={[]}
              activeFilters={activeFilters}
              columns={columns}
            />
          )}
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={cell.column.columnDef.meta?.className}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <PaginationComponent table={table} setTableState={setTableState} />
    </CustomTableContextProvider>
  );
}
export default ReactstrapTable;
