import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { AppUiData } from "../../../Constants/app-ui-data";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { CrmRoutePaths } from "../crmRoutePaths";

const getLinks = (t: TFunction) => {
  const links: SidebarItem[] = [];
  links.push(
    new SidebarItem({
      title: t("Organisations"),
      icon: qmBaseIcons.ListSolid,
      href: CrmRoutePaths.IndexPageOrganisations,
    }),
  );
  links.push(
    new SidebarItem({
      title: t("Tenants"),
      icon: qmBaseIcons.ListSolid,
      href: CrmRoutePaths.IndexPageTenants,
    }),
  );
  links.push(
    new SidebarItem({
      title: t("Ratings"),
      icon: qmBaseIcons.StarHalf,
      href: CrmRoutePaths.IndexPageOrganisationRatings,
    }),
  );
  links.push(
    new SidebarItem({
      title: t("Customer ratings"),
      icon: qmBaseIcons.Customer,
      href: `${CrmRoutePaths.IndexPageOrganisationRatings}/?organisationRatingType=Customer`,
    }),
  );
  links.push(
    new SidebarItem({
      title: t("Supplier ratings"),
      icon: qmBaseIcons.Supplier,
      href: `${CrmRoutePaths.IndexPageOrganisationRatings}/?organisationRatingType=Supplier`,
    }),
  );
  return links;
};

const appData = AppUiData[PbdModule.Crm];

function SidebarCrm() {
  const { t } = useTranslation();
  return <SidebarNew app={PbdModule.Crm} items={getLinks(t)} uiData={appData} />;
}

export default SidebarCrm;
