import { CapabilitiesDTO, ICommentDTO, PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { AbsencePlannerRoutePaths } from "../../ClientApp/absencePlanner/absencePlannerRoutePaths";
import { ArticleRoutePaths } from "../../ClientApp/articles/articleRoutePaths";
import { AuditRoutePaths } from "../../ClientApp/audits/auditRoutePaths";
import { ClaimRoutePaths } from "../../ClientApp/claims/claimRoutePaths";
import { CrmRoutePaths } from "../../ClientApp/crm/crmRoutePaths";
import { DefectRoutePaths } from "../../ClientApp/defects/defectRoutePaths";
import { EmployeeRoutePaths } from "../../ClientApp/employees/employeeRoutePaths";
import { GoalRoutePaths } from "../../ClientApp/goals/goalRoutePaths";
import { IdeaManagementRoutePaths } from "../../ClientApp/ideaManagement/ideaManagementRoutePaths";
import { MaintenanceManagementRoutePaths } from "../../ClientApp/maintenanceManagement/maintenanceManagementRoutePaths";
import { OpportunityRoutePaths } from "../../ClientApp/opportunities/opportunityRoutePaths";
import { ProductRoutePaths } from "../../ClientApp/products/productRoutePaths";
import { TaskManagementRoutePaths } from "../../ClientApp/taskManagement/taskManagementRoutePaths";
import { TrainingRoutePaths } from "../../ClientApp/trainings/trainingRoutePaths";
import { MeAsUser } from "../../pbdServices/services/UserSettings/models/me-as-user";
import { PbdRoles } from "../Authz/PbdRoles";
import { hasRole } from "../Authz/authService";

export default class CommentsService {
  /**Should be handled on the backend in the future */
  getCapabilities(item: ICommentDTO, pathname: string, meAsUser: MeAsUser): CapabilitiesDTO {
    const capabilities = new CapabilitiesDTO();
    if (
      pathname.startsWith(ClaimRoutePaths.HomePage) ||
      pathname.startsWith(TaskManagementRoutePaths.HomePage) ||
      pathname.startsWith(ArticleRoutePaths.HomePage(PbdModule.DocumentManagement)) ||
      pathname.startsWith(ArticleRoutePaths.HomePage(PbdModule.KnowledgeBase)) ||
      pathname.startsWith(ArticleRoutePaths.HomePage(PbdModule.Blog)) ||
      pathname.startsWith(IdeaManagementRoutePaths.HomePage) ||
      pathname.startsWith(GoalRoutePaths.HomePage) ||
      pathname.startsWith(MaintenanceManagementRoutePaths.HomePage) ||
      pathname.startsWith(ProductRoutePaths.HomePage) ||
      pathname.startsWith(DefectRoutePaths.HomePage) ||
      pathname.startsWith(CrmRoutePaths.OrganisationEdit.replace(":id", "")) ||
      pathname.startsWith(AbsencePlannerRoutePaths.HomePage) ||
      pathname.startsWith(AuditRoutePaths.HomePage) ||
      pathname.startsWith(EmployeeRoutePaths.HomePage) ||
      pathname.startsWith(TrainingRoutePaths.HomePage) ||
      pathname.startsWith(OpportunityRoutePaths.HomePage)
    ) {
      capabilities.canEdit = item.createdBy?.id == meAsUser.tenant.id && !item.isDeleted;
      capabilities.canDelete =
        (item.createdBy?.id == meAsUser.tenant.id || hasRole(meAsUser, [PbdRoles.Admin])) && !item.isDeleted;
      return capabilities;
    } else {
      return capabilities;
    }
  }

  static supportReply(app?: PbdModule) {
    if (app && app == PbdModule.ClaimManagement) return true;
    return false;
  }
}
