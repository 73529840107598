import { TFunction } from "i18next";

import { AppDTO, IAppDTO, IAppsControllerClient, PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { AbsencePlannerRoutePaths } from "../../../ClientApp/absencePlanner/absencePlannerRoutePaths";
import { AdminRoutePaths } from "../../../ClientApp/admin/adminRoutePaths";
import { ArticleRoutePaths } from "../../../ClientApp/articles/articleRoutePaths";
import { AuditRoutePaths } from "../../../ClientApp/audits/auditRoutePaths";
import { ClaimRoutePaths } from "../../../ClientApp/claims/claimRoutePaths";
import { CrmRoutePaths } from "../../../ClientApp/crm/crmRoutePaths";
import { DefectRoutePaths } from "../../../ClientApp/defects/defectRoutePaths";
import { DevRoutePaths } from "../../../ClientApp/dev/devRoutePaths";
import { EmployeeRoutePaths } from "../../../ClientApp/employees/employeeRoutePaths";
import { FormRoutePaths } from "../../../ClientApp/forms/formRoutePaths";
import { GoalRoutePaths } from "../../../ClientApp/goals/goalRoutePaths";
import { IdeaManagementRoutePaths } from "../../../ClientApp/ideaManagement/ideaManagementRoutePaths";
import { MaintenanceManagementRoutePaths } from "../../../ClientApp/maintenanceManagement/maintenanceManagementRoutePaths";
import { OpportunityRoutePaths } from "../../../ClientApp/opportunities/opportunityRoutePaths";
import { ProductRoutePaths } from "../../../ClientApp/products/productRoutePaths";
import { ProfileRoutePaths } from "../../../ClientApp/profile/profileRoutePaths";
import { QualificationMatrixRoutePaths } from "../../../ClientApp/qualificationMatrix/qualificationMatrixRoutePaths";
import { QualityMonitorRoutePaths } from "../../../ClientApp/qualityMonitor/qualityMonitorRoutePaths";
import { SettingsRoutePaths } from "../../../ClientApp/settings/settingsRoutePaths";
import { StoreRoutePaths } from "../../../ClientApp/store/storeRoutePaths";
import { TaskManagementRoutePaths } from "../../../ClientApp/taskManagement/taskManagementRoutePaths";
import { TrainingRoutePaths } from "../../../ClientApp/trainings/trainingRoutePaths";
import ModuleUiInfo from "../../../Constants/ModuleUiInfo";
import { AppVM } from "../../Models/App/AppVM";
import { AuthService } from "../Authz/authService";
import { MeAsUser } from "../UserSettings/models/me-as-user";

const admin = new AppDTO({ id: 100, type: PbdModule.Admin, showElement: true, href: "/Admin" });
const settings = new AppDTO({ id: 101, type: PbdModule.Settings, showElement: true, href: "/Settings" });
const store = new AppDTO({ id: 102, type: PbdModule.Store, showElement: true, href: "/Store" });

export default class AppService {
  appApi: IAppsControllerClient;
  constructor(appApi: IAppsControllerClient) {
    this.appApi = appApi;
  }

  async getAllAsVm(t: TFunction, meAsUser?: MeAsUser) {
    const apps = await this.appApi.getAll();
    // console.log(apps);
    let vm = this.convertToVM(apps, t);
    if (meAsUser) {
      vm = this.checkAccess(vm, meAsUser);
    }
    vm.sort((a, b) => a.displayName.localeCompare(b.displayName));
    vm = this.isVisibleInAppList(vm);
    return vm;
  }

  private _addVirtualApp(content: IAppDTO[]) {
    content.push(admin);
    content.push(settings);
    content.push(store);
    return content;
  }

  private isVisibleInAppList(items: AppVM[]): AppVM[] {
    return items.map((x) => {
      return { ...x, isVisibleInAppList: x.parentNavbarItemId != null && !this.excludedApps.includes(x.type) };
    });
  }

  private checkAccess(items: AppVM[], meAsUser: MeAsUser): AppVM[] {
    const authService = new AuthService(meAsUser);
    return items.map((x) => {
      const hasAccess =
        x.appInfo?.pbdModule && !this.excludedApps.includes(x.appInfo.pbdModule)
          ? authService.hasSufficientAppRights(x.appInfo.pbdModule)
          : true;
      return { ...x, hasAccess };
    });
  }

  convertToVM(content: IAppDTO[], t: TFunction, isMock = false): AppVM[] {
    this._addVirtualApp(content);
    const uiInfo = new ModuleUiInfo();
    const vmList = content.map((elem) => {
      const vm = new AppVM(elem);
      if (elem.type != PbdModule.None) {
        vm.appInfo = uiInfo.getByModule(elem.type);
        vm.displayNameSidebar = this._getSidebarTitle(elem.type);
        vm.displayName = elem.type.toString();
      } else {
        vm.displayName = this._getTitle(elem.id);
      }

      if (!isMock) {
        if (vm.displayName) {
          vm.displayName = t(vm.displayName);
        }
        if (vm.displayNameSidebar) {
          vm.displayNameSidebar = t(vm.displayNameSidebar);
        }
      }

      return vm;
    });

    for (const elem of vmList) {
      if (elem.parentNavbarItemId == null) {
        elem.children = vmList.filter((x) => x.parentNavbarItemId === elem.id);
      }
    }
    return vmList;
  }

  private _getTitle(id: number) {
    switch (id) {
      case 6:
        return "Organization";
      case 13:
        return "Resources";
      case 18:
        return "Quality control";
      case 28:
        return "Dashboard & Reportings";
      case 26:
        return "CRM";
      default:
        return "";
    }
  }

  private _getSidebarTitle(type: PbdModule) {
    switch (type) {
      case PbdModule.ClaimManagement:
        return "Claims";
      case PbdModule.TrainingManagement:
        return "Trainings";
      case PbdModule.DocumentManagement:
        return "Documents";
      default:
        return "";
    }
  }

  static getAppByFirstSegmentInUrl(pathName: string) {
    if (pathName.startsWith(ProfileRoutePaths.HomePage)) {
      return PbdModule.Profile;
    } else if (pathName.startsWith(ProductRoutePaths.HomePage)) {
      return PbdModule.Products;
    } else if (pathName.startsWith(QualityMonitorRoutePaths.HomePage)) {
      return PbdModule.QualityMonitor;
    } else if (pathName.startsWith(GoalRoutePaths.HomePage)) {
      return PbdModule.GoalManagement;
    } else if (pathName.startsWith(TrainingRoutePaths.HomePage)) {
      return PbdModule.TrainingManagement;
    } else if (pathName.startsWith(IdeaManagementRoutePaths.HomePage)) {
      return PbdModule.IdeaManagement;
    } else if (pathName.startsWith(ClaimRoutePaths.HomePage)) {
      return PbdModule.ClaimManagement;
    } else if (pathName.startsWith(StoreRoutePaths.HomePage)) {
      return PbdModule.Store;
    } else if (pathName.startsWith(QualificationMatrixRoutePaths.HomePage)) {
      return PbdModule.QualificationMatrix;
    } else if (pathName.startsWith(TaskManagementRoutePaths.HomePage)) {
      return PbdModule.ProjectAndTaskManagement;
    } else if (pathName.startsWith(CrmRoutePaths.HomePage)) {
      return PbdModule.Crm;
    } else if (pathName.startsWith(DefectRoutePaths.HomePage)) {
      return PbdModule.DefectManagement;
    } else if (pathName.startsWith(AuditRoutePaths.HomePage)) {
      return PbdModule.AuditManagement;
    } else if (pathName.startsWith(AbsencePlannerRoutePaths.HomePage)) {
      return PbdModule.AbsencePlanner;
    } else if (pathName.startsWith(OpportunityRoutePaths.HomePage)) {
      return PbdModule.OpportunityManagement;
    } else if (pathName.startsWith(FormRoutePaths.HomePage)) {
      return PbdModule.CustomForms;
    } else if (pathName.startsWith(MaintenanceManagementRoutePaths.HomePage)) {
      return PbdModule.MaintenanceManagement;
    } else if (pathName.startsWith(EmployeeRoutePaths.HomePage)) {
      return PbdModule.Employees;
    } else if (pathName.startsWith(ArticleRoutePaths.HomePage(PbdModule.Blog))) {
      return PbdModule.Blog;
    } else if (pathName.startsWith(ArticleRoutePaths.HomePage(PbdModule.KnowledgeBase))) {
      return PbdModule.KnowledgeBase;
    } else if (pathName.startsWith(ArticleRoutePaths.HomePage(PbdModule.DocumentManagement))) {
      return PbdModule.DocumentManagement;
    } else if (pathName.startsWith(SettingsRoutePaths.Home)) {
      return PbdModule.Settings;
    } else if (pathName.startsWith(AdminRoutePaths.HomePage)) {
      return PbdModule.Admin;
    } else if (pathName.startsWith(DevRoutePaths.HomePage)) {
      return PbdModule.Dev;
    } else {
      return PbdModule.Startpage;
    }
  }

  static appsForPermissionsPerUser(availableModules: AppVM[]) {
    const modulesAvailable = availableModules.filter(
      (x) =>
        x.showElement &&
        x.type != PbdModule.None &&
        x.type != PbdModule.Store &&
        x.type != PbdModule.CustomerSatisfactionManagement &&
        x.type != PbdModule.SupplierSatisfactionManagement &&
        x.type != PbdModule.ContactManagement,
    );
    return modulesAvailable;
  }

  /**Apps that are excluded on startpage and search */
  private excludedApps: PbdModule[] = [
    PbdModule.SupplierSatisfactionManagement,
    PbdModule.CustomerSatisfactionManagement,
    PbdModule.ContactManagement,
  ];
}
