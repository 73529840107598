import { uniq } from "lodash";

import { EntityKey, IBaseEntityPermissionWithId } from "@generatedCode/pbd-core/pbd-core-api";

export interface IGroupCountPermission {
  id: string;
  items: IBaseEntityPermissionWithId[];
  item: IBaseEntityPermissionWithId;
  count: number;
  capabilities: string[];
  entityKey: EntityKey;
}

export class GroupCountPermission implements IGroupCountPermission {
  constructor(items: IBaseEntityPermissionWithId[], type: "User" | "Group") {
    if (items.length == 0) {
      throw Error("Not implemented");
    }
    this.id = items[0].id;
    this.count = items.length;
    this.item = items[0];
    this.items = items;
    this.capabilities = uniq(items.filterMap((x) => x.capabilities));
    this.entityKey = type == "User" ? EntityKey.ApplicationUser : EntityKey.ApplicationGroup;
  }
  entityKey: EntityKey;
  capabilities: string[];
  id: string;
  items: IBaseEntityPermissionWithId[];
  item: IBaseEntityPermissionWithId;
  count: number;
}
