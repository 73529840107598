/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ApplicationGroupDTO,
  IApplicationGroupAssignedDTO,
  IApplicationGroupDTO,
  IEntityPermissionDTO,
  IGroupsControllerClient,
} from "@generatedCode/pbd-core/pbd-core-api";

import { AdminRoutePaths } from "../../../ClientApp/admin/adminRoutePaths";
import { DTOMap } from "../../../Helpers/filterMap";
import { BaseTableEntityProps } from "../../Models/BaseClasses/BaseTableEntityProps";
import { GroupWithPermission } from "./models/GroupWithPermission";

export default class GroupService {
  groupsApi: IGroupsControllerClient;
  constructor(groups: IGroupsControllerClient) {
    this.groupsApi = groups;
  }

  async getAllForAccess(permission?: IEntityPermissionDTO): Promise<GroupWithPermission[]> {
    const permGroups = permission?.groups;
    if (permGroups === undefined) return [];

    const ids = permGroups.map((g) => g.id);
    const groups = await this.groupsApi.getAllQuery({ id: ids });
    const groupsMap = new DTOMap<ApplicationGroupDTO, string>(groups);

    return permGroups.filterMap((permGroup) => {
      const group = groupsMap.getById(permGroup.id);
      if (!group) {
        //TODO2
        // eslint-disable-next-line no-console
        console.log("User for permission not found with id: " + permGroup.id, permGroup);
        return undefined;
      }

      return { ...permGroup, ...group };
    });
  }

  static mapToTableProps(data: (IApplicationGroupDTO | IApplicationGroupAssignedDTO)[] | undefined) {
    if (!data) return undefined;
    return data.map((group) => new BaseTableEntityProps(group.id, group.name ?? "", AdminRoutePaths.DetailsPageGroups));
  }
}
