import {
  IInventoryInspectionDoneDTO,
  IInventoryInspectionsControllerClient,
  IInventoryInspectionsDoneControllerClient,
  IInventoryItemsControllerClient,
} from "@generatedCode/pbd-core/pbd-core-api";

import { BaseExportService } from "../Base/BaseExportService";
import { ExportType } from "../Export/exportService";
import { IInventoryInspectionDoneVm } from "./models/inventory-inspection-done-vm";
import { InventoryInspectionDoneQueryParameters } from "./models/query-parameters";

const baseUrl = "/api/inventoryInspectionsDone";

export default class InventoryInspectionDoneService extends BaseExportService<IInventoryInspectionDoneDTO> {
  inspectionDoneApi: IInventoryInspectionsDoneControllerClient;
  inspectionsApi: IInventoryInspectionsControllerClient;
  inventoryItemsApi: IInventoryItemsControllerClient;
  constructor(
    inspectionDoneApi: IInventoryInspectionsDoneControllerClient,
    inspectionsApi: IInventoryInspectionsControllerClient,
    inventoryItemsApi: IInventoryItemsControllerClient,
  ) {
    super("Inspections done");
    this.inspectionDoneApi = inspectionDoneApi;
    this.inspectionsApi = inspectionsApi;
    this.inventoryItemsApi = inventoryItemsApi;
  }

  /**This maps inventoryItems and inspections to inspections done. This function helps reducing transferred data.
   * If the user has no access to an inspection or inventory item it will remove the inspection done and not return it in the mapped list.
   */
  async getAllAsVm(query?: InventoryInspectionDoneQueryParameters): Promise<IInventoryInspectionDoneVm[]> {
    const all = await this.inspectionDoneApi.getAllQuery(query ?? {});
    const inspections = await this.inspectionsApi.getAll();
    const inventoryItems = await this.inventoryItemsApi.getAll();
    const mapped: IInventoryInspectionDoneVm[] = [];
    for (const x of all) {
      const inventoryItem = inventoryItems.find((y) => y.id == x.inventoryItemId);
      const inspectionType = inspections.find((y) => y.id == x.inventoryInspectionId);
      if (!inventoryItem || !inspectionType) {
        continue;
      }
      mapped.push({
        ...x,
        inventoryItem,
        inventoryInspection: inspectionType,
      });
    }
    return mapped;
  }

  async getById(id: number): Promise<IInventoryInspectionDoneVm> {
    const done = await this.inspectionDoneApi.getById(id);
    const inspection = await this.inspectionsApi.getById(done.inventoryInspectionId);
    const inventoryItem = await this.inventoryItemsApi.getById(done.inventoryItemId);

    return {
      ...done,
      inventoryItem: inventoryItem,
      inventoryInspection: inspection,
    };
  }

  mapToExport(x: IInventoryInspectionDoneVm): ExportType {
    return {
      id: x.id,
      inventoryItem: `${x.inventoryItemId} ${x.inventoryItem.title}`,
      inventoryInspection: `${x.inventoryInspectionId} ${x.inventoryInspection.title}`,
      isInspectionOk: x.isInspectionOk,
      doneBy: x.doneBy?.fullName,
      doneAt: x.doneAt,
      costs: x.cost,
    };
  }
}
