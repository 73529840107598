import i18next from "i18next";
import { CellProps, Column } from "react-table";

import { ConnectAs, IBaseManyToManyDTO, IConnectedItem } from "@generatedCode/pbd-core/pbd-core-api";

import ConnectionTypeDiv from "../../connectionElements/shared/connectionTypeDiv";
import { qmBaseIcons } from "../../icons/qmBaseIcons";

interface IProps extends Partial<IConnectedItem> {
  id: number;
  manyToMany?: IBaseManyToManyDTO;
}

/**
 * This is the luxon datetime column
 * TODO: Is the accessor correct?
 */
export function getManyToManyColumn<T extends IProps>() {
  const column: Column<T> = {
    id: "manyToMany",
    Header: (
      <>
        <qmBaseIcons.Random title={i18next.t("Connection type")} />
      </>
    ),
    accessor: "manyToMany",
    disableSortBy: true,
    Cell: ({ row }: CellProps<T>) => (
      <>
        {row.original.manyToMany && (
          <ConnectionTypeDiv to="Tasks" manyToMany={row.original.manyToMany} id={row.original.id} />
        )}
        {row.original.connectAs == ConnectAs.Parent && <span>{i18next.t("Parent")}</span>}
        {row.original.connectAs == ConnectAs.Child && <span>{i18next.t("Child")}</span>}
      </>
    ),
  };
  return column;
}
