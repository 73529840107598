import { Field, FormikProps } from "formik";
import { Col, Dropdown, DropdownButton, Form, InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { TimeInterval, TimeIntervalDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { nameofFactory } from "../../../../../Helpers/nameof-factory";
import { FormikCheckbox } from "./formikCheckboxInput";
import { FormikNumberInput } from "./formikNumberInput";

const nameof = nameofFactory<IItemWithSurveillance>();

interface IItemWithSurveillance {
  isRecurring?: boolean;
  /**If the event is recurring the timeInterval must be set */
  monitoringInterval?: TimeIntervalDTO;
  /**This requires a recurring event */
  useWarningTime?: boolean;
  warningTimeInterval?: TimeIntervalDTO;
}

interface IProps<T extends IItemWithSurveillance> {
  formikBag: FormikProps<T>;
  includeWarningTime?: boolean;
}

function FormikRecurringComponent<T extends IItemWithSurveillance>(props: IProps<T>) {
  const { formikBag, includeWarningTime } = props;
  const { t } = useTranslation();

  return (
    <Row>
      <Col>
        <FormikCheckbox name={nameof("isRecurring")} label={t("Is this event recurring?")} />
        {formikBag.values.isRecurring && (
          <Form.Group className="mb-3">
            <Form.Label htmlFor="monitoringInterval">{t("Interval")}</Form.Label>
            <InputGroup>
              <Field name="monitoringInterval.value" component={FormikNumberInput} />
              <DropdownButton
                id="monitoringInterval.type"
                title={formikBag.values.monitoringInterval?.type ? t(formikBag.values.monitoringInterval.type) : ""}
              >
                {Object.keys(TimeInterval).map((x) => (
                  <Dropdown.Item key={x} onClick={() => formikBag.setFieldValue("monitoringInterval.type", x)}>
                    {t(x)}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </InputGroup>
          </Form.Group>
        )}
      </Col>
      {formikBag.values.isRecurring && includeWarningTime && (
        <Col>
          <FormikCheckbox name={nameof("useWarningTime")} label={t("Use early warning time?")} />
          {formikBag.values.useWarningTime && (
            <Form.Group className="mb-3">
              <Form.Label htmlFor="warningTimeInterval">{t("Warning time")}</Form.Label>
              <InputGroup>
                <Field name="warningTimeInterval.value" component={FormikNumberInput} />
                <DropdownButton
                  id="warningTimeInterval.type"
                  title={formikBag.values.warningTimeInterval?.type ? t(formikBag.values.warningTimeInterval.type) : ""}
                >
                  {Object.keys(TimeInterval).map((x) => (
                    <Dropdown.Item key={x} onClick={() => formikBag.setFieldValue("warningTimeInterval.type", x)}>
                      {t(x)}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </InputGroup>
              <Form.Text color="muted">{t("Timespan before the deadline is reached")}</Form.Text>
            </Form.Group>
          )}
        </Col>
      )}
    </Row>
  );
}
export default FormikRecurringComponent;
