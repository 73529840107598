import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

import { IApplicationUserDTO, ITenantCreateDTO, TenantCreateDTO } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import TenantService from "../../../../pbdServices/services/Tenants/tenantService";
import UserService from "../../../../pbdServices/services/Users/userService";
import { FormikInputGroupWrapper } from "../../../shared/components/forms/formik/formikInputGroupWrapper";
import FormikSubmitButton from "../../../shared/components/forms/formik/formikSubmitButton";
import { FormikTextInputGroup } from "../../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../../shared/components/forms/formik/formikValidationSummary";
import SalutationSelect from "../../../shared/components/inputControl/select/salutationSelect";

interface IProps {
  user: IApplicationUserDTO;
  onSuccess: () => void;
}

function CreateMyProfileForm(props: IProps) {
  const { t } = useTranslation();
  const { user, onSuccess } = props;
  const { accountApi } = useAPIs();

  const submitter = useFormikAPISubmitter<ITenantCreateDTO, IApplicationUserDTO>(
    (values) => accountApi.createTenant(new TenantCreateDTO(values)),
    [accountApi],
    onSuccess,
  );

  const initialValues = UserService.getInitialValues(user);
  return (
    <>
      <p className="text-center">{t("You are almost there. Please complete your profile.")}</p>
      <Formik
        initialValues={initialValues}
        onSubmit={submitter}
        validationSchema={TenantService.validationSchemaCreateMyProfile(t)}
      >
        {(formikBag) => (
          <Form>
            <FormikInputGroupWrapper name="gender" label={t("Salutation")}>
              <Field name="gender" component={SalutationSelect} />
            </FormikInputGroupWrapper>
            <FormikTextInputGroup name="firstName" label={t("First name")} />
            <FormikTextInputGroup name="lastName" label={t("Last name")} />
            <FormikValidationSummary formikBag={formikBag} />
            <div className="d-grid gap-2 mb-3">
              <FormikSubmitButton formikBag={formikBag} canSubmitDirty label={t("Continue")} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
export default CreateMyProfileForm;
