import React from "react";
import useSWR from "swr";

import { ICustomField, ICustomFieldDTO } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import CustomFieldService from "../../../../pbdServices/services/CustomFields/customFieldService";
import AvatarSpanWithName from "../tenants/avatarSpanWithName";

interface IProps {
  customField: ICustomField;
  customFieldContent: ICustomFieldDTO;
}

const CustomFieldApiRenderer: React.FC<IProps> = (props) => {
  const { customField, customFieldContent } = props;
  const { tenantsApi } = useAPIs();

  const value = React.useMemo(() => {
    if (customFieldContent.value) {
      return CustomFieldService.getApiFieldValue(customFieldContent.value);
    } else {
      return undefined;
    }
  }, [customFieldContent.value]);

  const { data: tenant } = useSWR(["/api/tenants", value?.id], () => tenantsApi.getById(value?.id));
  return <React.Fragment>{tenant && <AvatarSpanWithName tenant={tenant} />}</React.Fragment>;
};

export default CustomFieldApiRenderer;
