/* eslint-disable max-lines-per-function */
import { TFunction } from "i18next";
import { uniq } from "lodash";
import { Dispatch, SetStateAction, useState } from "react";
import CommandPalette, { JsonStructure, JsonStructureItem, filterItems, getItemIndex } from "react-cmdk";
import "react-cmdk/dist/cmdk.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useSWR from "swr";

import { PbdModule, SearchResponse } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../pbdServices/services/service-context";

import { IHelpLinks } from "../../Constants/productConfig";
import { AppVM } from "../../pbdServices/Models/App/AppVM";
import { RedirectSearchType, SearchPages, SearchService } from "../../services/GlobalSearch/searchService";
import { AdminRoutePaths } from "../admin/adminRoutePaths";
import { ProfileRoutePaths } from "../profile/profileRoutePaths";
import { SettingsRoutePaths } from "../settings/settingsRoutePaths";
import { qmBaseIcons } from "../shared/components/icons/qmBaseIcons";
import IdComponent from "../shared/components/id/idComponent";
import SearchBoxAppIcon from "../shared/components/navbar/searchBoxAppIcon";
import SearchBoxDynamicSpecificPages from "../shared/components/navbar/searchBoxDynamicSpecificPages";
import { useDebounceHook } from "../shared/components/search/useDebounceHook";
import Loading from "./loading";
import NoResults from "./noResults";
import Welcome from "./welcome";

function mapSearchData(
  searchResponse: SearchResponse,
  redirectToSomething: RedirectSearchType,
  t: TFunction,
): JsonStructure {
  const { results } = searchResponse;

  const uniqueApps = uniq(results.map((x) => x.appId));
  const structure: JsonStructure = uniqueApps.map((x) => {
    const itemsMapped: JsonStructureItem[] = results
      .filter((a) => a.appId == x)
      .map((x) => {
        return {
          id: x.id,
          title: x.displayName,
          children: (
            <>
              <span className="me-0.5">{x.displayName}</span> <IdComponent id={x.id.split("_")[1]} />
            </>
          ),
          onClick: () => x.links && redirectToSomething(x.links[0].href),
          keywords: [x.displayName],
        };
      });
    return { items: itemsMapped, id: x.toString(), heading: t(x) };
  });
  return structure;
}

interface IProps {
  available: AppVM[];
  helpLinks: IHelpLinks;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

function CommandPaletteComponent(props: IProps) {
  const { available, helpLinks, setIsOpen, isOpen } = props;
  const { t } = useTranslation();
  const { searchApi } = useAPIs();
  const navigate = useNavigate();
  const [page, setPage] = useState<SearchPages>("Root");
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounceHook(search, 400);
  const { data: dynamicAllData, isLoading } = useSWR(
    page != "DynamicSearch" && debouncedSearchTerm && debouncedSearchTerm.length > 2
      ? ["/api/search", "mapped", debouncedSearchTerm]
      : null,
    () =>
      searchApi
        .getAllQuery({
          q: debouncedSearchTerm,
          take: 25,
          apps: SearchService.getAppsByPage(page as Exclude<SearchPages, "Root">),
        })
        .then((x) => mapSearchData(x, redirectToSomething, t)),
  );

  const redirectToSomething = (navigateTo: string) => {
    setPage("Root");
    setSearch("");
    navigate(navigateTo);
  };

  const dynamicAllItems = filterItems(dynamicAllData ?? [], search);

  const dynamicPages: JsonStructureItem[] = [
    {
      id: PbdModule.AuditManagement,
      children: t("AuditManagement"),
      icon: () => SearchBoxAppIcon({ app: PbdModule.AuditManagement }),
      closeOnSelect: false,
      onClick: () => {
        setPage(PbdModule.AuditManagement);
        setSearch("");
      },
    },
    {
      id: PbdModule.ClaimManagement,
      children: t("ClaimManagement"),
      icon: () => SearchBoxAppIcon({ app: PbdModule.ClaimManagement }),
      closeOnSelect: false,
      onClick: () => {
        setPage(PbdModule.ClaimManagement);
        setSearch("");
      },
    },
    {
      id: PbdModule.Crm,
      children: t("Crm"),
      icon: () => SearchBoxAppIcon({ app: PbdModule.Crm }),
      closeOnSelect: false,
      onClick: () => {
        setPage(PbdModule.Crm);
        setSearch("");
      },
    },
    {
      id: PbdModule.CustomForms,
      children: t("CustomForms"),
      icon: () => SearchBoxAppIcon({ app: PbdModule.CustomForms }),
      closeOnSelect: false,
      onClick: () => {
        setPage(PbdModule.CustomForms);
        setSearch("");
      },
    },
    {
      id: PbdModule.DefectManagement,
      children: t("DefectManagement"),
      icon: () => SearchBoxAppIcon({ app: PbdModule.DefectManagement }),
      closeOnSelect: false,
      onClick: () => {
        setPage(PbdModule.DefectManagement);
        setSearch("");
      },
    },
    {
      id: PbdModule.GoalManagement,
      children: t("GoalManagement"),
      icon: () => SearchBoxAppIcon({ app: PbdModule.GoalManagement }),
      closeOnSelect: false,
      onClick: () => {
        setPage(PbdModule.GoalManagement);
        setSearch("");
      },
    },
    {
      id: PbdModule.ProjectAndTaskManagement,
      children: t("Tasks"),
      icon: () => SearchBoxAppIcon({ app: PbdModule.ToDoManagement }),
      closeOnSelect: false,
      onClick: () => {
        setPage(PbdModule.ProjectAndTaskManagement);
        setSearch("");
      },
    },
    {
      id: PbdModule.Blog,
      children: t("Blog"),
      icon: () => SearchBoxAppIcon({ app: PbdModule.Blog }),
      closeOnSelect: false,
      onClick: () => {
        setPage(PbdModule.Blog);
        setSearch("");
      },
    },
    {
      id: PbdModule.DocumentManagement,
      children: t("DocumentManagement"),
      icon: () => SearchBoxAppIcon({ app: PbdModule.DocumentManagement }),
      closeOnSelect: false,
      onClick: () => {
        setPage(PbdModule.DocumentManagement);
        setSearch("");
      },
    },
    {
      id: PbdModule.KnowledgeBase,
      children: t("Wiki"),
      icon: () => SearchBoxAppIcon({ app: PbdModule.KnowledgeBase }),
      closeOnSelect: false,
      onClick: () => {
        setPage(PbdModule.KnowledgeBase);
        setSearch("");
      },
    },
    {
      id: PbdModule.MaintenanceManagement,
      children: t("MaintenanceManagement"),
      icon: () => SearchBoxAppIcon({ app: PbdModule.MaintenanceManagement }),
      closeOnSelect: false,
      onClick: () => {
        setPage(PbdModule.MaintenanceManagement);
        setSearch("");
      },
    },
    {
      id: PbdModule.OpportunityManagement,
      children: t("OpportunityManagement"),
      icon: () => SearchBoxAppIcon({ app: PbdModule.OpportunityManagement }),
      closeOnSelect: false,
      onClick: () => {
        setPage(PbdModule.OpportunityManagement);
        setSearch("");
      },
    },
    {
      id: PbdModule.Products,
      children: t("Products"),
      icon: () => SearchBoxAppIcon({ app: PbdModule.Products }),
      closeOnSelect: false,
      onClick: () => {
        setPage(PbdModule.Products);
        setSearch("");
      },
    },
    {
      id: PbdModule.TrainingManagement,
      children: t("TrainingManagement"),
      icon: () => SearchBoxAppIcon({ app: PbdModule.TrainingManagement }),
      closeOnSelect: false,
      onClick: () => {
        setPage(PbdModule.TrainingManagement);
        setSearch("");
      },
    },
  ];

  const filteredItems = filterItems(
    [
      {
        heading: t("General"),
        id: "home",
        items: [
          {
            id: "DynamicSearch",
            children: t("Narrow search"),
            icon: "CircleStackIcon",
            closeOnSelect: false,
            className: "bg-info",
            onClick: () => {
              setPage("DynamicSearch");
              setSearch("");
            },
          },
          {
            id: "home",
            children: t("Home"),
            icon: "HomeIcon",
            iconType: "outline",
            onClick: () => redirectToSomething("/"),
          },
          {
            id: "admin",
            children: t("Admin"),
            icon: qmBaseIcons.Admin,
            onClick: () => redirectToSomething(AdminRoutePaths.HomePage),
          },
          {
            id: "settings",
            children: t("Settings"),
            icon: qmBaseIcons.Gear,
            iconType: "outline",
            onClick: () => redirectToSomething(SettingsRoutePaths.Home),
          },
          {
            id: "profile",
            children: t("Profile"),
            icon: qmBaseIcons.UserGear,
            iconType: "outline",
            onClick: () => redirectToSomething(ProfileRoutePaths.HomePage),
          },
        ],
      },
      {
        heading: t("Apps"),
        id: "apps",
        items: SearchService.mapApps(available, redirectToSomething),
      },
      {
        heading: t("Other"),
        id: "advanced",
        items: [
          {
            id: "help",
            children: t("Help"),
            icon: "QuestionMarkCircleIcon",
            href: helpLinks.homePage.url,
            target: "_blank",
            rel: "noopener noreferrer",
          },
        ],
      },
    ],
    search,
  );

  const filteredDynamicItems = filterItems(
    [
      {
        heading: t("Limit your results and search by app data"),
        id: "specificSearch",
        items: dynamicPages,
      },
    ],
    search,
  );

  const noResults = filterItems.length + (dynamicAllData?.length ?? 0) == 0 && !isLoading;

  return (
    <CommandPalette onChangeSearch={setSearch} onChangeOpen={setIsOpen} search={search} isOpen={isOpen} page={page}>
      <CommandPalette.Page id="Root">
        {noResults ? (
          <NoResults />
        ) : (
          <>
            {filteredItems.map((list) => (
              <CommandPalette.List key={list.id} heading={list.heading}>
                {list.items.map(({ id, ...rest }) => (
                  <CommandPalette.ListItem key={id} index={getItemIndex(filteredItems, id)} {...rest} />
                ))}
              </CommandPalette.List>
            ))}
            {dynamicAllItems.map((list) => (
              <CommandPalette.List key={list.id} heading={list.heading}>
                {list.items.map(({ id, ...rest }, i) => (
                  <CommandPalette.ListItem
                    key={i}
                    index={getItemIndex(dynamicAllItems, id)}
                    showType={false}
                    {...rest}
                  />
                ))}
              </CommandPalette.List>
            ))}
          </>
        )}
        {isLoading && <Loading />}
      </CommandPalette.Page>
      <CommandPalette.Page id="DynamicSearch" searchPrefix={[t("Your data")]} onEscape={() => setPage("Root")}>
        {dynamicAllItems.length == 0 && !search && <Welcome />}
        {debouncedSearchTerm && !isLoading && filteredDynamicItems.length == 0 && dynamicAllItems.length == 0 && (
          <NoResults />
        )}

        {filteredDynamicItems.length > 0 &&
          filteredDynamicItems.map((list) => (
            <CommandPalette.List key={list.id} heading={list.heading}>
              {list.items.map(({ id, ...rest }) => (
                <CommandPalette.ListItem key={id} index={getItemIndex(filteredDynamicItems, id)} {...rest} />
              ))}
            </CommandPalette.List>
          ))}

        {dynamicAllItems.map((list) => (
          <CommandPalette.List key={list.id} heading={list.heading}>
            {list.items.map(({ id, ...rest }, i) => (
              <CommandPalette.ListItem key={i} index={getItemIndex(dynamicAllItems, id)} showType={false} {...rest} />
            ))}
          </CommandPalette.List>
        ))}

        {isLoading && <Loading />}
      </CommandPalette.Page>

      <SearchBoxDynamicSpecificPages
        pages={dynamicPages}
        onEscape={() => setPage("DynamicSearch")}
        prefix={t("Your data")}
      >
        {search && !isLoading && filteredDynamicItems.length == 0 && dynamicAllItems.length == 0 && <NoResults />}
        {dynamicAllItems.map((list) => (
          <CommandPalette.List key={list.id} heading={list.heading}>
            {list.items.map(({ id, ...rest }, i) => (
              <CommandPalette.ListItem key={i} index={getItemIndex(dynamicAllItems, id)} showType={false} {...rest} />
            ))}
          </CommandPalette.List>
        ))}
      </SearchBoxDynamicSpecificPages>
    </CommandPalette>
  );
}
export default CommandPaletteComponent;
