import { DateTime, Duration } from "luxon";
import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SetQuery, useQueryParams } from "use-query-params";

import { PbdStatus } from "@generatedCode/pbd-core/pbd-core-api";

import { qmBaseIcons } from "../icons/qmBaseIcons";
import AddFilterComponent from "./addFilterComponent";
import { FilterElement, SearchFilterTypes } from "./availableSearchFilters";
import FilterItem from "./filterItem";
import { DefaultQueryParamsConfig, QueryParamsConfigPbd } from "./queryParamsConfigPbd";
import { useLocalQueryParams } from "./useLocalQueryParams";
import { useSelectedFilterHook } from "./useSelectedFilterHookNew";

interface IProps {
  availableFilters: FilterElement[];
  onChange?: (dto: FilterElement[]) => void;
  defaultValue?: Record<string, unknown>;
  setQuery: SetQuery<typeof QueryParamsConfigPbd>;
  children?: React.ReactNode;
}

export const FilterBaseComponent = (props: IProps) => {
  const { availableFilters, onChange, defaultValue, setQuery } = props;
  const { t } = useTranslation();
  const [selectedFilters, setSelectedFilters] = useSelectedFilterHook(availableFilters, defaultValue);

  const handleClear = () => {
    setSelectedFilters([]);
    if (onChange) {
      onChange([]);
    } else {
      setQuery({}, "replace");
    }
  };

  const handleChange = (dto: FilterElement, remove?: boolean) => {
    if (!selectedFilters) throw Error("Missing filter");
    const copy = [...selectedFilters];
    const v = copy.find((x) => x.type == dto.type);
    if (v && !remove) {
      v.queryValue = dto.queryValue;
      setSelectedFilters(copy);
    }
    if (dto.queryValue == null) {
      setSelectedFilters(selectedFilters.filter((x) => x.type != dto.type));
    }
    // else if (copy.find((x) => x.type == dto.type) && remove) {
    //   copy.find((x) => x.type == dto.type).queryValue = null;
    // }
    if (onChange) {
      onChange(copy);
      return;
    } else {
      switch (dto.type) {
        case SearchFilterTypes.Tags:
          setQuery({ tags: dto.queryValue as string[] | undefined });
          break;
        case SearchFilterTypes.IsDeleted:
          setQuery({ isDeleted: dto.queryValue as boolean | undefined });
          break;
        case SearchFilterTypes.Department:
          setQuery({ departmentId: dto.queryValue as number[] | undefined });
          break;
        case SearchFilterTypes.DepartmentPosition:
          setQuery({ departmentPositionId: dto.queryValue as number[] | undefined });
          break;
        case SearchFilterTypes.CreatedFrom:
          if (typeof dto.queryValue == "string") {
            setQuery({ createdFrom: dto.queryValue ? DateTime.fromISO(dto.queryValue) : undefined });
          }
          break;
        case SearchFilterTypes.CreatedTo:
          if (typeof dto.queryValue == "string") {
            setQuery({ createdTo: dto.queryValue ? DateTime.fromISO(dto.queryValue) : undefined });
          }
          break;
        case SearchFilterTypes.LastUpdatedFrom:
          setQuery({ lastUpdatedFrom: dto.queryValue as DateTime | undefined });
          break;
        case SearchFilterTypes.LastUpdatedTo:
          setQuery({ lastUpdatedTo: dto.queryValue as DateTime | undefined });
          break;
        case SearchFilterTypes.IsEmployee:
          setQuery({ isEmployee: (dto.queryValue as boolean | null) ?? undefined });
          break;
        case SearchFilterTypes.IsApplicationUser:
          setQuery({ isApplicationUser: (dto.queryValue as boolean | null) ?? undefined });
          break;
        case SearchFilterTypes.Recent:
          setQuery({ recent: dto.queryValue as Duration | undefined });
          break;
        case SearchFilterTypes.Status:
          setQuery({ status: dto.queryValue as PbdStatus[] | undefined });
          break;
        case SearchFilterTypes.IsQualificationRequired:
          setQuery({ isQualificationRequired: dto.queryValue as boolean | undefined });
          break;
        default:
          throw Error("Not implemented");
      }
    }
  };

  const handleAddFilter = (filter: SearchFilterTypes) => {
    if (!selectedFilters) throw Error("Missing filter");
    const filterToAdd = availableFilters.find((x) => x.type == filter);
    if (filterToAdd) {
      setSelectedFilters((prevState) => [...(prevState ?? []), filterToAdd]);
    }
  };

  return (
    <Card className="mb-3">
      <Card.Body>
        {props.children}
        <Row>
          {selectedFilters?.map((x) => (
            <Col key={x.type} md={3}>
              <FilterItem item={x} onChange={handleChange} />
            </Col>
          ))}
          <Col md={3}>
            <Form.Group className="mb-3" controlId="addFilterId">
              <Form.Label>
                <qmBaseIcons.Plus /> {t("Add new filter")}
              </Form.Label>
              {selectedFilters && (
                <AddFilterComponent
                  data={availableFilters}
                  selected={selectedFilters}
                  onSelect={handleAddFilter}
                  inputId="addFilterId"
                />
              )}
            </Form.Group>
          </Col>
          {selectedFilters && selectedFilters.length > 1 && (
            <Col md={3}>
              <Form.Group className="mb-3">
                <Button variant="outline-danger" size="sm" onClick={handleClear}>
                  <qmBaseIcons.Eraser /> {t("Clear all filters")}
                </Button>
              </Form.Group>
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

export function FilterComponent(props: Omit<IProps, "setQuery">) {
  const [query, setQuery] = useQueryParams(QueryParamsConfigPbd);

  return <FilterBaseComponent {...props} setQuery={setQuery} />;
}

export function StringFilterComponent(props: Omit<IProps, "setQuery">) {
  const [query, setQuery] = useLocalQueryParams(QueryParamsConfigPbd, DefaultQueryParamsConfig);
  return <FilterBaseComponent {...props} setQuery={setQuery} />;
}
export default FilterComponent;
