import i18next from "i18next";
import React from "react";
import { Badge, Button, Card, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { NumberParam, useQueryParams, withDefault } from "use-query-params";

import { IArticleDTO, IArticleRevisionDTO, PbdModule } from "@generatedCode/pbd-core/pbd-core-api";
import { StoreItemDTO } from "@generatedCode/store/storeApi";
import { useAPIs, useStoreAPIs } from "../../../../pbdServices/services/service-context";

import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { hasRole } from "../../../../services/Authz/authService";
import GenericSelect, { OptionData } from "../../../shared/components/inputControl/select/genericSelect";
import { useAppContext } from "../../../shared/contexts/appContext";
import { useJwtExternalHook } from "../../../shared/hooks/useJwtExternalHook";

type FilterKey = "article" | "revision" | "storeItem";

export const mapToOptionData = (
  filterKey: FilterKey,
  array: IArticleDTO[] | IArticleRevisionDTO[] | StoreItemDTO[],
) => {
  const options: OptionData[] = [];
  for (const element of array) {
    const toAdd = new OptionData({ id: element.id, title: "" });
    if (filterKey == "article" || filterKey == "storeItem") {
      toAdd.label = `#${element.id} ${element.title}`;
    } else {
      const elementAsRevision = element as IArticleRevisionDTO;
      toAdd.label = `#${elementAsRevision.id} ${elementAsRevision.version ?? i18next.t("Not set")}`;
    }
    options.push(toAdd);
  }
  return options;
};

interface IProps {
  articles: IArticleDTO[];
  comparisonSide: "left" | "right";
  articleId?: number;
  storeItemId?: number;
  cardTitle: string;
}

function ArticleCompareFilterComponent(props: IProps) {
  const { articleId, storeItemId, comparisonSide, cardTitle, articles } = props;
  const { isLoggedIn } = useJwtExternalHook();
  const { storeItemsClient } = useStoreAPIs();
  const { articleRevisionsApi } = useAPIs();
  const { t } = useTranslation();
  const { meAsUser } = useAppContext();
  const { data: storeItems } = useSWR(isLoggedIn ? "/api/store/storeItems" : null, () => storeItemsClient.getAll());
  const [compareType, setCompareType] = React.useState<PbdModule.DocumentManagement | PbdModule.Store>();
  const [query, setQuery] = useQueryParams({
    articleId: withDefault(NumberParam, undefined),
    leftArticleRevisionId: withDefault(NumberParam, undefined),
    rightArticleRevisionId: withDefault(NumberParam, undefined),
    leftArticleId: withDefault(NumberParam, undefined),
    rightArticleId: withDefault(NumberParam, undefined),
    leftStoreItemId: withDefault(NumberParam, undefined),
    rightStoreItemId: withDefault(NumberParam, undefined),
  });

  const { data: revisions } = useSWR(articleId ? ["/api/articleRevisions", "articleId", articleId] : undefined, () => {
    if (articleId) {
      return articleRevisionsApi.getAllQuery({ articleId: [articleId] });
    } else {
      return null;
    }
  });

  React.useEffect(() => {
    if (comparisonSide == "right") {
      if (query.rightArticleId || query.rightArticleRevisionId) {
        setCompareType(PbdModule.DocumentManagement);
      } else if (query.rightStoreItemId) {
        setCompareType(PbdModule.Store);
      }
    } else if (comparisonSide == "left") {
      if (query.leftArticleId || query.leftArticleRevisionId) {
        setCompareType(PbdModule.DocumentManagement);
      } else if (query.leftStoreItemId) {
        setCompareType(PbdModule.Store);
      }
    }
  }, [
    comparisonSide,
    query.leftArticleId,
    query.leftArticleRevisionId,
    query.leftStoreItemId,
    query.rightArticleId,
    query.rightArticleRevisionId,
    query.rightStoreItemId,
  ]);

  const selectedArticleRevisionId =
    comparisonSide == "left" ? query.leftArticleRevisionId : query.rightArticleRevisionId;

  const handleChange = (key: FilterKey, selectedData?: OptionData[]) => {
    const queryValue = selectedData ? Number(selectedData[0].value) : undefined;
    switch (key) {
      case "article":
        if (comparisonSide == "left") {
          setQuery({ leftArticleId: queryValue });
        } else {
          setQuery({ rightArticleId: queryValue });
        }
        break;
      case "revision":
        if (comparisonSide == "left") {
          setQuery({ leftArticleRevisionId: queryValue });
        } else {
          setQuery({ rightArticleRevisionId: queryValue });
        }
        break;
      case "storeItem":
        if (comparisonSide == "left") {
          setQuery({ leftStoreItemId: queryValue });
        } else {
          setQuery({ rightStoreItemId: queryValue });
        }
        break;
    }
  };

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between">
        <Card.Title as="h5">{cardTitle}</Card.Title>
        <div>{compareType && <Badge bg="secondary">{t(compareType)}</Badge>}</div>
      </Card.Header>
      <Card.Body>
        {!compareType && (
          <>
            <Button variant="secondary" onClick={() => setCompareType(PbdModule.DocumentManagement)} className="me-2">
              {t(PbdModule.DocumentManagement)}
            </Button>
            {hasRole(meAsUser, [PbdRoles.Dev]) && (
              <Button onClick={() => setCompareType(PbdModule.Store)}>
                {t("Templates")} / {t(PbdModule.Store)}
              </Button>
            )}
          </>
        )}
        {compareType == PbdModule.Store && storeItems && (
          <Form.Group className="mb-3">
            <Form.Label htmlFor="storeItem">{t("Store item")}</Form.Label>
            <GenericSelect
              name="storeItem"
              onChange={(x) => handleChange("storeItem", x)}
              selected={storeItemId}
              data={mapToOptionData("storeItem", storeItems)}
              isClearable
            />
          </Form.Group>
        )}
        {compareType == PbdModule.DocumentManagement && (
          <>
            {!query.articleId && (
              <Form.Group className="mb-3">
                <Form.Label htmlFor="article">{t("Article")}</Form.Label>
                <GenericSelect
                  name="article"
                  onChange={(x) => handleChange("article", x)}
                  selected={articleId}
                  data={mapToOptionData("article", articles)}
                  isClearable
                />
              </Form.Group>
            )}
            {revisions && (
              <Form.Group className="mb-3">
                <Form.Label htmlFor="revision">{t("Revision")}</Form.Label>
                <GenericSelect
                  name="revision"
                  onChange={(x) => handleChange("revision", x)}
                  selected={selectedArticleRevisionId}
                  data={mapToOptionData("revision", revisions)}
                  isClearable
                />
              </Form.Group>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
}

export default ArticleCompareFilterComponent;
