import { IArticleViewDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { ListHelpers } from "../../../Helpers/ListHelpers";

export default class ArticleViewService {
  getGroupedArticleViewsTenantsTenants(articleViews: IArticleViewDTO[], showTopX?: number) {
    const tenants = articleViews.filterMap((x) => x.viewer);
    const grouped = ListHelpers.groupAndSortArrayById(tenants);
    grouped.reverse();
    if (showTopX) {
      const paged = grouped.splice(0, showTopX);
      return paged;
    } else {
      return grouped;
    }
  }
}
