import clsx from "clsx";
import React from "react";
import { Badge } from "react-bootstrap";
import { Variant } from "react-bootstrap/esm/types";
import { useTranslation } from "react-i18next";

import { PbdStatus } from "@generatedCode/pbd-core/pbd-core-api";

import { qmBaseIcons } from "../icons/qmBaseIcons";

interface StatusInfo {
  contextClass: Variant;
  color: string;
  iconClass: React.ComponentType;
}

interface IProps {
  status: PbdStatus;
  mr?: boolean;
}

const getStatusInfo = (status: PbdStatus) => {
  let info: StatusInfo;
  switch (status) {
    case PbdStatus.Open:
      info = { contextClass: "secondary", color: "info", iconClass: qmBaseIcons.CheckCircle };
      break;
    case PbdStatus.Completed:
      info = { contextClass: "success", color: "success", iconClass: qmBaseIcons.CheckCircle };
      break;
    case PbdStatus.InProgress:
      info = { contextClass: "primary", color: "primary", iconClass: qmBaseIcons.Wrench };
      break;
    case PbdStatus.Approved:
      info = { contextClass: "success", color: "success", iconClass: qmBaseIcons.Check };
      break;
    case PbdStatus.Submitted:
      info = { contextClass: "info", color: "info", iconClass: qmBaseIcons.Submitted };
      break;
    case PbdStatus.Blocked:
      info = { contextClass: "danger", color: "danger", iconClass: qmBaseIcons.DoorClosed };
      break;
    default:
      throw Error(`Status "${status}" not defined`);
  }
  return info;
};

function StatusBadge(props: IProps) {
  const { status, mr } = props;
  const { t } = useTranslation();
  const info = getStatusInfo(status);
  return (
    <Badge bg={info.contextClass} className={clsx(mr && "me-2")}>
      <info.iconClass /> {t(status)}
    </Badge>
  );
}

export default StatusBadge;
