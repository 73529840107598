import { uniqBy } from "lodash";

import {
  IDepartmentPositionDTO,
  IDepartmentPositionWithChildrenDTO,
  ITenantDTO,
} from "@generatedCode/pbd-core/pbd-core-api";

import { BaseExportService } from "../Base/BaseExportService";
import { ExportType } from "../Export/exportService";
import { D3OrgChart } from "./models/d3OrgChart";
import { IDepartmentPositionVM } from "./models/department-position-vm";

export default class DepartmentPositionService extends BaseExportService<IDepartmentPositionDTO> {
  constructor() {
    super("Department positions");
  }

  mapToExport(x: IDepartmentPositionDTO): ExportType {
    return {
      id: x.id,
      title: x.title,
      departmentId: x.department.id,
      departmentTitle: x.department.title,
      requiredCompanyFunctionIds: x.requiredCompanyFunctions?.map((x) => x.id).join(),
      requiredCompanyFunctions: x.requiredCompanyFunctions?.map((x) => x.title).join(),
    };
  }
  getUniqueCompanyFunctionsByDepartmentPositions(items: IDepartmentPositionDTO[]) {
    const requiredCompanyFunctionByPositions = items.flatMap((x) => x.requiredCompanyFunctions ?? []);

    const uniqueCompanyFunctions = uniqBy(requiredCompanyFunctionByPositions, (x) => x.id).sort((a, b) =>
      a.title.localeCompare(b.title),
    );
    return uniqueCompanyFunctions;
  }

  getParentDepartmentPositions(
    dp: IDepartmentPositionWithChildrenDTO,
    allDepartmentPositions: IDepartmentPositionWithChildrenDTO[],
  ): IDepartmentPositionDTO[] {
    const parents: IDepartmentPositionDTO[] = [];
    for (const element of allDepartmentPositions) {
      if (element.childDepartmentPositions?.map((x) => x.id).includes(dp.id)) {
        parents.push(element);
      }
    }
    return parents;
  }

  mapToVM(items: IDepartmentPositionWithChildrenDTO[]): IDepartmentPositionVM[] {
    const mapped: IDepartmentPositionVM[] = [];
    for (const element of items) {
      const parentDps = this.getParentDepartmentPositions(element, items);
      const vm: IDepartmentPositionVM = {
        ...element,
        parentDepartmentPositionCount: parentDps.length,
        requiredCompanyFunctionsCount: element.requiredCompanyFunctions?.length,
      };
      const childDepartmentPositions = vm.childDepartmentPositions;
      if (childDepartmentPositions) {
        vm.childDepartmentPositionCount = childDepartmentPositions.length;
        if (parentDps.length > 0 && childDepartmentPositions.length > 0) {
          const circularRelation = parentDps
            .map((x) => x.id)
            .some((r) => childDepartmentPositions.map((c) => c.id).includes(r));
          vm.circularRelation = circularRelation;
        }
      }
      mapped.push(vm);
    }
    return mapped;
  }

  getTopNodes(items: IDepartmentPositionWithChildrenDTO[]) {
    return items.filter(
      (x) =>
        (x.parentDepartmentPositionIds == undefined || x.parentDepartmentPositionIds.length == 0) &&
        x.childDepartmentPositions &&
        x.childDepartmentPositions.length > 0,
    );
  }

  getNodesToIgnore(items: IDepartmentPositionWithChildrenDTO[]) {
    return items.filter(
      (x) =>
        (x.parentDepartmentPositionIds == undefined || x.parentDepartmentPositionIds.length == 0) &&
        (x.childDepartmentPositions == undefined || x.childDepartmentPositions.length == 0),
    );
  }

  mapToD3OrgChart(
    items: IDepartmentPositionWithChildrenDTO[],
    selectedTopNode: IDepartmentPositionWithChildrenDTO,
    tenants: ITenantDTO[],
  ): D3OrgChart[] {
    const chart = new D3OrgChart(selectedTopNode, tenants, items);
    const childs = chart.getChildren();
    return childs;
  }
}
