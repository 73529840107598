import { Formik } from "formik";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { IFileCategoryCreateDTO, IFileCategoryDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { nameofFactory } from "../../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { requiredTitleStringSchema } from "../../../../services/validation/stringSchemas";
import FormikCustomForm from "../../../shared/components/forms/formik/formikCustomForm";
import { FormikHtmlInputGroup } from "../../../shared/components/forms/formik/formikHtmlInput";
import { FormikTextInputGroup } from "../../../shared/components/forms/formik/formikTextInput";

const nameof = nameofFactory<IFileCategoryDTO>();

const getSchema = (t: TFunction) => {
  const ValidationSchema: yup.ObjectSchema<IFileCategoryCreateDTO> = yup.object({
    title: requiredTitleStringSchema(t),
    description: yup.string(),
  });
  return ValidationSchema;
};

interface IProps {
  itemToUpdate?: IFileCategoryDTO;
  onSubmit: (values: IFileCategoryCreateDTO) => Promise<void>;
  onCancel: () => void;
}

function FileCategoriesForm(props: IProps) {
  const { itemToUpdate, onSubmit, onCancel } = props;
  const { t } = useTranslation();

  const submitter = useFormikAPISubmitter<IFileCategoryCreateDTO>((values) => onSubmit(values), [onSubmit]);
  const initialValues: IFileCategoryCreateDTO = {
    title: itemToUpdate?.title ?? "",
    description: itemToUpdate?.description ?? "",
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={getSchema(t)}>
      {(formikBag) => (
        <FormikCustomForm formikBag={formikBag} onCancel={onCancel}>
          <FormikTextInputGroup name={nameof("title")} />
          <FormikHtmlInputGroup name={nameof("description")} />
        </FormikCustomForm>
      )}
    </Formik>
  );
}

export default FileCategoriesForm;
