import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { IQualificationDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { wrapApiCallWithToast } from "../../../../../pbdServices/services/Api/api-wrapper";
import { QualificationTable } from "../../../../qualifications/components/qualificationTable";
import { useConfirmation } from "../../../contexts/modalConfirmationContext";
import { useToggle } from "../../../hooks/useToggle";
import { TableAction, TableClickCommand } from "../../tables/TableClickCommand";
import { ConnectedCardProps } from "../connectedCardProps";
import { ConnectedCardHeader } from "../shared/connectedCardHeader";
import ConnectQualificationsModal from "./connectQualificationsModal";

const availableTableActions: TableAction[] = ["Delete"];

function QualificationsConnectedCard(props: ConnectedCardProps<IQualificationDTO>) {
  const { t } = useTranslation();
  const {
    refreshParent,
    data,
    baseDTO,
    onConnectSubmit,
    cardTitle = t("Qualification"),
    onDisconnectSubmit,
    columnsToExclude,
  } = props;
  const [connectModal, toggleConnectModal] = useToggle();
  const [selected, setSelected] = React.useState<IQualificationDTO[]>([]);
  const confirm = useConfirmation();

  const submitConnectionRequest = async (selectedIds: number[]) => {
    if (onConnectSubmit) {
      await onConnectSubmit(selectedIds);
    }
  };

  const handleTableClick = async (command: TableClickCommand) => {
    const { action } = command;
    const itemsToTake = selected.length > 0 ? selected : data;
    switch (action) {
      case "Delete":
        confirm({
          itemsToDelete: selected,
        }).then(() => submitDeleteRequest(selected.map((x) => Number(x.id))));
        break;
      default:
        throw Error("Not implemented");
    }
  };

  const submitDeleteRequest = async (dto: number[]) => {
    if (!onDisconnectSubmit) throw Error("Missing disconnect");
    for (const i of dto) {
      const resp = await wrapApiCallWithToast(() => onDisconnectSubmit(i));
    }
    refreshParent?.();
  };

  const title = (
    <span>
      <i className="fas icon-qmBase-QualificationMatrix" /> {cardTitle}
    </span>
  );

  return (
    <Card className="mb-3">
      <ConnectedCardHeader
        title={title}
        toggleConnectModal={toggleConnectModal}
        data={data}
        canAdd={baseDTO.capabilities?.canEdit || baseDTO.capabilities?.canConnectTask}
      />
      {data && data.length > 0 && (
        <Card.Body>
          <QualificationTable
            tableRows={data}
            columnsVisibleDefault={["title"]}
            columnsToExclude={columnsToExclude}
            onCreateClick={toggleConnectModal}
            onCreateButtonTitle={t("Add qualification")}
            setSelected={setSelected}
            availableTableActions={availableTableActions}
            onClick={handleTableClick}
          />
        </Card.Body>
      )}
      {data && (
        <ConnectQualificationsModal
          modal={connectModal}
          toggle={toggleConnectModal}
          connectedElements={data}
          onSubmit={submitConnectionRequest}
          canSubmit={true}
          onSuccess={refreshParent ? refreshParent : undefined}
        />
      )}
    </Card>
  );
}

export default QualificationsConnectedCard;
