import { useTranslation } from "react-i18next";

import { OpportunityType } from "@generatedCode/pbd-core/pbd-core-api";

import CustomSelect from "./customSelect";

interface IProps {
  onChange: (id?: string | string[]) => void;
  selectedIds?: string[];
  isMulti?: boolean;
  isClearable?: boolean;
}

const types = Object.keys(OpportunityType);

function OpportunityTypeSelect(props: IProps) {
  const { t } = useTranslation();

  return (
    <CustomSelect<string>
      data={types.map((x) => {
        return {
          title: t(x),
          id: x,
        };
      })}
      name="opportunityType"
      {...props}
    />
  );
}

export default OpportunityTypeSelect;
