import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSWRImmutable from "swr/immutable";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import { ITenantDTO } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../pbdServices/services/service-context";

import { ClaimTypeNames } from "../../../Constants/ClaimTypeNames";
import { wrapApiCallWithToast } from "../../../pbdServices/services/Api/api-wrapper";
import UserService from "../../../pbdServices/services/Users/userService";
import GenericAlert from "../../shared/components/alerts/genericAlert";
import { LoadingComponent } from "../../shared/components/loading/loadingComponent";
import { useMyProfileHook } from "../../shared/hooks/useMyProfileHook";
import { StartpageRoutePaths } from "../../startpage/startpageRoutePaths";
import CreateMyProfileForm from "../components/activateProfile/createMyProfileForm";
import ExistingTenantComponent from "../components/activateProfile/existingTenantComponent";
import CardFooterAccount from "../components/cardFooterAccount";

function ActivatePageProfile() {
  const { t } = useTranslation();
  const { data: meAsUser, mutate } = useMyProfileHook();
  const navigate = useNavigate();
  const { userApi, tenantsApi, accountApi } = useAPIs();
  const { tenantService } = useAPIServices();
  const [loading, setLoading] = React.useState(true);
  const [existingTenant, setExistingTenant] = React.useState<ITenantDTO>();
  const { data: admins } = useSWRImmutable(["/api/tenants", "Role", "Admin"], () =>
    tenantService.getAdminsExcludeQmBaseUser(),
  );
  const [returnUrl, setReturnUrl] = useQueryParam("returnUrl", withDefault(StringParam, undefined));

  React.useEffect(() => {
    async function getData() {
      if (meAsUser?.tenant && meAsUser.user?.claims?.find((x) => x.claimType == ClaimTypeNames.TenantId)) {
        let urlAfterLogin = returnUrl ?? "/";
        if (urlAfterLogin.toLowerCase().includes("error")) {
          urlAfterLogin = "/";
        }
        const href = urlAfterLogin;
        navigate(href);
      } else if (meAsUser?.tenant) {
        setExistingTenant(meAsUser.tenant);
      }
      if (meAsUser?.user?.email) {
        const resp = await tenantsApi.getAllQuery({ email: meAsUser.user.email });
        if (resp.data.length == 1) {
          setExistingTenant(resp.data[0]);
        }
      }
      setLoading(false);
    }
    getData();
  }, [meAsUser, returnUrl]);

  const mapAccountToTenant = async () => {
    const resp = await wrapApiCallWithToast(() => accountApi.mapAccountToTenant());
    if (resp.isOk) {
      void handleRedirect();
    }
  };

  const handleRedirect = async () => {
    await userApi.resetThemeToDefault();
    await mutate();
    navigate(StartpageRoutePaths.StartPage);
  };

  if (loading || !meAsUser?.user) return <LoadingComponent />;

  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title as="h5" className="text-center">
          👋 {t("Hello,")} <b>{existingTenant?.fullName ?? meAsUser.user.email}</b>
        </Card.Title>
        {!UserService.isProfileMissing(meAsUser) && (
          <GenericAlert type="success" heading={t("Your account has been successfully activated")} />
        )}
        {existingTenant ? (
          <ExistingTenantComponent
            user={meAsUser.user}
            existingTenant={existingTenant}
            admins={admins}
            mapAccountToTenant={mapAccountToTenant}
          />
        ) : (
          <CreateMyProfileForm onSuccess={handleRedirect} user={meAsUser.user} />
        )}
        <CardFooterAccount />
      </Card.Body>
    </Card>
  );
}
export default ActivatePageProfile;
