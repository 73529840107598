import React from "react";
import { Column } from "react-table";

import { ICustomField, IDefectDTO } from "@generatedCode/pbd-core/pbd-core-api";

import BaseTableProps from "../../shared/components/tables/BaseTableProps";
import VisibleColumns from "../../shared/components/tables/VisibleColumns";
import { getCategoryColumn } from "../../shared/components/tables/columns/categoryColumn";
import { getCreatedAtColumn } from "../../shared/components/tables/columns/createdAtColumn";
import { getCustomFieldsColumns } from "../../shared/components/tables/columns/customFieldColumns";
import { getIdColumn } from "../../shared/components/tables/columns/idColumn";
import { getResponsibleColumn } from "../../shared/components/tables/columns/responsibleColumn";
import { getTagColumn } from "../../shared/components/tables/columns/tagsColumn";
import { getTitleColumn } from "../../shared/components/tables/columns/titleColumn";
import { getLastUpdatedAtColumn } from "../../shared/components/tables/columns/updatedAtColumn";
import { ReactstrapTable } from "../../shared/components/tables/reactstrapTable";
import { DefectRoutePaths } from "../defectRoutePaths";

interface IProps extends BaseTableProps<IDefectDTO> {
  customFields?: ICustomField[];
}
export function DefectTable(props: IProps) {
  const {
    includeTitleLink,
    titleColumnBaseLink = DefectRoutePaths.EditPage,
    columnsVisibleDefault = VisibleColumns.appTableVisibleColumns,
    customFields,
  } = props;

  const columns = React.useMemo<Column<IDefectDTO>[]>(
    () => [
      getIdColumn(includeTitleLink, titleColumnBaseLink),
      getTitleColumn(includeTitleLink, titleColumnBaseLink),
      getTagColumn(),
      getCategoryColumn(),
      getResponsibleColumn(),
      getCreatedAtColumn(),
      getLastUpdatedAtColumn(),
      ...getCustomFieldsColumns<IDefectDTO>(customFields),
    ],
    [],
  );

  return (
    <ReactstrapTable<IDefectDTO>
      columns={columns}
      columnsVisibleDefault={columnsVisibleDefault}
      localStorageStateKey={"DefectDTO"}
      {...props}
    />
  );
}
