import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import {
  AbsencePolicyEditDTO,
  AbsencePolicyEditOverwriteDTO,
  ChangeStatusDTO,
  ConnectApproversDTO,
  ConnectDepartmentPositionsDTO,
  ConnectTenantDTO,
  IAbsencePolicyCreateDTO,
  IAbsencePolicyDTO,
  ITenantAbsencePolicyDTO,
  ITenantMinDTO,
  PbdStatus,
} from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import GenericAlert from "../../../shared/components/alerts/genericAlert";
import DepartmentPositionsConnectedCard from "../../../shared/components/connectionElements/departmentPositions/departmentPositionsConnectedCard";
import ConnectTenantsModal from "../../../shared/components/connectionElements/tenants/connectTenantsModal";
import TenantsConnectedCard from "../../../shared/components/connectionElements/tenants/tenantsConnectedCard";
import { useToggle } from "../../../shared/hooks/useToggle";
import { absencePolicyColumn } from "../../../tenants/components/table/tenantAbsencePolicyTableCell";
import CreateFormAbsencePolicy from "./createFormAbsencePolicy";
import DetailsCardAbsencePolicy from "./detailsCardAbsencePolicy";
import OverwritePolicyPerTenantModal from "./overwritePolicyPerTenantModal";

interface IProps {
  itemToUpdate: IAbsencePolicyDTO;
  refreshParent: () => void;
}

function EditPageAbsencePolicyContent(props: IProps) {
  const { t } = useTranslation();
  const { itemToUpdate, refreshParent } = props;
  const { absencePoliciesApi } = useAPIs();
  const [editMode, toggleEditMode] = useToggle();
  const [overwriteModel, toggleOverwriteModal] = useToggle();
  const [approverModal, toggleApproverModal] = useToggle();
  const [selectedTenant, setSelectedTenant] = React.useState<ITenantAbsencePolicyDTO>();

  const { data: connectedTenants, mutate: mutateConnectedTenants } = useSWR(
    ["/api/absencePolicies", itemToUpdate.id, "connectedTenants"],
    () => absencePoliciesApi.getConnectedTenants(itemToUpdate.id),
  );

  async function handleDelete() {
    await wrapApiCallWithToast(() => absencePoliciesApi.delete(itemToUpdate.id));
    refreshParent();
  }

  async function handlePolicyStatus() {
    await wrapApiCallWithToast(() =>
      absencePoliciesApi.setStatus(
        itemToUpdate.id,
        new ChangeStatusDTO({
          status: itemToUpdate.status == PbdStatus.Active ? PbdStatus.Inactive : PbdStatus.Active,
        }),
      ),
    );
    refreshParent();
  }

  async function handleConnectionToApprovers(tenantIds: number[]) {
    const resp = await absencePoliciesApi.connectApprovers(
      itemToUpdate.id,
      new ConnectApproversDTO({ approverIds: tenantIds }),
    );
    refreshParent();
    return resp;
  }

  async function handleSetOrderForApprover(tenantId: number, value: number) {
    const resp = await absencePoliciesApi.setOrder(itemToUpdate.id, tenantId, value);
    refreshParent();
    return resp;
  }

  async function handleDisconnectApprover(tenantId: number) {
    const resp = await absencePoliciesApi.disconnectApprovers(itemToUpdate.id, tenantId);
    refreshParent();
    return resp;
  }

  async function handleConnectDepartmentPositions(departmentPositionIds: number[]) {
    await wrapApiCallWithToast(() =>
      absencePoliciesApi.connectDepartmentPositions(
        itemToUpdate.id,
        new ConnectDepartmentPositionsDTO({ departmentPositionIds }),
      ),
    );
  }

  function handleDisconnectDepartmentPosition(departmentPositionId: number) {
    return absencePoliciesApi.disconnectDepartmentPositions(itemToUpdate.id, departmentPositionId);
  }

  function handleRowEditClick(dto: ITenantMinDTO) {
    if (!connectedTenants) throw Error("Missing connected tenants");
    const tenantToEdit = connectedTenants.find((x) => x.id == dto.id);
    setSelectedTenant(tenantToEdit);
    toggleOverwriteModal();

    // if (action == "CustomizeApprovers") {
    //   triggerCustomizeApprovers(id);
    // } else if (action == "CustomizeConnectedData") {
    //   const tenantToEdit = connectedTenants.find((x) => x.id == id);
    //   setSelectedTenant(tenantToEdit);
    //   toggleOverwriteModal();
    // }
  }

  function triggerCustomizeApprovers(dto: ITenantAbsencePolicyDTO) {
    if (!connectedTenants) throw Error("Missing connected tenants");
    const tenantToEdit = connectedTenants.find((x) => x.id == dto.id);
    setSelectedTenant(tenantToEdit);
    toggleApproverModal();
    // alert(tenantToEdit.id);
  }

  async function resetPolicyForTenant(dto: ITenantAbsencePolicyDTO) {
    await absencePoliciesApi.overwritePolicyForTenant(
      itemToUpdate.id,
      dto.id,
      new AbsencePolicyEditOverwriteDTO({ id: itemToUpdate.id }),
    );
    toggleOverwriteModal();
    mutateConnectedTenants();
  }

  const handleOverwriteApprovers = async (ids?: number[]) => {
    if (!selectedTenant) throw Error("Missing policy");

    await absencePoliciesApi.overwritePolicyForTenant(
      itemToUpdate.id,
      selectedTenant.id,
      new AbsencePolicyEditOverwriteDTO({
        ...selectedTenant.absencePolicyOverwriteableParameters,
        absencePolicyApproverIds: ids,
        id: itemToUpdate.id,
      }),
    );
  };

  const handleSubmit = (baseId: number, dto: IAbsencePolicyCreateDTO) =>
    absencePoliciesApi.edit(baseId, new AbsencePolicyEditDTO({ ...dto, id: baseId }));

  const handleSuccess = () => {
    toggleEditMode();
    refreshParent();
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="qmBaseDarkToLightBlueGradient" />
        <Card.Body>
          {editMode && (
            <CreateFormAbsencePolicy
              itemToUpdate={itemToUpdate}
              onCancel={toggleEditMode}
              onSuccess={handleSuccess}
              onSubmit={(dto) => handleSubmit(itemToUpdate.id, dto)}
            />
          )}
          {!editMode && (
            <DetailsCardAbsencePolicy
              policy={itemToUpdate}
              handleDelete={handleDelete}
              handlePolicyStatus={handlePolicyStatus}
              toggleEditMode={toggleEditMode}
            />
          )}
        </Card.Body>
      </Card>
      <DepartmentPositionsConnectedCard
        baseDTO={itemToUpdate}
        data={itemToUpdate.departmentPositions}
        refreshParent={refreshParent}
        onConnectSubmit={handleConnectDepartmentPositions}
        onDisconnectSubmit={handleDisconnectDepartmentPosition}
        columnsVisibleDefault={["title", "department"]}
        columnsToExclude={[
          "isPrimary",
          "childDepartmentPositions",
          "parentDepartmentPositionCount",
          "requiredCompanyFunctionCount",
          "requiredCompanyFunctions",
        ]}
      />
      <TenantsConnectedCard
        baseDTO={itemToUpdate}
        data={itemToUpdate.absencePolicyApprovers}
        refreshParent={refreshParent}
        onConnectSubmit={handleConnectionToApprovers}
        onDisconnectSubmit={handleDisconnectApprover}
        cardTitle={t("Default approvers")}
      />
      <TenantsConnectedCard
        baseDTO={itemToUpdate}
        data={connectedTenants}
        refreshParent={mutateConnectedTenants}
        onConnectSubmit={(ids) =>
          absencePoliciesApi.connectTenants(itemToUpdate.id, new ConnectTenantDTO({ tenantIds: ids }))
        }
        onDisconnectSubmit={(id) => absencePoliciesApi.disconnectTenant(itemToUpdate.id, id)}
        cardTitle={t("Persons")}
        onRowEditClick={handleRowEditClick}
        additionalColumns={[absencePolicyColumn as ColumnDef<ITenantMinDTO>]}
        noDataPlaceholder={
          <GenericAlert
            type="info"
            heading={t(
              "This absence policy is available for everyone. Connect persons to make it only available for individuals.",
            )}
          />
        }
      />
      {selectedTenant && (
        <OverwritePolicyPerTenantModal
          absencePolicy={itemToUpdate}
          tenant={selectedTenant}
          modal={overwriteModel}
          toggle={toggleOverwriteModal}
          refreshParent={mutateConnectedTenants}
          onReset={resetPolicyForTenant}
          onCustomizeApprovers={triggerCustomizeApprovers}
        />
      )}

      <ConnectTenantsModal
        modal={approverModal}
        toggle={toggleApproverModal}
        connectedElements={[]}
        onSubmit={handleOverwriteApprovers}
        // Needs to be handled via logic
        canSubmit={true}
        refreshParent={mutateConnectedTenants}
      />
    </>
  );
}
export default EditPageAbsencePolicyContent;
