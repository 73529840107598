import { Result } from "@badrap/result";
import i18next from "i18next";
import _ from "lodash";

import { ApiException } from "@generatedCode/pbd-core/pbd-core-api";

import { toastError } from "../../../ClientApp/shared/components/toasts/toastError";
import { toastSuccess } from "../../../ClientApp/shared/components/toasts/toastSuccess";
import { ApiError, ApiUnknownError, apiExceptionToApiError } from "./models/api-error";
import { ToastOptionsPbd } from "./models/api-wrapper-options";

export type ApiResult<T> = Result<T, ApiError>;

export type ApiFn<T> = () => Promise<T>;
export type ApiFn1<T, U> = (v1: U) => Promise<T>;
export type ApiFn2<T, U1, U2> = (v1: U1, v2: U2) => Promise<T>;
export type ApiFn3<T, U1, U2, U3> = (v1: U1, v2: U2, v3: U3) => Promise<T>;

export type ApiCall<T> = () => Promise<ApiResult<T>>;
export type ApiCall1<T, U> = (v1: U) => Promise<ApiResult<T>>;
export type ApiCall2<T, U1, U2> = (v1: U1, v2: U2) => Promise<ApiResult<T>>;
export type ApiCall3<T, U1, U2, U3> = (v1: U1, v2: U2, v3: U3) => Promise<ApiResult<T>>;

export function handleErr<T>(err: unknown): ApiResult<T> {
  if (ApiException.isApiException(err)) {
    return Result.err(apiExceptionToApiError(err));
  }

  if (_.isError(err)) return Result.err(new ApiUnknownError(err));

  //TODO: evalualate what this means
  throw err;
}

export async function wrapApiCall<T>(apiFn: ApiFn<T>): Promise<ApiResult<T>> {
  try {
    return Result.ok(await apiFn());
  } catch (err) {
    return handleErr(err);
  }
}

/**This creates a toast on error
 * TODO: Create wrapper for long running api calls with promises
 */
export async function wrapApiCallWithToast<T>(apiFn: ApiFn<T>, opts?: ToastOptionsPbd): Promise<ApiResult<T>> {
  const resp = await wrapApiCall(apiFn);
  if (resp.isErr && !opts?.hideOnFailure) {
    // TODO: Dependencies not good?
    if (opts?.handleApiError) {
      opts.handleApiError(resp.error);
    } else {
      toastError(resp.error.message, resp.error);
    }
  } else if (resp.isOk) {
    opts?.onSuccess?.();
    if (!opts?.hideOnSuccess) {
      toastSuccess(i18next.t("Saved"));
    }
  }
  return resp;
}

export async function wrapApiCall1<T, U>(apiFn: ApiFn1<T, U>, v1: U): Promise<ApiResult<T>> {
  try {
    return Result.ok(await apiFn(v1));
  } catch (err) {
    return handleErr(err);
  }
}

export async function wrapApiCall2<T, U1, U2>(apiFn: ApiFn2<T, U1, U2>, v1: U1, v2: U2): Promise<ApiResult<T>> {
  try {
    return Result.ok(await apiFn(v1, v2));
  } catch (err) {
    return handleErr(err);
  }
}

export async function wrapApiCall3<T, U1, U2, U3>(
  apiFn: ApiFn3<T, U1, U2, U3>,
  v1: U1,
  v2: U2,
  v3: U3,
): Promise<ApiResult<T>> {
  try {
    return Result.ok(await apiFn(v1, v2, v3));
  } catch (err) {
    return handleErr(err);
  }
}
