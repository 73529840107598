import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { FileCategoryDTO } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import { ErrorMessage } from "../../../Models/Errors/ErrorMessage";
import { SettingsIndexDTO } from "../../../Models/Settings/SettingsIndexDTO";
import { wrapApiCallWithToast } from "../../../pbdServices/services/Api/api-wrapper";
import SwrApiHelpers from "../../../pbdServices/services/Api/swr-api-helpers";
import { PbdRoles } from "../../../services/Authz/PbdRoles";
import { hasRole } from "../../../services/Authz/authService";
import { mapFileCategoryDTOToSettingsDTO } from "../../categories/mapFileCategoryDTOToSettingsDTO";
import { TableClickCommand } from "../../shared/components/tables/TableClickCommand";
import { useAppContext } from "../../shared/contexts/appContext";
import { useConfirmation } from "../../shared/contexts/modalConfirmationContext";
import BaseSettingsIndexCard from "../components/baseSettingsIndexCard";
import { BaseSettingsTable } from "../components/baseSettingsTable";
import { SettingsRoutePaths } from "../settingsRoutePaths";

function IndexPageFileCategories() {
  const navigate = useNavigate();
  const { meAsUser, setErrorMessage, handleApiError } = useAppContext();
  const { fileCategoriesApi } = useAPIs();
  const [selected, setSelected] = React.useState<SettingsIndexDTO[]>([]);
  const confirm = useConfirmation();
  const { t } = useTranslation();
  const { data, isValidating, mutate } = useSWR<FileCategoryDTO[]>(
    [`/api/fileCategories`, "includePredefinedCategories=false"],
    () => fileCategoriesApi.getAllQuery({ includePredefinedCategories: false }),
    SwrApiHelpers.defaultIndexPageOptions(),
  );

  const tableRows = React.useMemo(
    () => mapFileCategoryDTOToSettingsDTO(SettingsRoutePaths.EditPageFileCategories, data),
    [data],
  );

  const handleClick = (command: TableClickCommand) => {
    if (command.action === "Delete") {
      if (selected.length > 0) {
        void confirm({ itemsToDelete: selected }).then(() => submitDeleteRequest(selected.map((x) => Number(x.id))));
      }
    }
  };

  const submitDeleteRequest = async (items: number[]) => {
    if (!hasRole(meAsUser, [PbdRoles.Settings_Standard])) {
      setErrorMessage(new ErrorMessage(403));
    } else {
      for (const s of items) {
        await wrapApiCallWithToast(() => fileCategoriesApi.delete(Number(s)), { handleApiError });
      }
      void mutate();
    }
  };

  return (
    <BaseSettingsIndexCard
      data={data}
      cardTitle={t("File categories")}
      pathToCreate={SettingsRoutePaths.CreatePageFileCategories}
      loading={isValidating}
    >
      <BaseSettingsTable
        tableRows={tableRows}
        onCreateClick={() => navigate(SettingsRoutePaths.CreatePageFileCategories)}
        setSelected={(ids) => setSelected(ids.map((x) => x))}
        availableTableActions={["Delete"]}
        onClick={handleClick}
      />
    </BaseSettingsIndexCard>
  );
}

export default IndexPageFileCategories;
