import i18next from "i18next";
import { sortBy } from "lodash";
import { DateTime } from "luxon";

import {
  ArticleRevisionQueryField,
  ArticleRevisionType,
  IArticleDTO,
  IArticleDetailsDTO,
  IArticleRevisionApprovalsControllerClient,
  IArticleRevisionDTO,
  IArticleRevisionReviewDTO,
  IArticleRevisionReviewsControllerClient,
  IArticleRevisionsControllerClient,
  IArticleViewDTO,
  IArticlesControllerClient,
  IFileDTO,
  ITagDTO,
  PbdModule,
  RevisionIncrementType,
} from "@generatedCode/pbd-core/pbd-core-api";

import { ArticleRoutePaths } from "../../../ClientApp/articles/articleRoutePaths";
import { PrintRoutePaths } from "../../../ClientApp/prints/printRoutePaths";
import { IFlow } from "../../../ClientApp/shared/components/FlowEditor/models/pbd-flow-dto";
import { SearchFilterTypes } from "../../../ClientApp/shared/components/genericSearchFilter/availableSearchFilters";
import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import StringHelpers from "../../../Helpers/StringHelpers";
import { PbdRoles } from "../../../services/Authz/PbdRoles";
import { hasRole } from "../../../services/Authz/authService";
import RestUtilities from "../../../services/restClients/restUtilities";
import { AppNotifications } from "../../Models/Notifications/AppNotifications";
import { ModuleNotification, NotificationItem } from "../../Models/Notifications/ModuleNotification";
import { ValidationResultDescriber } from "../../Models/Shared/validation-result-describer";
import { WithViews } from "../../Models/Shared/with-views";
import { WithWarnings } from "../../Models/Shared/with-warnings";
import { AuthService } from "../Authz/authService";
import { BaseExportService } from "../Base/BaseExportService";
import { EntityPermissionService } from "../EntityPermission/entityPermissionService";
import { ExportType } from "../Export/exportService";
import JsonHelpers from "../Json/jsonHelpers";
import { MeAsUser } from "../UserSettings/models/me-as-user";

import { ArticleContentCardVm } from "./models/article-content-card-vm";
import { BpmnEditorContent, EditorContent } from "./models/editor-content";
import { ArticlesQueryParameters } from "./models/query-parameters";

export type ValidArticleApps = PbdModule.Blog | PbdModule.DocumentManagement | PbdModule.KnowledgeBase;

export const ArticleExportName = "Articles";

export default class ArticleService extends BaseExportService<IArticleDTO> {
  articlesApi: IArticlesControllerClient;
  articleRevisionsApi: IArticleRevisionsControllerClient;
  articleRevisionReviewsApi: IArticleRevisionReviewsControllerClient;
  articleRevisionApprovalsApi: IArticleRevisionApprovalsControllerClient;
  entityPermissionService: EntityPermissionService;
  constructor(
    articlesApi: IArticlesControllerClient,
    articleRevisionsApi: IArticleRevisionsControllerClient,
    articleRevisionApprovalsApi: IArticleRevisionApprovalsControllerClient,
    articleRevisionReviewsApi: IArticleRevisionReviewsControllerClient,
    entityPermissionService: EntityPermissionService,
  ) {
    super(ArticleExportName);
    this.articlesApi = articlesApi;
    this.articleRevisionsApi = articleRevisionsApi;
    this.articleRevisionApprovalsApi = articleRevisionApprovalsApi;
    this.articleRevisionReviewsApi = articleRevisionReviewsApi;
    this.entityPermissionService = entityPermissionService;
  }

  async getByIdAsVm(id: string, articleRevisionId?: number) {
    const article = await this.articlesApi.getById(id);
    const articleRevision = articleRevisionId ? await this.articleRevisionsApi.getById(articleRevisionId) : undefined;
    return { article, articleRevision };
  }

  getAllReviews(module: PbdModule, isReviewed = false) {
    return this.articleRevisionReviewsApi.getAllQuery({
      module: module,
      //TODO not in API showAllApprovals: showAllApprovals,
      isReviewed: isReviewed,
    });
  }

  async getAllNotifications(module: PbdModule, tenantId: number) {
    const unpublishedRevisions = await this.articleRevisionsApi.getAllQuery({
      module,
      isPublished: false,
      publishProcessStopped: false,
      fields: [ArticleRevisionQueryField.Approvers],
    });

    const documentsNeedReviewFromApi = await this.articleRevisionReviewsApi.getAllQuery({
      module,
      reviewerId: [tenantId],
      isReviewed: false,
      onlyLatestRevision: true,
      pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
    });
    const articleForSurveillance = await this.articlesApi.getAllQuery({
      module,
      responsibleId: [tenantId],
      nextInspectionTo: DateTime.now().endOf("day"),
    });

    return {
      unpublished: unpublishedRevisions.filter((x) => x.responsible?.id == tenantId),
      pendingApproval: unpublishedRevisions.filter(
        (x) =>
          x.isPublishProcessStarted &&
          x.approvals
            ?.filter((x) => !x.approvedAt)
            .map((x) => x.approverId)
            .includes(tenantId),
      ),
      pendingReview: documentsNeedReviewFromApi.data,
      surveillanceRequired: articleForSurveillance,
    };
  }

  async getAllNotificationsAsAppNotification(app: PbdModule, tenantId: number) {
    const { pendingApproval, pendingReview, unpublished, surveillanceRequired } = await this.getAllNotifications(
      app,
      tenantId,
    );

    const notificationList: NotificationItem[] = [
      new NotificationItem<IArticleRevisionDTO>(
        "My responsibility",
        ArticleRoutePaths.UnpublishedPage(app),
        unpublished,
      ),
      new NotificationItem<IArticleRevisionDTO>(
        "To approve by me",
        ArticleRoutePaths.UnpublishedPage(app),
        pendingApproval,
      ),
      new NotificationItem<IArticleRevisionReviewDTO>(
        "To be noted",
        `${ArticleRoutePaths.ReviewsPage(app)}/?groupBy=Tenants&tenantId=${tenantId}`,
        pendingReview,
      ),
      new NotificationItem<IArticleDTO>(
        "Surveillance required",
        `${ArticleRoutePaths.IndexPage(app)}/?nextInspectionTo=${DateTimeLuxonHelpers.getISODateForQuery(
          DateTime.now().plus({ day: 1 }),
        )}&responsibleId=${tenantId}`,
        surveillanceRequired,
      ),
    ];
    return new AppNotifications(app, notificationList);
  }

  async getAllNotificationsAsModuleNotification(module: PbdModule, tenantId: number): Promise<ModuleNotification> {
    const { unpublished, pendingApproval, pendingReview, surveillanceRequired } = await this.getAllNotifications(
      module,
      tenantId,
    );

    const notifications = new ModuleNotification(module, [
      {
        title: i18next.t("Unpublished"),
        count: unpublished.length,
        href: "tbd",
      },
      {
        title: i18next.t("Pending approval"),
        count: pendingApproval.length,
        href: "tbd",
      },
      {
        title: i18next.t("To be noted"),
        count: pendingReview.length,
        href: "tbd",
      },
      {
        title: i18next.t("Surveillance required"),
        count: surveillanceRequired.length,
        href: "tbd",
      },
    ]);
    return notifications;
  }

  mapToExport(x: IArticleDTO): ExportType {
    return {
      id: x.id,
      title: x.title,
      responsible: x.responsible?.fullName,
      revision: `'${x.version}'`,
      createdAt: x.createdAt,
      lastUpdatedAt: x.lastUpdatedAt,
      nextInspection: x.nextInspection,
      views: x.viewCount,
      likes: x.likeCount,
      dislikes: x.dislikeCount,
      updated: x.lastUpdatedAt,
    };
  }

  getAllApprovalsByTenant(module: PbdModule) {
    return this.articleRevisionApprovalsApi.getAllQuery({
      module,
    });
  }

  //TODO2: Move getQueryString somewhere else
  getArticlesPrintUrl(module: PbdModule, articles: Pick<IArticleDTO, "id">[]) {
    const url = PrintRoutePaths.ArticlesBulk.replace(":module", module.toString());
    const query: ArticlesQueryParameters = {
      module,
      id: articles.map((r) => r.id),
    };

    return url + RestUtilities.getQueryString(query);
  }

  static canAccessEditOverview(authService: AuthService, article: IArticleDTO, revisions: IArticleRevisionDTO[]) {
    if (authService.hasRole([PbdRoles.Admin])) {
      return true;
    }

    const tenantId = authService.tenantId;

    if (article.responsible?.id === tenantId) {
      return true;
    }
    if (authService.hasRole([`${article.module}_ModuleAdmin`])) {
      return true;
    }

    if (revisions.some((x) => x.approvals?.some((app) => app.approverId == tenantId))) return true;

    return false;
  }

  canAccessEditOverviewLegacy(user: MeAsUser, article: IArticleDTO, revisions: IArticleRevisionDTO[]) {
    if (hasRole(user, [PbdRoles.Admin])) {
      return true;
    }
    if (article.responsible?.id == user.tenant.id) {
      return true;
    }
    if (hasRole(user, [this.getModuleAdminRole(article.module)])) {
      return true;
    }
    if (revisions.find((x) => x.approvals?.map((y) => y.approverId).includes(user.tenant.id))) {
      return true;
    }
    if (
      article.entityPermission &&
      this.entityPermissionService.canAccess2(user, article.entityPermission, this.getModuleAdminRole(article.module))
    ) {
      return true;
    }
    return false;
  }

  getModuleAdminRole(module: PbdModule) {
    return `${module}_ModuleAdmin`;
  }

  static getRevisionOptions() {
    return Object.values(RevisionIncrementType).filter((x) => x != RevisionIncrementType.None);
  }

  static getNextRevision(dto: { version?: string }, revisionIncrementType: RevisionIncrementType) {
    if (dto.version == "unknown") return revisionIncrementType.toString();
    const maxVersion = dto.version ?? "0.0.0";
    const parts = maxVersion.split(".");
    //TODO2 parse version
    switch (revisionIncrementType) {
      case RevisionIncrementType.MAJOR:
        return `${Number(parts[0]) + 1}.0.0`;
      case RevisionIncrementType.MINOR:
        return `${parts[0]}.${Number(parts[1]) + 1}.0`;
      case RevisionIncrementType.PATCH:
        return `${parts[0]}.${parts[1]}.${Number(parts[2]) + 1}`;
      default:
        throw new Error("Not implemented");
    }
  }

  static isPublishClickPossible(item: IArticleRevisionDTO, meAsUser: MeAsUser) {
    const canPublish = item.capabilities?.canPublish ?? false;
    const canApprove = item.capabilities?.canApprove ?? false;

    const otherUsersThatHaveToApprove = item.approvals?.filter((x) => x.approverId != meAsUser.tenant.id) ?? [];
    if (canPublish && otherUsersThatHaveToApprove.length == 0 && canApprove) return true;

    return canPublish && otherUsersThatHaveToApprove.filter((x) => !x.isApproved).length == 0;
  }

  static isDynamicTemplate(article: { tags?: ITagDTO[] }): boolean {
    if (!article.tags) return false;
    return article.tags.map((x) => x.title).includes(GlobalQmBaseConstants.Articles.DynamicContentTag);
  }

  static getWarning(resp: WithWarnings<IArticleDetailsDTO>) {
    resp.warnings = [];
    if (resp.nextInspection && resp.nextInspection < DateTime.now()) {
      resp.warnings.push(ValidationResultDescriber.monitoringIntervalExpired());
    }
    return resp;
  }

  static getArticleContentCardData(
    article: IArticleDetailsDTO,
    revision?: IArticleRevisionDTO,
    contentModified?: string,
  ): ArticleContentCardVm {
    if (revision) {
      return { ...article, ...revision, content: contentModified ?? revision.content };
    }
    return { ...article, content: contentModified ?? article.content };
  }

  static getArticleContentAsBootstrap(input: string): string {
    input = input.replace(/<table/g, '<div class="table-responsive"><table').replace(/<\/table>/g, "</table></div>");

    return input;
  }

  static mapViewsToArticle(articles: IArticleDTO[], views?: IArticleViewDTO[]): WithViews<IArticleDTO>[] {
    if (!views) {
      return articles;
    }
    return articles.map((x) => {
      const viewsForArticle = views.filter((v) => v.article?.id == x.id);
      const sorted = sortBy(viewsForArticle, (d) => d.dateOfView).reverse();
      return { ...x, views: sorted, lastViewed: sorted.length > 0 ? sorted[0] : undefined };
    });
  }

  static sanitizeArticleTitle(articleRevision: {
    content?: string;
    title: string;
    file?: IFileDTO;
    type: ArticleRevisionType;
  }) {
    if (articleRevision.type == ArticleRevisionType.Bpmn && !articleRevision.title.endsWith(".bpmn")) {
      return `${articleRevision.title}.bpmn`;
    } else if (
      this.getArticleType(articleRevision) == ArticleRevisionType.Flow &&
      !articleRevision.title.endsWith(".flow")
    ) {
      return `${articleRevision.title}.flow`;
    } else if (
      this.getArticleType(articleRevision) == ArticleRevisionType.Markdown &&
      !articleRevision.title.endsWith(".md")
    ) {
      return `${articleRevision.title}.md`;
    }
    return articleRevision.title;
  }

  static isFlowChart(articleContent: string) {
    return JsonHelpers.isJson(articleContent) && this.deserializeToEditor(articleContent).editorId == "Flow";
  }

  static isMarkdown(articleContent: string) {
    return JsonHelpers.isJson(articleContent) && this.deserializeToEditor(articleContent).editorId == "Markdown";
  }

  static isBpmn(articleContent: string) {
    return JsonHelpers.isJson(articleContent) && this.deserializeToEditor(articleContent).editorId == "Bpmn";
  }

  static getArticleType(item: { content?: string; title: string; file?: IFileDTO }): ArticleRevisionType {
    const test = this.getArticleTypeWithContent(item);
    return test.kind;
  }

  static serializeFlowChartContent(x: IFlow): string {
    delete x.viewport;
    const content = new EditorContent("Flow", x);
    return JSON.stringify(content);
  }

  static deserializeToFlow(content: string): IFlow {
    const dest = this.deserializeToEditor(content);
    return JsonHelpers.parse<IFlow>(dest.content);
  }

  static deserializeToEditor<T extends EditorContent>(content: string) {
    return JsonHelpers.parse<T>(content);
  }

  static serializeMarkdownContent(x: string | undefined): string {
    const content = new EditorContent("Markdown", x, "1-alpha");
    return JSON.stringify(content);
  }

  static deserializeToMarkdown(content: string | undefined): string {
    if (!content) return "";
    const dest = this.deserializeToEditor(content);
    return dest.content;
  }

  static serializeBpmnContent(x: string | undefined, legends?: Record<string, string>): string {
    const content = new BpmnEditorContent(x, legends, "1-beta");
    return JSON.stringify(content);
  }

  static deserializeBpmn(content: string | undefined): BpmnEditorContent {
    if (!content) return new BpmnEditorContent();
    const dest = this.deserializeToEditor<BpmnEditorContent>(content);
    return dest;
  }

  /**
   * This functions identifies the article type it will fallback to text if nothing else matches.
   */
  static getArticleTypeWithContent(item: { content?: string; title: string; file?: IFileDTO }): ArticleTypeWithContent {
    // console.log(this.isFlowChart(item.content!));
    if (item.file) {
      return { kind: ArticleRevisionType.File, file: item.file };
    } else if (item.content && this.isFlowChart(item.content)) {
      return { kind: ArticleRevisionType.Flow, flow: this.deserializeToFlow(item.content) };
    } else if (item.content && this.isMarkdown(item.content)) {
      return { kind: ArticleRevisionType.Markdown, content: this.deserializeToMarkdown(item.content) };
    } else if (item.content && this.isBpmn(item.content)) {
      return {
        kind: ArticleRevisionType.Bpmn,
        content: this.deserializeBpmn(item.content).content,
        legends: this.deserializeBpmn(item.content).legends,
      };
    }
    return { kind: ArticleRevisionType.Html, content: item.content };
  }

  static getPreviewText(item: IArticleDetailsDTO) {
    return item.abstract
      ? StringHelpers.truncate(item.abstract, 300)
      : StringHelpers.truncate(StringHelpers.stripHtmlFromString(item.content ?? ""), 300);
  }

  static get validApps() {
    return [PbdModule.Blog, PbdModule.DocumentManagement, PbdModule.KnowledgeBase];
  }

  static get availableFilter() {
    return [
      SearchFilterTypes.Responsible,
      SearchFilterTypes.CreatedAt,
      SearchFilterTypes.Tags,
      SearchFilterTypes.IsDeleted,
    ];
  }
}

type ArticleTypeWithContent =
  | { kind: ArticleRevisionType.Html; content?: string }
  | { kind: ArticleRevisionType.File; file: IFileDTO }
  | { kind: ArticleRevisionType.Flow; flow: IFlow }
  | { kind: ArticleRevisionType.Markdown; content: string }
  | { kind: ArticleRevisionType.Bpmn; content: string; legends?: Record<string, string> };
