import i18next from "i18next";
import React from "react";
import { Badge } from "react-bootstrap";
import { CellProps, Column } from "react-table";

import { IQualificationDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { AppUiData } from "../../../Constants/app-ui-data";
import { QualificationMatrixRoutePaths } from "../../qualificationMatrix/qualificationMatrixRoutePaths";
import BaseTableProps from "../../shared/components/tables/BaseTableProps";
import { getCreatedAtColumn } from "../../shared/components/tables/columns/createdAtColumn";
import { getDescriptionColumn } from "../../shared/components/tables/columns/descriptionColumn";
import { getIdColumn } from "../../shared/components/tables/columns/idColumn";
import { getResponsibleColumn } from "../../shared/components/tables/columns/responsibleColumn";
import { getTagColumn } from "../../shared/components/tables/columns/tagsColumn";
import { getTitleColumn } from "../../shared/components/tables/columns/titleColumn";
import { ReactstrapTable } from "../../shared/components/tables/reactstrapTable";
import { useFilteredColumnsMemo } from "../../shared/components/tables/tableHelperFunctions";

type IProps = BaseTableProps<IQualificationDTO>;

export function QualificationTable(props: IProps) {
  const {
    columnsVisibleDefault = ["title"],
    titleColumnBaseLink = QualificationMatrixRoutePaths.DetailsPageQualification,
    includeTitleLink = true,
    columnsToExclude,
  } = props;

  const columns = React.useMemo<Column<IQualificationDTO>[]>(
    () => [
      getIdColumn(includeTitleLink, titleColumnBaseLink),
      getTitleColumn(includeTitleLink, titleColumnBaseLink),
      {
        id: "requirements",
        Header: <>{i18next.t("Assignment configuration")}</>,
        accessor: (x) => x.id,
        disableSortBy: true,
        Cell: ({ row }: CellProps<IQualificationDTO>) => (
          <>
            {row.original.connectionCondition?.tenantQualificationConnection?.isSelfAssignmentEnabled && (
              <Badge className="me-1">{i18next.t("Self assignment")}</Badge>
            )}
            {row.original.connectionCondition?.tenantQualificationConnection?.customForms &&
              row.original.connectionCondition.tenantQualificationConnection.customForms.length > 0 && (
                <Badge className="me-1" title={i18next.t("Forms")}>
                  <i className={AppUiData.CustomForms.iconClassName} />
                </Badge>
              )}
          </>
        ),
      },
      getTagColumn(),
      getResponsibleColumn(),
      getCreatedAtColumn(),
      getDescriptionColumn(),
      {
        id: "companyFunctionsCount",
        Header: <>{i18next.t("Count of connected company functions")}</>,
        accessor: (x) => x.companyFunctions?.length,
        Cell: ({ row }: CellProps<IQualificationDTO>) => <>{row.original.companyFunctions?.length}</>,
      },
      {
        id: "companyFunctions",
        Header: <>{i18next.t("Connected company functions")}</>,
        disableSortBy: true,
        Cell: ({ row }: CellProps<IQualificationDTO>) => {
          return <>{row.original.companyFunctions?.map((x) => x.name).join(", ")}</>;
        },
      },
    ],
    [],
  );

  const filteredColumns = useFilteredColumnsMemo(columns, columnsToExclude);
  return (
    <ReactstrapTable<IQualificationDTO>
      columns={filteredColumns}
      columnsVisibleDefault={columnsVisibleDefault}
      localStorageStateKey={"QualificationDTO"}
      {...props}
    />
  );
}
