import { Dictionary, groupBy, uniq } from "lodash";
import { Duration } from "luxon";

import { ICostDTO, ICostsControllerClient, PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { AdminRoutePaths } from "../../../ClientApp/admin/adminRoutePaths";
import { ClaimRoutePaths } from "../../../ClientApp/claims/claimRoutePaths";
import { CostRoutePaths } from "../../../ClientApp/costs/costRoutePaths";
import { GoalRoutePaths } from "../../../ClientApp/goals/goalRoutePaths";
import { IdeaManagementRoutePaths } from "../../../ClientApp/ideaManagement/ideaManagementRoutePaths";
import { TaskManagementRoutePaths } from "../../../ClientApp/taskManagement/taskManagementRoutePaths";
import { TrainingRoutePaths } from "../../../ClientApp/trainings/trainingRoutePaths";
import { NumberHelpers } from "../../../Helpers/NumberHelpers";
import { BaseExportService } from "../Base/BaseExportService";
import { ExportType } from "../Export/exportService";
import { ICostSum } from "./models/ICostSum";
import { ICostVM } from "./models/cost-vm";
import { CostQueryParameters } from "./models/query-parameters";

export const costKeyNamesArray = [
  "Training",
  "Claim",
  "Defect",
  "ToDo",
  "InventoryItem",
  "Goal",
  "EmployeeIdea",
  "",
] as const;
export type CostKeyNames = (typeof costKeyNamesArray)[number];

function groupByKeyValue(items: ICostDTO[]) {
  return groupBy(items, (x) => x.keyValues);
}

function getGroupByValue(dict: Dictionary<ICostDTO[]>, keyValue: number): ICostDTO[] | undefined {
  const found = dict[keyValue];
  return found;
}

export interface IHasId {
  id: number;
}

export type WithCosts<T extends IHasId> = T & {
  costs: ICostDTO[];
  costSums: ICostSum[];
};

export type WithCostsOptional<T extends IHasId> = T & {
  costs?: ICostDTO[];
  costSums?: ICostSum[];
};

export default class CostService extends BaseExportService<ICostDTO> {
  costsApi: ICostsControllerClient;
  constructor(costsApi: ICostsControllerClient) {
    super("Costs");
    this.costsApi = costsApi;
  }

  static mapWithCosts<T extends IHasId>(items: T[], costs: ICostDTO[]): WithCosts<T>[] {
    const groupedNew = groupByKeyValue(costs);
    return items.map((x) => {
      const costsForItem = getGroupByValue(groupedNew, x.id);
      const costSums = CostService.getSumOfCosts(costsForItem ?? []);
      return {
        ...x,
        costs: costsForItem ?? [],
        costSums,
      };
    });
  }

  async getAllMapped(query: CostQueryParameters, module: PbdModule): Promise<ICostVM[]> {
    const costs = await this.costsApi.getAllQuery(query);
    return this.mapToVM(costs, module);
  }

  async getByIdAsVm(id: number, module: PbdModule): Promise<ICostVM> {
    const resp = await this.costsApi.getById(id);
    return this.mapToVM([resp], module)[0];
  }

  mapToVM(items: ICostDTO[], module: PbdModule): ICostVM[] {
    return items.map((cost) => ({
      detailsUrl: this.getDetailsUrl(cost.id, module),
      routeLink: this.generateRouteLinkByKeyName(cost),
      module: this.generateModuleByKeyName(cost.keyName),
      valueFormatted: `${cost.value} ${cost.currency}`,
      ...cost,
    }));
  }

  getDetailsUrl(id: number, module: PbdModule | string) {
    let moduleAsString = module.toString();
    if (module == PbdModule.ProjectAndTaskManagement) {
      moduleAsString = "TaskManagement";
    } else if (module == PbdModule.TrainingManagement) {
      moduleAsString = "Trainings";
    } else if (module == PbdModule.ClaimManagement) {
      moduleAsString = "ClaimManagement";
    } else if (module == PbdModule.GoalManagement) {
      moduleAsString = "Goals";
    }
    return CostRoutePaths.DetailsPageCostsByModule.replace(":pbdModule", moduleAsString).replace(":id", id.toString());
  }

  getIndexUrl(module: PbdModule | string) {
    let moduleAsString = module.toString();
    if (module == PbdModule.ProjectAndTaskManagement) {
      moduleAsString = "TaskManagement";
    } else if (module == PbdModule.TrainingManagement) {
      moduleAsString = "Trainings";
    }
    return CostRoutePaths.IndexPageCostsByModule.replace(":pbdModule", moduleAsString);
  }

  generateModuleByKeyName(keyName?: string) {
    let moduleName = PbdModule.None;
    const value = keyName as CostKeyNames;

    if (value == "Training") {
      moduleName = PbdModule.TrainingManagement;
    } else if (value == "ToDo") {
      moduleName = PbdModule.ProjectAndTaskManagement;
    } else if (value == "Claim") {
      moduleName = PbdModule.ClaimManagement;
    } else if (value == "InventoryItem") {
      moduleName = PbdModule.MaintenanceManagement;
    } else if (value == "Defect") {
      moduleName = PbdModule.DefectManagement;
    }
    // for the other modules connected to the generic costs like claims and defects.
    // the code here will be implemented when needed.
    return moduleName;
  }

  generateRouteLinkByKeyName(dto: ICostDTO) {
    const { keyName, keyValues } = dto;

    if (keyValues === undefined || keyName === undefined) throw new Error("KeyValue undefined");
    const value = keyName as CostKeyNames;

    switch (value) {
      case "Training":
        return TrainingRoutePaths.EditPage.replace(":id", keyValues);
      case "ToDo":
        return TaskManagementRoutePaths.EditTodoPage.replace(":id", keyValues);
      case "Claim":
        return ClaimRoutePaths.EditPage.replace(":id", keyValues);
      case "Goal":
        return GoalRoutePaths.EditPage.replace(":id", keyValues);
      case "EmployeeIdea":
        return IdeaManagementRoutePaths.DetailsPage.replace(":id", keyValues);
      default:
        return AdminRoutePaths.DetailsPageCosts.replace(":id", dto.id.toString());
    }
  }

  getKeyNamesByApp(app: PbdModule): CostKeyNames[] | undefined {
    switch (app) {
      case PbdModule.Admin:
        return undefined;
      case PbdModule.ProjectAndTaskManagement:
        return ["ToDo"];
      case PbdModule.TrainingManagement:
        return ["Training"];
      case PbdModule.DefectManagement:
        return ["Defect"];
      case PbdModule.ClaimManagement:
        return ["Claim"];
      case PbdModule.MaintenanceManagement:
        return ["InventoryItem"];
      case PbdModule.GoalManagement:
        return ["Goal"];
      default:
        throw new Error("Missing app " + app);
    }
  }

  mapToExport(x: ICostVM): ExportType {
    return {
      id: x.id,
      title: x.title,
      description: x.description,
      value: x.value,
      valueFormatted: NumberHelpers.convertToMoney(x.value, x.currency),
      duration: x.duration,
      unit: x.costUnit,
      currency: x.currency,
      categoryId: x.category?.id,
      category: x.category?.title,
      costCenterId: x.costCenter?.id,
      costCenter: x.costCenter?.title,
      createdAt: x.createdAt,
      baseEntity: x.keyName,
      baseId: x.keyValues,
    };
  }

  static getSumOfCosts(items: ICostDTO[]): ICostSum[] {
    // TODO2: Group by currencies
    const currencies = this.getDistinctCurrencies(items);

    return currencies.map((element) => {
      const currencyItems = items.filter((x) => x.currency === element);
      const duration = this.getSumOfDurations(currencyItems);
      return {
        currency: element,
        total: currencyItems.reduce((pv, cv) => cv.value + pv, 0),
        duration,
        items: currencyItems,
        costUnit: currencyItems[0].costUnit,
      };
    });
  }

  static getDistinctCurrencies(items: ICostDTO[]) {
    return uniq(items.map((x) => x.currency));
  }

  // TODO: duration is in the format from asp.net d.HH:MM:SS how can this be converted to luxon duration?
  static getSumOfDurations(items: ICostDTO[]) {
    // TODO2: Group by currencies, filter by time maybe
    return items.reduce((acc, cost) => {
      if (cost.duration) {
        if (this.isASPnetDuration(cost.duration)) {
          return acc.plus(Duration.fromISO(this.aspDurationToLuxon(cost.duration)));
        } else {
          return acc.plus(Duration.fromISO(cost.duration));
        }
      }
      return acc;
    }, Duration.fromMillis(0));
  }

  static isASPnetDuration(item: string) {
    const regex = new RegExp(":", "g");
    if ((item.match(regex) || []).length >= 2) {
      return true;
    } else {
      return false;
    }
  }

  static aspDurationToLuxon(item: string) {
    const dot = item.indexOf(".");
    const days = parseInt(item.split(".")[0]);
    let duration: Duration;
    if (dot > 0) {
      const durationStringWithoutDays = item.split(".")[1];
      duration = Duration.fromISOTime(durationStringWithoutDays);
      duration = duration.plus({ days: days });
    } else {
      duration = Duration.fromISOTime(item);
    }
    return duration.toString();
  }

  static getSupportedCurrencies(items?: string[]) {
    if (items && items.length > 0) return items;

    return ["EUR"];
  }

  static convertToString(items: ICostDTO[]) {
    return items.map((x) => `${x.currency} ${x.value}`).join(";");
  }

  static convertCostSumToString(items: ICostSum[], includeSpacing = false) {
    return items.map((x) => `${x.currency} ${x.total}`).join(includeSpacing ? "; " : ";");
  }

  static convertCostDetailsToString(items: ICostDTO[], includeSpacing = false) {
    return items
      .map((x) => `${x.currency} ${x.value}  Category:${x.category?.title} CostCenter:${x.costCenter?.title}`)
      .join(includeSpacing ? "; " : ";");
  }
}
