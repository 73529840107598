import useSWR from "swr";

import { PbdStatus } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../../pbdServices/services/service-context";

import CustomSelect from "./customSelect";
import { ICustomSelectProps } from "./models/customSelectProps";

interface IProps extends ICustomSelectProps {
  excludeId?: number;
  activityStatus: PbdStatus;
  departmentPositionId?: number;
}

function AbsencePolicySelect(props: IProps) {
  const { selectedIds, excludeId, activityStatus, departmentPositionId } = props;
  const { absencePoliciesApi } = useAPIs();
  const { data } = useSWR(["api/absencePolicies", activityStatus, departmentPositionId, excludeId], () =>
    absencePoliciesApi.getAllQuery({
      status: [activityStatus],
      departmentPositionId: departmentPositionId ? [departmentPositionId] : undefined,
      idToExclude: excludeId ? [excludeId] : undefined,
    }),
  );

  if (!data) return null;

  return <CustomSelect data={data} name="absencePolicyId" selectedIds={selectedIds} {...props} />;
}

export default AbsencePolicySelect;
