import {
  ICompanyFunctionDTO,
  IDepartmentPositionDTO,
  IDepartmentPositionWithChildrenDTO,
} from "@generatedCode/pbd-core/pbd-core-api";

import { CompanyFunctionFitnessService } from "../CompanyFunctions/companyFunctionFitnessService";
import { AvailableQualificationDTO } from "../QualificationMatrix/models/AvailableQualificationDTO";

export class DepartmentPositionFitnessService {
  /**return 0-1 for the fitness of the departmentPosition */
  // static getFitnessForDepartmentPosition(
  //   departmentPosition: IDepartmentPositionDTO,
  //   companyFunctions: ICompanyFunctionDTO[],
  //   availableQualifications: AvailableQualificationDTO[],
  // ) {
  //   const requiredCfs = departmentPosition.requiredCompanyFunctions ?? [];
  //   if (requiredCfs.length == 0) return 1;
  //   // console.log(departmentPosition);

  //   return _.meanBy(requiredCfs, (cf) => {
  //     const requiredCompanyFunction = companyFunctions.find((x) => x.id == cf.id);
  //     if (!requiredCompanyFunction) throw Error("Missing company function");
  //     const fitnessForCompanyFunction = CompanyFunctionFitnessService.getFitnessForCompanyFunction(
  //       requiredCompanyFunction,
  //       availableQualifications,
  //     );
  //     return fitnessForCompanyFunction;
  //   });
  // }

  /**return 0-1 for the fitness of the departmentPosition */
  static getFitnessForDepartmentPosition(
    departmentPosition: IDepartmentPositionWithChildrenDTO,
    companyFunctions: ICompanyFunctionDTO[],
    availableQualifications: AvailableQualificationDTO[],
  ): number {
    if (!departmentPosition.requiredCompanyFunctions) throw Error("Missing required company function data");
    if (departmentPosition.requiredCompanyFunctions.length == 0) return 1;
    // console.log(departmentPosition);
    let fitness = 0;
    for (let index = 0; index < departmentPosition.requiredCompanyFunctions.length; index++) {
      const element = departmentPosition.requiredCompanyFunctions[index];
      const requiredCompanyFunction = companyFunctions.find((x) => x.id == element.id);
      if (!requiredCompanyFunction) throw Error("Missing company function");
      const fitnessForCompanyFunction = CompanyFunctionFitnessService.getFitnessForCompanyFunction(
        requiredCompanyFunction,
        availableQualifications,
      );
      fitness += fitnessForCompanyFunction;
    }
    // console.group("d");
    // console.log("Fitness for " + departmentPosition.title);
    // console.log("fitness " + fitness);
    // console.log("requiredCompanyFunction length " + departmentPosition.requiredCompanyFunctions.length);
    // console.groupEnd();
    return fitness / departmentPosition.requiredCompanyFunctions.length;
  }

  static getUniqueCompanyFunctions(
    array: IDepartmentPositionDTO[],
    companyFunctions: ICompanyFunctionDTO[],
  ): ICompanyFunctionDTO[] {
    return array.flatMap((x) => {
      const connectedCFIds = x.requiredCompanyFunctions?.map((x) => x.id) ?? [];
      const sorted = companyFunctions.sort((a, b) => a.title.localeCompare(b.title));
      const filtered = sorted.filter((x) => connectedCFIds.includes(x.id));
      return filtered;
    });
  }
}
