import i18next from "i18next";
import { CellProps, Column } from "react-table";

import { ICategoryMinDTO } from "@generatedCode/pbd-core/pbd-core-api";

import CategorySpan from "../../categories/categorySpan";

interface IProps {
  category?: ICategoryMinDTO;
}

/**
 * This column requires a property of type tags:TagDTO
 */
export function getCategoryColumn<T extends IProps>() {
  const column: Column<T> = {
    id: "category",
    Header: <>{i18next.t("Category")}</>,
    accessor: (x) => x.category?.title,
    Cell: ({ row }: CellProps<T>) => <>{row.original.category && <CategorySpan item={row.original.category} />}</>,
  };
  return column;
}
