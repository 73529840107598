import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IPrivacyPolicySettingDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { AccountRoutePaths } from "../accountRoutePaths";

interface IProps {
  privacyPolicy?: IPrivacyPolicySettingDTO;
}

export function RegistrationTerms(props: IProps) {
  const { t } = useTranslation();
  const { privacyPolicy } = props;
  return (
    <>
      <Trans i18nKey="acceptTermsKey">
        By signing up, I agree to the <Link to={AccountRoutePaths.PrivacyPage}>Privacy Policy</Link>.
      </Trans>
    </>
  );
}
