import { entries } from "lodash";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import CustomFieldService from "../../../../pbdServices/services/CustomFields/customFieldService";
import CustomFieldSelect from "../inputControl/select/customFieldSelect";

import CustomFieldSearchInputComponent from "./customFieldSearchInputComponent";

interface IProps {
  pbdModule: PbdModule;
  /**
   * customFieldId:customFieldValue | [customFieldId:customFieldValue]
   */
  defaultValue: string[] | undefined;
  /**
   * customFieldId:customFieldValue | [customFieldId:customFieldValue]
   */
  onChange: (item?: string[]) => void;
}

function CustomFieldSearchComponent(props: IProps) {
  const { t } = useTranslation();
  const { pbdModule, onChange, defaultValue } = props;

  const customFieldSelectedIds = CustomFieldService.getCustomFieldIdsFromQuery(defaultValue);
  const customFieldRecord = CustomFieldService.getCustomFieldKeyValuesFromQuery(defaultValue);

  const handleChange = (val?: string | string[]) => {
    const valAsArray = val ? (Array.isArray(val) ? val : [val]) : [];
    onChange(CustomFieldService.changeCustomFieldIds(valAsArray, defaultValue));
  };

  const handleValueChange = (key: string, value: string) => {
    const newCustomFields: string[] | undefined = CustomFieldService.handleCustomFieldSelectValue(
      value,
      key,
      defaultValue,
    );
    onChange(newCustomFields);
  };

  return (
    <>
      <Form.Group controlId="customFieldType" className="mb-3">
        <Form.Label>{t("Custom Field")}</Form.Label>
        <CustomFieldSelect
          module={pbdModule}
          isClearable
          selected={customFieldSelectedIds}
          onChange={handleChange}
          isMulti
        />
      </Form.Group>
      {customFieldRecord &&
        entries(customFieldRecord).map((x) => (
          <CustomFieldSearchInputComponent
            key={x[0]}
            customFieldId={x[0]}
            app={pbdModule}
            onChange={handleValueChange}
            defaultValue={x[1]}
          />
        ))}
    </>
  );
}

export default CustomFieldSearchComponent;
