import {
  CultureSettingDTO,
  IThemeSetting,
  IUserControllerClient,
  IUserSettingDTO,
  PbdModule,
  ThemeSetting,
  UserSettingDTO,
  UserSettingType,
} from "@generatedCode/pbd-core/pbd-core-api";

import { ClaimTypeNames } from "../../../Constants/ClaimTypeNames";
import { OrderDisplayName } from "../../Models/Enums/OrderDisplayName";
import { SupportedThemes } from "../../Models/Enums/SupportedThemes";
import JsonHelpers from "../Json/jsonHelpers";
import { MeAsUser } from "./models/me-as-user";
import { TourSettingsValue, TourStatus } from "./models/tour-settings";

export default class UserSettingsService {
  userApi: IUserControllerClient;

  constructor(userApi: IUserControllerClient) {
    this.userApi = userApi;
  }

  addOrUpdateValue(module: PbdModule, type: UserSettingType, value: unknown) {
    const data: IUserSettingDTO = {
      module: module,
      type: type,
      value: JSON.stringify(value),
    };

    return this.userApi.patchUserSettings(new UserSettingDTO(data));
  }

  getPersonDisplayNameSortOrder(meAsUser: MeAsUser) {
    const available = meAsUser.user.claims?.find((x) => x.claimType == ClaimTypeNames.TenantNameOrder);
    if (available) {
      return OrderDisplayName.LastName_FirstName;
    } else {
      return OrderDisplayName.FirstName_LastName;
    }
  }

  setTheme(value: IThemeSetting) {
    return this.userApi.setTheme(new ThemeSetting(value));
  }

  getSelectedTheme(meAsUser: MeAsUser) {
    const defaultTheme: IThemeSetting = {
      theme: SupportedThemes.Standard,
      appIconColor: SupportedThemes.Standard,
    };
    const themeSetting = meAsUser.settings.find((x) => x.type == UserSettingType.Theme);
    if (themeSetting?.value && typeof themeSetting.value == "string") {
      const dto = JsonHelpers.parseToCamelCase<ThemeSetting>(themeSetting.value);
      return dto;
    }
    return defaultTheme;
  }

  getMe() {
    return this.userApi.getMe();
  }

  changeLanguage(culture: string) {
    return this.userApi.setLanguage(new CultureSettingDTO({ culture }));
  }

  getTourStatus(meAsUser: MeAsUser, module: PbdModule, tourId: string): TourStatus | undefined {
    const tourStatusSetting = meAsUser.settings.find((x) => x.module == module && x.type == UserSettingType.TourStatus);
    if (tourStatusSetting?.value === undefined) return undefined;
    if (tourStatusSetting.value && typeof tourStatusSetting.value == "string") {
      const dto = JsonHelpers.parse<TourSettingsValue>(tourStatusSetting.value);
      const tourDTO = dto[tourId];
      return tourDTO;
    } else {
      return undefined;
    }
  }

  async markTourCompleted(meAsUser: MeAsUser, module: PbdModule, tourId: string, version: number) {
    const type = UserSettingType.TourStatus;
    //Either look up the settings or create a default one with an empty map
    const tourStatusSetting = meAsUser.settings.find((x) => x.module == module && x.type == type) ?? {
      type: type,
      module: module,
      value: {},
    };
    const tourStatus = tourStatusSetting.value as TourSettingsValue;
    tourStatus[tourId] = {
      tourId,
      version,
      finished: true,
    };

    // This try catch block was implemented to address a bug that has been introduced while changing userSettings from objects to strings in the backend
    // 2021-11-30 PP
    try {
      tourStatus[tourId] = {
        tourId,
        version,
        finished: true,
      };

      await this.addOrUpdateValue(module, type, tourStatus);
    } catch {
      const tourStatusReplacement: Record<string, unknown> = {};
      tourStatusReplacement[tourId] = {
        tourId,
        version,
        finished: true,
      };
      await this.addOrUpdateValue(module, type, tourStatusReplacement);
    }
  }
}
