import React from "react";
import { Col, Row } from "reactstrap";

import { IFileDTO } from "@generatedCode/pbd-core/pbd-core-api";

import FileRoutePaths from "../../../files/fileRoutePaths";

interface IProps {
  connectedFiles: IFileDTO[];
  imageStyle?: React.CSSProperties;
}

const ImageRowComponent: React.FC<IProps> = (props) => {
  const { connectedFiles, imageStyle = { height: "128px" } } = props;
  return (
    <Row>
      {connectedFiles
        .filter((x) => x.mimeType?.startsWith("image/"))
        .map((x) => (
          <Col lg={3} md={4} xs={6} key={x.id} className="col-lg-3 col-md-4 col-xs-6">
            <a href={FileRoutePaths.SaSUrl(x.id)} className="d-block mb-4 h-100">
              <img
                alt="thumbnail"
                className="img-fluid img-thumbnail"
                src={FileRoutePaths.SaSUrl(x.id)}
                style={imageStyle}
                title={x.title}
              />
            </a>
          </Col>
        ))}
    </Row>
  );
};

export default ImageRowComponent;
