import i18next from "i18next";
import React from "react";
import { Column } from "react-table";

import { IModuleSettingDTO } from "@generatedCode/pbd-core/pbd-core-api";

import BaseTableProps from "../../shared/components/tables/BaseTableProps";
import { getIdColumn } from "../../shared/components/tables/columns/idColumn";
import { getTitleColumn } from "../../shared/components/tables/columns/titleColumn";
import { ReactstrapTable } from "../../shared/components/tables/reactstrapTable";
import { useFilteredColumnsMemo } from "../../shared/components/tables/tableHelperFunctions";

export interface IModuleSettingsTableProps extends IModuleSettingDTO {
  id: string;
  title: string;
  /**React link */
  linkTo: string;
  // Just for testing
  // createdAt: DateTime;
}

type IProps = BaseTableProps<IModuleSettingsTableProps>;

export const ModuleSettingsTable = (props: IProps) => {
  const {
    columnsVisibleDefault = ["title"],
    localStorageStateKey = "ModuleSettings",
    includeTitleLink = true,
    columnsToExclude,
  } = props;

  const columns = React.useMemo<Column<IModuleSettingsTableProps>[]>(
    () => [
      {
        Header: <>{i18next.t("App")}</>,
        id: "app",
        accessor: "module",
      },
      {
        Header: <>{i18next.t("Type")}</>,
        id: "type",
        accessor: "type",
      },
      getIdColumn(includeTitleLink),
      getTitleColumn(includeTitleLink),
      // Just for testing
      // getCreatedAtColumn()
    ],
    [includeTitleLink],
  );

  const filteredColumns = useFilteredColumnsMemo(columns, columnsToExclude);

  return (
    <ReactstrapTable<IModuleSettingsTableProps>
      columns={filteredColumns}
      columnsVisibleDefault={columnsVisibleDefault}
      localStorageStateKey={localStorageStateKey}
      {...props}
    />
  );
};
