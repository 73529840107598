import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";

import { ICommentDTO, ICostDTO, ICustomField, ICustomFieldDTO, IFileDTO } from "@generatedCode/pbd-core/pbd-core-api";

import AttachmentTablePrintView from "../../../prints/components/attachmentTablePrintView";
import CommentsComponent from "../../../prints/components/commentsComponent";
import ConnectedElementsTable from "../../../prints/components/connectedElementsTable";
import CostsPrintTable from "../../../prints/components/costsPrintTable";
import PrintoutIncludeOptions, { PrintOptions } from "../../../prints/components/printoutIncludeOptions";
import QrCodeContainer from "../../../prints/components/qrCodeContainer";
import CustomFieldValueRenderer from "../../components/customFields/customFieldValueRenderer";
import ImageRowComponent from "../../components/fileUpload/imageRowComponent";
import { IBaseApi, isRequiredCommentApi } from "../../components/fileUploaderCommentsTab/baseApi";
import { PrintContextProvider } from "../../contexts/printContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { IApiDataBundleExtension } from "./printService";

interface IProps {
  title: React.ReactNode;
  item?: { id: number; createdAt: DateTime; customFields?: ICustomFieldDTO[] };
  availableOptions?: (keyof PrintOptions)[];
  urlToDetailsPage?: string;
  attachments?: IFileDTO[];
  comments?: ICommentDTO[];
  costs?: ICostDTO[];
  customFields?: ICustomField[];
  commentsApi?: IBaseApi;
  children: React.ReactNode;
  dataArray?: IApiDataBundleExtension[];
}

function DetailsPrintPageLayout(props: IProps) {
  const { t } = useTranslation();
  const {
    availableOptions = ["showQrCode", "showAttachments", "showComments"],
    urlToDetailsPage,
    attachments,
    comments,
    title,
    costs,
    customFields,
    item,
    commentsApi,
    dataArray,
  } = props;
  const [options, setOptions] = useLocalStorage<PrintOptions>("printOptions", new PrintOptions());

  const handleOptionsChange = (value: PrintOptions) => {
    setOptions(value);
  };

  const imageStyle: React.CSSProperties = {
    maxHeight: "100%",
    maxWidth: "100%",
  };
  return (
    <React.Fragment>
      <PrintoutIncludeOptions
        availableOptions={availableOptions}
        onChange={handleOptionsChange}
        initialOptions={options}
      />
      <h2>{title}</h2>
      <hr />
      <PrintContextProvider options={options}>{props.children}</PrintContextProvider>
      {customFields && options.showCustomFields && (
        <React.Fragment>
          <h5>{t("Custom Fields")}</h5>
          <hr />
          {item?.customFields?.map((x) => {
            const field = customFields.find((f) => f.id == x.id);
            return (
              <React.Fragment key={x.id}>
                <dt>{field?.name}</dt>
                <dd>
                  <CustomFieldValueRenderer customField={field} customFieldContent={x} />
                </dd>
                <hr />
              </React.Fragment>
            );
          })}
        </React.Fragment>
      )}
      {dataArray
        ?.filter((x) => options[x.printOptionId])
        .map((x) => (
          <div key={x.printOptionId}>
            <ConnectedElementsTable data={x.dataArray} type={t(x.title)} />
          </div>
        ))}

      {costs && options.showCosts && <CostsPrintTable items={costs} />}
      {attachments && options.includeImages && (
        <ImageRowComponent connectedFiles={attachments} imageStyle={imageStyle} />
      )}
      {attachments && options.showAttachments && <AttachmentTablePrintView attachments={attachments} />}
      {comments && options.showComments && commentsApi && item && isRequiredCommentApi(commentsApi) && (
        <CommentsComponent comments={comments} baseApi={commentsApi} item={item} />
      )}
      {urlToDetailsPage && options.showQrCode && <QrCodeContainer url={urlToDetailsPage} />}
    </React.Fragment>
  );
}
export default DetailsPrintPageLayout;
