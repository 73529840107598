import { Parser } from "expr-eval";

import { ICompanyFunctionDTO, IRequiredQualificationDTO } from "@generatedCode/pbd-core/pbd-core-api";

import StringHelpers from "../../../Helpers/StringHelpers";
import { DTOMap } from "../../../Helpers/filterMap";
import { WithExpired } from "../../Models/Shared/with-expired";
import { AvailableQualificationDTO } from "../QualificationMatrix/models/AvailableQualificationDTO";

export class CompanyFunctionFitnessService {
  /**return 0-1 for the fitness of the valid qualifications */
  static getFitnessForCompanyFunction(
    item: ICompanyFunctionDTO,
    availableQualifications: WithExpired<AvailableQualificationDTO>[],
  ) {
    const requiredQualifications = item.requiredQualifications ?? [];
    if (requiredQualifications.length == 0) return 1;
    const availableNeededQualifications = this.getAvailableRequiredQualifications(
      availableQualifications,
      requiredQualifications,
    );
    let fitness = 0;
    if (item.formula && !StringHelpers.isNullOrWhitespace(item.formula)) {
      const expr = Parser.parse(item.formula);
      const variables = expr.variables({ withMembers: true });
      const variablesWithValue = this.mapQualificationsAsVariables(variables, availableNeededQualifications);
      fitness = expr.evaluate(variablesWithValue);
      return fitness;
    } else {
      const totalWeightings = this.getTotalWeighting(requiredQualifications);
      const fitnessAvailable = this.getSumOfAvailableQualificationsFitnessByWeighting(
        availableNeededQualifications,
        requiredQualifications,
      );
      fitness = totalWeightings > 0 ? fitnessAvailable / totalWeightings : 0;
      return fitness;
    }
  }

  static mapQualificationsAsVariables(variables: string[], availableQualifications: AvailableQualificationDTO[]) {
    const record: Record<string, number> = {};
    for (const variable of variables) {
      const id = variable.split("_")[1];
      const avQ = availableQualifications.find((x) => x.id == Number(id));
      record[`QualificationId_${id}`] = avQ?.actualValue ?? 0;
    }
    return record;
  }

  static isQualificationIncluded(variables: string[], requiredQualification: IRequiredQualificationDTO[]) {
    if (requiredQualification.length == 0) {
      return false;
    }
    for (const variable of variables) {
      const id = variable.split("_")[1];
      const qualification = requiredQualification.find((x) => x.id == Number(id));
      if (!qualification) {
        return false;
      }
    }
    return true;
  }

  static getTotalWeighting(requiredQualifications: IRequiredQualificationDTO[]) {
    const totalWeightings = requiredQualifications.reduce((pv, cv) => pv + (cv.weighting ?? 1), 0);
    return totalWeightings;
  }

  static getAvailableRequiredQualifications<T extends AvailableQualificationDTO>(
    availableQualifications: T[],
    requiredQualifications: IRequiredQualificationDTO[],
  ): T[] {
    return availableQualifications.filter((x) => requiredQualifications.some((y) => y.id === x.id));
  }

  static getSumOfAvailableQualificationsFitnessByWeighting(
    availableQualifications: WithExpired<AvailableQualificationDTO>[],
    requiredQualifications: IRequiredQualificationDTO[],
  ) {
    const reqQualMap = new DTOMap(requiredQualifications);
    return availableQualifications.reduce((acc, qual) => {
      if (qual.isExpired) {
        return acc;
      }

      const requiredQualification = reqQualMap.getById(qual.id);
      if (!requiredQualification) throw Error("Missing required qualification");
      return acc + (qual.actualValue ?? 0) * requiredQualification.weighting;
    }, 0);
  }

  static getVariablesWithValues(formula: string, availableNeededQualifications: AvailableQualificationDTO[]) {
    const parser = new Parser();
    const expr = parser.parse(formula);
    const variables = expr.variables({ withMembers: true });
    const variablesWithValue = this.mapQualificationsAsVariables(variables, availableNeededQualifications);
    return { variablesWithValue, expr };
  }

  static getQualificationsAsVariables(items: IRequiredQualificationDTO[]) {
    return items.map((x) => `QualificationId_${x.id}`);
  }

  static isValid(formula: string, qualifications: IRequiredQualificationDTO[]) {
    const parser = new Parser();
    if (formula == "") {
      return true;
    }
    try {
      const exp = parser.parse(formula);
      const variables = exp.variables({ withMembers: true });
      return this.isQualificationIncluded(variables, qualifications);
    } catch (ex) {
      return false;
    }
  }
}
