import { last, meanBy, sortBy } from "lodash";

import { IOrganisationRatingDTO, OrganisationRatingType } from "@generatedCode/pbd-core/pbd-core-api";

import { DeltaType, getDeltaType } from "../../../../ClientApp/shared/components/cardComponents/kpiCard";
import { NumberHelpers } from "../../../../Helpers/NumberHelpers";

export interface IOrganisationRatingVM extends IOrganisationRatingDTO {
  ratingValueFormatted?: string;
}

export class SubtypeRatings {
  constructor(items: IOrganisationRatingDTO[]) {
    this.total = sortBy(items, (x) => x.doneAt).map((x) => {
      return { ...x, ratingValueFormatted: NumberHelpers.convertToPercentage(x.ratingValue) };
    });
    this.averageRating = meanBy(items, (x) => x.ratingValue);
    this.averageRatingFormatted = NumberHelpers.convertToPercentage(this.averageRating);
    const secondLast = this.total.length > 1 ? this.total[this.total.length - 2] : undefined;
    this.latestRating = this.total.length > 0 ? last(this.total) : undefined;
    this.delta = secondLast && this.latestRating && this.latestRating.ratingValue - secondLast.ratingValue;
    this.deltaType = this.delta ? getDeltaType(this.delta) : undefined;
  }
  total: IOrganisationRatingVM[];
  averageRating: number;
  averageRatingFormatted: string;
  latestRating?: IOrganisationRatingVM;
  delta?: number;
  deltaType?: DeltaType;
}

export class OrganisationRatingKpis {
  constructor(items: IOrganisationRatingDTO[]) {
    this.total = sortBy(items, (x) => x.doneAt).map((x) => {
      return { ...x, ratingValueFormatted: NumberHelpers.convertToPercentage(x.ratingValue) };
    });
    this.supplier = new SubtypeRatings(
      items.filter((x) => x.organisationRatingType == OrganisationRatingType.Supplier),
    );
    this.customer = new SubtypeRatings(
      items.filter((x) => x.organisationRatingType == OrganisationRatingType.Customer),
    );
  }
  total: IOrganisationRatingVM[];
  customer: SubtypeRatings;
  supplier: SubtypeRatings;
}
