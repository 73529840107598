import { BooleanParam, DecodedValueMap, SetQuery, withDefault } from "use-query-params";

import {
  ClaimTypeArrayParam,
  LuxonDateParam,
  LuxonDateTimeParam,
  NumberArrayParam,
  QmQueryParams,
  StatusArrayParam,
  StringArrayParam,
} from "../../../../Helpers/QueryHelpers";

export const QueryParamsConfigPbd = {
  status: withDefault(StatusArrayParam, undefined),
  recent: withDefault(QmQueryParams.Duration, undefined),
  createdFrom: withDefault(LuxonDateTimeParam, undefined),
  createdTo: withDefault(LuxonDateTimeParam, undefined),
  claimedFrom: withDefault(LuxonDateTimeParam, undefined),
  claimedTo: withDefault(LuxonDateTimeParam, undefined),
  nextInspectionFrom: withDefault(LuxonDateTimeParam, undefined),
  nextInspectionTo: withDefault(LuxonDateTimeParam, undefined),
  deadlineFrom: withDefault(LuxonDateParam, undefined),
  deadlineTo: withDefault(LuxonDateParam, undefined),
  plannedFrom: withDefault(LuxonDateParam, undefined),
  plannedTo: withDefault(LuxonDateParam, undefined),
  lastUpdatedFrom: withDefault(LuxonDateTimeParam, undefined),
  lastUpdatedTo: withDefault(LuxonDateTimeParam, undefined),
  absentFrom: withDefault(LuxonDateParam, undefined),
  absentTo: withDefault(LuxonDateParam, undefined),
  doneFrom: withDefault(LuxonDateParam, undefined),
  doneTo: withDefault(LuxonDateParam, undefined),
  responsibleId: withDefault(NumberArrayParam, undefined),
  ownerId: withDefault(NumberArrayParam, undefined),
  attendeeId: withDefault(NumberArrayParam, undefined),
  tenantId: withDefault(NumberArrayParam, undefined),
  claimType: withDefault(ClaimTypeArrayParam, undefined),
  tags: withDefault(StringArrayParam, undefined),
  useAllTags: withDefault(BooleanParam, undefined),
  organisationId: withDefault(NumberArrayParam, undefined),
  functioning: withDefault(StringArrayParam, undefined),
  inventoryItemId: withDefault(NumberArrayParam, undefined),
  inventoryStatusId: withDefault(NumberArrayParam, undefined),
  inventoryCategoryId: withDefault(NumberArrayParam, undefined),
  inventoryInspectionId: withDefault(NumberArrayParam, undefined),
  absentForId: withDefault(NumberArrayParam, undefined),
  absencePolicyId: withDefault(NumberArrayParam, undefined),
  departmentId: withDefault(NumberArrayParam, undefined),
  departmentPositionId: withDefault(NumberArrayParam, undefined),
  isDeleted: withDefault(BooleanParam, undefined),
  opportunityType: withDefault(StringArrayParam, undefined),
  opportunityCategoryId: withDefault(NumberArrayParam, undefined),
  customFields: withDefault(StringArrayParam, undefined),
  auditRequirement: withDefault(NumberArrayParam, undefined),
  claimId: withDefault(NumberArrayParam, undefined),
  qualificationId: withDefault(NumberArrayParam, undefined),
  addressId: withDefault(NumberArrayParam, undefined),
  categoryId: withDefault(NumberArrayParam, undefined),
  createdById: withDefault(NumberArrayParam, undefined),
  reviewerId: withDefault(NumberArrayParam, undefined),
  approvedById: withDefault(NumberArrayParam, undefined),
  connectedTo: withDefault(StringArrayParam, undefined),
  goalId: withDefault(NumberArrayParam, undefined),
  customFormId: withDefault(NumberArrayParam, undefined),
  isEmployee: withDefault(BooleanParam, undefined),
  isCustomer: withDefault(BooleanParam, undefined),
  isSupplier: withDefault(BooleanParam, undefined),
  userId: withDefault(StringArrayParam, undefined),
  isApplicationUser: withDefault(BooleanParam, undefined),
  isEmailConfirmed: withDefault(BooleanParam, undefined),
  contributorId: withDefault(NumberArrayParam, undefined),
  projectId: withDefault(NumberArrayParam, undefined),
  supporterId: withDefault(NumberArrayParam, undefined),
  namedQuery: withDefault(StringArrayParam, undefined),
  isQualificationRequired: withDefault(BooleanParam, undefined),
  applicationGroupId: withDefault(StringArrayParam, undefined),
  addressRoomId: withDefault(NumberArrayParam, undefined),
  publishedFrom: withDefault(LuxonDateParam, undefined),
  publishedTo: withDefault(LuxonDateParam, undefined),
  onlyLatestRevision: withDefault(BooleanParam, undefined),
  claimIsJustified: withDefault(BooleanParam, undefined),
  productId: withDefault(NumberArrayParam, undefined),
};

export type QueryParamsConfigPbdValues = DecodedValueMap<typeof QueryParamsConfigPbd>;
export type QueryParamsConfigPbdSetQuery = SetQuery<typeof QueryParamsConfigPbd>;

export const DefaultQueryParamsConfig: QueryParamsConfigPbdValues = {
  status: undefined,
  createdFrom: undefined,
  recent: undefined,
  createdTo: undefined,
  claimedFrom: undefined,
  claimedTo: undefined,
  deadlineFrom: undefined,
  deadlineTo: undefined,
  plannedFrom: undefined,
  plannedTo: undefined,
  lastUpdatedFrom: undefined,
  lastUpdatedTo: undefined,
  absentFrom: undefined,
  absentTo: undefined,
  doneFrom: undefined,
  doneTo: undefined,
  responsibleId: undefined,
  ownerId: undefined,
  attendeeId: undefined,
  tenantId: undefined,
  claimType: undefined,
  tags: undefined,
  useAllTags: undefined,
  organisationId: undefined,
  functioning: undefined,
  inventoryItemId: undefined,
  inventoryStatusId: undefined,
  inventoryCategoryId: undefined,
  inventoryInspectionId: undefined,
  absentForId: undefined,
  absencePolicyId: undefined,
  departmentId: undefined,
  departmentPositionId: undefined,
  isDeleted: undefined,
  opportunityType: undefined,
  opportunityCategoryId: undefined,
  customFields: undefined,
  auditRequirement: undefined,
  claimId: undefined,
  qualificationId: undefined,
  addressId: undefined,
  categoryId: undefined,
  createdById: undefined,
  reviewerId: undefined,
  approvedById: undefined,
  connectedTo: undefined,
  goalId: undefined,
  customFormId: undefined,
  isEmployee: undefined,
  isCustomer: undefined,
  isSupplier: undefined,
  userId: undefined,
  isApplicationUser: undefined,
  contributorId: undefined,
  nextInspectionFrom: undefined,
  nextInspectionTo: undefined,
  projectId: undefined,
  supporterId: undefined,
  isEmailConfirmed: undefined,
  namedQuery: undefined,
  isQualificationRequired: undefined,
  applicationGroupId: undefined,
  addressRoomId: undefined,
  publishedFrom: undefined,
  publishedTo: undefined,
  onlyLatestRevision: undefined,
  claimIsJustified: undefined,
  productId: undefined,
};
