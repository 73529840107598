import { useOs } from "@mantine/hooks";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps {
  onClick: () => void;
}

export function SearchBoxNavbarCmdkButton(props: IProps) {
  const { t } = useTranslation();
  const os = useOs();
  const { onClick } = props;
  return (
    <Button variant="outline-secondary" aria-label="Search global" onClick={onClick}>
      <span className="me-4">
        <qmBaseIcons.Search /> {t("Search")}
      </span>
      <kbd>{os == "macos" ? "⌘" : t("ctrl")}</kbd> <kbd>k</kbd>
    </Button>
  );
}
