import React from "react";
import { Card, Col, Collapse, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";

import {
  IOpportunityCategoryDTO,
  IOpportunityCategoryVariable,
  OpportunityCategoryVariable,
} from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import CollapseChevron from "../../../shared/components/icons/collapseChevron";
import { TableRowClickCommand } from "../../../shared/components/tables/TableRowClickCommand";
import { useConfirmation } from "../../../shared/contexts/modalConfirmationContext";
import EditFormVariable from "./editFormVariable";
import ScaleSectionsConnectedCard from "./scaleSectionConnectedCard";
import { VariablesTable } from "./variablesTable";

interface IProps {
  baseDTO: IOpportunityCategoryDTO;
  refreshParent: () => void;
}

function VariablesConnectedCard(props: IProps) {
  const { baseDTO, refreshParent } = props;
  const { t } = useTranslation();
  const { opportunityCategoriesApi } = useAPIs();
  const [itemToUpdate, setItemToUpdate] = React.useState<IOpportunityCategoryVariable>();
  const [showForm, setShowForm] = React.useState(false);
  const [showScaleSection, setShowScaleSection] = React.useState(false);
  const [isOpen, setIsOpen] = useQueryParam("variables", withDefault(BooleanParam, false));
  const confirm = useConfirmation();

  const toggle = () => setIsOpen(!isOpen);

  const handleRowClick = async (command: TableRowClickCommand<IOpportunityCategoryVariable>) => {
    const { action, row } = command;
    setItemToUpdate(undefined);
    setItemToUpdate(row);
    switch (action) {
      case "Details":
        setShowScaleSection(true);
        break;
      case "Delete":
        confirm({ itemsToDelete: [{ id: row.variableId, title: row.variableName }] }).then(async () => {
          await opportunityCategoriesApi.deleteVariable(baseDTO.id, row.variableId);
          refreshParent();
        });
        break;
      case "Select":
        setShowForm(true);
        break;
      default:
        throw Error("Not implemented");
    }
  };

  const handleCancel = () => {
    setItemToUpdate(undefined);
    setShowForm(false);
  };

  const handleAdd = () => {
    setItemToUpdate(undefined);
    setShowForm(true);
  };

  function closeScaleSections() {
    setItemToUpdate(undefined);
    setShowForm(false);
    setShowScaleSection(false);
  }

  const handleSuccess = () => {
    setItemToUpdate(undefined);
    setShowForm(false);
    refreshParent();
  };

  const editVariable = async (dto: IOpportunityCategoryVariable) =>
    opportunityCategoriesApi.addOrUpdateVariable(baseDTO.id, new OpportunityCategoryVariable(dto));

  return (
    <>
      <Card.Header className="d-flex justify-content-between">
        <Card.Title as="h5">
          <a href="#variablesConnectedCard" onClick={toggle}>
            <CollapseChevron isOpen={isOpen} /> 2. {t("Variables")}
          </a>
        </Card.Title>
      </Card.Header>
      <Collapse in={isOpen}>
        <Card.Body>
          {showForm && (
            <EditFormVariable
              itemToUpdate={itemToUpdate}
              onSubmit={editVariable}
              onCancel={handleCancel}
              onSuccess={handleSuccess}
            />
          )}
          {!showForm && (
            <Row>
              <Col md={showScaleSection ? 6 : 12}>
                <VariablesTable tableRows={baseDTO.variables} onRowClick={handleRowClick} onCreateClick={handleAdd} />
              </Col>
              {showScaleSection && itemToUpdate && (
                <Col md={6}>
                  <ScaleSectionsConnectedCard
                    baseDTO={itemToUpdate}
                    refreshParent={refreshParent}
                    onCancel={closeScaleSections}
                    opportunityCategoryId={baseDTO.id}
                  />
                </Col>
              )}
            </Row>
          )}
        </Card.Body>
      </Collapse>
    </>
  );
}

export default VariablesConnectedCard;
