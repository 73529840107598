import i18next from "i18next";
import { DateTime } from "luxon";
import React from "react";
import { Link } from "react-router-dom";
import { FormText } from "reactstrap";

import {
  IInventoryInspectionsConnectedControllerClient,
  IInventoryItemsControllerClient,
  ITenantMinDTO,
  PbdModule,
} from "@generatedCode/pbd-core/pbd-core-api";

import { AdminRoutePaths } from "../../../ClientApp/admin/adminRoutePaths";
import { MaintenanceManagementRoutePaths } from "../../../ClientApp/maintenanceManagement/maintenanceManagementRoutePaths";
import { SendNotificationForm } from "../../../ClientApp/shared/components/modals/notificationForm";
import { IProductConfigInterface } from "../../../Constants/productConfig";
import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import { ModuleNotification, NotificationItem } from "../../Models/Notifications/ModuleNotification";
import FeatureFlagService from "../FeatureFlags/featureFlagService";
import SettingsService from "../Settings/settingsService";

export default class NotificationService {
  inventoryItemsApi: IInventoryItemsControllerClient;
  inventoryInspectionsConnectedApi: IInventoryInspectionsConnectedControllerClient;
  settingService: SettingsService;
  constructor(
    inventoryItemsApi: IInventoryItemsControllerClient,
    inventoryInspectionsConnectedApi: IInventoryInspectionsConnectedControllerClient,
    settingService: SettingsService,
  ) {
    this.inventoryItemsApi = inventoryItemsApi;
    this.inventoryInspectionsConnectedApi = inventoryInspectionsConnectedApi;
    this.settingService = settingService;
  }

  async getMaintenanceNotifications(query: { responsibleId: number }) {
    const inventoryInspections = await this.inventoryInspectionsConnectedApi.getAllQuery({
      responsibleId: [query.responsibleId],
    });
    const filteredInspections = inventoryInspections.filter(
      (x) => !x.inventoryItem?.excludeFromMonitoring && x.nextInspection && x.nextInspection <= DateTime.now(),
    );
    const inventoryItems = await this.inventoryItemsApi.getAllQuery({
      responsibleId: [query.responsibleId],
      nextInspectionTo: DateTime.now(),
    });
    const filteredItems = inventoryItems.filter((x) => !x.excludeFromMonitoring);

    const notificationList: NotificationItem[] = [
      new NotificationItem(
        i18next.t("Machines need maintenance"),
        `${MaintenanceManagementRoutePaths.IndexPage}/?responsibleId=${
          query.responsibleId
        }&nextInspectionTo=${DateTimeLuxonHelpers.getISODateForQuery(DateTime.now())}`,
        filteredItems,
      ),
      new NotificationItem(
        i18next.t("Overdue inspections"),
        `${MaintenanceManagementRoutePaths.UpcomingInspection}/?responsibleId=${
          query.responsibleId
        }&nextInspectionTo=${DateTimeLuxonHelpers.getISODateForQuery(DateTime.now())}`,
        filteredInspections,
      ),
    ];
    return new ModuleNotification(PbdModule.MaintenanceManagement, notificationList);
  }

  async getInviteUserTemplate(
    product: IProductConfigInterface,
    tenant: ITenantMinDTO,
    emailTo?: string,
  ): Promise<SendNotificationForm & { footer?: React.ReactNode }> {
    const register = this._getRegisterParagraph(product);
    const template: SendNotificationForm = {
      to: emailTo || "",
      subject: `Einladung zu ${product.productDisplayName} von ${tenant.fullName}`,
      message: `${tenant.fullName} hat Sie eingeladen sich bei ${product.productDisplayName} zu registrieren.<br/>${register}
      <br/>Viele Grüße<br/> ${tenant.fullName}`,
    };
    if (FeatureFlagService.isMubea(window.location.host)) {
      template.subject = `Einladung zur Beteiligung an unserem Mubea Ideenmanagement von ${tenant.fullName}`;
      template.message = `${tenant.fullName} hat Sie eingeladen sich bei unserem Mubea Ideenmanagement zu registrieren und eine Idee einzureichen.<br/>${register}
      <br/>Viele Grüße<br/> ${tenant.fullName}`;
    }
    const domains = await this.settingService.getAllowedDomains();
    const footer = (
      <FormText color="muted">
        {i18next.t("Please keep in mind that it is only possible to register with an Email that ends with")}{" "}
        {domains?.join(", ")} <Link to={AdminRoutePaths.AllowedDomains}>{i18next.t("Change")}</Link>
      </FormText>
    );
    return {
      ...template,
      footer,
    };
  }

  private _getRegisterParagraph(product: IProductConfigInterface) {
    const hostUrl = `${window.location.protocol}//${window.location.hostname}`;
    const register = `${hostUrl}/Account/Register`;
    const url = product.landingPageUrl;
    // if (productId == ProductNames.DocuPro) {
    //   url = "https://jowecon.de/docupro/";
    // } else if (productId == ProductNames.CareNavigator) {
    //   url = "https://carenavigator.de/";
    // } else if (productId == ProductNames.imsBase) {
    //   url = "https://imsbase.de/";
    // }
    const registerParagraph = `<h4>Wie geht es weiter?</h4>
    <ol>
    <li>Registrieren Sie sich hier: <a href="${register}">${register}</a></li>
    <li>Sie erhalten eine E-Mail mit der weiteren Anleitung und der Bitte Ihr Konto zu bestätigen</li>
    </ol>
    Mehr über ${product.productDisplayName} erfahren: <a href=${url}>${url}</a><br/><br/>`;
    return registerParagraph;
  }
}
