import i18next from "i18next";
import React from "react";
import { Collapse } from "react-bootstrap";

import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { FilterElement, PredefinedFilters, SearchFilterTypes } from "../genericSearchFilter/availableSearchFilters";
import FilterComponent from "../genericSearchFilter/filterComponent";
import GenericExtendedSearchFilter from "../genericSearchFilter/genericExtendedSearchFilter";
import SearchCriteria from "./searchCriteria";

function convertSearchArray(keys?: string[]) {
  if (keys) {
    const convertedKeys: string[] = [];
    for (let element of keys) {
      if (element == "tags.name") {
        element = "tags";
      } else if (element == "createdBy.fullName") {
        element = "created by";
      } else if (element == "refUrl") {
        continue;
      }
      element = element.charAt(0).toUpperCase() + element.slice(1);
      element = i18next.t(element);
      convertedKeys.push(element);
    }
    const joinedString = convertedKeys.join(", ");
    return `${i18next.t("Search by")} ${joinedString}...`;
  } else {
    return `${i18next.t("Search")}...`;
  }
}

interface IProps {
  doSearch: (criteria: string) => void;
  extraFilters?: SearchFilterTypes[];
  // to select the custom fields with respect to the selected module.
  pbdModule?: PbdModule;
  /**list the columns to search for. This will be converted in the following ways
   * from: ["id","title"]
   * to: Seaarch by Id, Title...
   * while converting it will be translated
   */
  searchKeys?: string[];
  searchFilterCustomization?: React.ReactNode;
  defaultValue?: string;
  placeholder?: string;
  onChange?: (dto: FilterElement[]) => void;
}

export const getAvailableFilters = (filters: SearchFilterTypes[]) => {
  const resp = PredefinedFilters.getList(filters);
  return resp;
};

function GenericSearchComponent(props: IProps) {
  const {
    doSearch,
    extraFilters,
    pbdModule,
    searchKeys,
    searchFilterCustomization,
    defaultValue,
    placeholder,
    onChange,
  } = props;
  const [collapse, setCollapse] = React.useState(false);

  return (
    <>
      <SearchCriteria
        placeholder={placeholder ?? convertSearchArray(searchKeys)}
        doSearch={doSearch}
        showFilterButton={true}
        onFilterButtonClick={() => setCollapse(!collapse)}
        extraBoxFilterComponent
        defaultValue={defaultValue}
      />
      {extraFilters && !onChange && (
        <GenericExtendedSearchFilter
          availableFilters={getAvailableFilters(extraFilters)}
          collapsed={collapse}
          pbdModule={pbdModule}
          searchFilterCustomization={searchFilterCustomization}
        />
      )}

      {extraFilters && onChange && (
        <Collapse in={collapse}>
          <div>
            <FilterComponent availableFilters={getAvailableFilters(extraFilters)} onChange={onChange} />
          </div>
        </Collapse>
      )}
    </>
  );
}

export default GenericSearchComponent;
