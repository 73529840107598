import { isRouteErrorResponse, useParams, useRouteError } from "react-router-dom";

import ErrorPageLayout from "../layouts/errors/errorPageLayout";

import ErrorPageContentDefault from "./errorPageContentDefault";

interface IProps {
  code?: string;
}

export function ErrorPage(props: IProps) {
  const params = useParams<{ code?: string }>();
  const error = useRouteError();

  const codeOverall = isRouteErrorResponse(error) ? error.status.toString() : (params.code ?? props.code ?? "Unknown");

  return (
    <ErrorPageLayout>
      <ErrorPageContentDefault code={codeOverall} />
    </ErrorPageLayout>
  );
}
