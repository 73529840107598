import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import {
  CategoryCreateDTO,
  ConnectQualificationsDTO,
  ICategoryCreateDTO,
  ICategoryWithResponsibleDTO,
  IQualificationDTO,
} from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { ApiResult, wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import QualificationsConnectedCard from "../../../shared/components/connectionElements/qualifications/qualificationsConnectedCard";
import { useRefreshHook } from "../../../shared/hooks/useRefreshHook";
import BaseSettingsForm, { hasId } from "../../components/editPage/baseSettingsForm";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

const EditPageTrainingTypes = () => {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { trainingTypesApi } = useAPIs();
  const [loading, setLoading] = React.useState(true);
  const [itemToUpdate, setItemToUpdate] = React.useState<ICategoryWithResponsibleDTO>();
  const [connectedQualifications, setConnectedQualifications] = React.useState<IQualificationDTO[]>();
  const [{ refresh, handleRefresh }] = useRefreshHook();

  const { data: existingTrainingTypes } = useSWR(`trainingTypes`, () => trainingTypesApi.getAll());

  React.useEffect(() => {
    async function getData() {
      const category = await trainingTypesApi.getById(Number(params.id));
      setItemToUpdate(category);
      const qualifications = await trainingTypesApi.getQualifications(Number(params.id));
      setConnectedQualifications(qualifications);
    }
    if (params.id != null) {
      getData().then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [params.id, refresh]);

  async function handleSubmit(itemToCreate: ICategoryCreateDTO) {
    if (params.id) {
      const resp = await trainingTypesApi.edit(Number(params.id), new CategoryCreateDTO(itemToCreate));
      if (
        itemToCreate.responsibleId &&
        itemToUpdate?.responsible &&
        itemToCreate.responsibleId != itemToUpdate.responsible.id
      ) {
        handleRefresh();
      }
      return resp;
    } else {
      return trainingTypesApi.create(new CategoryCreateDTO(itemToCreate));
    }
  }

  async function handleDelete() {
    if (!itemToUpdate) throw Error("Missing itemToUpdate");
    let resp: ApiResult<void>;
    if (itemToUpdate.isDeleted) {
      resp = await wrapApiCallWithToast(() => trainingTypesApi.restore(itemToUpdate.id));
    } else {
      resp = await wrapApiCallWithToast(() => trainingTypesApi.delete(itemToUpdate.id));
    }
    if (resp.isOk) {
      handleRefresh();
    }
  }

  const handleSuccess = (dto?: unknown) => {
    if (hasId(dto)) {
      navigate(SettingsRoutePaths.EditPageTrainingTypes.replace(":id", dto.id.toString()));
    } else {
      handleRefresh();
    }
  };
  const columnsToExclude = ["companyFunctionsCount", "requirements"];

  return (
    <>
      {!loading && (
        <BaseSettingsForm
          itemToUpdate={itemToUpdate}
          onSuccess={handleSuccess}
          onSubmit={handleSubmit}
          additionalFields={["Color", "DescriptionAsHtml"]}
          onDelete={handleDelete}
          onRestore={(id) => trainingTypesApi.restore(id)}
          handleRefresh={handleRefresh}
          existingItems={existingTrainingTypes}
          formType={"TrainingType"}
        />
      )}

      {itemToUpdate && (
        <QualificationsConnectedCard
          baseDTO={itemToUpdate}
          data={connectedQualifications}
          refreshParent={handleRefresh}
          columnsToExclude={columnsToExclude}
          onConnectSubmit={async (ids) => {
            await trainingTypesApi.addQualifications(
              itemToUpdate.id,
              new ConnectQualificationsDTO({ qualificationIds: ids }),
            );
            handleRefresh();
          }}
          onDisconnectSubmit={(id) => trainingTypesApi.deleteQualification(itemToUpdate.id, id)}
          cardTitle={t("Qualifications")}
        />
      )}
    </>
  );
};

export default EditPageTrainingTypes;
