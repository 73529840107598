import { uniq } from "lodash";
import { Navigate, useLocation } from "react-router-dom";

import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { getModuleRoleNames } from "../../../pbdServices/services/Authz/authService";
import { AccountRoutePaths } from "../../account/accountRoutePaths";
import { StartpageRoutePaths } from "../../startpage/startpageRoutePaths";
import { useCurrentAppHook } from "../hooks/useCurrentAppHook";

import { useAuthenticationContext } from "./authenticationContext";

const appsWithoutPermissions = [PbdModule.None, PbdModule.Startpage, PbdModule.Profile];

interface IProps {
  children: React.ReactNode;
}

function AuthorizationWrapper(props: IProps) {
  const { children } = props;
  const { user } = useAuthenticationContext();
  const { currentApp } = useCurrentAppHook();
  const location = useLocation();

  if (
    user.isProfileMissing &&
    location.pathname != AccountRoutePaths.ActivateProfilePage &&
    !location.pathname.startsWith(AccountRoutePaths.ErrorPage) &&
    !location.pathname.startsWith(StartpageRoutePaths.AccessDenied)
  ) {
    return <Navigate to={AccountRoutePaths.ActivateProfilePage} replace />;
  }

  if (!appsWithoutPermissions.includes(currentApp)) {
    const rolesObj = getModuleRoleNames(currentApp);
    const roles = [rolesObj.standardRole, rolesObj.adminRole];
    const uniqueRoles = uniq(roles.filterMap((x) => x));
    if (!user.hasSufficientAppRights(currentApp)) {
      const redirectTo = StartpageRoutePaths.AccessDeniedWithRoles(uniqueRoles, location.pathname);
      return <Navigate to={redirectTo} replace />;
    }
  }

  return <>{children}</>;
}
export default AuthorizationWrapper;
