import React from "react";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { IAddressDTO, IAddressRoomDTO } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { useTryDelete } from "../../../../pbdServices/services/Api/api-hooks";
import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";
import RoomsForm from "./roomsForm";

interface IProps {
  address: IAddressDTO;
  reloadParent: () => void;
}

function RoomsTable(props: IProps) {
  const { address, reloadParent } = props;
  const { t } = useTranslation();
  const { addressesApi } = useAPIs();
  const [selectedRoom, setSelectedRoom] = React.useState<IAddressRoomDTO>();
  const [showForm, setShowForm] = React.useState(false);

  const afterFormSubmit = () => {
    reloadParent();
    setShowForm(false);
  };

  function setRoomForEdit(dto: IAddressRoomDTO) {
    setSelectedRoom(dto);
    setShowForm(true);
  }

  const deleteRoom = useTryDelete(
    (val) => addressesApi.deleteAddressRoom(address.id, val.id),
    [address.id, addressesApi],
    reloadParent,
  );

  return (
    <>
      <div className="d-flex justify-content-between">
        <h4>
          {t("Rooms")}{" "}
          {address.addressRooms && address.addressRooms.length > 1 && <span>({address.addressRooms.length})</span>}
        </h4>
        <Button color="outline-primary" onClick={() => setShowForm(!showForm)}>
          <qmBaseIcons.Plus />
        </Button>
      </div>
      {showForm ? (
        <RoomsForm baseObject={address} itemToUpdate={selectedRoom} afterSubmit={afterFormSubmit} />
      ) : (
        <Table responsive>
          <tbody>
            {address.addressRooms?.map((x) => (
              <tr key={x.id}>
                <td>#{x.id}</td>
                <td>{x.title}</td>
                <td>
                  <div className="float-end">
                    <Button size="sm" variant="outline-primary" onClick={() => setRoomForEdit(x)}>
                      <qmBaseIcons.Pencil />
                    </Button>
                    <Button size="sm" className="m-1" variant="outline-danger" onClick={() => deleteRoom(x)}>
                      <qmBaseIcons.Delete />
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
}

export default RoomsTable;
