import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

import { PbdModule, PrivacyPolicySetting } from "@generatedCode/pbd-core/pbd-core-api";

import { useAPIs } from "../../../pbdServices/services/service-context";
import PrivacyPolicyForm from "../components/privacyPolicyForm";

export function PrivacyPage() {
  const { t } = useTranslation();
  const { settingsApi, articlesApi } = useAPIs();
  const { data, mutate, isLoading } = useSWR(["/api/settings/privacyPolicy"], () =>
    settingsApi.getPrivacyPolicySetting(),
  );
  const { data: articles } = useSWRImmutable(`/api/articles/?module=${PbdModule.DocumentManagement}&take=1000`, () =>
    articlesApi.getAllQuery({ module: PbdModule.DocumentManagement, take: 1000 }),
  );
  return (
    <>
      <h2>{t("Custom data privacy")}</h2>
      <hr />
      <Card className="mb-3">
        <Card.Body>
          {articles && !isLoading && (
            <PrivacyPolicyForm
              onSubmit={(x) => settingsApi.addOrUpdatePrivacyPolicySetting(new PrivacyPolicySetting(x))}
              itemToUpdate={data}
              onSuccess={mutate}
              articles={articles}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
}
