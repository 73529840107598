import React, { ReactNode } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ICompanyFunctionDTO, ITenantDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { CompanyFunctionsConnectedToTenant } from "../../../../../Models/CompanyFunctions/CompanyFunctionsConntectedToTenant";
import { wrapApiCallWithToast } from "../../../../../pbdServices/services/Api/api-wrapper";
import { CompanyFunctionTable } from "../../../../companyFunctions/components/companyFunctionTable";
import { useConfirmation } from "../../../contexts/modalConfirmationContext";
import { TableAction, TableClickCommand } from "../../tables/TableClickCommand";
import { ConnectedCardProps } from "../connectedCardProps";
import { ConnectedCardHeader } from "../shared/connectedCardHeader";
import ConnectCompanyFunctionsModal from "./connectCompanyFunctionsModal";

interface IProps extends ConnectedCardProps<ICompanyFunctionDTO | CompanyFunctionsConnectedToTenant> {
  tenants?: ITenantDTO[];
  cardBody?: ReactNode;
}

interface ISelected {
  id: number;
  title?: string | undefined;
}

function CompanyFunctionsConnectedCard(props: IProps) {
  const { t } = useTranslation();
  const {
    refreshParent,
    data,
    baseDTO,
    cardBody,
    cardTitle = t("Functions"),
    onConnectSubmit,
    onDisconnectSubmit,
  } = props;
  const confirm = useConfirmation();
  const [selected, setSelected] = React.useState<ISelected[]>([]);
  const [connectionModal, setConnectionModal] = React.useState(false);

  const toggleConnectModal = () => setConnectionModal(!connectionModal);

  const handleTableClick = async (command: TableClickCommand) => {
    const { action } = command;
    switch (action) {
      case "Delete":
        confirm({ itemsToDelete: selected }).then(() => submitDeleteRequest(selected.map((x) => x.id)));
        break;
      default:
        throw Error("Not implemented");
    }
  };

  const submitDeleteRequest = async (dto: number[]) => {
    if (!onDisconnectSubmit) throw Error("Missing disconnect");
    for (const i of dto) {
      const resp = await wrapApiCallWithToast(() => onDisconnectSubmit(i));
    }
    refreshParent?.();
  };

  const availableTableActions: TableAction[] = ["Delete"];

  const cardContent = (
    <>
      <ConnectedCardHeader
        title={
          <span>
            <i className="icon-qmBase-ProjectAndTaskManagement" /> {cardTitle}
          </span>
        }
        toggleConnectModal={toggleConnectModal}
        data={data}
        canAdd={baseDTO.capabilities?.canEdit}
      />
      {cardBody}
      {data && data.length > 0 && (
        <Card.Body>
          <CompanyFunctionTable
            tableRows={data}
            columnsVisibleDefault={["title"]}
            setSelected={(items) => setSelected(items)}
            availableTableActions={availableTableActions}
            onClick={handleTableClick}
            onCreateClick={toggleConnectModal}
            onCreateButtonTitle={t("Add company function")}
            columnsToExclude={[]}
            {...props}
          />
        </Card.Body>
      )}
      {data && onConnectSubmit && refreshParent && (
        <ConnectCompanyFunctionsModal
          modal={connectionModal}
          toggle={toggleConnectModal}
          connectedElements={data}
          onSubmit={onConnectSubmit}
          canSubmit={true}
          refreshParent={refreshParent}
        />
      )}
    </>
  );
  if (props.renderOnlyCardContent) {
    return cardContent;
  } else {
    return <Card className="mb-3">{cardContent}</Card>;
  }
}

export default CompanyFunctionsConnectedCard;
