import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

import { IEntityTemplateDTO, PbdModule, SettingType } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../pbdServices/services/service-context";

import { AdminRoutePaths } from "../../admin/adminRoutePaths";
import EditFormTemplate from "../../admin/components/editFormTemplate";
import IdComponent from "../../shared/components/id/idComponent";

function EditPageTemplates() {
  const navigate = useNavigate();
  const { id, pbdModule } = useParams<{ id: string; pbdModule: PbdModule }>() as { id?: string; pbdModule: PbdModule };
  const { t } = useTranslation();
  const { moduleSettingsService } = useAPIServices();
  const { moduleSettingsApi } = useAPIs();
  const [loading, setLoading] = React.useState(true);
  const [itemToUpdate, setItemToUpdate] = React.useState<IEntityTemplateDTO>();
  const [templates, setTemplates] = React.useState<IEntityTemplateDTO[]>();

  React.useEffect(() => {
    async function getData() {
      const data = await moduleSettingsApi.getTemplateSettings(pbdModule);
      setTemplates(data);
      if (id) {
        const template = data.find((x) => x.id == id);
        setItemToUpdate(template);
      }
      setLoading(false);
    }
    getData();
  }, [id, pbdModule]);

  const handleRedirectToIndex = () => navigate(AdminRoutePaths.AppConfigurationPage.replace(":pbdModule", pbdModule));

  const onSubmit = (dto: IEntityTemplateDTO) => {
    if (!templates) throw Error("No data");
    const valueNew = [...templates];
    const index = templates.findIndex((x) => x.id == dto.id);
    if (index > -1) {
      valueNew[index] = dto;
    } else {
      valueNew.push(dto);
    }
    return moduleSettingsService.save(pbdModule, SettingType.Templates, valueNew);
  };

  return (
    <>
      <h2>{t(pbdModule)}</h2>
      <hr />
      <Card>
        <CardHeader>
          <CardTitle tag="h5">{t("Templates")}</CardTitle>
        </CardHeader>
        <CardBody>
          <h6>
            {id ? (
              <>
                {t("Edit")} <IdComponent id={id} />
              </>
            ) : (
              t("New")
            )}
          </h6>
          {!loading && (
            <EditFormTemplate onCancel={handleRedirectToIndex} onSubmit={onSubmit} itemToUpdate={itemToUpdate} />
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default EditPageTemplates;
