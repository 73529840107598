import { uniqBy } from "lodash";
import { DateTime } from "luxon";

import {
  ICapabilitiesDTO,
  ICompanyFunctionDTO,
  IDepartmentPositionForTenantDTO,
  IDepartmentPositionWithChildrenDTO,
  IEntityPermissionDTO,
  IQualificationDTO,
  ITenantCompanyFunctionDTO,
  ITenantDTO,
} from "@generatedCode/pbd-core/pbd-core-api";

import { ListHelpers } from "../../../../Helpers/ListHelpers";
import { CompanyFunctionsConnectedToTenant } from "../../../../Models/CompanyFunctions/CompanyFunctionsConntectedToTenant";
import { DepartmentPositionFitnessService } from "../../DepartmentPositions/departmentPositionFitnessService";
import { QualificationVm } from "../../Qualifications/models/qualification-vm";
import { AvailableQualificationDTO } from "./AvailableQualificationDTO";
import { ITenantQualificationVm } from "./tenant-qualification-vm";

const getRequiredQualifications = (
  tenant: ITenantDTO,
  departmentPositions: IDepartmentPositionWithChildrenDTO[],
  allCompanyFunctions: ICompanyFunctionDTO[],
  requiredCompanyFunctions: ITenantCompanyFunctionDTO[],
  qualifications: QualificationVm[],
) => {
  const companyFunctionsConnected = mapConnectedCompanyFunctions(
    tenant,
    requiredCompanyFunctions,
    departmentPositions,
    allCompanyFunctions,
  );
  const reqQual = companyFunctionsConnected
    .filterMap((x) => x.requiredQualifications)
    .reduce((pv, cv) => pv.concat(cv), []);
  const uniqueIds = ListHelpers.uniq(reqQual.map((x) => x.id));
  return qualifications.filter((x) => uniqueIds.includes(x.id));
};

const mapAvailableQualifications = (tenantQualifications: ITenantQualificationVm[]): AvailableQualificationDTO[] => {
  const av: AvailableQualificationDTO[] = [];
  for (const element of tenantQualifications) {
    av.push({ ...element.qualification, ...element });
  }
  return av;
};

const mapConnectedCompanyFunctions = (
  tenant: ITenantDTO,
  tenantCompanyFunctions: ITenantCompanyFunctionDTO[],
  departmentPositions: IDepartmentPositionWithChildrenDTO[],
  companyFunctions: ICompanyFunctionDTO[],
) => {
  const items: CompanyFunctionsConnectedToTenant[] = [];
  const connectedDepartmentPositions = departmentPositions.filter((x) =>
    tenant.departmentPositions?.map((c) => c.id).includes(x.id),
  );
  const cfForPositions = DepartmentPositionFitnessService.getUniqueCompanyFunctions(
    connectedDepartmentPositions,
    companyFunctions,
  );
  for (const element of cfForPositions) {
    const cfConnected: CompanyFunctionsConnectedToTenant = { isManuallyConnected: false, ...element };
    items.push(cfConnected);
  }

  for (const element of tenantCompanyFunctions) {
    // Only add company functions that are not included by positions
    if (!cfForPositions.map((x) => x.id).includes(element.companyFunctionId)) {
      const selectedCf = companyFunctions.find((x) => x.id == element.companyFunctionId);
      if (selectedCf) {
        const objToAdd: CompanyFunctionsConnectedToTenant = {
          isManuallyConnected: element.isManuallyConnected,
          ...selectedCf,
        };
        items.push(objToAdd);
      } else {
        console.log(`CompanyFunction not found #${element.companyFunctionId}`);
      }
    }
  }
  return uniqBy(items, (x) => x.id);
};

/**This view model will be created on the client */
export class TenantForQualificationVM {
  constructor(
    tenant: ITenantDTO,
    tenantQualifications: ITenantQualificationVm[],
    tenantCompanyFunctions: ITenantCompanyFunctionDTO[],
    departmentPositions: IDepartmentPositionWithChildrenDTO[],
    companyFunctions: ICompanyFunctionDTO[],
    qualifications: QualificationVm[],
    // entityPermission?: IEntityPermissionDTO,
  ) {
    this.id = tenant.id;
    this.tenant = tenant;
    this.isPrivate = tenant.isPrivate;
    this.entityPermission = tenant.entityPermission;

    this.tenantQualifications = tenantQualifications;
    this.tenantCompanyFunctions = tenantCompanyFunctions;
    this.qualificationsRequired = getRequiredQualifications(
      tenant,
      departmentPositions,
      companyFunctions,
      tenantCompanyFunctions,
      qualifications,
    );

    this.qualificationsAvailable = mapAvailableQualifications(tenantQualifications);
    this.connectedCompanyFunctions = mapConnectedCompanyFunctions(
      tenant,
      tenantCompanyFunctions,
      departmentPositions,
      companyFunctions,
    );
    this.qualificationsMissing = this._getMissingQualifications;
    this.primaryDepartmentPosition = this._getPrimaryPosition;
    this.departmentPositions = tenant.departmentPositions ?? [];
    this.createdAt = tenant.createdAt;
    this.queriedAt = DateTime.now().toISO();
  }
  /**@deprecated
   * TenantId */
  id: number;
  tenant: ITenantDTO;
  /**All qualifications that the tenant has */
  tenantQualifications: ITenantQualificationVm[];
  /**Qualifications that the tenant owns */
  qualificationsAvailable: AvailableQualificationDTO[];
  /**Qualifications that the tenant must own*/
  qualificationsRequired: IQualificationDTO[];
  tenantCompanyFunctions: ITenantCompanyFunctionDTO[];
  connectedCompanyFunctions: CompanyFunctionsConnectedToTenant[];
  departmentPositions: IDepartmentPositionForTenantDTO[];

  isPrivate: boolean;
  entityPermission?: IEntityPermissionDTO;
  capabilities: ICapabilitiesDTO;

  /** Qualifications that are required but not available */
  qualificationsMissing: IQualificationDTO[];
  createdAt: DateTime;
  queriedAt: string;
  private get _getMissingQualifications() {
    return this.qualificationsRequired.filter(
      (x) =>
        !this.qualificationsAvailable
          .filter((q) => !q.isExpired)
          .map((tq) => tq.id)
          .includes(x.id),
    );
  }

  primaryDepartmentPosition?: IDepartmentPositionForTenantDTO;

  private get _getPrimaryPosition() {
    return this.tenant.departmentPositions?.find((x) => x.isPrimary);
  }
}
