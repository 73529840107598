import { WorkflowConditionType } from "@generatedCode/pbd-core/pbd-core-api";

export interface IWorkflowConditionDropdown {
  value: WorkflowConditionType;
  label: string;
}

export const AvailableWorkflowConditions: IWorkflowConditionDropdown[] = [
  {
    value: WorkflowConditionType.ValueOfNotEquals,
    label: "The value of ... (not equals)",
  },
  {
    value: WorkflowConditionType.ValueOfEquals,
    label: "The value of ... (equals)",
  },
  {
    value: WorkflowConditionType.CurrentUserIsNotMemberOfRole,
    label: "Current user is not member of role",
  },
  {
    value: WorkflowConditionType.CurrentUserIsMemberOfRole,
    label: "Current user is member of role",
  },
];
