import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { CategoryCreateDTO, ICategoryCreateDTO, ICategoryDTO } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { useRefreshHook } from "../../../shared/hooks/useRefreshHook";
import BaseSettingsForm, { hasId } from "../../components/editPage/baseSettingsForm";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

function EditPageAbsenceType() {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const { absenceTypesApi } = useAPIs();
  const [loading, setLoading] = React.useState(true);
  const [itemToUpdate, setItemToUpdate] = React.useState<ICategoryDTO>();
  const [{ refresh, handleRefresh }] = useRefreshHook();

  const { data: existingAbsenceTypes } = useSWR(`absenceTypes`, () =>
    absenceTypesApi.getAllQuery({ isDeleted: false }),
  );

  React.useEffect(() => {
    async function getData() {
      const category = await absenceTypesApi.getById(Number(id));
      setItemToUpdate(category);
    }
    if (id != null) {
      getData().then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [id, refresh]);

  function handleSubmit(itemToCreate: ICategoryCreateDTO) {
    if (id) {
      return absenceTypesApi.edit(Number(id), new CategoryCreateDTO(itemToCreate));
    } else {
      return absenceTypesApi.create(new CategoryCreateDTO(itemToCreate));
    }
  }

  async function toggleDelete() {
    if (!itemToUpdate) throw Error("Item missing");
    if (itemToUpdate.isDeleted) {
      await absenceTypesApi.restore(itemToUpdate.id);
    } else {
      await absenceTypesApi.delete(itemToUpdate.id);
    }
    handleRefresh();
  }

  const handleSuccess = (dto?: unknown) => {
    if (hasId(dto)) {
      navigate(SettingsRoutePaths.EditPageAbsenceType.replace(":id", dto.id.toString()));
    } else {
      handleRefresh();
    }
  };

  return (
    <>
      {!loading && (
        <BaseSettingsForm
          itemToUpdate={itemToUpdate}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          additionalFields={["Color"]}
          onDelete={toggleDelete}
          onRestore={(tId) => absenceTypesApi.restore(tId)}
          handleRefresh={handleRefresh}
          existingItems={existingAbsenceTypes}
          formType={"AbsenceType"}
        />
      )}
    </>
  );
}

export default EditPageAbsenceType;
