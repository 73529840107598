import i18next from "i18next";
import * as yup from "yup";

import {
  CustomFieldCreateDTO,
  CustomFormCreateDTO,
  CustomFormUsageType,
  ICustomFormCreateDTO,
  ICustomFormDTO,
  ICustomFormsControllerClient,
} from "@generatedCode/pbd-core/pbd-core-api";

import { CopyForm } from "../../pbdServices/services/copy/copyService";
import { requiredTitleStringSchema } from "../validation/stringSchemas";

export default class CustomFormService {
  private customFormsApi: ICustomFormsControllerClient;
  constructor(customFormsApi: ICustomFormsControllerClient) {
    this.customFormsApi = customFormsApi;
  }

  copy(oldValues: ICustomFormDTO, newValues: Partial<CopyForm>) {
    const createForm = new CustomFormCreateDTO({
      ...oldValues,
      title: newValues.title ?? `Copy of ${oldValues.title}`,
      customFields: oldValues.customFields.map(
        (x) =>
          new CustomFieldCreateDTO({
            title: x.name,
            type: x.type,
            optionalHelpText: x.optionalHelpText,
            choices: x.options?.map((x) => x.name),
            isRequired: x.isRequired,
            gradingBases: x.gradingBases,
            customFieldInfo: x.customFieldInfo,
          }),
      ),
      isQuiz: oldValues.evaluationSchema?.isQuiz ?? false,
    });
    if (!newValues.options?.description) {
      createForm.description = "";
    }
    return this.customFormsApi.create(createForm);
  }

  static getTotalPoints(item: ICustomFormDTO): number {
    return item.customFields.reduce((pv, cv) => pv + (cv.gradingBases?.point ?? 0), 0);
  }

  static get initialValues(): ICustomFormCreateDTO {
    return {
      title: "",
      customFormUsageType: CustomFormUsageType.Internal,
      isQuiz: false,
      accessOnlyOnInvite: false,
      editableAfterSubmit: false,
      uniqueSubmitPerTenant: false,
      submitterCanSeeResults: false,
    };
  }

  static get validationSchema() {
    // Error goes away in non strict mode //@ts-expect-error TODO: FIx with better typings.
    const fieldValidation: yup.ObjectSchema<Pick<ICustomFormCreateDTO, "customFields">> = yup.object({
      customFields: yup.array().notRequired(),
    });

    const ValidationSchema: yup.ObjectSchema<ICustomFormCreateDTO> = yup
      .object({
        title: requiredTitleStringSchema(i18next.t),
        description: yup.string(),
        customFormUsageType: yup.mixed<CustomFormUsageType>().oneOf(Object.values(CustomFormUsageType)).required(),
        responsibleId: yup.number(),
        isQuiz: yup.boolean().required(),
        totalPoints: yup.number(),
        percentToPass: yup.number(),
        accessOnlyOnInvite: yup.boolean().required(),
        editableAfterSubmit: yup.boolean().required(),
        uniqueSubmitPerTenant: yup.boolean().required(),
        submitterCanSeeResults: yup.boolean().required(),
      })
      .concat(fieldValidation);
    return ValidationSchema;
  }
}
