import { Badge, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ITenantDetailsDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { ExternalIdSettings } from "../../../../Models/Settings/ExternalIdSettings";
import TenantRoutePaths from "../../../tenants/tenantRoutePaths";
import { qmBaseIcons } from "../icons/qmBaseIcons";
import ExternalIdComponent from "../id/externalIdComponent";
import IdComponent from "../id/idComponent";
import AvatarSpanWithName from "../tenants/avatarSpanWithName";

interface IProps {
  item: ITenantDetailsDTO;
  externalIdSetting?: ExternalIdSettings;
}

function TenantHoverCardContent(props: IProps) {
  const { t } = useTranslation();
  const { item, externalIdSetting } = props;
  return (
    <>
      <Popover.Header as="h3">
        {item.fullName} <IdComponent id={item.id} externalIdSettings={externalIdSetting} />
        {item.externalId && externalIdSetting && (
          <ExternalIdComponent externalId={item.externalId} externalIdSettings={externalIdSetting} />
        )}
      </Popover.Header>
      <Popover.Body>
        <AvatarSpanWithName tenant={item} includePopover={false} showNotificationBell />
        <div>
          {item.isEmployee && (
            <Badge className="me-1" pill bg="secondary">
              {t("Employee")}
            </Badge>
          )}
          {item.applicationUserId && (
            <Badge className="me-1" pill bg="secondary">
              {t("User")}
            </Badge>
          )}
        </div>
        {item.departmentPositions && item.departmentPositions.length > 0 && (
          <>
            <h6>{t("Positions")}</h6>
            <ul>
              {item.departmentPositions.map((x) => (
                <li key={x.id}>
                  {x.isPrimary && <qmBaseIcons.Star title={t("Primary")} />} {x.title} {x.department?.title}
                </li>
              ))}
            </ul>
          </>
        )}
        <Link to={TenantRoutePaths.EditPageFn(item)}>{t("View profile")}</Link>
      </Popover.Body>
    </>
  );
}
export default TenantHoverCardContent;
