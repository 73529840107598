import React from "react";
import { useTranslation } from "react-i18next";
import { GroupBase, MultiValue, OptionProps, SingleValue, components } from "react-select";
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";
import { Badge, FormText } from "reactstrap";

import {
  IOrganisationMinDTO,
  ITenantDTO,
  ITenantMinDTO,
  TenantsQueryField,
} from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../../../pbdServices/services/service-context";

import { GlobalQmBaseConstants } from "../../../../../Constants/GlobalQmBaseConstants";
import StringHelpers from "../../../../../Helpers/StringHelpers";
import { TenantQueryParameters } from "../../../../../pbdServices/services/Tenants/models/query-parameters";
import { isTenant } from "../../../../../pbdServices/services/Tenants/tenant-type-guard";
import { convertRoleToHumanReadable } from "../../../../../services/Authz/authService";
import { useAppContext } from "../../../contexts/appContext";
import { qmBaseIcons } from "../../icons/qmBaseIcons";
import IdComponent from "../../id/idComponent";
import ActiveFilterBadge from "../../tables/toolbar/activeFilterBadge";
import NoUserComponent from "../../tenants/noUserComponent";
import { Additional } from "./models/additional";
import { getValue } from "./reactSelectHelpers";

type LocalType = ITenantMinDTO | ITenantDTO;

type FilterKeys = Extract<keyof TenantQueryParameters, string>;

interface IProps {
  onChange: (item?: ITenantDTO | ITenantDTO[]) => void;
  selected?: ITenantMinDTO | (ITenantDTO | ITenantMinDTO)[];
  // selected?: ITenantDTO | (ITenantDTO )[];
  isMulti?: boolean;
  isClearable?: boolean;
  organisationToFilterBy?: IOrganisationMinDTO;
  /**
   * default query: { isEmployee:true, fields:[TenantsQueryField.DepartmentPositions]}
   */
  query?: TenantQueryParameters;
  isReadonly?: boolean;
  defaultValue?: LocalType;
  inputId?: string;
}

function TenantSelectAsync(props: IProps) {
  const { defaultValue, onChange, organisationToFilterBy, inputId } = props;
  const { meAsUser } = useAppContext();
  const { tenantsApi } = useAPIs();
  const { userSettingsService } = useAPIServices();
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<ITenantMinDTO | (ITenantDTO | ITenantMinDTO)[]>();
  const [localQuery, setLocalQuery] = React.useState<TenantQueryParameters | undefined>(props.query);

  const removeInput = (key: FilterKeys) => {
    setLocalQuery((current) => {
      if (current && current[key]) {
        const { [key]: _, ...rest } = current;

        return rest;
      } else {
        return current;
      }
    });
  };

  const getQueryOrderBy = userSettingsService.getPersonDisplayNameSortOrder(meAsUser).toString().split("_")[0];

  const loadOptions: LoadOptions<LocalType, GroupBase<LocalType>, Additional> = async (
    search,
    loadedOptions,
    additional,
  ) => {
    let tenantQuery: TenantQueryParameters = {};
    if (localQuery) {
      tenantQuery = localQuery;
    } else {
      tenantQuery.isEmployee = true;
    }
    tenantQuery.sortBy = getQueryOrderBy;
    tenantQuery.pageSize = GlobalQmBaseConstants.DefaultPageSize;
    tenantQuery.page = additional?.page;
    tenantQuery.q = search;
    if (!tenantQuery.fields) {
      tenantQuery.fields = [TenantsQueryField.DepartmentPositions, TenantsQueryField.ApplicationUser];
    }

    const resp = await tenantsApi.getAllQuery(tenantQuery);
    return {
      options: resp.data,
      hasMore: resp.pagination.hasNext,
      additional: {
        page: (additional?.page ?? 0) + 1,
      },
    };
  };

  React.useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  function handleChange(newValue: MultiValue<ITenantDTO | ITenantMinDTO> | SingleValue<ITenantDTO | ITenantMinDTO>) {
    if (newValue == null) {
      setSelected([]);
      onChange(undefined);
    } else {
      const selected2 = newValue as ITenantDTO | ITenantDTO[];
      setSelected(selected2);
      onChange(selected2);
    }
  }

  return (
    <>
      <AsyncPaginate
        inputId={inputId}
        key={JSON.stringify(localQuery)}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        cacheUniqs={organisationToFilterBy == null ? ["1"] : [organisationToFilterBy.id]}
        menuPosition="fixed"
        debounceTimeout={GlobalQmBaseConstants.DebounceMs}
        value={selected}
        defaultValue={defaultValue}
        loadOptions={loadOptions}
        additional={{
          page: 1,
        }}
        onChange={handleChange}
        getOptionLabel={getLabel}
        getOptionValue={getValue}
        placeholder={t("Please select") + "..."}
        isClearable={props.isClearable}
        isMulti={props.isMulti}
        isDisabled={props.isReadonly}
        // eslint-disable-next-line @typescript-eslint/naming-convention
        components={{ Option: CustomOption }}
      />
      <FilteredByComponent apiQuery={localQuery} organisation={organisationToFilterBy} onRemoveFilter={removeInput} />
    </>
  );
}

const CustomOption = (props: OptionProps<ITenantDTO | ITenantMinDTO>) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      {data.fullName} <IdComponent id={data.id} /> <NoUserComponent item={data} />
      {isTenant(data) && data.primaryDepartmentPosition && (
        <span>
          <br />
          <small>
            {data.primaryDepartmentPosition.title} - {data.primaryDepartmentPosition.department.title}
          </small>
        </span>
      )}
    </components.Option>
  );
};

export function FilteredByComponent(props: {
  apiQuery?: TenantQueryParameters;
  organisation?: IOrganisationMinDTO;
  onRemoveFilter?: (key: FilterKeys) => void;
}) {
  const { apiQuery, organisation, onRemoveFilter } = props;
  const { t } = useTranslation();
  if (!apiQuery && !organisation) return null;

  const filterTextOrg = organisation ? (
    <Badge className="me-1">
      {t("Organisation")}:{organisation.title}
    </Badge>
  ) : (
    ""
  );
  const filterTextRole = apiQuery?.roleName ? (
    <>
      <div className="mb-2">
        <Badge pill>
          {t("Role")}: {convertRoleToHumanReadable(apiQuery.roleName, t)}{" "}
          <qmBaseIcons.Lock title={t("Required permission")} />
        </Badge>
      </div>
      {apiQuery.applicationUserGroupId && apiQuery.applicationUserGroupId.length > 0 && (
        <div className="d-flex gap-2">
          <ActiveFilterBadge
            filterKey="applicationUserGroupId"
            values={apiQuery.applicationUserGroupId}
            //@ts-expect-error TODO: Fix with better typings. PP 20240310
            onClose={onRemoveFilter}
          />
        </div>
      )}
    </>
  ) : (
    ""
  );
  if (!filterTextOrg && !filterTextRole) return null;
  return (
    <>
      <FormText color="muted">{t("Filtered by")}:</FormText> {filterTextOrg} {filterTextRole}
    </>
  );
}

function getLabel(option: ITenantMinDTO) {
  if (StringHelpers.isNullOrWhitespace(option.fullName)) {
    return `#${option.id.toString()}`;
  } else {
    return `#${option.id} ${option.fullName}`;
  }
}

interface IPropsMulti extends Omit<IProps, "onChange"> {
  onChange: (item?: ITenantDTO[]) => void;
}

export function TenantSelectAsyncMulti(props: IPropsMulti) {
  const handleChange = (item?: ITenantDTO | ITenantDTO[] | undefined) => {
    if (!item) {
      return props.onChange(undefined);
    } else if (Array.isArray(item)) {
      return props.onChange(item);
    } else {
      return props.onChange([item]);
    }
  };

  return <TenantSelectAsync {...props} onChange={handleChange} isMulti />;
}

export default TenantSelectAsync;
