import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { CompanyFunctionQueryField } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import SwrApiHelpers from "../../../pbdServices/services/Api/swr-api-helpers";
import { QualificationMatrixRoutePaths } from "../../qualificationMatrix/qualificationMatrixRoutePaths";
import { LoadingComponent } from "../../shared/components/loading/loadingComponent";
import { useLocalStorage } from "../../shared/hooks/useLocalStorage";
import { useTypedParams } from "../../shared/hooks/useTypedParams";
import JobDescriptionPrintout from "../components/jobDescriptionPrintout";
import PrintoutIncludeOptions, { PrintOptions } from "../components/printoutIncludeOptions";
import QrCodeContainer from "../components/qrCodeContainer";

const PrintJobDescription = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { departmentPositionsApi, companyFunctionsApi } = useAPIs();
  const [options, setOptions] = useLocalStorage<PrintOptions>("printOptions", new PrintOptions());
  const { id } = useTypedParams(["id"]);

  const { data: departmentPositionResp } = useSWR(
    "/api/departmentPositions/" + id,
    () => departmentPositionsApi.getById(Number(id)),
    SwrApiHelpers.getDefaultGetOptions(navigate),
  );
  const { data: cfResp } = useSWR(`/api/companyFunctions/?departmentPositionsId=${Number(id)}`, () =>
    companyFunctionsApi.getAllQuery({
      departmentPositionId: [Number(id)],
      fields: [CompanyFunctionQueryField.Qualifications],
    }),
  );

  const handleOptionsChange = (value: PrintOptions) => {
    setOptions(value);
  };

  if (!departmentPositionResp || !cfResp) return <LoadingComponent />;

  return (
    <>
      <PrintoutIncludeOptions
        availableOptions={["showQrCode", "showJobholderSignature", "showResponsibleSignature"]}
        onChange={handleOptionsChange}
        initialOptions={options}
      />
      <h2>{t("Job description")}</h2>
      <hr />
      <JobDescriptionPrintout departmentPosition={departmentPositionResp} requiredCompanyFunctions={cfResp} />
      {options.showQrCode && (
        <QrCodeContainer url={QualificationMatrixRoutePaths.DetailsPageJobDescriptions.replace(":id", id)} />
      )}
      <div className="d-flex justify-content-between">
        {options.showResponsibleSignature && (
          <div className="mt-5">
            <h4 className="border-top">
              {t("Date")}, {t("Responsible signature")}
            </h4>
          </div>
        )}
        {options.showJobholderSignature && (
          <div className="mt-5">
            <h4 className="border-top">
              {t("Date")}, {t("Job holder signature")}
            </h4>
          </div>
        )}
      </div>
    </>
  );
};

export default PrintJobDescription;
