import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import FeatureFlagService from "../../../pbdServices/services/FeatureFlags/featureFlagService";
import { hasRole } from "../../../services/Authz/authService";
import { PbdRoles } from "../../../services/Authz/PbdRoles";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { useAppContext } from "../../shared/contexts/appContext";
import { QualityMonitorRoutePaths } from "../qualityMonitorRoutePaths";

/**
 * QualityMonitor Sidebar
 */
function SidebarQualityMonitor() {
  const { availableModules, meAsUser } = useAppContext();
  const { t } = useTranslation();
  const modulesForQMonitor = availableModules
    .sort((a, b) => a.displayName.localeCompare(b.displayName))
    .filter((x) => x.appInfo?.dashboard);
  const links: SidebarItem[] = [];
  if (FeatureFlagService.isOverviewQMonitorAvailable() && hasRole(meAsUser, [PbdRoles.Dev])) {
    links.push({
      id: "all",
      title: t("All"),
      icon: qmBaseIcons.Atlas,
      href: QualityMonitorRoutePaths.All,
    });
  }

  links.push(SidebarItem.costs(QualityMonitorRoutePaths.Costs, t));
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.ChartLine,
      title: t("Dashboards"),
      href: QualityMonitorRoutePaths.IndexPageDashboard,
      newSince: DateTime.fromISO("2023-03-20"),
    }),
  );
  modulesForQMonitor.forEach((x, i) =>
    links.push(
      new SidebarItem({
        title: x.displayName,
        href: x.appInfo?.dashboard,
      }),
    ),
  );
  return <SidebarNew app={PbdModule.QualityMonitor} items={links} />;
}

export default SidebarQualityMonitor;
