import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Card, CardBody, FormGroup, ListGroup, ListGroupItem } from "reactstrap";
import useSWR from "swr";

import { ITagSettings, Setting } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import FeatureFlagService from "../../../../pbdServices/services/FeatureFlags/featureFlagService";
import { SettingKeyNames } from "../../../../services/Settings/SettingKeyNames";
import CostTemplateConfigurationComponent from "../../components/costTemplateConfigurationComponent";

const GeneralConfigurationPage = () => {
  const { t } = useTranslation();
  const { settingsApi } = useAPIs();
  const { data: tagSettings } = useSWR("/api/settings/tagSettings", () => settingsApi.getTagSettings());

  const handleSave = async (dto: ITagSettings) => {
    await wrapApiCallWithToast(() =>
      settingsApi.edit(
        SettingKeyNames.TagSettings,
        new Setting({
          key: SettingKeyNames.TagSettings,
          value: JSON.stringify(dto),
        }),
      ),
    );
  };

  return (
    <>
      <h2>{t("General")}</h2>
      <hr />
      <Card className="mb-3">
        <CardBody>
          <h4>{t("Tags")}</h4>
          <ListGroup className="mb-3">
            <ListGroupItem>
              <FormGroup>
                {tagSettings && (
                  <Form.Check
                    type="switch"
                    id="createTagsInSettingsOnly"
                    defaultChecked={tagSettings.restrictCreation}
                    onChange={(y) =>
                      handleSave({
                        ...tagSettings,
                        restrictCreation: y.target.checked,
                      })
                    }
                    label={t("New tags can be created only in settings")}
                  />
                )}
              </FormGroup>
            </ListGroupItem>
          </ListGroup>

          {FeatureFlagService.isCostTemplateAvailable() && <CostTemplateConfigurationComponent />}
        </CardBody>
      </Card>
    </>
  );
};

export default GeneralConfigurationPage;
