import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { IProductConfigInterface } from "../../../Constants/productConfig";
import { AppVM } from "../../../pbdServices/Models/App/AppVM";

import { useCurrentAppHook } from "./useCurrentAppHook";

export const usePageTitle = (availableModules?: AppVM[], productConfig?: IProductConfigInterface) => {
  const { currentApp } = useCurrentAppHook();
  const title =
    currentApp != PbdModule.None ? availableModules?.find((x) => x.type == currentApp)?.displayName : undefined;

  const siteTitle = productConfig?.productDisplayName;
  const titleDelimiter = "|";
  return title?.trim().length ? `${title.trim()} ${titleDelimiter} ${siteTitle}` : siteTitle;
};
