import React from "react";
import { useTranslation } from "react-i18next";
import Select, { MultiValue, SingleValue } from "react-select";
import useSWRImmutable from "swr/immutable";

import { ICustomField, PbdModule } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../../pbdServices/services/service-context";

interface IProps {
  onChange: (id?: string[] | string) => void;
  module: PbdModule;
  selected?: string[];
  isMulti?: boolean;
  isClearable?: boolean;
  options?: ICustomField[];
  invalid?: boolean;
}

function CustomFieldSelect(props: IProps) {
  const { selected, invalid, onChange, isMulti, isClearable, module, options } = props;
  const { t } = useTranslation();
  const { customFieldsApi } = useAPIs();
  const { data: resp } = useSWRImmutable(options ? null : [`/api/customFields/`, module], () =>
    customFieldsApi.getCustomFieldsByModule(module).then((resp) => resp.sort((a, b) => a.name.localeCompare(b.name))),
  );

  const data = React.useMemo(() => {
    const array = options ?? resp ?? [];
    // This is needed to make the list display in react-select. The options property leads to errors if not removed. Because it assumes a nested list of options
    return array.map(({ options, ...keepAttrs }) => keepAttrs);
  }, [options, resp]);

  const defaultValue = React.useMemo(() => {
    if (selected && resp) {
      const def: ICustomField[] = [];
      for (const element of selected) {
        const opt = resp.find((x) => x.id == element);
        if (opt) {
          def.push(opt);
        }
      }
      return def;
    } else {
      return undefined;
    }
  }, [resp, selected]);

  function handleChange(newValue: SingleValue<ICustomField> | MultiValue<ICustomField>) {
    if (newValue == null) {
      onChange(undefined);
    } else if (Array.isArray(newValue)) {
      onChange(newValue.map((x) => (x as ICustomField).id));
    } else {
      const item = newValue as ICustomField;
      onChange(item.id);
    }
  }

  const key = JSON.stringify(selected?.join() ?? "" + defaultValue?.map((x) => x.id).join());

  if (!resp && !options) return null;

  return (
    <Select
      key={key}
      styles={{
        control: (base) => ({
          ...base,
          border: 0,
          // This line disables the blue border
          boxShadow: "none",
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      }}
      onChange={handleChange}
      placeholder={`${t("Select")}...`}
      isClearable={isClearable}
      menuPosition="fixed"
      defaultValue={defaultValue}
      options={data}
      isMulti={isMulti}
      getOptionLabel={getLabel}
      className={invalid ? "form-control is-invalid p-0 pr-3" : "form-control p-0 pr-3"}
      getOptionValue={getValue}
    />
  );
}

const getLabel = (option: ICustomField) => {
  if (option.name != "") {
    return option.name;
  } else {
    return `#${option.id.toString()}`;
  }
};

const getValue = (option: ICustomField) => option.id.toString();

export default CustomFieldSelect;
