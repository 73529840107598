import { ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWRImmutable from "swr/immutable";

import { HealthStatus } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIServices } from "../../../pbdServices/services/service-context";

import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import ExternalLink from "../../shared/components/links/externalLink";
import { StartpageRoutePaths } from "../startpageRoutePaths";

function StatusEntry() {
  const { t } = useTranslation();
  const { settingsService } = useAPIServices();

  const { data, error } = useSWRImmutable("/api/health", () => settingsService.getHealth());

  if (!data || data.status == HealthStatus.Healthy) return null;
  return (
    <ListGroup.Item title={t("Status degraded")} className="bg-body-tertiary">
      <qmBaseIcons.Alert color="warning" className="me-1" />
      <ExternalLink href={StartpageRoutePaths.StatusPage} label={t("Status Monitor")} />
    </ListGroup.Item>
  );
}
export default StatusEntry;
