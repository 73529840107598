import { Form as FormFormik, Formik } from "formik";
import { TFunction } from "i18next";
import React from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import * as yup from "yup";

import {
  ForgotPasswordRequest,
  IForgotPasswordRequest,
  IForgotPasswordResponse,
  UserIdentifier,
} from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import { nameofFactory } from "../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import GenericAlert from "../../shared/components/alerts/genericAlert";
import ButtonLink from "../../shared/components/buttons/buttonLink";
import { FormikTextInputGroup } from "../../shared/components/forms/formik/formikTextInput";
import { AccountRoutePaths } from "../accountRoutePaths";
import CardFooterAccount from "../components/cardFooterAccount";
import LoginValidationSummary from "../components/loginValidationSummary";

const nameof = nameofFactory<IForgotPasswordRequest>();

const getValidationSchema = (t: TFunction) => {
  const ValidationSchema: yup.ObjectSchema<IForgotPasswordRequest> = yup.object({
    userName: yup.string().required(t("This field is required")).max(100),
  });
  return ValidationSchema;
};

function ForgotPasswordPage() {
  const { t } = useTranslation();
  const { accountApi } = useAPIs();
  const [success, setSuccess] = React.useState(false);
  const [userName, setUserName] = useQueryParam("userName", StringParam);
  const navigate = useNavigate();

  const submitter = useFormikAPISubmitter<IForgotPasswordRequest, IForgotPasswordResponse>(
    (values) => accountApi.forgotPassword(new ForgotPasswordRequest(values)),
    [accountApi],
    (resp) => {
      if (resp.userIdentifier == UserIdentifier.UserName) {
        navigate(AccountRoutePaths.ResetPasswordFn(resp.userName));
      } else {
        setSuccess(true);
      }
    },
  );

  const initialValues: IForgotPasswordRequest = { userName: userName ?? "" };

  return (
    <Card className="accountCard">
      <Card.Body>
        <h1 className="text-center">{t("Forgot password?")}</h1>
        <hr />
        <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={getValidationSchema(t)}>
          {(formikBag) => (
            <FormFormik>
              <LoginValidationSummary formikBag={formikBag} />
              <FormikTextInputGroup name={nameof("userName")} label={t("Email or user name")} />
              {success ? (
                <GenericAlert type={"success"}>
                  <p>
                    {t("The instruction has been send to you.")} {t("Please check your inbox.")}
                  </p>
                </GenericAlert>
              ) : (
                <div className="d-grid gap-3 mb-3">
                  <Button variant="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
                    {formikBag.isSubmitting ? t("Loading...") : t("Reset password")}
                  </Button>
                </div>
              )}
            </FormFormik>
          )}
        </Formik>
        <div className="text-center">
          <ButtonLink variant="link" to={AccountRoutePaths.Login}>
            {t("Back to login")}
          </ButtonLink>
        </div>
        <CardFooterAccount />
      </Card.Body>
    </Card>
  );
}

export default ForgotPasswordPage;
