import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GroupBase, MultiValue, SingleValue } from "react-select";
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";

import { IAddressDTO, IAddressRoomDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { GlobalQmBaseConstants } from "../../../../../Constants/GlobalQmBaseConstants";
import { useAPIs } from "../../../../../pbdServices/services/service-context";

import GenericSelect, { OptionData, mapToOptionData } from "./genericSelect";
import { Additional } from "./models/additional";
import { getValue } from "./reactSelectHelpers";

const getLabel = (option: IAddressDTO) => {
  if (option.fullAddress) {
    return option.fullAddress;
  } else {
    return `#${option.id.toString()}`;
  }
};

function mapToRooms(input: IAddressDTO | IAddressDTO[]): IAddressRoomDTO[] {
  if (Array.isArray(input)) {
    const room = input.filterMap((x) => x.addressRooms);
    const result = room.reduce((pv, cv) => pv.concat(cv), []);
    return result;
  } else {
    return input.addressRooms ?? [];
  }
}

interface IProps {
  value?: IAddressDTO | IAddressDTO[];
  room?: IAddressRoomDTO | IAddressRoomDTO[];
  onChange?: (addresses?: IAddressDTO[], rooms?: IAddressRoomDTO[]) => void;
  includeRoomSelect?: boolean;
  isMulti?: boolean;
  inputId?: string;
}

function AddressSelectAsync(props: IProps) {
  const { value, onChange, includeRoomSelect = true, room, isMulti, inputId } = props;
  const { t } = useTranslation();
  const [selectedAddress, setSelectedAddress] = React.useState<IAddressDTO[]>();
  const [selectedRoom, setSelectedRoom] = React.useState<IAddressRoomDTO[]>();
  const { addressesApi } = useAPIs();

  React.useEffect(() => {
    async function getData() {
      if (value) {
        const valueAsArray = Array.isArray(value) ? value : [value];
        const valueFromApi = await addressesApi.getAllQuery({ id: valueAsArray.map((x) => x.id) });
        setSelectedAddress(valueFromApi.data);
      } else {
        setSelectedAddress(undefined);
      }
    }
    getData();
  }, [value]);

  React.useEffect(() => {
    if (room) {
      setSelectedRoom(Array.isArray(room) ? room : [room]);
    } else {
      setSelectedRoom(undefined);
    }
  }, [room]);

  const handleChangeRooms = (dto?: OptionData[] | undefined) => {
    if (!dto) {
      setSelectedRoom(undefined);
      onChange?.(selectedAddress, undefined);
    } else {
      const myRooms = selectedAddress
        ?.filterMap((x) => x.addressRooms)
        .reduce((pv, cv) => pv.concat(cv), [])
        .filter((f) => dto.map((x) => x.value).includes(f.id.toString()));

      setSelectedRoom(myRooms);

      onChange?.(selectedAddress, myRooms);
    }
  };

  const handleChange = (dto?: MultiValue<IAddressDTO> | SingleValue<IAddressDTO>) => {
    if (!dto) {
      setSelectedAddress(undefined);
      onChange?.(undefined, selectedRoom);
    } else if (Array.isArray(dto)) {
      setSelectedAddress(dto);
      onChange?.(dto, selectedRoom);
    } else {
      onChange?.([dto as IAddressDTO], selectedRoom);
      setSelectedAddress([dto as IAddressDTO]);
    }
  };

  const loadOptions: LoadOptions<IAddressDTO, GroupBase<IAddressDTO>, Additional> = async (
    search,
    loadedOptions,
    additional,
  ) => {
    const response = await addressesApi.getAllQuery({
      q: search,
      page: additional?.page,
      pageSize: GlobalQmBaseConstants.DefaultPageSize,
      sortBy: "Line1",
    });

    return {
      options: response.data,
      hasMore: response.pagination.hasNext,
      additional: {
        page: (additional?.page ?? 0) + 1,
      },
    };
  };
  return (
    <>
      <AsyncPaginate
        inputId={inputId}
        menuPosition="fixed"
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        debounceTimeout={GlobalQmBaseConstants.DebounceMs}
        value={selectedAddress}
        loadOptions={loadOptions}
        onChange={handleChange}
        getOptionLabel={getLabel}
        getOptionValue={getValue}
        additional={{
          page: 1,
        }}
        isClearable
        isMulti={isMulti}
      />
      {(isMulti ?? includeRoomSelect) && selectedAddress && (
        <Form.Group className="mb-3">
          {mapToRooms(selectedAddress).length > 0 ? (
            <>
              <Form.Label>{t("Room")}</Form.Label>
              <GenericSelect
                data={mapToOptionData(mapToRooms(selectedAddress))}
                name="AddressRoom"
                onChange={handleChangeRooms}
                selected={selectedRoom?.map((room) => room.id)}
                isMulti={isMulti}
              />
            </>
          ) : (
            <Form.Text>{t("No rooms have been defined for this address")}</Form.Text>
          )}
        </Form.Group>
      )}
    </>
  );
}

export default AddressSelectAsync;
