import React from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { ValidationResult } from "../../../../pbdServices/Models/Shared/validation-result";
import { useSessionStorage } from "../../hooks/useSessionStorage";

import AlertIcon from "./alertIcon";

interface IProps {
  /**@default "info" */
  type?: "danger" | "info" | "success" | "warning";
  heading?: string | React.ReactNode;
  alertIcon?: React.ReactNode;
  description?: string;
  entries?: ValidationResult[];
  dismissible?: boolean;
  className?: string;
  children?: React.ReactNode;
  alertTitle?: string;
  /**This can used to make a one line alert */
  rightColumn?: React.ReactNode;
}

function GenericAlert(props: IProps) {
  const { t } = useTranslation();
  const {
    type = "info",
    heading,
    alertIcon,
    description,
    entries,
    dismissible,
    children,
    className = "mb-3",
    alertTitle,
    rightColumn,
  } = props;
  const location = useLocation();
  const [visible, setVisible] = useSessionStorage(location.pathname, true);

  const onDismiss = () => setVisible(false);

  const alertHeading = React.useMemo(() => {
    if (heading) return heading;
    switch (type) {
      case "danger":
        return t("Error");
      case "info":
        return t("Info");
      case "success":
        return t("Success");
      case "warning":
        return t("Warning");
      default:
        throw Error("Not implemented");
    }
  }, [heading, t, type]);

  return (
    <Alert
      variant={type}
      className={className}
      dismissible={dismissible}
      show={dismissible ? visible : undefined}
      onClose={dismissible ? onDismiss : undefined}
      title={alertTitle}
    >
      {rightColumn ? (
        <div className="d-flex justify-content-between">
          <Alert.Heading as="h4">
            {alertIcon ?? <AlertIcon type={type} />} {alertHeading}
          </Alert.Heading>
          {rightColumn && <div>{rightColumn}</div>}
        </div>
      ) : (
        <Alert.Heading as="h4">
          {alertIcon ?? <AlertIcon type={type} />} {alertHeading}
        </Alert.Heading>
      )}

      {children}
      {description && <p>{description}</p>}
      {entries && (
        <>
          <hr />
          <ul>
            {entries.map((x, i) => (
              <li key={i} title={x.code}>
                {t(x.description)}
              </li>
            ))}
          </ul>
        </>
      )}
    </Alert>
  );
}
export default GenericAlert;
