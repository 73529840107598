import _ from "lodash";

import {
  IArticleRevisionReviewDTO,
  IArticleRevisionReviewsControllerClient,
  ITenantDTO,
  PbdModule,
} from "@generatedCode/pbd-core/pbd-core-api";

import { SearchFilterTypes } from "../../../ClientApp/shared/components/genericSearchFilter/availableSearchFilters";
import { TableClickCommand } from "../../../ClientApp/shared/components/tables/TableClickCommand";
import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import { BaseExportService } from "../Base/BaseExportService";
import { ExportType } from "../Export/exportService";
import { ArticleReviewsTableData } from "./models/ArticleReviewsTableData";
import { ArticleRevisionReviewsQueryParameters } from "./models/query-parameters";

//import { ArticleReviewsTableData } from "./models/ArticleReviewsTableData";

export class ArticleRevisionReviewService extends BaseExportService<IArticleRevisionReviewDTO> {
  api: IArticleRevisionReviewsControllerClient;
  constructor(apiController: IArticleRevisionReviewsControllerClient) {
    super("ArticleRevisionReview");
    this.api = apiController;
  }

  mapToExport(x: IArticleRevisionReviewDTO): ExportType {
    return {
      id: x.id,
      name: x.reviewer?.fullName,
      title: x.articleRevision?.title,
      version: x.articleRevision?.version,
      releaseDate: x.articleRevision?.publishedAt,
    };
  }

  async exportToCsvFromCommand(
    command: TableClickCommand,
    module: PbdModule,
    query?: ArticleRevisionReviewsQueryParameters,
  ): Promise<void> {
    if (!command.isAllSelected && command.selected == undefined) {
      throw Error("Wrong data");
    }

    const pageSize = command.isAllSelected
      ? GlobalQmBaseConstants.DefaultPageSize_DuringMigration
      : command.selected!.length;
    const ids = !command.isAllSelected ? command.selected?.map((x) => Number(x)) : undefined;
    const resp = await this.api.getAllQuery({ ...query, id: ids, pageSize, module });
    this.exportToCsv(resp.data);
  }

  static get availableFilters(): SearchFilterTypes[] {
    return [
      SearchFilterTypes.ReviewerId,
      SearchFilterTypes.PublishedAt,
      SearchFilterTypes.Tags,
      SearchFilterTypes.OnlyLatestRevision,
    ];
  }

  groupByHandler(
    items: IArticleRevisionReviewDTO[],
    tenants: ITenantDTO[],
    groupBy: "Articles" | "Tenants" | string,
  ): ArticleReviewsTableData[] {
    if (groupBy == "Articles") {
      return _.chain(items)
        .countBy((x) => x.articleId)
        .map((val, key) => {
          const articleId = Number(key);
          const article = items.find((a) => a.articleId == articleId);
          return {
            id: articleId,
            articleId,
            pendingReviews: val,
            title: article?.articleRevision?.title ?? "",
          };
        })
        .value();
    } else {
      return _.chain(items)
        .countBy((x) => x.reviewerId)
        .map((val, key) => {
          const tenantId = Number(key);
          const reviewer = items.find((y) => y.reviewerId === tenantId);
          return {
            id: tenantId,
            tenantId,
            pendingReviews: val,
            tenant: reviewer?.reviewer,
            title: reviewer?.reviewer?.fullName ?? "",
          };
        })
        .value();
    }
  }
}
