import i18next from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { Column } from "react-table";

import { IManyToManyConnectionDTO, PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { AppUiData } from "../../../../Constants/app-ui-data";
import { WithOptionalCount } from "../../../../pbdServices/Models/Shared/with-count";
import BaseTableProps from "../tables/BaseTableProps";
import { ReactstrapTable } from "../tables/reactstrapTable";

type TableType = WithOptionalCount<IManyToManyConnectionDTO>;

interface IProps extends BaseTableProps<TableType> {
  queryStringKey?: string;
  queryStringValue?: string;
  localStorageKey: string;
  routePath: string;
}

function ManyToManyConnectionsGroupedTable(props: IProps) {
  const {
    columnsVisibleDefault = ["module", "numberOfConnectionsPerModule"],
    localStorageKey,
    queryStringKey,
    queryStringValue,
    routePath,
  } = props;

  function getEditLink(item: IManyToManyConnectionDTO, title: string) {
    if (queryStringKey && queryStringValue && Object.values(PbdModule).includes(item.module as PbdModule)) {
      const currentModule = item.module as PbdModule;
      const link = AppUiData[currentModule].list;
      if (link) {
        if (!link.startsWith(routePath)) {
          return <a href={`${link}?${queryStringKey}=${queryStringValue}`}>{title}</a>;
        } else {
          return <Link to={`${link}?${queryStringKey}=${queryStringValue}`}>{title}</Link>;
        }
      }
    }
    return <>{title}</>;
  }

  const columns = React.useMemo<Column<TableType>[]>(
    () => [
      {
        id: "module",
        Header: <>{i18next.t("Area")}</>,
        accessor: "module",
        Cell: ({ row }) => <>{getEditLink(row.original, row.original.module ?? "")}</>,
      },
      {
        id: "numberOfConnectionsPerModule",
        Header: <>{i18next.t("Number of connections")}</>,
        accessor: "count",
        Cell: ({ row }) => <>{getEditLink(row.original, (row.original.count ?? "").toString())}</>,
      },
    ],
    [],
  );

  return (
    <ReactstrapTable<TableType>
      columns={columns}
      localStorageStateKey={localStorageKey}
      columnsVisibleDefault={columnsVisibleDefault}
      {...props}
    />
  );
}

export default ManyToManyConnectionsGroupedTable;
