import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { NotificationType } from "./NotificationType";

export class NotificationItem<T = Record<string, any>> {
  title: string;
  description?: string;
  count: number;
  href?: string;
  entries?: T[];
  notificationType?: NotificationType;
  constructor(title: string, href?: string, entries?: T[], notificationType?: NotificationType, description?: string) {
    this.title = title;
    this.href = href;
    this.count = entries?.length ?? 0;
    this.entries = entries;
    this.notificationType = notificationType;
    this.description = description;
  }
}

export class ModuleNotification {
  module: PbdModule;
  totalCount: number;
  notifications: NotificationItem[];
  constructor(module: PbdModule, notifications: NotificationItem[]) {
    this.module = module;
    this.totalCount = notifications.reduce((pv, cv) => cv.count + pv, 0);
    this.notifications = notifications;
  }

  getNotification(type: NotificationType) {
    return this.notifications.find((x) => x.notificationType == type)!;
  }
}
