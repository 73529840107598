import { useState } from "react";
import { useHandleOpenCommandPalette } from "react-cmdk";
import "react-cmdk/dist/cmdk.css";

import { IHelpLinks } from "../../../../Constants/productConfig";
import { AppVM } from "../../../../pbdServices/Models/App/AppVM";
import CommandPaletteComponent from "../../../commandPalette/commandPaletteComponent";

import { SearchBoxNavbarCmdkButton } from "./searchBoxNavbarCmdkButton";

interface IProps {
  available: AppVM[];
  helpLinks: IHelpLinks;
}

function SearchBoxNavbarCmdk(props: IProps) {
  const { available, helpLinks } = props;

  const [isOpen, setIsOpen] = useState(false);

  useHandleOpenCommandPalette(setIsOpen);

  return (
    <>
      <SearchBoxNavbarCmdkButton onClick={() => setIsOpen(true)} />
      <CommandPaletteComponent available={available} helpLinks={helpLinks} isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}
export default SearchBoxNavbarCmdk;
