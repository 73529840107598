import React from "react";
import { useTranslation } from "react-i18next";
import Select, { createFilter, MultiValue, SingleValue } from "react-select";

import { ReactSelectDTO } from "../../../../../Models/ReactSelect/reactSelectDTO";
import { useAPIs } from "../../../../../pbdServices/services/service-context";
import { LoadingComponent } from "../../loading/loadingComponent";

interface IProps {
  onChange: (id?: number | number[]) => void;
  selectedIds?: number[];
  isMulti?: boolean;
  isClearable?: boolean;
}

const ClaimSelect: React.FC<IProps> = (props) => {
  const { onChange, selectedIds, isMulti, isClearable } = props;
  const { claimsApi } = useAPIs();
  const [loading, setLoading] = React.useState(true);
  const [selected, setSelected] = React.useState<number[] | null>(null);
  const [options, setOptions] = React.useState<ReactSelectDTO[]>([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    async function getData() {
      const claims = await claimsApi.getAll();
      const options: ReactSelectDTO[] = [];
      for (const d of claims) {
        options.push({ label: d.title, value: d.id.toString() });
      }
      setOptions(options);
      setLoading(false);
    }
    getData();
  }, []);

  React.useEffect(() => {
    if (selectedIds != null && selectedIds != undefined) {
      setSelected(selectedIds);
    }
  }, [selectedIds]);

  function handleChange(newValue: MultiValue<ReactSelectDTO> | SingleValue<ReactSelectDTO>) {
    if (newValue == null) {
      onChange(undefined);
    } else if (Array.isArray(newValue)) {
      onChange(newValue.map((x) => Number(x.value)));
    } else {
      const option = newValue as ReactSelectDTO;
      onChange(Number(option.value));
    }
  }

  const defaultValue: ReactSelectDTO[] = [];
  if (!loading && selectedIds != null) {
    for (const id of selectedIds) {
      const dv = options.find((x) => x.value == id.toString());
      if (dv != undefined) {
        defaultValue.push(dv);
      }
    }
  }
  return (
    <React.Fragment>
      {loading ? (
        <LoadingComponent />
      ) : (
        <Select
          key={
            defaultValue.length == 0
              ? "claimSelect"
              : selectedIds != undefined
                ? selectedIds[selectedIds.length - 1].toString()
                : 0
          }
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
          })}
          menuPosition="fixed"
          options={options}
          defaultValue={defaultValue.length == 0 ? null : defaultValue}
          isSearchable
          isClearable={isClearable}
          onChange={handleChange}
          placeholder={`${t("Search by name")}...`}
          name="tenantId"
          isMulti={isMulti}
          filterOption={createFilter({ ignoreAccents: false })}
        />
      )}
    </React.Fragment>
  );
};

export default ClaimSelect;
