import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";

import { IWorkflowRule } from "@generatedCode/pbd-core/pbd-core-api";

import IdComponent from "../../../shared/components/id/idComponent";

interface IProps {
  workflowRules: IWorkflowRule[];
}

function WorkflowRenderer(props: IProps) {
  const { workflowRules } = props;
  const { t } = useTranslation();
  return (
    <>
      <h4>
        {t("Existing rules")} ({workflowRules.length})
      </h4>
      {workflowRules.map((x) => (
        <div key={x.id}>
          <h5>
            {x.isActive && (
              <Badge color="success" className="me-2">
                {t("Active")}
              </Badge>
            )}
            {x.title} <IdComponent id={x.id} />
          </h5>
          <h6>
            {t("Conditions")} ({x.conditions.length})
          </h6>
          <pre>{JSON.stringify(x.conditions, null, 2)}</pre>
          <h6>
            {t("Actions")} ({x.actions.length})
          </h6>
          <pre>{JSON.stringify(x.actions, null, 2)}</pre>
        </div>
      ))}
    </>
  );
}

export default WorkflowRenderer;
