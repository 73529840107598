import { Badge, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import FeatureFlagService from "../../../../pbdServices/services/FeatureFlags/featureFlagService";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { hasRole, isInPreview, isInReadOnly } from "../../../../services/Authz/authService";
import { AdminRoutePaths } from "../../../admin/adminRoutePaths";
import { ProfileRoutePaths } from "../../../profile/profileRoutePaths";
import { SettingsRoutePaths } from "../../../settings/settingsRoutePaths";
import { useAppContext } from "../../contexts/appContext";
import { qmBaseIcons } from "../icons/qmBaseIcons";
import { NotificationBellComponent } from "../notificationBellComponent";
import AvatarSpan from "../tenants/avatarSpan";

import { HelpDropdownEntries } from "./helpDropdownEntries";
import { LoggedInDropdownEntries } from "./loggedInDropdownEntries";

export function RightNavbar() {
  const {
    meAsUser,
    productConfig: { helpLinks },
  } = useAppContext();
  const { t } = useTranslation();

  return (
    <>
      {FeatureFlagService.isNotificationAvailable() && <NotificationBellComponent meAsUser={meAsUser} />}
      <NavDropdown title={<qmBaseIcons.Gear />} id="navSettingsDropdown" align="end">
        <NavDropdown.Item as={Link} to={AdminRoutePaths.HomePage}>
          <i className="fa fa-user-md" /> {t("Admin")}
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to={SettingsRoutePaths.Home}>
          <i className="fa fa-cogs" /> {t("Settings")}
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to={ProfileRoutePaths.LanguagePage}>
          <qmBaseIcons.Language /> Language
        </NavDropdown.Item>
      </NavDropdown>
      <NavDropdown title={<qmBaseIcons.QuestionCircle />} id="navQuestionDropdown" align="end">
        <HelpDropdownEntries />
      </NavDropdown>
      {isInReadOnly(meAsUser) && (
        <Nav.Link href={`${helpLinks.homePage.url}?query=read+Only`}>
          <Badge bg="warning">{t("Read only")}</Badge>
        </Nav.Link>
      )}
      <Navbar.Text>
        {isInPreview(meAsUser) && (
          <Badge bg="success" className="me-1" title={t("Preview mode")}>
            P
          </Badge>
        )}
        {hasRole(meAsUser, [PbdRoles.Dev]) && <Badge bg="warning">D</Badge>}
      </Navbar.Text>
      <NavDropdown title={<AvatarSpan tenant={meAsUser.tenant} />} id="navProfileDropdown" align="end">
        <LoggedInDropdownEntries />
      </NavDropdown>
    </>
  );
}
