import useSWRImmutable from "swr/immutable";

import { useAPIs } from "../../../pbdServices/services/service-context";
import { ErrorKeys } from "../../../services/errors/error-keys";
import { AccountRoutePaths } from "../../account/accountRoutePaths";

export function useSubscriptionsHook() {
  const { appSettingsApi } = useAPIs();
  const { data, isValidating, error } = useSWRImmutable("/api/appSettings/subscriptions", () =>
    appSettingsApi.getAllSubscriptions(),
  );

  if (error) {
    window.location.replace(AccountRoutePaths.ErrorPage.replace(":code", ErrorKeys.SubscriptionNotSynced));
  }

  return {
    data,
    loading: !data && isValidating,
  };
}
