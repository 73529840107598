import { DateTime } from "luxon";

import { IInventoryInspectionsConnectedDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { DateTimeLuxonHelpers } from "../../../../Helpers/DateTimeLuxonHelpers";

export type FilterBy = "Overdue" | "ThisWeek" | "ThisMonth" | "ThisQuarter";
export default class InspectionsFilteredResult {
  constructor(filterBy: FilterBy, items: IInventoryInspectionsConnectedDTO[]) {
    this.filterBy = filterBy;
    this.endAt = getEndDate(filterBy);
    this.startAt = getStartDate(filterBy);
    this.items = items.filter(
      (x) => x.nextInspection && x.nextInspection > this.startAt && x.nextInspection < this.endAt,
    );
    this.queryString = getQueryString(this.startAt, this.endAt);
  }
  filterBy: FilterBy;
  items: IInventoryInspectionsConnectedDTO[];
  queryString: string;
  startAt: DateTime;
  endAt: DateTime;
}

const getQueryString = (start: DateTime, end: DateTime) =>
  `&nextInspectionFrom=${DateTimeLuxonHelpers.getISODateForQuery(
    DateTime.fromJSDate(start.toJSDate()),
  )}&nextInspectionTo=${DateTimeLuxonHelpers.getISODateForQuery(DateTime.fromJSDate(end.toJSDate()))}`;

const getEndDate = (filterBy: FilterBy) => {
  switch (filterBy) {
    case "Overdue":
      return DateTime.now();
    case "ThisWeek":
      return DateTime.now().endOf("week");
    case "ThisMonth":
      return DateTime.now().endOf("month");
    case "ThisQuarter":
      return DateTime.now().endOf("quarter");
    default:
      throw new Error("Not implemented");
  }
};

const getStartDate = (filterBy: FilterBy) => {
  switch (filterBy) {
    case "Overdue":
      return DateTime.fromISO("0000-01-01");
    case "ThisWeek":
      return DateTime.now().startOf("week");
    case "ThisMonth":
      return DateTime.now().startOf("month");
    case "ThisQuarter":
      return DateTime.now().startOf("quarter");
    default:
      throw new Error("Not implemented");
  }
};
