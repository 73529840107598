import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { CategoryCreateDTO, ICategoryCreateDTO, ICategoryDTO } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { useRefreshHook } from "../../../shared/hooks/useRefreshHook";
import BaseSettingsForm, { hasId } from "../../components/editPage/baseSettingsForm";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

const EditPageCostCenters: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const { costCentersApi } = useAPIs();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [itemToUpdate, setItemToUpdate] = React.useState<ICategoryDTO>();
  const [{ refresh, handleRefresh }] = useRefreshHook();

  const { data: existingCostCenters } = useSWR(`costCenters`, () => costCentersApi.getAll());

  React.useEffect(() => {
    async function getData() {
      const category = await costCentersApi.getById(Number(id));
      setItemToUpdate(category);
    }
    if (id != null) {
      getData().then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [id, refresh]);

  async function handleSubmit(itemToCreate: ICategoryCreateDTO) {
    if (id) {
      return costCentersApi.edit(Number(id), new CategoryCreateDTO(itemToCreate));
    } else {
      return costCentersApi.create(new CategoryCreateDTO(itemToCreate));
    }
  }

  async function toggleDelete() {
    if (!itemToUpdate) throw Error("Item missing");
    if (itemToUpdate.isDeleted) {
      await costCentersApi.restore(itemToUpdate.id);
    } else {
      await costCentersApi.delete(itemToUpdate.id);
    }
    handleRefresh();
  }

  const handleSuccess = (dto?: unknown) => {
    if (hasId(dto)) {
      navigate(SettingsRoutePaths.EditPageCostCenters.replace(":id", dto.id.toString()));
    } else {
      handleRefresh();
    }
  };

  return (
    <React.Fragment>
      {!loading && (
        <BaseSettingsForm
          itemToUpdate={itemToUpdate}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          onDelete={toggleDelete}
          onRestore={(tId) => costCentersApi.restore(tId)}
          existingItems={existingCostCenters}
          formType={"CostCenter"}
        />
      )}
    </React.Fragment>
  );
};

export default EditPageCostCenters;
