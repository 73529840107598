import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { IArticleDTO, IPrivacyPolicySetting, PbdStatus } from "@generatedCode/pbd-core/pbd-core-api";

import { nameofFactory } from "../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import { mapToOptionData } from "../../articles/components/compare/articleCompareFilterComponent";
import { FormikCheckbox } from "../../shared/components/forms/formik/formikCheckboxInput";
import FormikCustomForm from "../../shared/components/forms/formik/formikCustomForm";
import { FormikHtmlInputGroup } from "../../shared/components/forms/formik/formikHtmlInput";
import GenericSelect, { OptionData } from "../../shared/components/inputControl/select/genericSelect";

const nameof = nameofFactory<IPrivacyPolicySetting>();

// const ValidationSchema: yup.ObjectSchema<IPrivacyPolicySetting> = yup.object({
//   content: notRequiredStringSchema(),
// });

interface IProps {
  itemToUpdate?: IPrivacyPolicySetting;
  onSubmit: (dto: IPrivacyPolicySetting) => Promise<void>;
  onSuccess: () => void;
  articles: IArticleDTO[];
}

function PrivacyPolicyForm(props: IProps) {
  const { t } = useTranslation();
  const { onSubmit, itemToUpdate, onSuccess, articles } = props;
  const initialValues: IPrivacyPolicySetting = {
    content: itemToUpdate?.content ?? "",
    isActive: itemToUpdate?.isActive ?? false,
    isRequired: itemToUpdate?.isRequired ?? false,
    lastUpdatedAt: itemToUpdate?.lastUpdatedAt,
    revision: itemToUpdate?.revision ?? 0,
    status: PbdStatus.Open,
  };
  const submitter = useFormikAPISubmitter<IPrivacyPolicySetting>((val) => onSubmit(val), [], onSuccess);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={submitter}
        // validationSchema={ValidationSchema}
      >
        {(formikBag) => (
          <FormikCustomForm formikBag={formikBag}>
            <FormikCheckbox name={nameof("isActive")} label={t("Active")} />
            <FormikCheckbox name={nameof("isRequired")} label={t("Required")} />
            <Form.Group className="mb-3">
              <Form.Label htmlFor="article">{t("Article")}</Form.Label>
              <GenericSelect
                name="article"
                onChange={(x) => {
                  let value: OptionData | undefined = undefined;
                  if (Array.isArray(x)) {
                    value = x[0];
                  } else {
                    value = x;
                  }
                  void formikBag.setFieldValue(nameof("articleId"), value?.value);
                }}
                selected={initialValues.articleId}
                data={mapToOptionData("article", articles)}
                isClearable
              />
            </Form.Group>
            <FormikHtmlInputGroup name={nameof("content")} />
          </FormikCustomForm>
        )}
      </Formik>
    </>
  );
}
export default PrivacyPolicyForm;
