import { Field, FieldArray, Form, Formik } from "formik";
import { Info } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, Col, FormGroup, Row, Table } from "reactstrap";
import useSWR from "swr";

import { ISetting, IWorkingDaySettings } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../../pbdServices/services/service-context";

import { DateTimeLuxonHelpers } from "../../../../Helpers/DateTimeLuxonHelpers";
import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { useTryDelete } from "../../../../pbdServices/services/Api/api-hooks";
import { SettingKeyNames } from "../../../../services/Settings/SettingKeyNames";
import { FormikCheckboxInput } from "../../../shared/components/forms/formik/formikCheckboxInput";
import { toastSuccess } from "../../../shared/components/toasts/toastSuccess";

const CreatePageWorkingHours: React.FC = () => {
  const { t } = useTranslation();
  const { settingsApi } = useAPIs();
  const { settingsService } = useAPIServices();
  const { data, mutate } = useSWR("/api/appSettings/workingDays", () => settingsService.getWorkingDaySettings(), {
    revalidateOnFocus: false,
  });

  const totalHrs = 1;
  const averageHrs = 2;

  const weekDays = Info.weekdays(undefined, {});

  const submitter = useFormikAPISubmitter<IWorkingDaySettings, ISetting>(
    (values) => settingsService.updateWorkingHourSettings(values),
    [settingsService],
    () => {
      mutate();
      toastSuccess(t("Saved"));
    },
  );

  const tryDelete = useTryDelete<{ id: string }, string>(
    (dto) => settingsApi.hardDelete(dto.id),
    [settingsApi],
    () => {
      mutate();
    },
  );

  if (!data) return null;

  const initialValues: IWorkingDaySettings = data;

  return (
    <React.Fragment>
      <h2>{t("Working hours")}</h2>
      <hr />
      <Row>
        <Col md={9}>
          <Card className="mb-3">
            <CardBody>
              <Formik initialValues={initialValues} onSubmit={submitter} enableReinitialize>
                {(formikBag) => (
                  <Form>
                    {/* <>
                      <h5>DEV ONLY</h5>
                      <pre>{JSON.stringify(formikBag, null, 2)}</pre>
                    </> */}
                    <Table responsive>
                      <thead>
                        <tr>
                          <th></th>
                          <th>{t("Day")}</th>
                          <th>{t("From")}</th>
                          <th>{t("To")}</th>
                          <th>{t("Daily work time")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <FieldArray
                          name="operatingHours"
                          render={(arrayHelper) => (
                            <>
                              {data.operatingHours.map((x, i) => (
                                <tr key={i}>
                                  <td>
                                    <Field
                                      name={`operatingHours[${i}].isChecked`}
                                      component={FormikCheckboxInput}
                                      id={x.isoWeekday}
                                    />
                                  </td>
                                  <td>{weekDays[i]}</td>
                                  <td>{x && DateTimeLuxonHelpers.convertUtcToTime(x.from, false)}</td>
                                  <td>{x && DateTimeLuxonHelpers.convertUtcToTime(x.to, false)}</td>
                                  <td>{x && DateTimeLuxonHelpers.getReadableDuration(x.to.diff(x.from))}</td>
                                </tr>
                              ))}
                            </>
                          )}
                        />
                      </tbody>
                    </Table>
                    <FormGroup>
                      <Button
                        color="primary"
                        type="submit"
                        className="me-1"
                        disabled={formikBag.isSubmitting || !formikBag.isValid}
                      >
                        {t("Save")}
                      </Button>
                      <Button color="danger" outline onClick={() => tryDelete({ id: SettingKeyNames.WorkingDays })}>
                        {t("Reset")}
                      </Button>
                    </FormGroup>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default CreatePageWorkingHours;
