import { useTranslation } from "react-i18next";
import { FormGroup, FormText, Input, Label } from "reactstrap";

import { CustomFieldType, ICustomField } from "@generatedCode/pbd-core/pbd-core-api";

import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";

const renderFormField = (cf: ICustomField) => {
  switch (cf.type) {
    case CustomFieldType.Text:
    case CustomFieldType.Number:
    case CustomFieldType.Date:
      return (
        <Input
          type="text"
          style={{
            borderStyle: "solid",
            borderColor: "black",
            boxSizing: "border-box",
            pointerEvents: "none",
          }}
        />
      );
    case CustomFieldType.Choices:
      if (!cf.choices) throw Error("No choices defined");
      return (
        <div>
          {(JSON.parse(cf.choices) as string[]).map((x) => (
            <Input id={x} key={x} type="checkbox" label={x} inline />
          ))}
        </div>
      );
    case CustomFieldType.Textarea:
    case CustomFieldType.Paragraph:
      return (
        <textarea
          style={{
            borderStyle: "solid",
            borderColor: "black",
            pointerEvents: "none",
          }}
          className="form-control"
          rows={5}
        />
      );
    default:
      throw Error("Not implemented");
  }
};

interface IProps {
  fields: ICustomField[];
}

function FormFieldsPrintout(props: IProps) {
  const { fields } = props;
  const { t } = useTranslation();
  if (fields.length == 0) return null;
  return (
    <>
      {fields.map((f) => (
        <FormGroup key={f.id}>
          <Label for={f.id}>
            {f.name} {f.isRequired ? <qmBaseIcons.Star className="fa-xs" title={t("required")} /> : ""}
          </Label>
          {renderFormField(f)} <FormText color="muted">{f.optionalHelpText}</FormText>
        </FormGroup>
      ))}
    </>
  );
}

export default FormFieldsPrintout;
