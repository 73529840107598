import { Field, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { CardTitle, FormGroup, FormText } from "reactstrap";
import { boolean, object, ObjectSchema, string } from "yup";

import { IExternalIdSetting, IModuleSettingDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { nameofFactory } from "../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import { FormikCheckboxInput } from "../../shared/components/forms/formik/formikCheckboxInput";
import FormikCustomForm from "../../shared/components/forms/formik/formikCustomForm";
import { FormikTextInputGroup } from "../../shared/components/forms/formik/formikTextInput";
import { toastSuccess } from "../../shared/components/toasts/toastSuccess";

const nameof = nameofFactory<IExternalIdSetting>();

const validationSchema: ObjectSchema<IExternalIdSetting> = object({
  isUsed: boolean().required(),
  isRequired: boolean().required(),
  displayName: string().max(250),
  hideInternalId: boolean().required(),
});

interface IProps {
  itemToUpdate?: IExternalIdSetting;
  onSubmit: (dto: IExternalIdSetting) => Promise<IModuleSettingDTO>;
}

function ExternalIdForm(props: IProps) {
  const { itemToUpdate, onSubmit } = props;
  const { t } = useTranslation();

  const submitter = useFormikAPISubmitter<IExternalIdSetting, IModuleSettingDTO>(
    (val) => onSubmit(val),
    [onSubmit],
    () => toastSuccess(t("Saved")),
  );

  const initialValues: IExternalIdSetting = {
    displayName: itemToUpdate?.displayName ?? "",
    isRequired: itemToUpdate?.isRequired ?? false,
    isUsed: itemToUpdate?.isUsed ?? false,
    hideInternalId: itemToUpdate?.hideInternalId ?? false,
  };

  return (
    <>
      <CardTitle tag="h5">{t("External ID")}</CardTitle>
      <hr />
      <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={validationSchema}>
        {(formikBag) => (
          <FormikCustomForm formikBag={formikBag}>
            <FormGroup>
              <Field name={nameof("isUsed")} id="isUsed" component={FormikCheckboxInput} label={t("Is used")} />
            </FormGroup>
            {formikBag.values.isUsed && (
              <>
                <FormikTextInputGroup name={nameof("displayName")} label={t("Display name")} />
                <FormGroup>
                  <Field name="isRequired" id="isRequired" component={FormikCheckboxInput} label={t("Is required")} />
                  <FormText color="muted">
                    {t("Setting the external ID as required, users will be prompted to fill out this field.")}
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <Field
                    name="hideInternalId"
                    id="hideInternalId"
                    component={FormikCheckboxInput}
                    label={t("Hide internal ID")}
                  />
                  <FormText color="muted">
                    {t("We try to hide the internal ID wherever possible to prevent the irritation of multiple IDs")}
                  </FormText>
                </FormGroup>
              </>
            )}
          </FormikCustomForm>
        )}
      </Formik>
    </>
  );
}

export default ExternalIdForm;
