import { ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import {
  IEmployeeIdeaDetailsDTO,
  IEmployeeIdeaReviewDTO,
  ITenantContributingDTO,
} from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIServices } from "../../../../pbdServices/services/service-context";

import { DateTimeLuxonHelpers } from "../../../../Helpers/DateTimeLuxonHelpers";
import { NumberHelpers } from "../../../../Helpers/NumberHelpers";
import { ExternalIdSettings } from "../../../../Models/Settings/ExternalIdSettings";
import ReviewListGroupItem from "../../../ideaManagement/components/details/reviewListGroupItem";
import { SortOption } from "../../../shared/components/buttons/sortDropdownButton";
import ExternalIdComponent from "../../../shared/components/id/externalIdComponent";
import TagsInTable from "../../../shared/components/tags/tagsInTable";
import FormattedUserInput from "../../../shared/components/text/formattedUserInput";
import { useAppContext } from "../../../shared/contexts/appContext";
import { usePrintContext } from "../../../shared/contexts/printContext";
import IdeaContributorsTablePrintView from "../ideaContributorsTablePrintView";

interface IProps {
  item: IEmployeeIdeaDetailsDTO;
  extIdSetting: ExternalIdSettings | undefined;
  extIdEmployee: ExternalIdSettings | undefined;
  contributors: ITenantContributingDTO[] | undefined;
}

function PrintIdeaComponent(props: IProps) {
  const { t } = useTranslation();
  const { options } = usePrintContext();
  const { ideaManagementService } = useAPIServices();
  const { meAsUser } = useAppContext();
  const { item, extIdSetting, extIdEmployee, contributors } = props;

  const { data } = useSWR(
    item.reviews ? [item.reviews.map((x) => x.reviewerId), item.id] : null,
    () => {
      if (item.reviews) {
        return ideaManagementService.getReviewsAsVm(item.reviews);
      } else {
        return null;
      }
    },
    { revalidateOnFocus: false },
  );

  if (!data) return null;

  const visibleReviews = ideaManagementService.getVisibleReviews(
    item,
    data,
    meAsUser,
    new SortOption<IEmployeeIdeaReviewDTO>("createdAt", "desc"),
  );

  return (
    <>
      {extIdSetting?.isUsed && (
        <h5>
          <ExternalIdComponent externalId={item.externalId} externalIdSettings={extIdSetting} />
        </h5>
      )}
      {item.registeredAt && (
        <h5>
          {t("Registered")}: <small>{DateTimeLuxonHelpers.convertUtcToDate(item.registeredAt)}</small>
        </h5>
      )}

      <h5>
        {t("Created")}:{" "}
        <small>
          <span className="me-1">{item.createdBy?.fullName}</span>
          {extIdEmployee?.isUsed && (
            <span className="me-1">
              (
              <ExternalIdComponent externalIdSettings={extIdEmployee} externalId={item.createdBy?.externalId} />)
            </span>
          )}
          {DateTimeLuxonHelpers.convertUtcToDate(item.createdAt)}
        </small>
      </h5>
      <h5>
        {t("Approved")}:{" "}
        <small>
          <span className="me-2">{item.approvedBy?.fullName}</span>{" "}
          {item.approvedAt && DateTimeLuxonHelpers.convertUtcToDate(item.approvedAt)}
        </small>
      </h5>

      <h5>
        {t("Category")}: <small>{item.category.title}</small>
      </h5>

      <h5 className="me-2">
        {t("Tags")}:{" "}
        <small>
          <TagsInTable tags={item.tags} />
        </small>
      </h5>
      <br />
      <h5>{t("Current situation")}</h5>
      <FormattedUserInput content={item.currentSituation} />
      <h5>{t("Description of idea")}</h5>
      <FormattedUserInput content={item.description} />
      <h5>{t("Advantage of idea")}</h5>
      <FormattedUserInput content={item.ideaAdvantage} />
      {contributors && <IdeaContributorsTablePrintView contributors={contributors} extIdEmployee={extIdEmployee} />}
      {item.contributionComment && (
        <dl>
          <h5>{t("Comment")}</h5>
          <dd>{item.contributionComment}</dd>
        </dl>
      )}
      <hr />
      {options.showReviews && (
        <>
          <h5>
            {t("Reviews")} ({visibleReviews?.length})
          </h5>
          {visibleReviews && visibleReviews.length > 0 && (
            <ListGroup>
              {visibleReviews.map((x) => (
                <ReviewListGroupItem key={x.id} idea={item} item={x} />
              ))}
            </ListGroup>
          )}
          <hr />
        </>
      )}
      {options.decisionOfAcceptance && (
        <>
          <h5>{t("Decision of acceptance")}</h5>
          <dl>
            <dt>{t("Idea coordinator")}</dt>
            <dd>{item.approvedBy?.fullName}</dd>
            <dt>{t("Decision")}</dt>
            <dd>
              {item.approvedAt && (
                <>
                  {item.isApproved ? t("Approved") : t("Rejected")}{" "}
                  {DateTimeLuxonHelpers.convertUtcToDateTime(item.approvedAt)}
                </>
              )}
            </dd>
            <dt>{t("Comment")}</dt>
            <dd>{item.approvalComment && <FormattedUserInput content={item.approvalComment} />}</dd>
          </dl>
          <hr />
        </>
      )}
      {options.decisionOnRealization && (
        <>
          <h5>{t("Decision on realization")}</h5>
          <dl>
            <dt>{t("Should this idea be realized?")}</dt>
            <dd>
              {item.realizeIdea == undefined && t("Not decided")}
              {item.realizeIdea && <>{item.realizeIdea ? t("Realize") : t("Do not realize")}</>}{" "}
              {item.reviewResultCreatedAt != undefined &&
                DateTimeLuxonHelpers.convertUtcToDateTime(item.reviewResultCreatedAt)}
            </dd>
            <dt>{t("Comment")}</dt>
            <dd>{item.reviewResultComment && <FormattedUserInput content={item.reviewResultComment} />}</dd>
          </dl>
          <hr />
        </>
      )}
      {options.implementation && (
        <>
          <h5>{t("Implementation")}</h5>
          <dl>
            <dt>{t("Progress")}</dt>
            <dd>{NumberHelpers.convertToPercentage(item.progressValue ?? 0)}</dd>
            <dt>{t("Comment")}</dt>
            <dd>{item.progressComment && <FormattedUserInput content={item.progressComment} />}</dd>
            <dt>{t("Savings comment")}</dt>
            <dd>{item.savingsComment && <FormattedUserInput content={item.savingsComment} />}</dd>
            <dt>{t("Awarded comment")}</dt>
            <dd>{item.awardedComment && <FormattedUserInput content={item.awardedComment} />}</dd>
            <dt>{t("Completed")}</dt>
            <dd>{item.completedAt && DateTimeLuxonHelpers.convertUtcToDateTime(item.completedAt)}</dd>
          </dl>
          <hr />
        </>
      )}
    </>
  );
}
export default PrintIdeaComponent;
