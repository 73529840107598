import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import useSWR from "swr";

import { EntityKey, IOpportunityCategoryDTO } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { OpportunityRoutePaths } from "../../../opportunities/opportunityRoutePaths";
import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps {
  id: number;
}

function CardDynamicContentByModule({ data }: { data: IOpportunityCategoryDTO }) {
  const { t } = useTranslation();
  let content = <></>;
  if (data.entityKey == EntityKey.OpportunityType) {
    content = (
      <dl style={{ textAlign: "left" }}>
        {data.variables && data.variables.length > 0 && (
          <div>
            <dt>{t("Variables")}</dt>
            {data.variables.map((x) => (
              <dd key={x.variableId}>
                {x.variableName} ({x.scaleMinimum}-{x.scaleMaximum})
              </dd>
            ))}
          </div>
        )}

        {data.formulaInfo?.formula && (
          <div>
            <dt>
              <qmBaseIcons.Calculator /> {t("Formula")}
            </dt>
            <dd> {data.formulaInfo.formula}</dd>
          </div>
        )}

        {data.boundaries && data.boundaries.length > 0 && (
          <div>
            <dt>{t("Boundaries")}</dt>
            {data.boundaries.map((x) => (
              <dd key={x.boundaryId}>
                {x.boundaryName} ({x.boundaryValue}/{x.boundaryLevel})
              </dd>
            ))}
          </div>
        )}
      </dl>
    );
  }
  return content;
}

function HoveDynamicContent(props: IProps) {
  const { opportunityCategoriesApi } = useAPIs();
  const location = useLocation();
  const { id } = props;
  const { data } = useSWR(
    location.pathname.startsWith(OpportunityRoutePaths.HomePage) ? ["/api/opportunityCategories", id] : null,
    () => opportunityCategoriesApi.getById(id),
  );
  if (!data) return null;

  return <CardDynamicContentByModule data={data} />;
}

export default HoveDynamicContent;
