import React from "react";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, Input, Row } from "reactstrap";
import useSWR from "swr";

import { ICustomField, IWorkflowCondition, WorkflowConditionType } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { AvailableWorkflowConditions } from "../../../../Models/Settings/Workflows/WorkflowConditionType";
import { CustomRolePrefix } from "../../../../pbdServices/services/Account/roleService";

interface IProps {
  index: number;
  fields: string[];
  customFields: ICustomField[];
  currentValue: IWorkflowCondition;
  onChange: (item: IWorkflowCondition) => void;
}

const conditionTypeWithFieldSelect: WorkflowConditionType[] = [
  WorkflowConditionType.ValueOfEquals,
  WorkflowConditionType.ValueOfNotEquals,
];

const conditionTypeWithRole: WorkflowConditionType[] = [
  WorkflowConditionType.CurrentUserIsMemberOfRole,
  WorkflowConditionType.CurrentUserIsNotMemberOfRole,
];

function ConditionInput(props: IProps) {
  const { fields, customFields, onChange, currentValue, index } = props;
  const { t } = useTranslation();
  const { rolesApi } = useAPIs();
  const { data: roles } = useSWR("/api/identity/roles", () => rolesApi.getAll());

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //@ts-expect-error TODO 20240722: TODO Refactor to normal form or switch to yml
    currentValue[event.target.name] = event.target.value;
    onChange(currentValue);
  };

  return (
    <Row>
      <Col sm={2}>{index == 0 ? t("When") : t("And")}</Col>
      <Col>
        <FormGroup>
          <Input type="select" name="conditionType" id="conditionType" onChange={handleChange}>
            <option>{t("Please select...")}</option>
            {AvailableWorkflowConditions.map((x) => (
              <option key={x.value} value={x.value}>
                {x.label}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
      {conditionTypeWithRole.includes(currentValue.conditionType) && (
        <Col>
          <FormGroup>
            {roles && (
              <Input type="select" name="value" id="value" onChange={handleChange}>
                <option>{t("Please select...")}</option>
                <optgroup label={t("Roles")}>
                  {roles
                    .filter((x) => !x.name.startsWith(CustomRolePrefix))
                    .map((x) => (
                      <option key={x.id} value={x.name}>
                        {x.name}
                      </option>
                    ))}
                </optgroup>
                <optgroup label={t("Custom roles")}>
                  {roles
                    .filter((x) => x.name.startsWith(CustomRolePrefix))
                    .map((x) => (
                      <option key={x.id} value={x.name}>
                        {x.name}
                      </option>
                    ))}
                </optgroup>
              </Input>
            )}
          </FormGroup>
        </Col>
      )}
      {conditionTypeWithFieldSelect.includes(currentValue.conditionType) && (
        <Col>
          <FormGroup>
            <Input type="select" name="conditionTypeAppendix" id="conditionTypeAppendix" onChange={handleChange}>
              <option>{t("Please select...")}</option>
              <optgroup label={t("Default fields")}>
                {fields.map((x) => (
                  <option key={x}>{x}</option>
                ))}
              </optgroup>
              {customFields && (
                <optgroup label={t("Custom Fields")}>
                  {customFields.map((x) => (
                    <option key={x.id} value={x.id}>
                      {x.name}
                    </option>
                  ))}
                </optgroup>
              )}
            </Input>
          </FormGroup>
        </Col>
      )}
      {!conditionTypeWithRole.includes(currentValue.conditionType) && (
        <Col>
          <FormGroup>
            <Input type="text" name="value" id="value" placeholder={t("Input value")} onChange={handleChange} />
          </FormGroup>
        </Col>
      )}
    </Row>
  );
}

export default ConditionInput;
