import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { AppUiData } from "../../Constants/app-ui-data";
import { PbdRouteModel } from "../router/pbdRouteModel";
import CreatePageSelect from "../shared/pages/createPageSelect";
import { CrmRoutePaths } from "./crmRoutePaths";

const appData = AppUiData[PbdModule.Crm];

const defaultRouteParams: Partial<PbdRouteModel> = {
  roles: appData.minimumAccessRoles,
};

export const crmLazyRoutes: RouteObject[] = [
  {
    path: CrmRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePageCrm")),
    ...defaultRouteParams,
  },
  {
    path: CrmRoutePaths.CreatePageSelect,
    Component: () => <CreatePageSelect entities={appData.entityKeys!} />,
    ...defaultRouteParams,
  },
  {
    path: CrmRoutePaths.OrganisationEdit,
    Component: lazy(() => import("../organisations/pages/detailsPageOrganisation")),
    ...defaultRouteParams,
  },
  {
    path: CrmRoutePaths.IndexPageOrganisations,
    Component: lazy(() => import("../organisations/pages/indexPageOrganisations")),
    ...defaultRouteParams,
  },
  {
    path: CrmRoutePaths.CreatePageOrganisation,
    Component: lazy(() => import("../organisations/pages/createPageOrganisation")),
    ...defaultRouteParams,
  },
  {
    path: CrmRoutePaths.IndexPageOrganisationRatings,
    Component: lazy(() => import("../organisationRatings/pages/indexPageOrganisationRatings")),
    ...defaultRouteParams,
  },
  {
    path: CrmRoutePaths.CreatePageOrganisationRatings,
    Component: lazy(() => import("../organisationRatings/pages/createPageOrganisationRating")),
    ...defaultRouteParams,
  },
  {
    path: CrmRoutePaths.EditPageOrganisationRatings,
    Component: lazy(() => import("../organisationRatings/pages/editPageOrganisationRatings")),
    ...defaultRouteParams,
  },
  {
    path: CrmRoutePaths.CreatePageTenant,
    Component: lazy(() => import("../tenants/pages/createPageTenant")),
    ...defaultRouteParams,
  },
  {
    path: CrmRoutePaths.EditPageTenant,
    Component: lazy(() => import("../tenants/pages/editPageTenant")),
    ...defaultRouteParams,
  },
  {
    path: CrmRoutePaths.IndexPageTenants,
    Component: lazy(() => import("../tenants/pages/indexPageTenants")),
    ...defaultRouteParams,
  },
];
