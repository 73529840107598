import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";

import { CustomFieldType, ICustomField, ICustomFieldDTO } from "@generatedCode/pbd-core/pbd-core-api";

import CustomFieldService from "../../../../pbdServices/services/CustomFields/customFieldService";
import { ErrorFallback } from "../../layouts/errors/errorFallback";
import FormattedUserInput from "../text/formattedUserInput";
import CustomFieldApiRenderer from "./customFieldApiRenderer";

interface IProps {
  customField?: ICustomField;
  customFieldContent: ICustomFieldDTO;
  renderMissingValueAsNull?: boolean;
}

function CustomFieldValueRenderer(props: IProps) {
  const { customField, customFieldContent, renderMissingValueAsNull } = props;
  const { t } = useTranslation();
  if (customField && customField.id != customFieldContent.id) throw Error("No matching ids");
  const { value } = customFieldContent;
  if (!value && customField && customField.type != CustomFieldType.Paragraph) {
    if (renderMissingValueAsNull) {
      return null;
    } else {
      return <em>{t("No value entered")}</em>;
    }
  }
  if (!customField?.type) {
    return (
      <>
        {t("Missing custom field")} {t("Value")} {value}
      </>
    );
  }
  switch (customField.type) {
    case CustomFieldType.Date:
    case CustomFieldType.Text:
    case CustomFieldType.Choices:
    case CustomFieldType.Number:
      return <>{CustomFieldService.formatCustomFieldValue(customFieldContent, customField)}</>;
    case CustomFieldType.Textarea:
      return <FormattedUserInput content={value} />;
    case CustomFieldType.Paragraph:
      return null;
    case CustomFieldType.Api:
      return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <CustomFieldApiRenderer customField={customField} customFieldContent={customFieldContent} />
        </ErrorBoundary>
      );
    default:
      return <span>{t("Undefined")}</span>;
  }
}

export default CustomFieldValueRenderer;
