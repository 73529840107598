import { Field, Form as FormFormik, FormikProps } from "formik";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { IPrivacyPolicySettingDTO, IRegisterDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { nameofFactory } from "../../../Helpers/nameof-factory";
import PasswordStrengthComponent from "../../profile/components/passwordStrengthComponent";
import { FormikCheckbox } from "../../shared/components/forms/formik/formikCheckboxInput";
import { FormikTextInput, FormikTextInputGroup } from "../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../shared/components/forms/formik/formikValidationSummary";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import { useToggle } from "../../shared/hooks/useToggle";

import { RegistrationTerms } from "./registrationTerms";

const nameof = nameofFactory<IRegisterDTO>();

interface IProps {
  formikBag: FormikProps<IRegisterDTO & { code?: string }>;
  privacyPolicy?: IPrivacyPolicySettingDTO;
}

export function RegisterForm(props: IProps) {
  const { t } = useTranslation();
  const { formikBag, privacyPolicy } = props;

  const [passwordShown, togglePasswordVisibility] = useToggle();

  return (
    <FormFormik>
      <FormikValidationSummary
        formikBag={formikBag}
        keysToExclude={["email", "password", "confirmPassword", "acceptTerms"]}
      />
      <FormikTextInputGroup label={t("Email")} name={nameof("email")} type="email" autoComplete="username" />
      <Form.Group className="mb-3">
        <Form.Label htmlFor="password">{t("Password")}</Form.Label>
        <InputGroup>
          <Field
            name="password"
            component={FormikTextInput}
            type={passwordShown ? "text" : "password"}
            autoComplete="new-password"
          />

          <Button onClick={togglePasswordVisibility} title={t("Show password")}>
            <qmBaseIcons.NotWatching />
          </Button>
        </InputGroup>
        <PasswordStrengthComponent password={formikBag.values.password} />
      </Form.Group>
      <FormikTextInputGroup
        label={t("Confirm password")}
        name={nameof("confirmPassword")}
        type={passwordShown ? "text" : "password"}
        autoComplete="new-password"
      />
      {privacyPolicy?.content && (
        <FormikCheckbox
          name={nameof("acceptTerms")}
          label={t("Accept terms and conditions")}
          formText={<RegistrationTerms privacyPolicy={privacyPolicy} />}
        />
      )}

      <Form.Group className="mb-3">
        <div className="d-grid gap-2">
          <Button variant="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
            {formikBag.isSubmitting ? t("Loading...") : t("Register")}
          </Button>
        </div>
      </Form.Group>
    </FormFormik>
  );
}
