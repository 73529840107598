import { useTranslation } from "react-i18next";

import { ICustomFormDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import { ICustomFormAnswerVm } from "../../../pbdServices/services/CustomFormAnswers/models/custom-form-answer-vm";
import { CustomFormAnswerHeader } from "../../forms/customFormAnswers/components/customFormAnswerHeader";
import IdComponent from "../../shared/components/id/idComponent";

interface IProps {
  form: ICustomFormDTO;
  answer?: ICustomFormAnswerVm;
}

function FormHeaderPrintout(props: IProps) {
  const { form, answer } = props;
  const { t } = useTranslation();

  return (
    <>
      <h2>
        {form.title} <IdComponent id={form.id} />
      </h2>
      <span>{t("Revision")}: </span> <span>{form.revision}</span> <span>{t("Updated")}: </span>
      {DateTimeLuxonHelpers.convertUtcToDate(form.lastUpdatedAt ?? form.createdAt)} <span>{t("Responsible")}: </span>
      <span>{form.responsible.fullName}</span>
      <hr />
      {answer && <CustomFormAnswerHeader answer={answer} />}
    </>
  );
}

export default FormHeaderPrintout;
