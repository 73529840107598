import { Field, FieldArray, Formik } from "formik";
import { ChangeEvent } from "react";
import { Button, Container, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import {
  ChoiceType,
  CustomFieldGradingBasis,
  CustomFieldInfo,
  CustomFieldOption,
  CustomFieldType,
  ICustomField,
  ICustomFieldEditDTO,
  ICustomFieldGradingBasis,
  ICustomFieldInfo,
  ICustomFieldOption,
  OptionDisplayStyle,
} from "@generatedCode/pbd-core/pbd-core-api";

import { nameofFactory, nameOfFactoryDeep } from "../../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import JsonHelpers from "../../../../services/Json/jsonHelpers";
import { nullableNumber } from "../../../../services/validation/nullableNumber";
import { FormikCheckbox, FormikCheckboxInput } from "../../../shared/components/forms/formik/formikCheckboxInput";
import FormikCustomForm from "../../../shared/components/forms/formik/formikCustomForm";
import { FormikNumberInputGroup } from "../../../shared/components/forms/formik/formikNumberInput";
import { FormikTextInput, FormikTextInputGroup } from "../../../shared/components/forms/formik/formikTextInput";
import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";

const nameof = nameOfFactoryDeep<ICustomFieldEditDTO>();
const nameofGrading = nameofFactory<ICustomFieldGradingBasis>();

const InfoValidation: yup.ObjectSchema<ICustomFieldInfo> = yup.object({
  isRequired: yup.boolean().required(),
  isMultiLine: yup.boolean().required(),
  point: nullableNumber,
  choiceType: yup.mixed<ChoiceType>().oneOf(Object.values(ChoiceType)).required(),
  optionDisplayStyle: yup.mixed<OptionDisplayStyle>().oneOf(Object.values(OptionDisplayStyle)).required(),
});

const OptionsValidation: yup.ObjectSchema<ICustomFieldOption> = yup.object({
  name: yup.string().required(),
  value: yup.string(),
  isAnswerKey: yup.boolean().required(),
});

//@ts-expect-error TODO: Fix with better typings
const ValidationSchema: yup.ObjectSchema<ICustomFieldEditDTO> = yup.object({
  id: yup.string().required(),
  title: yup.string().required().min(2).max(250),
  type: yup.mixed<CustomFieldType>().oneOf(Object.values(CustomFieldType)).required(),
  optionalHelpText: yup.string(),
  choices: yup.array(yup.string().min(1).max(250).required()).notRequired(),
  isRequired: yup.boolean().required(),
  options: yup
    .array()
    .of(OptionsValidation)
    // .unique("Options must be unique", (val) => val.name)
    .notRequired(),
  customFieldInfo: InfoValidation.optional(),
});

interface IProps {
  itemToUpdate: ICustomField;
  onSubmit: (values: ICustomFieldEditDTO) => Promise<void>;
  toggleEditMode?: () => void;
  onSuccess?: () => void;
}

/**
 * This component is used for custom fields in the apps and also for the custom forms
 */
function CustomFieldForm(props: IProps) {
  const { itemToUpdate, onSubmit, toggleEditMode, onSuccess } = props;
  const { t } = useTranslation();

  const submitter = useFormikAPISubmitter<ICustomFieldEditDTO>(
    (values) => onSubmit(values),
    [onSubmit],
    () => {
      onSuccess?.();
      toggleEditMode?.();
    },
  );

  const initialValues: ICustomFieldEditDTO = {
    id: itemToUpdate.id,
    title: itemToUpdate.name || "",
    type: itemToUpdate.type,
    optionalHelpText: itemToUpdate.optionalHelpText ?? "",
    choices: itemToUpdate.choices ? JsonHelpers.parse<string[]>(itemToUpdate.choices) : [],
    isRequired: itemToUpdate.isRequired || false,
    customFieldInfo: itemToUpdate.customFieldInfo
      ? CustomFieldInfo.fromJS(itemToUpdate.customFieldInfo)
      : new CustomFieldInfo({
          isRequired: itemToUpdate.isRequired,
          isMultiLine: false,
          choiceType: ChoiceType.Single,
          optionDisplayStyle: OptionDisplayStyle.Dropdown,
        }),
    options: itemToUpdate.options
      ? itemToUpdate.options.map((x) =>
          CustomFieldOption.fromJS({ ...x, value: x.value ?? undefined, isAnswerKey: x.isAnswerKey ?? false }),
        )
      : undefined,
    gradingBases: itemToUpdate.gradingBases
      ? CustomFieldGradingBasis.fromJS(itemToUpdate.gradingBases)
      : new CustomFieldGradingBasis(),
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
      {(formikBag) => (
        <FormikCustomForm formikBag={formikBag} onCancel={toggleEditMode}>
          <FormikTextInputGroup name={nameof("title")} />
          {formikBag.values.type == CustomFieldType.Choices && (
            <Container>
              <h6>{t("Options")}</h6>
              <FieldArray
                name="options"
                render={({ push, remove }) => (
                  <div>
                    {formikBag.values.options?.map((friend, index) => (
                      <InputGroup key={index} className="mb-3">
                        <Field name={`options[${index}].name`} component={FormikTextInput} />
                        <Button variant="outline-danger" onClick={() => remove(index)}>
                          <qmBaseIcons.Delete />
                        </Button>
                      </InputGroup>
                    ))}
                    <Form.Group className="mb-3">
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => push(new CustomFieldOption({ name: "", isAnswerKey: false }))}
                      >
                        <qmBaseIcons.Plus /> {t("Add")}
                      </Button>
                    </Form.Group>
                  </div>
                )}
              />
            </Container>
          )}
          <FormikTextInputGroup label={t("Optional help text")} textarea name={nameof("optionalHelpText")} />
          <FormikCheckbox
            name="isRequired"
            label={t("Required field")}
            formText={t("This will highlight the field in any form if no value has been set.")}
          />
          {itemToUpdate.type == CustomFieldType.Choices && (
            <>
              <Form.Group controlId="customFieldInfo.isDropdown" className="mb-3">
                <Form.Label>{t("Dropdown")}</Form.Label>
                <Field
                  name="customFieldInfo.optionDisplayStyle"
                  id="isDropdown"
                  component={FormikCheckboxInput}
                  label={t("Render field as dropdown.")}
                  checked={formikBag.values.customFieldInfo?.optionDisplayStyle == OptionDisplayStyle.Dropdown}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formikBag.setFieldValue(
                      "customFieldInfo.optionDisplayStyle",
                      e.target.checked ? OptionDisplayStyle.Dropdown : OptionDisplayStyle.List,
                    )
                  }
                />
              </Form.Group>
              <Form.Group controlId="customFieldInfo.choiceType" className="mb-3">
                <Form.Label>{t("Multiple answers")}</Form.Label>
                <Field
                  name="customFieldInfo.choiceType"
                  id="choiceType"
                  component={FormikCheckboxInput}
                  label={t("Allow multiple answers")}
                  checked={formikBag.values.customFieldInfo?.choiceType == ChoiceType.Multi}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    formikBag.setFieldValue(
                      "customFieldInfo.choiceType",
                      e.target.checked ? ChoiceType.Multi : ChoiceType.Single,
                    )
                  }
                  disabled={
                    formikBag.values.customFieldInfo?.optionDisplayStyle == OptionDisplayStyle.Dropdown
                      ? true
                      : undefined
                  }
                  formText={
                    formikBag.values.customFieldInfo?.optionDisplayStyle == OptionDisplayStyle.Dropdown
                      ? t("Multiple answers can not be combined with dropdowns")
                      : undefined
                  }
                />
              </Form.Group>
            </>
          )}
          {itemToUpdate.isQuiz && (
            <>
              <h6>{t("Evaluation")}</h6>
              <FormikNumberInputGroup
                label={t("Point")}
                name={`${nameof("gradingBases")}.${nameofGrading("point")}`}
                formText={t("Maximum points for this question")}
              />
              <FormikTextInputGroup
                label={`${t("Correct answer")} (${t("Plain")})`}
                name={nameof("gradingBases.answerPlain")}
                formText={t(
                  'This will be used for a simple comparison like correct answer == answer. For multi select values use arrays ["Correct1","Correct2"] Notice no white space between the values.',
                )}
              />
            </>
          )}
        </FormikCustomForm>
      )}
    </Formik>
  );
}

export default CustomFieldForm;
