import { Formik } from "formik";
import i18next from "i18next";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { IOpportunityCategoryVariable } from "@generatedCode/pbd-core/pbd-core-api";

import { GlobalQmBaseConstants } from "../../../../Constants/GlobalQmBaseConstants";
import { nameofFactory } from "../../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { BaseFormProps } from "../../../shared/components/forms/formik/BaseFormProps";
import FormikCustomForm from "../../../shared/components/forms/formik/formikCustomForm";
import { FormikNumberInputGroup } from "../../../shared/components/forms/formik/formikNumberInput";
import { FormikTextInputGroup } from "../../../shared/components/forms/formik/formikTextInput";

// TODO: Add yup.ObjectSchema
const validationSchema = yup.object({
  variableName: yup
    .string()
    .matches(/^\w+$/, i18next.t("Only letters, numbers and underscore allowed"))
    .required()
    .min(2)
    .max(GlobalQmBaseConstants.MaxTitleLength),
  scaleMinimum: yup.number().required().lessThan(yup.ref("scaleMaximum")),
  scaleMaximum: yup.number().required().min(0).moreThan(yup.ref("scaleMinimum")),
  variableId: yup.string().notRequired(),
  description: yup.string(),
  scaleSections: yup.array().notRequired(),
});

const nameof = nameofFactory<IOpportunityCategoryVariable>();

function EditFormVariable(props: BaseFormProps<IOpportunityCategoryVariable>) {
  const { itemToUpdate, onSubmit, onCancel, onSuccess } = props;
  const { t } = useTranslation();

  const submitter = useFormikAPISubmitter<IOpportunityCategoryVariable>((val) => onSubmit(val), [onSubmit], onSuccess);

  const initialValues: IOpportunityCategoryVariable = {
    variableId: itemToUpdate?.variableId || nanoid(),
    scaleMinimum: itemToUpdate?.scaleMinimum || 0,
    scaleMaximum: itemToUpdate?.scaleMaximum || 0,
    variableName: itemToUpdate?.variableName || "",
    description: itemToUpdate?.description || "",
    scaleSections: [],
  };

  return (
    <>
      {itemToUpdate ? (
        <h6>
          {t("Edit")}: {itemToUpdate.variableName}
        </h6>
      ) : (
        <h6>{t("New")}</h6>
      )}
      <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={validationSchema}>
        {(formikBag) => (
          <FormikCustomForm formikBag={formikBag} onCancel={onCancel}>
            <FormikTextInputGroup label={t("Title")} name={nameof("variableName")} />
            <FormikNumberInputGroup label={t("From")} name={nameof("scaleMinimum")} />
            <FormikNumberInputGroup label={t("To")} name={nameof("scaleMaximum")} />
            <FormikTextInputGroup name={nameof("description")} textarea rows={3} />
          </FormikCustomForm>
        )}
      </Formik>
    </>
  );
}

export default EditFormVariable;
