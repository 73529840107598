import i18next from "i18next";
import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { Column } from "react-table";

import { IOpportunityCategoryVariable } from "@generatedCode/pbd-core/pbd-core-api";

import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";
import BaseTableProps from "../../../shared/components/tables/BaseTableProps";
import { ReactstrapTable } from "../../../shared/components/tables/reactstrapTable";

type IProps = BaseTableProps<IOpportunityCategoryVariable>;

export function VariablesTable(props: IProps) {
  const { onRowClick } = props;

  const columns = React.useMemo<Column<IOpportunityCategoryVariable>[]>(
    () => [
      {
        id: "id",
        Header: <>{i18next.t("ID")}</>,
        accessor: "variableId",
        Cell: ({ row }) => <>{row.original.variableId}</>,
      },
      {
        id: "title",
        Header: <>{i18next.t("Title")}</>,
        accessor: "variableName",
        Cell: ({ row }) => <>{row.original.variableName}</>,
      },
      {
        id: "scaleMinimum",
        Header: <>{i18next.t("Minimum")}</>,
        accessor: "scaleMinimum",
        Cell: ({ row }) => <>{row.original.scaleMinimum}</>,
      },
      {
        id: "scaleMaximum",
        Header: <>{i18next.t("Maximum")}</>,
        accessor: "scaleMaximum",
        Cell: ({ row }) => <>{row.original.scaleMaximum}</>,
      },
      {
        id: "editColumn",
        accessor: "variableId", // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ row }) => (
          <>
            {onRowClick && (
              <ButtonGroup size="sm">
                <Button variant="link" onClick={() => onRowClick({ row: row.original, action: "Select" })}>
                  <qmBaseIcons.Pencil /> {i18next.t("Edit")}
                </Button>
                <Button variant="link" onClick={() => onRowClick({ row: row.original, action: "Details" })}>
                  {i18next.t("Scale sections")} ({row.original.scaleSections.length})
                </Button>
                <Button variant="danger" onClick={() => onRowClick({ row: row.original, action: "Delete" })}>
                  <qmBaseIcons.Delete />
                </Button>
              </ButtonGroup>
            )}
          </>
        ),
      },
    ],
    [],
  );

  return (
    <ReactstrapTable<IOpportunityCategoryVariable>
      columns={columns}
      columnsVisibleDefault={["title", "scaleMinimum", "scaleMaximum", "editColumn"]}
      localStorageStateKey={"BoundariesTable"}
      {...props}
    />
  );
}
