import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { DecodedValueMap, SetQuery } from "use-query-params";

import {
  ClaimType,
  IAddressDTO,
  IAddressRoomDTO,
  ICategoryDTO,
  ICustomFormDTO,
  IDepartmentDTO,
  IOrganisationMinDTO,
  ITenantMinDTO,
  PbdModule,
  PbdStatus,
} from "@generatedCode/pbd-core/pbd-core-api";

import { GlobalQmBaseConstants } from "../../../../Constants/GlobalQmBaseConstants";
import { useAPIServices, useAPIs } from "../../../../pbdServices/services/service-context";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import InventoryFunctioningSelect from "../../../maintenanceManagement/components/inventoryFunctioningSelect";
import InventoryItemSelect from "../../../maintenanceManagement/components/inventoryItemSelect";
import { useToggle } from "../../hooks/useToggle";
import { qmBaseIcons } from "../icons/qmBaseIcons";
import AbsencePolicySelect from "../inputControl/select/absencePolicySelect";
import AddressSelectAsync from "../inputControl/select/addressSelectAsync";
import AuditRequirementSelect from "../inputControl/select/auditRequirementSelect";
import { CategorySelect } from "../inputControl/select/categorySelect";
import ClaimSelect from "../inputControl/select/claimSelect";
import ClaimTypeSelect from "../inputControl/select/claimTypeSelect";
import CustomFormSelect from "../inputControl/select/customFormSelect";
import DepartmentPositionSelect from "../inputControl/select/departmentPositionSelect";
import DepartmentSelect from "../inputControl/select/departmentSelect";
import GenericSelect, { mapToOptionData } from "../inputControl/select/genericSelect";
import GoalSelect from "../inputControl/select/goalSelect";
import GroupSelect from "../inputControl/select/groupSelect";
import InventoryCategorySelect from "../inputControl/select/inventoryCategorySelect";
import InventoryInspectionSelect from "../inputControl/select/inventoryInspectionsSelect";
import InventoryStatusSelect from "../inputControl/select/inventoryStatusSelect";
import OpportunityCategorySelect from "../inputControl/select/opportunityCategorySelect";
import OpportunityTypeSelect from "../inputControl/select/opportunityTypeSelect";
import { OrganisationSelect } from "../inputControl/select/organisationSelect";
import ProductSelect from "../inputControl/select/productSelect";
import QualificationSelect from "../inputControl/select/qualificationSelect";
import StatusSelect from "../inputControl/select/statusSelect";
import TagSelect from "../inputControl/select/tagSelect";
import TenantSelectAsync from "../inputControl/select/tenantSelectAsync";

import { FilterElement, SearchFilterTypes } from "./availableSearchFilters";
import CustomFieldSearchComponent from "./customFieldSearchComponent";
import DateRangeFilter from "./dateRangeFilter";
import { QueryParamsConfigPbd } from "./queryParamsConfigPbd";

interface IProps {
  type: FilterElement;
  /** to select the custom fields with respect to the selected module. */
  pbdModule?: PbdModule;
  query: DecodedValueMap<typeof QueryParamsConfigPbd>;
  setQuery: SetQuery<typeof QueryParamsConfigPbd>;
  defaultValue?: string;
  onFilterChanged?: () => void;
}

// eslint-disable-next-line max-lines-per-function
function RenderSearchFilterLegacy(props: IProps) {
  const { type, pbdModule, query, setQuery, onFilterChanged } = props;
  const { t } = useTranslation();
  const {
    usersApi,
    goalsApi,
    addressesApi,
    organisationsApi,
    claimsApi,
    customFormsApi,
    departmentsApi,
    absencePoliciesApi,
    qualificationsApi,
    projectsApi,
    groupsApi,
    productsApi,
  } = useAPIs();
  const { tenantService } = useAPIServices();
  const [auditRequirement, setAuditRequirement] = React.useState<number[]>();
  const [departments, setDepartments] = React.useState<IDepartmentDTO[]>();
  const [departmentPositions, setDepartmentPositions] = React.useState<number[]>();
  const [customForm, setCustomForm] = React.useState<number[]>();
  const [showAllTenants, toggleShowAllTenants] = useToggle();

  const { data: userData } = useSWR(
    type.type == SearchFilterTypes.ApplicationUser ? "/api/identity/users" : null,
    () => usersApi.getAll(),
    { revalidateOnFocus: false },
  );
  const { data: projectData } = useSWR(
    type.type == SearchFilterTypes.Project ? "/api/projects" : null,
    () => projectsApi.getAll(),
    { revalidateOnFocus: false },
  );

  const { data: owner } = useSWR(
    query.ownerId ? ["/api/tenants/owners", query.ownerId] : null,
    () => tenantService.getAllQuery({ id: query.ownerId }),
    { revalidateOnFocus: false },
  );

  const { data: responsible } = useSWR(
    query.responsibleId ? ["/api/tenants/responsible", query.responsibleId] : null,
    () => tenantService.getAllQuery({ id: query.responsibleId }),
    { revalidateOnFocus: false },
  );

  const { data: contributor } = useSWR(
    query.contributorId ? ["/api/tenants/contributors", query.contributorId] : null,
    () => tenantService.getAllQuery({ id: query.contributorId }),
    { revalidateOnFocus: false },
  );

  const { data: reviewer } = useSWR(
    query.reviewerId ? ["/api/tenants/contributors", query.reviewerId] : null,
    () => tenantService.getAllQuery({ id: query.reviewerId }),
    { revalidateOnFocus: false },
  );

  const { data: approver } = useSWR(
    query.approvedById ? ["/api/tenants/approver", query.approvedById] : null,
    () => tenantService.getAllQuery({ id: query.approvedById }),
    { revalidateOnFocus: false },
  );

  const { data: tenants } = useSWR(
    query.tenantId ? ["/api/tenants", query.tenantId] : null,
    () => tenantService.getAllQuery({ id: query.tenantId }),
    { revalidateOnFocus: false },
  );

  const { data: attendee } = useSWR(
    query.attendeeId ? ["/api/tenants", query.attendeeId] : null,
    () => tenantService.getAllQuery({ id: query.attendeeId }),
    { revalidateOnFocus: false },
  );

  const { data: absentFor } = useSWR(
    query.absentForId ? ["/api/tenants", query.absentForId] : null,
    () => tenantService.getAllQuery({ id: query.absentForId }),
    { revalidateOnFocus: false },
  );

  const { data: qualifications } = useSWR(
    query.qualificationId ? ["/api/qualifications/", query.qualificationId] : null,
    () => qualificationsApi.getAllQuery({ id: query.qualificationId }),
    { revalidateOnFocus: false },
  );

  const { data: organisations } = useSWR(
    query.organisationId ? ["/api/organisations/", query.organisationId] : null,
    () => organisationsApi.getAllQuery({ id: query.organisationId }),
    { revalidateOnFocus: false },
  );

  const { data: claims } = useSWR(
    query.claimId ? ["/api/claims/", query.claimId] : null,
    () => claimsApi.getAllQuery({ id: query.claimId }),
    { revalidateOnFocus: false },
  );

  const { data: goals } = useSWR(
    query.goalId ? ["/api/golas/", query.goalId] : null,
    () => goalsApi.getAllQuery({ id: query.goalId }),
    { revalidateOnFocus: false },
  );

  const { data: supportingPerson } = useSWR(
    query.supporterId ? ["/api/tenants/supportingPersons", query.supporterId] : null,
    () => tenantService.getAllQuery({ id: query.supporterId }),
    { revalidateOnFocus: false },
  );

  const { data: createdBy } = useSWR(
    query.createdById ? ["/api/tenants/createdBy", query.createdById] : null,
    () => tenantService.getAllQuery({ id: query.createdById }),
    { revalidateOnFocus: false },
  );

  const { data: absencePolicies } = useSWR(
    query.absencePolicyId ? ["/api/absencePolicies", query.absencePolicyId] : null,
    () => absencePoliciesApi.getAllQuery({ id: query.absencePolicyId }),
    { revalidateOnFocus: false },
  );

  const { data: applicationGroups } = useSWR(
    query.absencePolicyId ? ["/api/identity/groups", query.applicationGroupId] : null,
    () => groupsApi.getAllQuery({ id: query.applicationGroupId }),
    { revalidateOnFocus: false },
  );

  const { data: addresses } = useSWR(
    query.addressId ? ["/api/addresses", query.addressId] : null,
    () => addressesApi.getAllQuery({ id: query.addressId }),
    { revalidateOnFocus: false },
  );

  const { data: addressRooms } = useSWR(
    query.addressRoomId ? ["/api/addresses", query.addressRoomId, "addressRoomQueryDiscriminator"] : null,
    () =>
      addressesApi.getAllQuery({ addressRoomId: query.addressRoomId }).then((resp) =>
        resp.data
          .filterMap((x) => x.addressRooms)
          .reduce((pv, cv) => pv.concat(cv))
          .filter((x) => query.addressRoomId?.includes(x.id)),
      ),
    { revalidateOnFocus: false },
  );

  const { data: product } = useSWR(
    query.productId ? ["/api/products/", query.productId] : null,
    () =>
      productsApi.getAllQuery({ id: query.productId, pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration }),
    { revalidateOnFocus: false },
  );

  useEffect(() => {
    async function getForms() {
      const data: ICustomFormDTO[] = [];
      if (query.customFormId != null && query.customFormId.length > 0) {
        for (const entry of query.customFormId) {
          const form = await customFormsApi.getById(Number(entry));
          data.push(form);
        }
      }
      setCustomForm(data.map((x) => x.id));
    }

    function getAuditRequirements() {
      const data: number[] = [];
      if (query.auditRequirement != null && query.auditRequirement.length > 0) {
        for (const entry of query.auditRequirement) {
          data.push(entry);
        }
      }
      setAuditRequirement(data);
    }

    async function getDepartments() {
      if (query.departmentId != null && query.departmentId.length > 0) {
        const departments = await departmentsApi.getAllQuery({ id: query.departmentId });
        setDepartments(departments);
      }
    }

    function getDepartmentPositions() {
      if (query.departmentPositionId) {
        setDepartmentPositions(query.departmentPositionId);
      }
    }

    getDepartments();
    getDepartmentPositions();
    getAuditRequirements();
    getForms();
  }, [
    customFormsApi,
    departmentsApi,
    query.auditRequirement,
    query.customFormId,
    query.departmentId,
    query.departmentPositionId,
  ]);

  function handleOrganisationChange(item?: IOrganisationMinDTO | IOrganisationMinDTO[]) {
    if (item == null) {
      setQuery({ organisationId: [] });
    } else if (Array.isArray(item)) {
      setQuery({ organisationId: item.map((x) => x.id) });
    } else {
      setQuery({ organisationId: [item.id] });
    }
  }

  function onChangeResponsibleId(item?: ITenantMinDTO | ITenantMinDTO[]) {
    if (item) {
      if (Array.isArray(item)) {
        setQuery({ responsibleId: item.map((x) => x.id) });
      } else {
        setQuery({ responsibleId: [item.id] });
      }
    } else {
      setQuery({ responsibleId: [] });
    }
  }

  function onChangeOwnerId(item?: ITenantMinDTO | ITenantMinDTO[]) {
    if (item) {
      if (Array.isArray(item)) {
        setQuery({ ownerId: item.map((x) => x.id) });
      } else {
        setQuery({ ownerId: [item.id] });
      }
    } else {
      setQuery({ ownerId: [] });
    }
  }

  function onChangeCreatedById(item?: ITenantMinDTO | ITenantMinDTO[]) {
    if (item) {
      if (Array.isArray(item)) {
        setQuery({ createdById: item.map((x) => x.id) });
      } else {
        setQuery({ createdById: [item.id] });
      }
    } else {
      setQuery({ createdById: undefined });
    }
  }

  function onChangeReviewedById(item?: ITenantMinDTO | ITenantMinDTO[]) {
    if (item) {
      if (Array.isArray(item)) {
        setQuery({ reviewerId: item.map((x) => x.id) });
      } else {
        setQuery({ reviewerId: [item.id] });
      }
    } else {
      setQuery({ reviewerId: [] });
    }
    onFilterChanged?.();
  }

  function onChangeApprovedById(item?: ITenantMinDTO | ITenantMinDTO[]) {
    if (item) {
      if (Array.isArray(item)) {
        setQuery({ approvedById: item.map((x) => x.id) });
      } else {
        setQuery({ approvedById: [item.id] });
      }
    } else {
      setQuery({ approvedById: [] });
    }
  }

  function onChangeContributorById(item?: ITenantMinDTO | ITenantMinDTO[]) {
    if (item) {
      if (Array.isArray(item)) {
        setQuery({ contributorId: item.map((x) => x.id) });
      } else {
        setQuery({ contributorId: [item.id] });
      }
    } else {
      setQuery({ contributorId: [] });
    }
  }

  const onChangeTenant = (item?: ITenantMinDTO[]) => {
    if (item) {
      setQuery({ tenantId: item.map((x) => x.id) });
    } else {
      setQuery({ tenantId: undefined });
    }
  };

  function onChangeAttendeeId(item?: ITenantMinDTO | ITenantMinDTO[]) {
    if (item == null) {
      // setOrganisations([]);
      setQuery({ attendeeId: [] });
    } else if (Array.isArray(item)) {
      // setOrganisations(item);
      if (Array(item).length == 0) {
        setQuery({ attendeeId: [] });
      } else {
        setQuery({ attendeeId: item.map((x) => x.id) });
      }
    } else {
      // setOrganisations([item]);
      setQuery({ attendeeId: [item.id] });
    }
  }

  function onChangeClaim(item?: number | number[]) {
    if (item) {
      if (Array.isArray(item)) {
        setQuery({ claimId: item });
      } else {
        setQuery({ claimId: [item] });
      }
    } else {
      setQuery({ claimId: [] });
    }
  }

  function onChangeGoal(item?: number | number[]) {
    if (item) {
      if (Array.isArray(item)) {
        setQuery({ goalId: item });
      } else {
        setQuery({ goalId: [item] });
      }
    } else {
      setQuery({ goalId: [] });
    }
  }

  function onChangeCustomForm(item?: number | number[]) {
    if (item) {
      if (Array.isArray(item)) {
        setQuery({ customFormId: item });
      } else {
        setQuery({ customFormId: [item] });
      }
    } else {
      setQuery({ customFormId: [] });
    }
  }

  function onChangeAuditRequirement(item?: number | number[]) {
    if (item) {
      if (Array.isArray(item)) {
        setQuery({ auditRequirement: item });
      } else {
        setQuery({ auditRequirement: [item] });
      }
    } else {
      setQuery({ auditRequirement: [] });
    }
  }

  function onInventoryStatusChange(id?: number | number[]) {
    if (id == null) {
      setQuery({ inventoryStatusId: undefined });
    } else {
      if (Array.isArray(id)) {
        setQuery({ inventoryStatusId: id });
      } else {
        setQuery({ inventoryStatusId: [id] });
      }
    }
  }

  function onInventoryCategoryChange(id?: number | number[]) {
    if (id == null) {
      setQuery({ inventoryCategoryId: undefined });
    } else {
      if (Array.isArray(id)) {
        setQuery({ inventoryCategoryId: id });
      } else {
        setQuery({ inventoryCategoryId: [id] });
      }
    }
  }

  function onApplicationGroupChange(id?: string | string[]) {
    if (id == null) {
      setQuery({ applicationGroupId: undefined });
    } else {
      if (Array.isArray(id)) {
        setQuery({ applicationGroupId: id });
      } else {
        setQuery({ applicationGroupId: [id] });
      }
    }
  }

  function onInventoryInspectionChange(id?: number | number[]) {
    if (id == null) {
      setQuery({ inventoryInspectionId: undefined });
    } else {
      if (Array.isArray(id)) {
        setQuery({ inventoryInspectionId: id.map((x) => x) });
      } else {
        setQuery({ inventoryInspectionId: [id] });
      }
    }
  }

  function onInventoryItemChange(id?: number | number[]) {
    if (id == null) {
      setQuery({ inventoryItemId: undefined });
    } else {
      if (Array.isArray(id)) {
        setQuery({ inventoryItemId: id.map((x) => x) });
      } else {
        setQuery({ inventoryItemId: [id] });
      }
    }
  }

  function onFunctioningChange(id?: string | string[]) {
    if (id == null) {
      setQuery({ functioning: undefined });
      // setFunctioning(null);
    } else {
      if (Array.isArray(id)) {
        setQuery({ functioning: id.map((x) => x.toString()) });
      } else {
        setQuery({ functioning: [id.toString()] });
      }
    }
  }

  function onOpportunityCategoryChange(item?: ICategoryDTO | ICategoryDTO[]) {
    if (item == null) {
      setQuery({ opportunityCategoryId: undefined });
    } else {
      if (Array.isArray(item)) {
        setQuery({ opportunityCategoryId: item.map((x) => x.id) });
      } else {
        setQuery({ opportunityCategoryId: [item.id] });
      }
    }
  }

  function onStatusChange(id?: PbdStatus | PbdStatus[]) {
    if (id == null) {
      setQuery({ status: undefined });
    } else {
      if (Array.isArray(id)) {
        setQuery({ status: id });
      } else {
        setQuery({ status: [id] });
      }
    }
  }

  function onClaimTypeChange(id?: ClaimType | ClaimType[]) {
    if (id == null) {
      setQuery({ claimType: undefined });
    } else {
      if (Array.isArray(id)) {
        setQuery({ claimType: id });
      } else {
        setQuery({ claimType: [id] });
      }
    }
  }

  function onTagChange(id?: string | string[]) {
    if (id == null) {
      setQuery({ tags: undefined });
    } else {
      if (Array.isArray(id)) {
        setQuery({ tags: id.map((x) => x) });
      } else {
        setQuery({ tags: [id] });
      }
      onFilterChanged?.();
    }
  }

  const onChangeLocation = (addresses?: IAddressDTO[], rooms?: IAddressRoomDTO[]) => {
    setQuery({ addressId: addresses?.map((x) => x.id) });
    setQuery({ addressRoomId: rooms?.map((x) => x.id) });
  };

  function onChangeAbsentForId(item?: ITenantMinDTO | ITenantMinDTO[]) {
    if (item == null) {
      setQuery({ absentForId: undefined });
    } else if (Array.isArray(item)) {
      if (Array(item).length == 0) {
        setQuery({ absentForId: [] });
      } else {
        setQuery({ absentForId: item.map((x) => x.id) });
      }
    } else {
      // setOrganisations([item]);
      setQuery({ absentForId: [item.id] });
    }
  }

  function onChangeAbsencePolicyId(item?: number | number[]) {
    if (item == null) {
      setQuery({ absencePolicyId: undefined });
    } else if (Array.isArray(item)) {
      if (Array(item).length == 0) {
        setQuery({ absencePolicyId: [] });
      } else {
        setQuery({ absencePolicyId: item });
      }
    } else {
      // setOrganisations([item]);
      setQuery({ absencePolicyId: [item] });
    }
  }

  function onChangeDepartmentId(item?: number | number[]) {
    if (item == null) {
      setQuery({ departmentId: [] });
    } else if (Array.isArray(item)) {
      if (Array(item).length == 0) {
        setQuery({ departmentId: [] });
      } else {
        setQuery({ departmentId: item });
      }
    } else {
      setQuery({ departmentId: [item] });
    }
  }
  function onChangeDepartmentPositionId(item?: number | number[]) {
    if (item == null) {
      setQuery({ departmentPositionId: [] });
    } else if (Array.isArray(item)) {
      if (Array(item).length == 0) {
        setQuery({ departmentPositionId: [] });
      } else {
        setQuery({ departmentPositionId: item });
      }
    } else {
      setQuery({ departmentPositionId: [item] });
    }
  }

  function onChangeOpportunityType(item?: string | string[]) {
    if (item == null) {
      setQuery({ opportunityType: [] });
    } else if (Array.isArray(item)) {
      if (Array(item).length == 0) {
        setQuery({ opportunityType: [] });
      } else {
        setQuery({ opportunityType: item.map((x) => x) });
      }
    } else {
      setQuery({ opportunityType: [item] });
    }
  }

  function onChangeCustomField(item?: string[] | string) {
    if (item) {
      if (Array.isArray(item)) {
        setQuery({ customFields: item });
      } else {
        setQuery({ customFields: [item] });
      }
    } else {
      setQuery({ customFields: [] });
    }
  }

  const onChangeCategory = (item?: number[]) => {
    if (item) {
      setQuery({ categoryId: item });
    } else {
      setQuery({ categoryId: undefined });
    }
  };

  function onChangeSupportingPerson(item?: ITenantMinDTO | ITenantMinDTO[]) {
    if (item) {
      if (Array.isArray(item)) {
        setQuery({ supporterId: item.map((x) => x.id) });
      } else {
        setQuery({ supporterId: [item.id] });
      }
    } else {
      setQuery({ supporterId: [] });
    }
  }

  function onChangeProductId(item?: number | number[]) {
    if (item) {
      if (Array.isArray(item)) {
        setQuery({ productId: item });
      } else {
        setQuery({ productId: [item] });
      }
    } else {
      setQuery({ productId: [] });
    }
  }

  switch (type.type) {
    case SearchFilterTypes.Status:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Status")}</Form.Label>
          <StatusSelect
            isMulti
            isClearable
            onChange={onStatusChange}
            selectedIds={query.status}
            availableOptions={type.statusOptions}
          />
        </Form.Group>
      );
    case SearchFilterTypes.Responsible:
      return (
        <Form.Group className="mb-3">
          <div className="d-flex justify-content-between">
            <Form.Label>{t("Responsible")}</Form.Label>
            <Button
              variant="link"
              size="sm"
              active={!showAllTenants}
              onClick={toggleShowAllTenants}
              title={showAllTenants ? t("All persons") : t("Filtered by employees")}
            >
              {showAllTenants ? <qmBaseIcons.FilterXmark /> : <qmBaseIcons.Filter />}
            </Button>
          </div>
          <TenantSelectAsync
            onChange={onChangeResponsibleId}
            query={
              showAllTenants
                ? {
                    isEmployee: undefined,
                  }
                : undefined
            }
            selected={responsible}
            isMulti
            isClearable
          />
        </Form.Group>
      );
    case SearchFilterTypes.Owner:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Owner")}</Form.Label>
          <TenantSelectAsync
            onChange={onChangeOwnerId}
            selected={owner}
            query={{ roleName: PbdRoles.IdeaManagement_Manager }}
            isMulti
            isClearable
          />
        </Form.Group>
      );
    case SearchFilterTypes.CreatedBy:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Created by")}</Form.Label>
          <TenantSelectAsync onChange={onChangeCreatedById} selected={createdBy} isMulti isClearable />
        </Form.Group>
      );

    case SearchFilterTypes.ReviewerId:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Review by")}</Form.Label>
          <TenantSelectAsync onChange={onChangeReviewedById} selected={reviewer} isMulti isClearable />
        </Form.Group>
      );
    case SearchFilterTypes.ApprovedById:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Approve by")}</Form.Label>
          <TenantSelectAsync onChange={onChangeApprovedById} selected={approver} isMulti isClearable />
        </Form.Group>
      );
    case SearchFilterTypes.ContributorId:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Contributor")}</Form.Label>
          <TenantSelectAsync onChange={onChangeContributorById} selected={contributor} isMulti isClearable />
        </Form.Group>
      );
    case SearchFilterTypes.Tenant:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Persons")}</Form.Label>
          <TenantSelectAsync
            onChange={(ids) => onChangeTenant(ids as ITenantMinDTO[])}
            selected={tenants}
            isMulti
            isClearable
          />
        </Form.Group>
      );
    case SearchFilterTypes.Attendees:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Attendees")}</Form.Label>
          <TenantSelectAsync onChange={onChangeAttendeeId} selected={attendee} isMulti isClearable />
        </Form.Group>
      );
    case SearchFilterTypes.Claim:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Claim")}</Form.Label>
          <ClaimSelect onChange={onChangeClaim} selectedIds={claims?.map((x) => x.id)} isMulti isClearable />
        </Form.Group>
      );
    case SearchFilterTypes.Goal:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Goal")}</Form.Label>
          <GoalSelect onChange={onChangeGoal} selectedIds={goals?.map((x) => x.id)} isMulti isClearable />
        </Form.Group>
      );
    case SearchFilterTypes.CustomForm:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Form")}</Form.Label>
          <CustomFormSelect onChange={onChangeCustomForm} selectedIds={customForm} isClearable />
        </Form.Group>
      );
    case SearchFilterTypes.AuditRequirement:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Audit requirement")}</Form.Label>
          <AuditRequirementSelect
            onChange={onChangeAuditRequirement}
            selectedIds={auditRequirement}
            displayParentsOnly
            isMulti
            isClearable
          />
        </Form.Group>
      );
    case SearchFilterTypes.ClaimType:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Claim type")}</Form.Label>
          <ClaimTypeSelect onChange={onClaimTypeChange} selectedIds={query.claimType} isMulti isClearable />
        </Form.Group>
      );
    case SearchFilterTypes.Organisation:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Organisation")}</Form.Label>
          <OrganisationSelect onChange={handleOrganisationChange} selected={organisations?.data} isClearable isMulti />
        </Form.Group>
      );
    case SearchFilterTypes.CreatedAt:
      return (
        <DateRangeFilter
          label={t("Created")}
          from={query.createdFrom}
          to={query.createdTo}
          setFrom={(e) => setQuery({ createdFrom: e })}
          setTo={(e) => setQuery({ createdTo: e })}
        />
      );
    case SearchFilterTypes.ClaimedAt:
      return (
        <DateRangeFilter
          label={t("Claimed At")}
          from={query.claimedFrom}
          to={query.claimedTo}
          setFrom={(e) => setQuery({ claimedFrom: e })}
          setTo={(e) => setQuery({ claimedTo: e })}
        />
      );
    case SearchFilterTypes.NextInspectionAt:
      return (
        <DateRangeFilter
          label={t("Next inspection")}
          from={query.nextInspectionFrom}
          to={query.nextInspectionTo}
          setFrom={(e) => setQuery({ nextInspectionFrom: e })}
          setTo={(e) => setQuery({ nextInspectionTo: e })}
        />
      );
    case SearchFilterTypes.DoneAt:
      return (
        <DateRangeFilter
          label={t("Done")}
          from={query.doneFrom}
          to={query.doneTo}
          setFrom={(e) => setQuery({ doneFrom: e })}
          setTo={(e) => setQuery({ doneTo: e })}
        />
      );
    case SearchFilterTypes.LastUpdatedAt:
      return (
        <DateRangeFilter
          label={t("Updated")}
          from={query.lastUpdatedFrom}
          to={query.lastUpdatedTo}
          setFrom={(e) => setQuery({ lastUpdatedFrom: e })}
          setTo={(e) => setQuery({ lastUpdatedTo: e })}
        />
      );
    case SearchFilterTypes.Deadline:
      return (
        <DateRangeFilter
          label={t("Deadline")}
          from={query.deadlineFrom}
          to={query.deadlineTo}
          setFrom={(e) => setQuery({ deadlineFrom: e })}
          setTo={(e) => setQuery({ deadlineTo: e })}
        />
      );
    case SearchFilterTypes.PublishedAt:
      return (
        <DateRangeFilter
          label={t("Published At")}
          from={query.publishedFrom}
          to={query.publishedTo}
          setFrom={(e) => {
            setQuery({ publishedFrom: e });
            onFilterChanged?.();
          }}
          setTo={(e) => {
            setQuery({ publishedTo: e });
            onFilterChanged?.();
          }}
        />
      );
    case SearchFilterTypes.OnlyLatestRevision:
      return (
        <Form.Group className="mb-3">
          <Form.Check
            type="switch"
            id="onlyLatestRevision"
            onChange={() => {
              setQuery({ onlyLatestRevision: !query.onlyLatestRevision });
              onFilterChanged?.();
            }}
            label={t("Only latest revision")}
            defaultChecked={query.onlyLatestRevision ?? undefined}
          />
        </Form.Group>
      );
    case SearchFilterTypes.PlannedAt:
      return (
        <DateRangeFilter
          label={t("Planned")}
          from={query.plannedFrom}
          to={query.plannedTo}
          setFrom={(e) => setQuery({ plannedFrom: e })}
          setTo={(e) => setQuery({ plannedTo: e })}
        />
      );
    case SearchFilterTypes.Tags:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Tags")}</Form.Label>
          <TagSelect onChange={onTagChange} isMulti isClearable selectedIds={query.tags} />
        </Form.Group>
      );
    case SearchFilterTypes.InventoryStatus:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Status")}</Form.Label>
          <InventoryStatusSelect
            onChange={onInventoryStatusChange}
            isMulti
            isClearable
            selectedIds={query.inventoryStatusId}
          />
        </Form.Group>
      );
    case SearchFilterTypes.InventoryCategory:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Category")}</Form.Label>
          <InventoryCategorySelect
            onChange={onInventoryCategoryChange}
            isMulti={true}
            isClearable={true}
            selectedIds={query.inventoryCategoryId}
          />
        </Form.Group>
      );
    case SearchFilterTypes.InventoryInspection:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Inspection")}</Form.Label>
          <InventoryInspectionSelect
            onChange={onInventoryInspectionChange}
            isMulti={true}
            isClearable={true}
            selectedIds={query.inventoryInspectionId}
          />
        </Form.Group>
      );
    case SearchFilterTypes.InventoryItem:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Inventory item")}</Form.Label>
          <InventoryItemSelect
            onChange={onInventoryItemChange}
            isMulti
            isClearable
            selectedIds={query.inventoryItemId}
          />
        </Form.Group>
      );
    case SearchFilterTypes.Functioning:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Functioning")}</Form.Label>
          <InventoryFunctioningSelect
            onChange={onFunctioningChange}
            isMulti={true}
            isClearable={true}
            selectedIds={query.functioning}
          />
        </Form.Group>
      );
    case SearchFilterTypes.AbsentAt:
      return (
        <DateRangeFilter
          label={t("Absent date")}
          from={query.absentFrom}
          to={query.absentTo}
          setFrom={(e) => setQuery({ absentFrom: e })}
          setTo={(e) => setQuery({ absentTo: e })}
        />
      );

    case SearchFilterTypes.AbsentFor:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Absent for")}</Form.Label>
          <TenantSelectAsync onChange={onChangeAbsentForId} selected={absentFor} isMulti isClearable />
        </Form.Group>
      );

    case SearchFilterTypes.AbsencePolicy:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Policy")}</Form.Label>
          <AbsencePolicySelect
            activityStatus={PbdStatus.Active}
            onChange={onChangeAbsencePolicyId}
            selectedIds={absencePolicies?.map((x) => x.id)}
            isMulti
            isClearable
          />
        </Form.Group>
      );

    case SearchFilterTypes.Department:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Department")}</Form.Label>
          <DepartmentSelect
            selectedIds={departments?.map((x) => x.id)}
            onChange={onChangeDepartmentId}
            isMulti
            isClearable
          />
        </Form.Group>
      );

    case SearchFilterTypes.DepartmentPosition:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Position")}</Form.Label>
          <DepartmentPositionSelect selectedIds={departmentPositions} onChange={onChangeDepartmentPositionId} isMulti />
        </Form.Group>
      );
    case SearchFilterTypes.IsDeleted:
      return (
        <Form.Group className="mb-3">
          <Form.Check
            type="switch"
            id="isDeleted"
            onChange={() => setQuery({ isDeleted: !query.isDeleted })}
            name="customSwitch"
            label={t("Deleted")}
            defaultChecked={query.isDeleted ?? undefined}
          />
        </Form.Group>
      );
    case SearchFilterTypes.IsCustomer:
      return (
        <Form.Group className="mb-3">
          <Form.Check
            type="switch"
            id="isCustomer"
            onChange={() => setQuery({ isCustomer: !query.isCustomer })}
            name="customSwitch"
            label={t("Customer")}
            defaultChecked={query.isCustomer}
          />
        </Form.Group>
      );

    case SearchFilterTypes.IsSupplier:
      return (
        <Form.Group className="mb-3">
          <Form.Check
            type="switch"
            id="isSupplier"
            onChange={() => setQuery({ isSupplier: !query.isSupplier })}
            name="customSwitch"
            label={t("Supplier")}
            defaultChecked={query.isSupplier}
          />
        </Form.Group>
      );
    case SearchFilterTypes.OpportunityType:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Type")}</Form.Label>
          <OpportunityTypeSelect isMulti onChange={onChangeOpportunityType} selectedIds={query.opportunityType} />
        </Form.Group>
      );
    case SearchFilterTypes.OpportunityCategory:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Category")}</Form.Label>
          <OpportunityCategorySelect
            onChange={onOpportunityCategoryChange}
            // selectedIds={[query.opportunityCategoryId?.map(x => Number(x))]}
          />
        </Form.Group>
      );
    case SearchFilterTypes.Qualification:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Qualification")}</Form.Label>
          <QualificationSelect
            onChange={(ids) => {
              if (ids) {
                setQuery({ qualificationId: Array.isArray(ids) ? ids : [ids] });
              } else {
                setQuery({ qualificationId: undefined });
              }
            }}
            isMulti
            selected={qualifications}
          />
        </Form.Group>
      );

    case SearchFilterTypes.Address:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Addresses")}</Form.Label>
          <AddressSelectAsync
            value={addresses?.data}
            room={addressRooms}
            onChange={onChangeLocation}
            includeRoomSelect
            isMulti
          />
        </Form.Group>
      );

    case SearchFilterTypes.Category:
      if (!type.categoryOptions) throw Error("Missing options");
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Category")}</Form.Label>
          <CategorySelect
            availableOptions={type.categoryOptions}
            defaultValue={query.categoryId}
            onChange={onChangeCategory}
            isMulti
          />
        </Form.Group>
      );
    case SearchFilterTypes.CustomField:
      if (pbdModule) {
        return (
          <CustomFieldSearchComponent
            defaultValue={query.customFields}
            onChange={onChangeCustomField}
            pbdModule={pbdModule}
          />
        );
      } else {
        return null;
      }
    case SearchFilterTypes.ApplicationUser:
      return (
        <GenericSelect
          name="usersSelect"
          data={
            userData
              ? mapToOptionData(
                  userData.map((x) => {
                    return { id: x.id, title: x.userName };
                  }),
                )
              : undefined
          }
          selected={query.userId}
          isMulti
          onChange={(x) => setQuery({ userId: x ? x.map((y) => y.value) : undefined })}
        />
      );
    case SearchFilterTypes.ApplicationGroup:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Group")}</Form.Label>
          <GroupSelect
            onChange={onApplicationGroupChange}
            isMulti={true}
            isClearable={true}
            selectedIds={query.applicationGroupId}
          />
        </Form.Group>
      );
    case SearchFilterTypes.IsEmailConfirmed:
      return (
        <Form.Group className="mb-3">
          <Form.Check
            type="switch"
            id="isEmailConfirmed"
            onChange={(e) => setQuery({ isEmailConfirmed: e.target.checked })}
            name="customSwitch"
            label={t("IsEmailConfirmed")}
            defaultChecked={query.isEmailConfirmed}
          />
        </Form.Group>
      );
    case SearchFilterTypes.Project:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Project")}</Form.Label>
          <GenericSelect
            name="projectSelect"
            data={
              projectData
                ? mapToOptionData(
                    projectData.map((x) => {
                      return { id: x.id, title: x.title };
                    }),
                  )
                : undefined
            }
            selected={query.projectId}
            isMulti
            onChange={(x) => setQuery({ projectId: x ? x.map((y) => Number(y.value)) : undefined })}
          />
        </Form.Group>
      );
    case SearchFilterTypes.SupporterId:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Supporter")}</Form.Label>
          <TenantSelectAsync onChange={onChangeSupportingPerson} selected={supportingPerson} isMulti isClearable />
        </Form.Group>
      );
    case SearchFilterTypes.claimIsJustified:
      return (
        <Form.Group className="mb-3">
          <Form.Check
            type="switch"
            id="claimIsJustified"
            onChange={() => setQuery({ claimIsJustified: !query.claimIsJustified })}
            name="customSwitch"
            label={t("Justified")}
            defaultChecked={query.claimIsJustified ?? undefined}
          />
        </Form.Group>
      );
    case SearchFilterTypes.ProductId:
      return (
        <Form.Group className="mb-3">
          <Form.Label>{t("Product")}</Form.Label>
          <ProductSelect
            onChange={onChangeProductId}
            selectedIds={product?.data.map((x) => x.id)}
            isMulti
            isClearable
          />
        </Form.Group>
      );

    default:
      return <React.Fragment />;
  }
}

export default RenderSearchFilterLegacy;
