/* eslint-disable @typescript-eslint/no-non-null-assertion */
import _ from "lodash";

import {
  ActivationVM,
  ApiException,
  BankHolidaySettings,
  HealthReportDTO,
  HealthReportDataDTO,
  HealthReportEntryDTO,
  IActivationVM,
  IAppSettingsControllerClient,
  IBankHolidaySettings,
  IHealthControllerClient,
  ISetting,
  ISettingsControllerClient,
  IWorkingDaySettings,
  Setting,
  WorkingDaySettings,
} from "@generatedCode/pbd-core/pbd-core-api";

import { getProductConfig } from "../../../Constants/productConfig";
import Auth from "../../../stores/Auth";
import JsonHelpers from "../Json/jsonHelpers";
import { BaseSettingsService, JsonSetting, StringArraySetting } from "./baseSettingsService";
import { SettingKeyNames } from "./models/setting-key-names";
import { SettingsMappingService } from "./settingsMappingService";

export interface BasicAdminData {
  dataProtectionOfficerId: number;
  dataProtectionOfficerIsExternal: boolean;
  managingDirectorId: number;
  organisationId: number;
}

type SettingKey = keyof typeof SettingKeyNames;

export function settingKeyShouldParse(key: keyof typeof SettingKeyNames) {
  switch (key) {
    case SettingKeyNames.AllowedDomains:
    case SettingKeyNames.DefaultPermissions_Readonly:
    case SettingKeyNames.Global_PrintLogo:
      return false;
    default:
      return true;
  }
}

//TODO

export interface BankHoliday {
  a: string;
}

export const AllowedDomainSetting = new StringArraySetting(SettingKeyNames.AllowedDomains);
export const DefaultPermissionsReadonlySetting = new StringArraySetting(SettingKeyNames.DefaultPermissions_Readonly);
export const BankHolidaySetting = new JsonSetting<BankHoliday, SettingKey>(SettingKeyNames.BankHolidays);

export default class SettingsService extends BaseSettingsService<SettingKey> {
  settingsApi: ISettingsControllerClient;
  appSettingsApi: IAppSettingsControllerClient;
  healthApi: IHealthControllerClient;

  constructor(
    settingsApi: ISettingsControllerClient,
    appSettingsApi: IAppSettingsControllerClient,
    healthApi: IHealthControllerClient,
  ) {
    super();
    this.settingsApi = settingsApi;
    this.appSettingsApi = appSettingsApi;
    this.healthApi = healthApi;
  }

  getRawSettingValue(key: SettingKey): Promise<string | undefined> {
    return this.settingsApi.getByKey(key).then((set) => set.value);
  }
  setSettingRawValue(key: SettingKey, value: string): Promise<void> {
    return this.settingsApi
      .edit(
        key,
        new Setting({
          key: key,
          value,
        }),
      )
      .then();
  }

  setCustomerId(id: number): void {
    Auth.setCustomerId(id.toString());
  }
  static getCustomerId() {
    const customerId = Auth.getCustomerId();
    if (!customerId) {
      console.warn("CustomerId is missing; 0 will be used");
    }
    return customerId ?? "0";
  }

  setCustomerKey(customerKey: string): void {
    Auth.setCustomerKey(customerKey);
  }

  static getCustomerKey() {
    return Auth.getCustomerKey();
  }

  private _getByKey(key: keyof typeof SettingKeyNames) {
    return this.settingsApi.getByKey(key);
  }

  async getValueByKey<T = string>(key: keyof typeof SettingKeyNames, defaultValue?: T) {
    const setting = await this._getByKey(key);
    const { value } = setting;
    const shouldParse = settingKeyShouldParse(key);

    //DISC: Throw here if default value === undefined?
    if (value == undefined) return defaultValue;

    if (shouldParse) {
      return JsonHelpers.parseToCamelCase<T>(value);
    }

    let jsonValue = "";
    if (key == SettingKeyNames.AllowedDomains) {
      jsonValue = JSON.stringify(SettingsMappingService.getAllowedDomains(setting));
    } else {
      jsonValue = JSON.stringify(value);
    }
    return JsonHelpers.parseToCamelCase<T>(jsonValue);
  }

  async getGettingStartedDone(): Promise<boolean> {
    const setting = await this._getByKey(SettingKeyNames.GettingStartedAdminDone);
    return setting.value === "True";
  }

  updateAppSettings(appSetting: ISetting) {
    return this.settingsApi.edit(appSetting.key, new Setting(appSetting));
  }

  // TODO ??
  // async getProductConfig() {
  //   const billingProps = await this.getBillingProperties();
  //   // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //   //const configData = getProductConfig(billingProps.consolidatedProductName! as ProductId, billingProps);
  //  // return configData;
  //   return undefined;
  // }

  getAllowedDomains() {
    return this.getValueByKey<string[]>(SettingKeyNames.AllowedDomains);
  }

  updateAllowedDomains(allowedDomains: string[]) {
    const unique = _.uniq(allowedDomains);
    const array = Array.from(unique).filter((x) => x != "");
    return this.updateAppSettings({
      key: SettingKeyNames.AllowedDomains,
      value: JSON.stringify(array),
    });
  }

  getWorkingDaySettings() {
    return this.appSettingsApi.getWorkingDays();
  }

  updateBankHolidaySettings(data: IBankHolidaySettings) {
    return this.appSettingsApi.updateBankHolidays(new BankHolidaySettings(data));
  }

  updateWorkingHourSettings(data: IWorkingDaySettings) {
    return this.appSettingsApi.updateWorkingDays(new WorkingDaySettings(data));
  }

  activateSoftware(data: IActivationVM) {
    return this.appSettingsApi.activateSoftware(new ActivationVM(data));
  }

  getDefaultPermissions() {
    return this.getValueByKey<string[]>(SettingKeyNames.DefaultPermissions, []);
  }

  updateDefaultPermissions(roles: string[]) {
    const unique = _.uniq(roles);
    const array = Array.from(unique).filter((x) => x != "");
    return this.updateAppSettings({
      key: SettingKeyNames.DefaultPermissions,
      value: JSON.stringify(array),
    });
  }

  async getDefaultPermissionsReadonly() {
    const perm = await this.getValueByKey(SettingKeyNames.DefaultPermissions_Readonly);
    return perm === "True" || perm === "true";
  }

  async getProductConfig() {
    const version = await this.appSettingsApi.getVersion();
    return getProductConfig(version.productId);
  }

  /**
   * This method expects responses of code
   * - 200 OK
   * - 503 Unhealth
   */
  async getHealth(): Promise<HealthReportDTO | undefined> {
    const resp = await this.healthApi.get().catch((e) => {
      if (ApiException.isApiException(e)) {
        if (e.status == 503) {
          const report = JsonHelpers.parse<HealthReportDTO>(e.response);
          if (report.entries) {
            const configCheck = report.entries.ConfigurationHealthCheck;
            for (const [key, value] of Object.entries(configCheck.data as Entries)) {
              report.entries[key] = new HealthReportEntryDTO({ ...value, duration: "" });
            }
          }

          return report;
        }
      }
      throw Error("Unknown exception");
    });
    return resp;
  }
}

type Entries = Record<string, HealthReportDataDTO>;
