import { uniq } from "lodash";

import {
  ICustomField,
  IInventoryItemDTO,
  IInventoryItemsControllerClient,
  InventoryItemQueryField,
} from "@generatedCode/pbd-core/pbd-core-api";

import { SearchFilterTypes } from "../../../ClientApp/shared/components/genericSearchFilter/availableSearchFilters";
import { BaseExportService } from "../Base/BaseExportService";
import ExportService, { ExportType } from "../Export/exportService";

import { InventoryItemQueryParameters } from "./models/query-parameters";

type ItemWithCustomFields = IInventoryItemDTO & {
  customFieldLegacyMapped: Record<string, string>;
  customFieldsMapped: Record<string, string>;
};
export interface IInventoryItemDTOWithParent extends IInventoryItemDTO {
  parent?: IInventoryItemDTO;
}
export default class InventoryItemService extends BaseExportService<IInventoryItemDTO> {
  inventoryItemApi: IInventoryItemsControllerClient;

  constructor(inventoryItemApi: IInventoryItemsControllerClient) {
    super("Inventory items");
    this.inventoryItemApi = inventoryItemApi;
  }

  async getAllQueryByParams(query: InventoryItemQueryParameters): Promise<IInventoryItemDTOWithParent[]> {
    return this.getAllQuery({
      tags: query.tags,
      createdFrom: query.createdFrom,
      createdTo: query.createdTo,
      lastUpdatedFrom: query.lastUpdatedFrom,
      lastUpdatedTo: query.lastUpdatedTo,
      nextInspectionFrom: query.nextInspectionFrom,
      nextInspectionTo: query.nextInspectionTo,
      responsibleId: query.responsibleId,
      inventoryCategoryId: query.inventoryCategoryId,
      inventoryStatusId: query.inventoryStatusId,
      inventoryInspectionId: query.inventoryInspectionId,
      functioning: query.functioning,
      isDeleted: query.isDeleted,
      addressId: query.addressId,
      addressRoomId: query.addressRoomId,
      fields: [InventoryItemQueryField.Address],
    });
  }

  async getAllQuery(query: InventoryItemQueryParameters): Promise<IInventoryItemDTOWithParent[]> {
    const allItems = await this.inventoryItemApi.getAllQuery(query);
    const result = allItems.map((x) => {
      if (x.parentInventoryItemId != undefined) {
        const parent = allItems.find((y) => y.id == x.parentInventoryItemId);
        return { ...x, parent };
      }
      return x;
    });
    return result;
  }

  getUniqueCustomFieldsLegacy(items: IInventoryItemDTO[]): string[] {
    const customFieldsLegacy = items.filterMap((x) => x.customFieldsLegacy).reduce((pv, cv) => pv.concat(cv), []);
    return uniq(customFieldsLegacy.map((x) => x.id));
  }

  mapCustomFields(items: IInventoryItemDTO[], cFields?: ICustomField[]): ItemWithCustomFields[] {
    const customFieldsLegacy = this.getUniqueCustomFieldsLegacy(items);
    const resp: ItemWithCustomFields[] = [];
    for (const item of items) {
      const dict = this.#mapToCustomFieldDict(item, customFieldsLegacy);
      const cfMapped = ExportService.getCustomFields(item.customFields ?? [], cFields ?? []);
      resp.push({ ...item, customFieldLegacyMapped: dict, customFieldsMapped: cfMapped });
    }
    return resp;
  }

  #mapToCustomFieldDict(item: IInventoryItemDTO, uniqueCustomFields: string[]) {
    const record: Record<string, string> = {};
    uniqueCustomFields.forEach((x) => {
      const existingItem = item.customFieldsLegacy?.find((c) => c.id == x);
      record[`legacy_${x}`] = existingItem?.value ?? "";
    });
    return record;
  }

  exportCsv(items: IInventoryItemDTO[], customFields: ICustomField[]) {
    const mapped = this.mapCustomFields(items, customFields);
    super.exportToCsv(mapped);
  }

  mapToExport(x: ItemWithCustomFields): ExportType {
    return {
      id: x.id,
      title: x.title,
      inventoryNumber: x.inventoryNumber,
      inventoryCategory: x.category?.title,
      serialNumber: x.serialNumber,
      manufacturer: x.manufacturer,
      model: x.model,
      lastInspection: x.lastInspection,
      nextInspection: x.nextInspection,
      responsible: x.responsible?.fullName,
      status: x.inventoryStatus,
      functioning: x.functioning,
      functioningComment: x.functioningComment,
      description: x.description,
      address: x.address == null ? "" : x.address.fullAddress,
      addressRoom: x.addressRoom == null ? "" : x.addressRoom.title,
      createdAt: x.createdAt,
      lastUpdatedAt: x.lastUpdatedAt,
      excludeFromMonitoring: x.excludeFromMonitoring,
      ...x.customFieldsMapped,
      ...x.customFieldLegacyMapped,
    };
  }

  static get availableFilter() {
    return [
      SearchFilterTypes.Tags,
      SearchFilterTypes.CreatedAt,
      SearchFilterTypes.LastUpdatedAt,
      SearchFilterTypes.NextInspectionAt,
      SearchFilterTypes.Responsible,
      SearchFilterTypes.InventoryCategory,
      SearchFilterTypes.InventoryStatus,
      SearchFilterTypes.InventoryInspection,
      SearchFilterTypes.Functioning,
      SearchFilterTypes.IsDeleted,
      SearchFilterTypes.Address,
      SearchFilterTypes.CustomField,
    ];
  }
}
