import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";

import { ITenantContributingDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { NumberHelpers } from "../../../Helpers/NumberHelpers";
import { ExternalIdSettings } from "../../../Models/Settings/ExternalIdSettings";
import ExternalIdComponent from "../../shared/components/id/externalIdComponent";

interface IProps {
  contributors: ITenantContributingDTO[];
  extIdEmployee?: ExternalIdSettings;
}

const IdeaContributorsTablePrintView: React.FC<IProps> = (props) => {
  const { contributors, extIdEmployee } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <h5>
        {t("Contributors")} ({contributors.length})
      </h5>
      <Table responsive>
        <thead>
          <tr>
            <th>{t("Name")}</th>
            <th>{t("Contribution")}</th>
          </tr>
        </thead>
        <tbody>
          {contributors.map((d) => (
            <tr key={d.id}>
              <td>
                <span className="me-2">{d.fullName}</span>
                {extIdEmployee?.isUsed && (
                  <span>
                    (
                    <ExternalIdComponent externalIdSettings={extIdEmployee} externalId={d.externalId} />)
                  </span>
                )}
              </td>
              <td>{NumberHelpers.convertToPercentage(d.partOfContribution)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default IdeaContributorsTablePrintView;
