import React from "react";
import { useTranslation } from "react-i18next";

import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { FormRoutePaths } from "../formRoutePaths";

const SidebarForms: React.FC = (props) => {
  const { t } = useTranslation();
  const data: SidebarItem[] = [
    {
      id: "fillOutForm",
      title: t("Fill out form"),
      icon: qmBaseIcons.Plus,
      href: FormRoutePaths.FillOutFormRoute,
    },
    {
      id: "filledOutForms",
      title: t("Filled out forms"),
      href: FormRoutePaths.IndexPageCustomFormAnswers,
    },
    {
      id: "manage",
      title: t("Manage forms"),
      href: FormRoutePaths.IndexPageCustomForm,
    },
  ];
  return <SidebarNew app={PbdModule.CustomForms} items={data} />;
};

export default SidebarForms;
