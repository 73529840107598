import useSWR from "swr";

import { useAPIs } from "../../../pbdServices/services/service-context";
import { DepartmentPositionQueryParameters } from "../../../pbdServices/services/DepartmentPositions/models/query-parameters";

export function useDepartmentPositionsHook(query?: DepartmentPositionQueryParameters) {
  const { departmentPositionsApi } = useAPIs();
  const { data, mutate, isLoading } = useSWR(query ? ["/api/departmentPositions", query] : null, () => {
    if (!query) return undefined;
    return departmentPositionsApi.getAllQuery(query);
  });
  return {
    data: data, // ? ListHelpers.sortBy(data.content, (x) => x.fullName) : undefined,
    isLoading: isLoading,
    mutate,
  };
}
