import { Formik } from "formik";
import { nanoid } from "nanoid";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { IScaleSection } from "@generatedCode/pbd-core/pbd-core-api";

import { nameofFactory } from "../../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { BaseFormProps } from "../../../shared/components/forms/formik/BaseFormProps";
import FormikCustomForm from "../../../shared/components/forms/formik/formikCustomForm";
import { FormikNumberInputGroup } from "../../../shared/components/forms/formik/formikNumberInput";
import { FormikTextInputGroup } from "../../../shared/components/forms/formik/formikTextInput";

const ValidationSchema: yup.ObjectSchema<IScaleSection> = yup.object({
  scaleSectionId: yup.string().required(),
  from: yup.number().required(),
  to: yup.number().required(),
  description: yup.string().required().min(2).max(32),
  opportunityCategoryVariableId: yup.string().notRequired(),
  opportunityCategoryId: yup.number().notRequired(),
});

const nameof = nameofFactory<IScaleSection>();

const EditFormScaleSection: React.FC<BaseFormProps<IScaleSection>> = (props) => {
  const { itemToUpdate, onSubmit, onCancel, onSuccess } = props;
  const { t } = useTranslation();

  const submitter = useFormikAPISubmitter<IScaleSection>((val) => onSubmit(val), [onSubmit], onSuccess);

  const initialValues: IScaleSection = {
    scaleSectionId: itemToUpdate?.scaleSectionId || nanoid(),
    from: itemToUpdate?.from || 0,
    to: itemToUpdate?.to || 0,
    description: itemToUpdate?.description || "",
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
      {(formikBag) => (
        <FormikCustomForm formikBag={formikBag}>
          <FormikTextInputGroup name={nameof("description")} />
          <FormikNumberInputGroup name={nameof("from")} />
          <FormikNumberInputGroup name={nameof("to")} />
        </FormikCustomForm>
      )}
    </Formik>
  );
};

export default EditFormScaleSection;
