import React from "react";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, Input, Row } from "reactstrap";

import { IWorkflowAction, WorkflowActionType } from "@generatedCode/pbd-core/pbd-core-api";

import { StatusString } from "../../../../Models/Enums/Status";
import {
  AvailableWorkflowActions,
  RestrictConnectionOfValues,
} from "../../../../Models/Settings/Workflows/WorkflowActionType";

interface IProps {
  currentValue: IWorkflowAction;
  onChange: (item: IWorkflowAction) => void;
}

const ActionInput: React.FC<IProps> = (props) => {
  const { onChange, currentValue } = props;
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //@ts-expect-error TODO 20240722: TODO Refactor to normal form or switch to yml
    currentValue[event.target.name] = event.target.value;
    onChange(currentValue);
  };

  return (
    <Row>
      <Col>
        <FormGroup>
          <Input type="select" name="actionType" id="actionType" onChange={handleChange}>
            <option>{t("Please select...")}</option>
            {AvailableWorkflowActions.map((x) => (
              <option key={x.value} value={x.value}>
                {x.label}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          <Input type="select" name="value" id="value" onChange={handleChange}>
            <option>{t("Please select...")}</option>
            {currentValue.actionType == WorkflowActionType.RestrictConnectionOf &&
              RestrictConnectionOfValues.map((x) => <option key={x}>{x}</option>)}
            {currentValue.actionType == WorkflowActionType.RestrictChangeStatusTo &&
              Object.keys(StatusString).map((x) => <option key={x}>{x}</option>)}
          </Input>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default ActionInput;
