import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";

import { IAddressDetailsDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { CrmRoutePaths } from "../../../crm/crmRoutePaths";
import { MaintenanceManagementRoutePaths } from "../../../maintenanceManagement/maintenanceManagementRoutePaths";
import GenericAlert from "../../../shared/components/alerts/genericAlert";
import { TrainingRoutePaths } from "../../../trainings/trainingRoutePaths";

interface IProps {
  item: IAddressDetailsDTO;
}

function PlacesConnectedAlert(props: IProps) {
  const { t } = useTranslation();
  const { item } = props;
  return (
    <GenericAlert dismissible={false}>
      {t("Deleting this address is not possible because of connected objects")} <b>{item.connectedObjectsCount}</b>
      <Table responsive>
        {item.trainingsCount > 0 && (
          <tr>
            <td>
              {item.trainingsCount} {t("Trainings")}
            </td>
            <td>
              <Link to={TrainingRoutePaths.IndexPage + `/?addressId=${item.id}`}>Link</Link>
            </td>
          </tr>
        )}
        {item.tenantAddressCount > 0 && (
          <tr>
            <td>
              {item.tenantAddressCount} {t("Persons")}
            </td>
            <td>
              <Link to={CrmRoutePaths.IndexPageTenants + `/?addressId=${item.id}`}>Link</Link>
            </td>
          </tr>
        )}
        {item.inventoryItemsCount > 0 && (
          <tr>
            <td>
              {item.inventoryItemsCount} {t("Inventory items")}
            </td>
            <td>
              <Link to={MaintenanceManagementRoutePaths.IndexPage + `/?addressId=${item.id}`}>Link</Link>
            </td>
          </tr>
        )}
        {item.inventoryItemsCount > 0 && (
          <tr>
            <td>
              {item.organisationAddressCount} {t("Organisations")}
            </td>
            <td>
              <Link to={CrmRoutePaths.IndexPageOrganisations + `/?addressId=${item.id}`}>Link</Link>
            </td>
          </tr>
        )}
      </Table>
    </GenericAlert>
  );
}
export default PlacesConnectedAlert;
