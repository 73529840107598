import { createColumnHelper } from "@tanstack/react-table";
import i18next from "i18next";
import { Link } from "react-router-dom";

import { IDynamicConnectionDTO } from "@generatedCode/pbd-core/pbd-core-api";

import { AdminRoutePaths } from "../../admin/adminRoutePaths";
import { BaseTableProps } from "../../shared/components/tables/v8/base-table-props";
import ReactstrapTable from "../../shared/components/tables/v8/reactstrap-table";

const columnHelper = createColumnHelper<IDynamicConnectionDTO>();

const columns = [
  columnHelper.accessor((x) => x.id, {
    header: i18next.t("ID"),
    cell: (x) => (
      <Link to={AdminRoutePaths.DetailsPageConnection.replace(":id", x.getValue().toString())}>{x.getValue()}</Link>
    ),
  }),
  columnHelper.accessor((x) => x.fromEntity, { header: i18next.t("From entity") }),
  columnHelper.accessor((x) => x.fromId, { header: i18next.t("From ID") }),
  columnHelper.accessor((x) => x.toEntity, { header: i18next.t("To entity") }),
  columnHelper.accessor((x) => x.toId, { header: i18next.t("To ID") }),
  columnHelper.accessor((x) => x.content, { header: i18next.t("Content") }),
];

type IProps = BaseTableProps<IDynamicConnectionDTO>;

function ConnectionTable(props: IProps) {
  const { localStorageStateKey = "DynamicConnectionTable" } = props;
  return (
    <ReactstrapTable<IDynamicConnectionDTO>
      columns={columns}
      localStorageStateKey={localStorageStateKey}
      columnsVisibleDefault={["id", "area", "service", "auditActionType", "createdAt"]}
      {...props}
    />
  );
}

export default ConnectionTable;
