import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Cropper, { Area, Point } from "react-easy-crop";
import { useTranslation } from "react-i18next";

import { FileParameter, PredefinedFileCategory } from "@generatedCode/pbd-core/pbd-core-api";

import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import { useAppContext } from "../../contexts/appContext";
import CancelButton from "../buttons/cancelButton";

import { getCroppedImg, getImageFileExtension } from "./canvasUtils";
import { AvatarShape } from "./types";

interface BaseApi {
  uploadFiles: (
    id: number,
    files?: FileParameter[] | undefined,
    note?: string | undefined,
    categoryId?: number | undefined,
    predefinedCategory?: PredefinedFileCategory | undefined,
  ) => Promise<void>;
}

interface IProps {
  baseItem: { id: number | string };
  refreshParent: () => void;
  baseApi?: BaseApi;
  onSubmit?: (data: Blob, fileName: string) => Promise<void>;
  onCancel: () => void;
  avatarShape?: AvatarShape;
  imageSrcOfFile?: string | undefined;
}

function CustomCropper(props: IProps) {
  const {
    baseApi,
    baseItem,
    refreshParent,
    onCancel,
    onSubmit,
    avatarShape = AvatarShape.Round,
    imageSrcOfFile,
  } = props;
  const { t } = useTranslation();
  const { handleApiError } = useAppContext();
  const [imageSrc, setImageSrc] = React.useState<string>();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [selectedFile, setSelectedFile] = React.useState<File>();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const uploadCroppedImage = async (_imageSrc: string) => {
    if (!croppedAreaPixels) {
      throw Error("Missing crop");
    }
    const croppedImage = await getCroppedImg(_imageSrc, croppedAreaPixels, rotation);
    if (!croppedImage) throw Error("Missing cropped image");
    const fileName = `${selectedFile?.name ?? "profilePicture"}.${getImageFileExtension(croppedImage)}`;

    if (onSubmit) {
      await onSubmit(croppedImage, fileName);
    } else if (baseApi) {
      await wrapApiCallWithToast(
        () =>
          baseApi.uploadFiles(
            Number(baseItem.id),
            [
              {
                fileName: fileName,
                data: croppedImage,
              },
            ],
            "",
            0,
            PredefinedFileCategory.ProfilePicture,
          ),
        { handleApiError },
      );
    }
    onCancel();

    refreshParent();
  };

  const fileChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      throw new Error("File is missing");
    }
    setSelectedFile(event.target.files[0]);

    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.result && typeof reader.result == "string") {
        setImageSrc(reader.result);
      }
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  const containerStyle: React.CSSProperties = {
    position: "relative",
    width: "100%",
    height: 250,
    background: "#333",
  };

  useEffect(() => {
    if (imageSrcOfFile) {
      setImageSrc(imageSrcOfFile);
    }
  }, [imageSrcOfFile]);

  return (
    <div>
      {imageSrc && (
        <div className="cropContainer mb-3" style={containerStyle}>
          <Cropper
            image={imageSrc}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={1 / 1}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape={avatarShape}
          />
        </div>
      )}
      {!imageSrcOfFile && (
        <Form.Group className="mb-3">
          <Form.Label>{t("Please select a photo")}</Form.Label>
          <Form.Control type="file" onChange={fileChangedHandler} accept="image/*" />
        </Form.Group>
      )}

      {imageSrc && (
        <Form.Group className="mb-3">
          <Form.Label>{t("Rotate")}</Form.Label>
          <Form.Range
            value={rotation}
            min={0}
            max={360}
            step={1}
            aria-labelledby="Rotation"
            onChange={(e) => setRotation(e.target.valueAsNumber)}
          />
        </Form.Group>
      )}

      <CancelButton onClick={onCancel} />
      {imageSrc && (
        <Button onClick={() => uploadCroppedImage(imageSrc)} variant="primary">
          {t("Save")}
        </Button>
      )}
    </div>
  );
}

export default CustomCropper;
