import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { PbdModule } from "@generatedCode/pbd-core/pbd-core-api";

import { qmBaseIcons } from "../icons/qmBaseIcons";
import { BaseModalProps } from "../modals/baseModalProps";
import SmartViewTabs from "./smartViewTabs";

interface IProps extends BaseModalProps {
  app: PbdModule;
}

function SmartViewModal(props: IProps) {
  const { modal, toggle, app } = props;
  const { t } = useTranslation();

  return (
    <Modal show={modal} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>
          <qmBaseIcons.SmartView /> {t("Smart Views")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SmartViewTabs module={app} hideSaveButton toggleParentModal={toggle} />
      </Modal.Body>
    </Modal>
  );
}

export default SmartViewModal;
