import { CapabilitiesDTO } from "@generatedCode/pbd-core/pbd-core-api";

export function buildCapabilities(overrides?: Partial<CapabilitiesDTO>): CapabilitiesDTO {
  return new CapabilitiesDTO({
    tenantId: 1,
    canDelete: true,
    canEdit: true,
    canRestore: true,
    canEditReviewArea: true,
    canAddChildren: true,
    canRemoveChildren: true,
    canChangeResponsible: true,
    canChangeApprover: true,
    canApprove: true,
    canRegister: true,
    canOneClickPublish: true,
    canPublish: true,
    canWithdraw: true,
    canEditProgressArea: true,
    canEditFiles: true,
    canEditTags: true,
    canConnectTask: true,
    canChangeOwner: true,
    canAccess: true,
    canEditCustomFields: true,
    canDownload: true,
    canEditReviewer: true,
    canEditRating: true,
    canEditCosts: true,
    ...overrides,
  });
}

export function buildCapabilitiesForStandardPermission(overrides?: Partial<CapabilitiesDTO>): CapabilitiesDTO {
  return new CapabilitiesDTO({
    tenantId: 1,
    canAccess: true,
    canAddChildren: false,
    canApprove: true,
    canChangeApprover: false,
    canChangeOwner: true,
    canChangeResponsible: true,
    canConnectTask: true,
    canDelete: false,
    canDownload: false,
    canEdit: false,
    canEditCustomFields: false,
    canEditFiles: true,
    canEditProgressArea: false,
    canEditReviewArea: true,
    canEditReviewer: true,
    canEditTags: true,
    canOneClickPublish: false,
    canPublish: false,
    canRegister: false,
    canRemoveChildren: false,
    canRestore: false,
    canWithdraw: false,
    canEditRating: false,
    canEditCosts: false,
    ...overrides,
  });
}

export function buildCapabilitiesForCoordinatorPermission(overrides?: Partial<CapabilitiesDTO>): CapabilitiesDTO {
  return new CapabilitiesDTO({
    tenantId: 1,
    canAccess: true,
    canAddChildren: false,
    canApprove: true,
    canChangeApprover: false,
    canChangeOwner: true,
    canChangeResponsible: true,
    canConnectTask: true,
    canDelete: false,
    canDownload: false,
    canEdit: false,
    canEditCustomFields: false,
    canEditFiles: true,
    canEditProgressArea: false,
    canEditReviewArea: true,
    canEditReviewer: true,
    canEditTags: true,
    canOneClickPublish: false,
    canPublish: false,
    canRegister: false,
    canRemoveChildren: false,
    canRestore: false,
    canWithdraw: false,
    canEditRating: false,
    canEditCosts: false,
    ...overrides,
  });
}

export function buildCapabilitiesForManagerPermission(overrides?: Partial<CapabilitiesDTO>): CapabilitiesDTO {
  return new CapabilitiesDTO({
    tenantId: 1,
    canAccess: true,
    canAddChildren: false,
    canApprove: true,
    canChangeApprover: false,
    canChangeOwner: true,
    canChangeResponsible: true,
    canConnectTask: true,
    canDelete: false,
    canDownload: false,
    canEdit: false,
    canEditCustomFields: false,
    canEditFiles: true,
    canEditProgressArea: false,
    canEditReviewArea: true,
    canEditReviewer: true,
    canEditTags: true,
    canOneClickPublish: false,
    canPublish: false,
    canRegister: false,
    canRemoveChildren: false,
    canRestore: false,
    canWithdraw: false,
    canEditRating: false,
    canEditCosts: true,
    ...overrides,
  });
}
