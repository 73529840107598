import { FieldProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";

import { Gender } from "@generatedCode/pbd-core/pbd-core-api";

interface IProps {
  value?: Gender;
  onChange: (item: Gender) => void;
}

// const SalutationSelect: React.FC<IProps> = (props) => {
//   const { t } = useTranslation();
//   const { value, onChange } = props;

//   const options: ReactSelectDTO[] = [];
//   Object.values(Gender).forEach((x) => options.push({ label: t(`GenderKey_${x}`), value: x }));

//   const defaultValue: ReactSelectDTO[] = [];
//   if (value != null) {
//     const dv = options.find((x) => x.value == value);
//     if (dv) {
//       defaultValue.push(dv);
//     }
//   }

//   function handleChange(newValue: SingleValue<ReactSelectDTO>) {
//     // const option = newValue as ReactSelectDTO;
//     if (newValue) {
//       onChange(newValue.value as Gender);
//     }
//   }

//   return (
//     <React.Fragment>
//       <Select
//         key={defaultValue.length == 0 ? "salutationSelect" : value?.toString()}
//         theme={(theme) => ({
//           ...theme,
//           borderRadius: 0,
//         })}
//         menuPosition="fixed"
//         options={options}
//         defaultValue={defaultValue}
//         onChange={handleChange}
//         name="gender"
//       />
//     </React.Fragment>
//   );
// };

const SalutationSelect: React.FC<FieldProps<Gender>> = (props) => {
  const { t } = useTranslation();
  const { field } = props;

  return (
    <Input type="select" {...field} autoFocus>
      {Object.values(Gender).map((x) => (
        <option key={x} value={x}>
          {t(`GenderKey_${x}`)}
        </option>
      ))}
    </Input>
  );
};

export default SalutationSelect;
