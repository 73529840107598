import slugify from "slugify";

import { ArticleRevisionType } from "@generatedCode/pbd-core/pbd-core-api";

import { ScrollSpyList } from "../../documentManagement/components/scrollSpyComponent";

interface IProps {
  content?: string | undefined;
  type: ArticleRevisionType;
}

const pageBreakToken = "<!-- pagebreak -->";

export function getArticleContent(article: IProps, items: ScrollSpyList[]) {
  let content;
  if (article && article.type == ArticleRevisionType.Html && article.content != null) {
    content = article.content;
    const temp = document.createElement("div");
    temp.id = "articleModifiedDiv";
    temp.innerHTML = content;
    content = modifyLinksForScrollbar(temp, content, items);
    // content = modifyTables2(temp);
  }
  return content;
}

export const prepareArticleForPrint = (content: string) => {
  content = content.replaceAll(pageBreakToken, "<div style='page-break-before: always; clear:both'/></div>");
  return content;
};

export function isTruncated(content: string): boolean {
  return content.includes(pageBreakToken);
}

export function truncate(content: string): string {
  return content.split(pageBreakToken, 1).shift()!;
}

function modifyLinksForScrollbar(contentDiv: HTMLDivElement, content: string, items: ScrollSpyList[]) {
  const headings = contentDiv.querySelectorAll("h1, h2, h3, h4, h5, h6");
  for (let i = 0; i < headings.length; i++) {
    const element = headings[i];

    let innerHTML = element.textContent ?? "";
    let encodedLink = slugify(innerHTML, { lower: true, remove: /[*+~.()'"!:@,]/g });
    if (items.map((m) => m.uri).includes(encodedLink)) {
      encodedLink += `-${i}`;
    }
    items.push({ id: i, heading: innerHTML, uri: encodedLink });
    innerHTML += ` <a class="hash-link" href="#${encodedLink}" id="${encodedLink}"></a>`;
    const tagName = element.tagName;
    innerHTML = `<${tagName.toLowerCase()}>${innerHTML}</${tagName.toLowerCase()}>`;

    content = content.replace(element.outerHTML, innerHTML);
  }
  return content;
}

function modifyTables2(contentDiv: HTMLDivElement) {
  // const tHeads = contentDiv.querySelectorAll("th");
  // for (let index = 0; index < tHeads.length; index++) {
  //   const element = tHeads[index];
  //   if (element.style.borderStyle == "solid" && element.style.borderWidth == "0") {
  //     element.style.borderWidth = "1";
  //   }
  // }

  const tds = contentDiv.querySelectorAll("td");
  console.log(tds.length);
  // for (let index = 0; index < tds.length; index++) {
  //   const element = tds[index];
  //   console.log(element.style.borderWidth);
  //   // if (element.style.borderStyle == "solid" && element.style.borderWidth == "0") {
  //   element.style.borderWidth = "1px";
  //   // }
  //   console.log(element.style.borderWidth);
  // }

  const tables = contentDiv.querySelectorAll("table");
  for (let index = 0; index < tables.length; index++) {
    const element = tables[index];
    if (element.border == "1" && element.style.borderStyle != "") {
      for (let r = 0, n = element.rows.length; r < n; r++) {
        for (let c = 0, m = element.rows[r].cells.length; c < m; c++) {
          if (element.rows[r].cells[c].style.borderWidth == "" && element.rows[r].cells[c].style.borderStyle == "") {
            element.rows[r].cells[c].style.borderWidth = "1px";
          }
        }
      }
    }
    console.log(element.style);
    console.log(element.style.borderWidth);
    // if (element.style.borderStyle == "solid" && element.style.borderWidth == "0") {
    //   element.style.removeProperty("borderWidth");
    // }
    // element.style.borderWidth = "1";
    // tables[index].style.borderWidth = "1";
    // element.style.setProperty("border-width", "1", "important");
    console.log(element.style.borderWidth);
  }
  // const tBodies = contentDiv.querySelectorAll("tbody");
  // for (let index = 0; index < tBodies.length; index++) {
  //   const element = tBodies[index];
  //   console.log(element.style);
  //   element.style.borderWidth = "1";
  //   if (element.style.borderStyle == "solid" && element.style.borderWidth == "0") {
  //     element.style.removeProperty("borderWidth");
  //   }
  //   // element.classList.add(...["table", "table-responsive"]);
  // }
  return contentDiv.innerHTML;
}

function modifyTables(contentDiv: HTMLDivElement) {
  const tables = contentDiv.querySelectorAll("table");
  for (let index = 0; index < tables.length; index++) {
    const element = tables[index];
    element.classList.add(...["table", "table-responsive"]);
  }
  return contentDiv.innerHTML;
}
