import { Card, Form, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import { IStartPageFeatureSettings, PbdModule, SettingType } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../../pbdServices/services/service-context";

import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import QuickAccessConnectedCard from "../../components/quickAccessConnectedCard";

const StartPageConfigurationPage = () => {
  const { t } = useTranslation();
  const { moduleSettingsApi } = useAPIs();
  const { moduleSettingsService } = useAPIServices();

  const { data, mutate } = useSWR("/api/moduleSettings/startpage", () => moduleSettingsApi.getStartpageSettings());

  const handleSave = async (dto: IStartPageFeatureSettings) => {
    await wrapApiCallWithToast(() => moduleSettingsService.save(PbdModule.Startpage, SettingType.Features, dto));
  };

  return (
    <>
      <h2>{t("Start page")}</h2>
      <hr />
      <QuickAccessConnectedCard
        module={PbdModule.Startpage}
        data={data?.quickAccessLinks}
        refreshParent={() => mutate()}
        cardTitle={t("Quick access")}
      />
      <Card className="mb-3">
        <Card.Body>
          <h4>{t("Birthdays")}</h4>
          <ListGroup className="mb-3">
            <ListGroup.Item>
              <Form.Group className="mb-3" controlId="disableBirthdayCalendar">
                {data?.features && (
                  <Form.Check
                    type="switch"
                    id="disableBirthdayCalendar"
                    defaultChecked={data.features.disableBirthdayCalendar}
                    onChange={(y) =>
                      handleSave({
                        ...data.features,
                        disableBirthdayCalendar: y.target.checked,
                      })
                    }
                    label={t("Do not show upcoming birthdays in the sidebar of the start page")}
                  />
                )}
              </Form.Group>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </>
  );
};

export default StartPageConfigurationPage;
