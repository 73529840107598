import { Form, Formik } from "formik";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { CategoryCreateDTO, ICategoryCreateDTO, IDepartmentPositionDTO } from "@generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../../pbdServices/services/service-context";

import { useFormikAPISubmitter } from "../../../../../pbdServices/services/Api/api-formik-submitter";
import { requiredTitleStringSchema } from "../../../../../services/validation/stringSchemas";
import FormikSubmitButton from "../../forms/formik/formikSubmitButton";
import { FormikTextInputGroup } from "../../forms/formik/formikTextInput";

interface IProps {
  departmentId: number;
  onSuccess: () => void;
}

// TODO: Add yup.ObjectSchema
const getValidationSchema = (t: TFunction) => {
  const FormSchema = yup.object({
    title: requiredTitleStringSchema(t),
  });
  return FormSchema;
};

function EditFormDepartmentPosition(props: IProps) {
  const { departmentId, onSuccess } = props;
  const { departmentPositionsApi } = useAPIs();
  const { t } = useTranslation();

  const submitter = useFormikAPISubmitter<ICategoryCreateDTO, IDepartmentPositionDTO>(
    (val) => departmentPositionsApi.create(new CategoryCreateDTO(val)),
    [departmentPositionsApi],
    onSuccess,
  );

  const initialValues: ICategoryCreateDTO = {
    title: "",
    description: "",
    departmentId: departmentId,
    isRecurring: false,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={getValidationSchema(t)}>
      {(formikBag) => (
        <Form>
          <FormikTextInputGroup name="title" />
          <FormikSubmitButton formikBag={formikBag} />
        </Form>
      )}
    </Formik>
  );
}

export default EditFormDepartmentPosition;
