import { ErrorMessage, Field, FieldProps, Form as FormFormik, Formik, getIn } from "formik";
import { TFunction } from "i18next";
import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { TwitterPicker } from "react-color";
import { useTranslation } from "react-i18next";
import { FormFeedback, FormGroup, InputGroup, Label } from "reactstrap";
import * as yup from "yup";

import {
  IBaseMinDTO,
  ICategoryCreateDTO,
  ICompanyFunctionDTO,
  IDepartmentPositionDTO,
  IInventoryInspectionDTO,
  IQualificationDTO,
  ITagDTO,
  TimeIntervalDTO,
} from "@generatedCode/pbd-core/pbd-core-api";

import { ColorHelpers } from "../../../../Helpers/ColorHelpers";
import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { calculateTimeSpanFromInterval } from "../../../../pbdServices/services/DateTime/calculateDates";
import { requiredStringSchema } from "../../../../services/validation/stringSchemas";
import { timeIntervalSchema } from "../../../../services/validation/timeIntervalSchema";
import { validationWarningTimeInterval } from "../../../../services/validation/validationMonitoringWarning";
import DeletedComponent from "../../../shared/components/alerts/deletedComponent";
import GenericAlert from "../../../shared/components/alerts/genericAlert";
import CancelButton from "../../../shared/components/buttons/cancelButton";
import CardResponsibleComponent from "../../../shared/components/cardComponents/cardResponsibleComponent";
import DuplicatedItemsListGroup, { ExistingItem } from "../../../shared/components/duplicates/duplicatedItemsListGroup";
import FormikDebugInfo from "../../../shared/components/forms/formik/formikDebugInfo";
import { FormikHtmlInput } from "../../../shared/components/forms/formik/formikHtmlInput";
import { FormikNumberInputGroup } from "../../../shared/components/forms/formik/formikNumberInput";
import FormikRecurringComponent from "../../../shared/components/forms/formik/formikRecurringComponent";
import FormikSubmitButton from "../../../shared/components/forms/formik/formikSubmitButton";
import { FormikTextInput } from "../../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../../shared/components/forms/formik/formikValidationSummary";
import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";
import DepartmentSelect from "../../../shared/components/inputControl/select/departmentSelect";
import { useAppContext } from "../../../shared/contexts/appContext";
import { useToggle } from "../../../shared/hooks/useToggle";
import FormikStringArrayInput from "../../../todos/components/formikStringArrayInput";
import BaseSettingsFormHeader from "./baseSettingsFormHeader";
import { ChangeCustomFieldForm } from "./changeCustomFieldForm";

const getValidationSchema = (t: TFunction, isDepartmentRequired: boolean) => {
  //@ts-expect-error TODO: Fix with better typings
  const validationSchema: yup.ObjectSchema<ICategoryCreateDTO> = yup.object({
    title: requiredStringSchema(t),
    description: yup.string(),
    color: yup.string(),
    backgroundColor: yup.string(),
    responsibleId: yup.number(),
    isRecurring: yup.boolean(),
    monitoringInterval: yup.object().when("isRecurring", {
      is: true,
      then: (s) => timeIntervalSchema.required(),
      otherwise: (s) => s.nullable(),
    }),
    useWarningTime: yup.boolean().notRequired(),
    warningTimeInterval: yup.object().when("useWarningTime", {
      is: true,
      then: () => validationWarningTimeInterval,
      otherwise: (s) => s.nullable(),
    }),
    properties: yup.array().of(yup.string()).notRequired(),
    departmentId: yup.number().notRequired(),
    maximumCapacity: yup.number().notRequired().nullable(),
    minimumCapacity: yup.number().notRequired().nullable(),
    formula: yup.string().notRequired().nullable(),
  });

  if (isDepartmentRequired) {
    const departmentSchema = yup.object({
      departmentId: yup.number().required(),
    });
    return validationSchema.concat(departmentSchema);
  }
  return validationSchema;
};

interface IHaveId {
  id: number;
}

export function hasId(dto?: unknown): dto is IHaveId {
  if (!dto) return false;
  return (dto as IHaveId).id != undefined;
}

const getDefaultColor = (type?: FormType) => {
  if (type != "Tag") {
    return ColorHelpers.getRandomColor();
  }
  return undefined;
};

type AdditionalFields =
  | "Color"
  | "BackgroundColor"
  | "Responsible"
  | "Recurring"
  | "WarningTime"
  | "OldCustomFields"
  | "DescriptionAsHtml"
  | "Department"
  | "Capacity";

type FormType =
  | "Tag"
  | "CostCategory"
  | "CostCenter"
  | "AbsenceType"
  | "AuditType"
  | "DefectCategory"
  | "EmployeeIdeaCategory"
  | "InventoryCategory"
  | "InventoryInspection"
  | "OpportunityCategory"
  | "Qualification"
  | "TrainingType"
  | "DepartmentPosition"
  | "AuditRequirement"
  | "CompanyFunction"
  | "Department";

type TypeForUpdateItem = IDepartmentPositionDTO &
  IQualificationDTO &
  IInventoryInspectionDTO &
  ICompanyFunctionDTO &
  ITagDTO;

interface IProps {
  itemToUpdate?: Partial<TypeForUpdateItem> & { id: number; title: string };
  onDelete?: () => void;
  onCancel?: () => void;
  onSubmit: (dto: ICategoryCreateDTO) => Promise<unknown> | Promise<void>;
  onSuccess: (dto?: unknown) => void;
  additionalFields?: AdditionalFields[];
  handleRefresh?: () => void;
  department?: IBaseMinDTO;
  onRestore?: (id: number) => Promise<void>;
  disableDeleteButton?: boolean;
  entityTemplateComponent?: JSX.Element;
  existingItems?: ExistingItem[];
  formType: FormType;
  children?: React.ReactNode;
}

function BaseSettingsForm(props: IProps) {
  const { meAsUser } = useAppContext();
  const { t } = useTranslation();
  const {
    itemToUpdate,
    onDelete,
    additionalFields,
    onCancel,
    onSubmit,
    onSuccess,
    handleRefresh,
    department,
    onRestore,
    disableDeleteButton,
    entityTemplateComponent,
    existingItems,
    formType,
  } = props;
  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const [showBackgroundColorPicker, setShowBackgroundColorPicker] = React.useState(false);
  const [changeOrderMode, toggleChangeOrderMode] = useToggle();

  const initialValues: ICategoryCreateDTO = {
    title: itemToUpdate?.title ?? "",
    color: itemToUpdate?.color ?? getDefaultColor(formType),
    backgroundColor: itemToUpdate?.backgroundColor ?? getDefaultColor(formType),
    responsibleId: itemToUpdate?.responsible?.id ?? meAsUser.tenant.id,
    description: itemToUpdate?.description ?? "",
    isRecurring: itemToUpdate?.isRecurring ?? false,
    monitoringInterval: itemToUpdate?.monitoringInterval
      ? new TimeIntervalDTO(itemToUpdate.monitoringInterval)
      : undefined,
    warningTimeInterval: itemToUpdate?.warningTimeInterval
      ? new TimeIntervalDTO(itemToUpdate.warningTimeInterval)
      : undefined,
    useWarningTime: itemToUpdate?.useWarningTime,
    properties: itemToUpdate?.customFieldsArray ?? [],
    minimumCapacity: itemToUpdate?.minimumCapacity,
    maximumCapacity: itemToUpdate?.maximumCapacity,
    departmentId: itemToUpdate?.department?.id,
    formula: itemToUpdate?.formula ?? "",
  };

  const submitter = useFormikAPISubmitter<ICategoryCreateDTO, unknown>(
    (values) => {
      if (values.isRecurring && values.monitoringInterval) {
        values.monitoringInterval = new TimeIntervalDTO(values.monitoringInterval);
        values.monitoringInterval.timeSpanISO = calculateTimeSpanFromInterval(
          values.monitoringInterval.value,
          values.monitoringInterval.type,
        ).toISO();
        if (values.useWarningTime && values.warningTimeInterval) {
          values.warningTimeInterval = new TimeIntervalDTO(values.warningTimeInterval);
          values.warningTimeInterval.timeSpanISO = calculateTimeSpanFromInterval(
            values.warningTimeInterval.value,
            values.warningTimeInterval.type,
          ).toISO();
        }
      }
      values.properties = values.properties?.filter((x) => x);
      return onSubmit(values);
    },
    [onSubmit],
    (resp) => onSuccess(resp),
  );
  return (
    <Card className="mb-3">
      <BaseSettingsFormHeader
        itemToUpdate={itemToUpdate}
        onDelete={onDelete}
        disableDeleteButton={disableDeleteButton}
      />

      {itemToUpdate?.isDeleted && onRestore && (
        <Card.Body>
          <DeletedComponent
            baseObject={itemToUpdate}
            refreshParent={handleRefresh}
            onRestore={onRestore}
            {...itemToUpdate}
          />
        </Card.Body>
      )}

      {itemToUpdate?.capabilities && handleRefresh && additionalFields?.includes("Responsible") && (
        <Card.Body>
          <CardResponsibleComponent
            item={itemToUpdate}
            //@ts-expect-error TODO: Fix this after general return type is solved
            onSubmit={(_responsible) => onSubmit({ ...initialValues, responsibleId: _responsible?.id })}
          />
        </Card.Body>
      )}
      <Card.Body>
        <Formik
          initialValues={initialValues}
          onSubmit={submitter}
          validationSchema={getValidationSchema(t, additionalFields?.includes("Department") ?? false)}
        >
          {(formikBag) => (
            <FormFormik>
              <FormikDebugInfo formikBag={formikBag} />
              <FormGroup>
                <Label for="title">{t("Title")}</Label>
                <InputGroup>
                  <Field name="title" component={FormikTextInput} />
                  {entityTemplateComponent && entityTemplateComponent}
                </InputGroup>
              </FormGroup>

              <DuplicatedItemsListGroup
                searchTerm={formikBag.values.title}
                existingItems={existingItems}
                itemsToExclude={itemToUpdate ? [itemToUpdate] : undefined}
                {...props}
              />
              {additionalFields?.includes("Department") && (
                <FormGroup>
                  <Label for="departmentId">{t("Department")}</Label>
                  <Field name="departmentId">
                    {({ field, form: { touched, errors, setFieldTouched } }: FieldProps<number | undefined>) => (
                      <>
                        <DepartmentSelect
                          onChange={(x) => formikBag.setFieldValue("departmentId", Number(x))}
                          selectedIds={department ? [department.id] : undefined}
                          onBlur={() => setFieldTouched(field.name)}
                          invalid={getIn(errors, field.name) && getIn(touched, field.name)}
                        />
                        <ErrorMessage component={FormFeedback} {...field} />
                      </>
                    )}
                  </Field>
                </FormGroup>
              )}
              {additionalFields?.includes("Color") && (
                <FormGroup>
                  <Label>{t("Color")}</Label>
                  <div className="d-flex align-items-center">
                    <div>
                      <qmBaseIcons.SquareSolid
                        className="fa-3x"
                        style={{
                          color: formikBag.values.color,
                        }}
                      />
                    </div>
                    <div className="ms-3">
                      {showColorPicker ? (
                        <TwitterPicker
                          width="550px"
                          onChange={(x) => formikBag.setFieldValue("color", x.hex)}
                          triangle="hide"
                        />
                      ) : (
                        <Button variant="link" onClick={() => setShowColorPicker(true)}>
                          <qmBaseIcons.Pencil />
                        </Button>
                      )}
                    </div>
                  </div>
                </FormGroup>
              )}
              {additionalFields?.includes("BackgroundColor") && (
                <FormGroup>
                  <Label>{t("Background color")}</Label>
                  <div className="d-flex align-items-center">
                    <div>
                      <qmBaseIcons.SquareSolid
                        className="fa-3x"
                        style={{
                          color: formikBag.values.backgroundColor,
                        }}
                      />
                    </div>
                    <div className="ms-3">
                      {showBackgroundColorPicker ? (
                        <TwitterPicker
                          width="550px"
                          onChange={(x) => formikBag.setFieldValue("backgroundColor", x.hex)}
                          triangle="hide"
                        />
                      ) : (
                        <Button variant="link" onClick={() => setShowBackgroundColorPicker(true)}>
                          <qmBaseIcons.Pencil />
                        </Button>
                      )}
                    </div>
                  </div>
                </FormGroup>
              )}
              {additionalFields?.includes("Recurring") && (
                <FormikRecurringComponent
                  formikBag={formikBag}
                  includeWarningTime={additionalFields.includes("WarningTime")}
                />
              )}
              {additionalFields?.includes("Capacity") && (
                <Row>
                  <Col>
                    <FormikNumberInputGroup
                      label={t("Minimum capacity")}
                      name="minimumCapacity"
                      formText={t("Minimum amount of persons that should work in this position")}
                    />
                  </Col>
                  <Col>
                    <FormikNumberInputGroup
                      label={t("Maximum capacity")}
                      name="maximumCapacity"
                      formText={t("Maximum amount of persons that should work in this position")}
                    />
                  </Col>
                </Row>
              )}
              <FormGroup>
                <Label for="description">{t("Description")}</Label>
                <Field name="description" component={FormikHtmlInput} />
              </FormGroup>

              {additionalFields?.includes("OldCustomFields") && (
                <FormGroup>
                  <div className="d-flex justify-content-between">
                    <h5>{t("Custom Fields")}</h5>
                    <div>
                      <Button size="sm" variant="outline-secondary" onClick={toggleChangeOrderMode}>
                        <qmBaseIcons.ArrowUpDown />
                      </Button>
                    </div>
                  </div>
                  {formikBag.touched.properties && (
                    <GenericAlert type="warning">
                      {t("Changing the fields might lead corrupted data of the existing data.")}
                    </GenericAlert>
                  )}
                  {!changeOrderMode && <FormikStringArrayInput name="properties" />}
                </FormGroup>
              )}
              {changeOrderMode && (
                <ChangeCustomFieldForm
                  onChange={async (values) => {
                    await formikBag.setFieldValue("properties", values);
                  }}
                  customFields={formikBag.values.properties ?? []}
                />
              )}
              <FormGroup>
                {onCancel && <CancelButton onClick={onCancel} />}
                <FormikSubmitButton formikBag={formikBag} />
              </FormGroup>
              <FormikValidationSummary formikBag={formikBag} />
            </FormFormik>
          )}
        </Formik>
        {props.children}
      </Card.Body>
    </Card>
  );
}

export default BaseSettingsForm;
