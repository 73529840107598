import { createColumnHelper } from "@tanstack/react-table";
import i18next from "i18next";
import { Badge } from "react-bootstrap";
import { CellProps, Column } from "react-table";

import { PbdStatus } from "@generatedCode/pbd-core/pbd-core-api";

import { ValidationResult } from "../../../../../pbdServices/Models/Shared/validation-result";
import IsPrivateIcon from "../../icons/isPrivateIcon";
import { qmBaseIcons } from "../../icons/qmBaseIcons";
import StatusIconComponent from "../../status/statusIcon";
import WarningTableCell from "../cells/warningTableCell";
import LinkRenderer from "./linkRenderer";

interface IProps {
  title: string;
  status?: PbdStatus;
  isPrivate?: boolean;
  id: number | string;
  /**This will calculated on the client */
  warnings?: ValidationResult[];
  /**React link */
  linkTo?: string;
  isDeleted?: boolean;
  color?: string;
}

/**
 * The is private Icon will only be rendered for private entries on purpose
 * @param includeTitleLink
 * @param titleColumnBaseLink
 * @returns
 */
export function getTitleColumn<T extends IProps>(includeTitleLink = true, titleColumnBaseLink?: string) {
  const column: Column<T> = {
    id: "title",
    Header: <>{i18next.t("Title")}</>,
    accessor: "title",
    Cell: ({ row }: CellProps<T>) => (
      <>
        {row.original.status && <StatusIconComponent status={row.original.status} className="me-1" />}
        {row.original.color && <qmBaseIcons.Circle style={{ color: row.original.color }} className="me-1" />}
        <LinkRenderer
          renderAsLink={includeTitleLink}
          titleColumnBaseLink={titleColumnBaseLink}
          id={row.original.id}
          title={row.original.title}
          linkTo={row.original.linkTo}
        />
        {row.original.isPrivate && <IsPrivateIcon isPrivate={row.original.isPrivate} className="ms-1 text-muted" />}
        <WarningTableCell warnings={row.original.warnings} />
        {row.original.isDeleted && (
          <Badge bg="danger" className="ms-1" pill title={i18next.t("Item deleted")}>
            <qmBaseIcons.Delete />
          </Badge>
        )}
      </>
    ),
  };
  return column;
}

const columnHelper = createColumnHelper<IProps>();

export const titleColumn = (hideTitleLink?: boolean, titleColumnBaseLink?: string) => {
  return columnHelper.accessor("title", {
    id: "title",
    header: () => <>{i18next.t("Title")}</>,
    cell: ({ row }) => (
      <>
        {row.original.status && <StatusIconComponent status={row.original.status} className="me-1" />}
        {row.original.color && <qmBaseIcons.Circle style={{ color: row.original.color }} className="me-1" />}
        <LinkRenderer
          renderAsLink={!hideTitleLink}
          titleColumnBaseLink={titleColumnBaseLink}
          id={row.original.id}
          title={row.original.title}
          linkTo={row.original.linkTo}
        />
        {row.original.isPrivate && <IsPrivateIcon isPrivate={row.original.isPrivate} className="ms-1 text-muted" />}
        <WarningTableCell warnings={row.original.warnings} />
        {row.original.isDeleted && (
          <Badge bg="danger" className="ms-1" pill title={i18next.t("Item deleted")}>
            <qmBaseIcons.Delete />
          </Badge>
        )}
      </>
    ),
  });
};
